const actions = {
  ORCHESTRATOR_GET_ALL_BEGIN: 'ORCHESTRATOR_GET_ALL_BEGIN',
  ORCHESTRATOR_GET_ALL_SUCCESS: 'ORCHESTRATOR_GET_ALL_SUCCESS',
  ORCHESTRATOR_GET_ALL_ERROR: 'ORCHESTRATOR_GET_ALL_ERROR',

  ORCHESTRATOR_CREATE_BEGIN: 'ORCHESTRATOR_CREATE_BEGIN',
  ORCHESTRATOR_CREATE_SUCCESS: 'ORCHESTRATOR_CREATE_SUCCESS',
  ORCHESTRATOR_CREATE_ERROR: 'ORCHESTRATOR_CREATE_ERROR',

  ORCHESTRATOR_FIND_ONE_BEGIN: 'ORCHESTRATOR_FIND_ONE_BEGIN',
  ORCHESTRATOR_FIND_ONE_SUCCESS: 'ORCHESTRATOR_FIND_ONE_SUCCESS',
  ORCHESTRATOR_FIND_ONE_ERROR: 'ORCHESTRATOR_FIND_ONE_ERROR',
  ORCHESTRATOR_FIND_ONE_RESET: 'BOT_SCHEDULE_FIND_ONE_RESET',

  ORCHESTRATOR_UPDATE_BEGIN: 'ORCHESTRATOR_UPDATE_BEGIN',
  ORCHESTRATOR_UPDATE_SUCCESS: 'ORCHESTRATOR_UPDATE_SUCCESS',
  ORCHESTRATOR_UPDATE_ERROR: 'ORCHESTRATOR_UPDATE_ERROR',

  ORCHESTRATOR_REMOVE_BEGIN: 'ORCHESTRATOR_REMOVE_BEGIN',
  ORCHESTRATOR_REMOVE_SUCCESS: 'ORCHESTRATOR_REMOVE_SUCCESS',
  ORCHESTRATOR_REMOVE_ERROR: 'ORCHESTRATOR_REMOVE_ERROR',

  ORCHESTRATOR_DOWNLOAD_BEGIN: 'ORCHESTRATOR_DOWNLOAD_BEGIN',
  ORCHESTRATOR_DOWNLOAD_SUCCESS: 'ORCHESTRATOR_DOWNLOAD_SUCCESS',
  ORCHESTRATOR_DOWNLOAD_ERROR: 'ORCHESTRATOR_DOWNLOAD_ERROR',

  ORCHESTRATOR_DASHBOARD_BEGIN: 'ORCHESTRATOR_DASHBOARD_BEGIN',
  ORCHESTRATOR_DASHBOARD_SUCCESS: 'ORCHESTRATOR_DASHBOARD_SUCCESS',
  ORCHESTRATOR_DASHBOARD_ERROR: 'ORCHESTRATOR_DASHBOARD_ERROR',
  ORCHESTRATOR_DASHBOARD_RESET: 'ORCHESTRATOR_DASHBOARD_RESET',

  ORCHESTRATOR_DEACTIVATE_REDRAW_TABLE: 'ORCHESTRATOR_DEACTIVATE_REDRAW_TABLE',

  orchestratorGetAllBegin: () => {
    return {
      type: actions.ORCHESTRATOR_GET_ALL_BEGIN,
    };
  },

  orchestratorGetAllSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_GET_ALL_SUCCESS,
      data,
    };
  },

  orchestratorGetAllError: error => {
    return {
      type: actions.ORCHESTRATOR_GET_ALL_ERROR,
      error,
    };
  },

  orchestratorCreateBegin: () => {
    return {
      type: actions.ORCHESTRATOR_CREATE_BEGIN,
    };
  },

  orchestratorCreateSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_CREATE_SUCCESS,
      data,
    };
  },

  orchestratorCreateError: error => {
    return {
      type: actions.ORCHESTRATOR_CREATE_ERROR,
      error,
    };
  },

  orchestratorFindOneBegin: () => {
    return {
      type: actions.ORCHESTRATOR_FIND_ONE_BEGIN,
    };
  },

  orchestratorFindOneSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_FIND_ONE_SUCCESS,
      data,
    };
  },

  orchestratorFindOneError: error => {
    return {
      type: actions.ORCHESTRATOR_FIND_ONE_ERROR,
      error,
    };
  },

  orchestratorFindOneReset: () => {
    return {
      type: actions.ORCHESTRATOR_FIND_ONE_RESET,
    };
  },

  orchestratorUpdateBegin: () => {
    return {
      type: actions.ORCHESTRATOR_UPDATE_BEGIN,
    };
  },

  orchestratorUpdateSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_UPDATE_SUCCESS,
      data,
    };
  },

  orchestratorUpdateError: error => {
    return {
      type: actions.ORCHESTRATOR_UPDATE_ERROR,
      error,
    };
  },

  orchestratorRemoveBegin: () => {
    return {
      type: actions.ORCHESTRATOR_REMOVE_BEGIN,
    };
  },

  orchestratorRemoveSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_REMOVE_SUCCESS,
      data,
    };
  },

  orchestratorRemoveError: error => {
    return {
      type: actions.ORCHESTRATOR_REMOVE_ERROR,
      error,
    };
  },

  orchestratorDownloadBegin: () => {
    return {
      type: actions.ORCHESTRATOR_DOWNLOAD_BEGIN,
    };
  },

  orchestratorDownloadSuccess: () => {
    return {
      type: actions.ORCHESTRATOR_DOWNLOAD_SUCCESS,
    };
  },

  orchestratorDownloadError: error => {
    return {
      type: actions.ORCHESTRATOR_DOWNLOAD_ERROR,
      error,
    };
  },

  orchestratorDashboardBegin: () => {
    return {
      type: actions.ORCHESTRATOR_DASHBOARD_BEGIN,
    };
  },

  orchestratorDashboardSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_DASHBOARD_SUCCESS,
      data,
    };
  },

  orchestratorDashboardError: error => {
    return {
      type: actions.ORCHESTRATOR_DASHBOARD_ERROR,
      error,
    };
  },

  orchestratorDashboardReset: () => {
    return {
      type: actions.ORCHESTRATOR_DASHBOARD_RESET,
    };
  },

  orchestratorDeactivateReDraw: () => {
    return {
      type: actions.ORCHESTRATOR_DEACTIVATE_REDRAW_TABLE,
    };
  },
};

export default actions;
