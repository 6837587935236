const actions = {
  FORGOT_PASSWORD_BEGIN: 'FORGOT_PASSWORD_BEGIN',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERR: 'FORGOT_PASSWORD_ERR',

  forgotPasswordBegin: () => {
    return {
      type: actions.FORGOT_PASSWORD_BEGIN,
    };
  },

  forgotPasswordSuccess: data => {
    return {
      type: actions.FORGOT_PASSWORD_SUCCESS,
      data,
    };
  },

  forgotPasswordErr: err => {
    return {
      type: actions.FORGOT_PASSWORD_ERR,
      err,
    };
  },
};

export default actions;
