import { gql } from '@apollo/client';

export const QUERY_WORK_FLOW_FIND_ALL = gql`
  query workFlowFindAll($tenantId: String!) {
    workFlowFindAll(tenantId: $tenantId) {
      success
      code
      data {
        _id
        name
        connection {
          _id
        }
      }
    }
  }
`;

export const QUERY_WORK_FLOW_CREATE = gql`
  mutation workFlowCreate($workFlowCreateInput: WorkFlowCreateInput!) {
    workFlowCreate(workFlowCreateInput: $workFlowCreateInput) {
      success
      code
      data {
        _id
        name
      }
    }
  }
`;

export const QUERY_WORK_FLOW_FIND_ONE = gql`
  query workFlowFindOne($id: String!) {
    workFlowFindOne(id: $id) {
      success
      code
      data {
        _id
        name
        description
        nodes {
          nodeId
          name
          type
          description
          nextNode
          position {
            x
            y
          }
          botLicenses {
            _id
            bot {
              _id
              name
            }
          }
        }
      }
    }
  }
`;

export const QUERY_WORK_FLOW_UPDATE = gql`
  mutation workFlowUpdate($workFlowUpdateInput: WorkFlowUpdateInput!) {
    workFlowUpdate(workFlowUpdateInput: $workFlowUpdateInput) {
      success
      code
      data {
        _id
        name
      }
    }
  }
`;

export const QUERY_WORK_FLOW_REMOVE = gql`
  mutation workFlowRemove($id: String!) {
    workFlowRemove(id: $id) {
      success
      code
      data {
        _id
        name
      }
    }
  }
`;

export const QUERY_WORK_FLOW_START = gql`
  mutation workFlowStart($id: String!) {
    workFlowStart(workFlowId: $id) {
      success
      code
    }
  }
`;
