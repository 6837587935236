import { gql } from '@apollo/client';

export const QUERY_CUSTOM_REPORT_FIND_ALL = gql`
  query customReportFindAll($botId: String!) {
    customReportFindAll(botId: $botId) {
      success
      code
      data {
        _id
        displayName
        enabled
        createdAt
      }
    }
  }
`;

export const QUERY_CUSTOM_REPORT_FIND_ONE = gql`
  query customReportFindOne($id: String!) {
    customReportFindOne(id: $id) {
      success
      code
      data {
        _id
        displayName
        enabled
        createdAt
      }
    }
  }
`;

export const QUERY_CUSTOM_REPORT_REMOVE = gql`
  mutation customReportRemove($id: String!) {
    customReportRemove(id: $id) {
      success
      code
      data {
        _id
      }
    }
  }
`;

export const QUERY_CUSTOM_REPORT_CREATE = gql`
  mutation customReportCreate($input: CustomReportCreateInput!) {
    customReportCreate(customReportCreateInput: $input) {
      success
      code
      data {
        _id
        displayName
        enabled
        createdAt
      }
    }
  }
`;

export const QUERY_CUSTOM_REPORT_UPDATE = gql`
  mutation customReportUpdate($input: CustomReportUpdateInput!) {
    customReportUpdate(customReportUpdateInput: $input) {
      success
      code
      data {
        _id
        displayName
        enabled
        createdAt
      }
    }
  }
`;

export const QUERY_CUSTOM_REPORT_INFO_FIND_ALL = gql`
  query customReportInfoFindAll($input: TransactionFindAllInput!) {
    customReportInfoFindAll(transactionFindAllInput: $input) {
      success
      code
      data {
        _id
        customReport {
          _id
          displayName
        }
        content
        licenseSerial
        computerInfo {
          name
          domain
          os
          ram
          disk
          arquitecture
        }
        createdLocalAt
        createdAt
        updatedAt
      }
    }
  }
`;
