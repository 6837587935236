import React from 'react';
import { Row, Col } from 'antd';
import { Aside } from './style';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={0}>
          <Aside>
            <img src={require('../../static/img/auth/Banner.jpg')} alt="" className="auth-side-content" />
          </Aside>
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
