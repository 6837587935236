import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import FeatherIcon from 'feather-icons-react';
import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useParams, Redirect, Route, Switch } from 'react-router-dom';
import { Form, Row, Col, Spin, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { AddUser } from './style';
import actions from '../../../redux/user/actions';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import {
  QUERY_RESOURCE_GROUP_FIND_ALL,
  QUERY_RESOURCE_GROUP_FIND_ONE,
  QUERY_USER_FIND_ONE,
  QUERY_USER_UPDATE,
} from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const {
  userFindOneBegin,
  userFindOneSuccess,
  userFindOneError,
  userUpdateBegin,
  userUpdateSuccess,
  userUpdateError,
} = actions;

const UserCreateInfo = lazy(() => import('./UserCreateInfo'));
const UserCreatePermissions = lazy(() => import('./UserCreatePermissions'));

const UserEdit = ({ match }) => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userLoading = useSelector(state => state.user.loading);
  const user = useSelector(state => state.user.user);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [submitted, setSubmitted] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [resetFields, setResetFields] = useState(false);
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [userUpdate] = useMutation(QUERY_USER_UPDATE);
  const { userId } = useParams();
  const { data: dataResourceGroupFindAll } = useQuery(QUERY_RESOURCE_GROUP_FIND_ALL, {
    variables: {
      tenantId: currentTenant?.tenant._id,
    },
    fetchPolicy: 'network-only',
  });
  const [resourceGroupFindOne, { data: dataResourceGroupFindOne, loading: loadingResourceGroup }] = useLazyQuery(
    QUERY_RESOURCE_GROUP_FIND_ONE,
    {
      fetchPolicy: 'network-only',
    },
  );
  const { data, loading, error } = useQuery(QUERY_USER_FIND_ONE, {
    variables: { id: userId || '' },
    fetchPolicy: 'network-only',
  });
  const [state, setState] = useState({
    form: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: {
        phone: '',
        code: 0,
      },
      plataformaModelerLicense: false,
      userEnabled: false,
      userLicense: '',
      userPermissions: [],
      resourceGroup: null,
    },
  });
  const [permissions, setPermissions] = useState({
    bot: [],
    file: [],
    license: [],
    notification: [],
    tenant: [],
    transaction: [],
    user: [],
    workFlow: [],
  });

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1'),
    types: {
      email: t('type.email')
    }
  };
  /* eslint-enable */

  const handleNextStep = () => {
    const phoneErrors = form1.getFieldsError(['phone']);
    if (phoneErrors[0].errors.length > 0) return;
    setIsCompleted(true);
    history.push(`${match.url}/permissions`);
  };

  const handleResetFields = () => {
    setState({
      form: {
        ...state.form,
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phone: {
          phone: '',
          code: 0,
        },
      },
    });
    setIsCompleted(false);
    setResetFields(true);
  };

  const userNotFound = useCallback(
    codeError => {
      notificationError(t(`codeResponse.${codeError}`));
      history.push(`/admin/user/`);
    },
    [history, t],
  );

  const updateForm = useCallback(
    userFound => {
      form1.setFieldsValue({
        name: userFound.name,
        email: userFound.email,
        userEnabled: userFound.enabled,
        resourceGroup: userFound.mainTenant.resourceGroup.enabled ? userFound.mainTenant.resourceGroup._id : null,
        userLicense: userFound.mainTenant.userLicense,
      });
      setState(x => ({
        form: {
          ...x.form,
          name: userFound.name,
          email: userFound.email,
          phone: {
            phone: userFound.phoneNumber ? userFound.phoneNumber.toString() : '',
            code: userFound.phoneCode ? userFound.phoneCode : 0,
          },
          userEnabled: userFound.enabled,
          resourceGroup: userFound.mainTenant.resourceGroup.enabled ? userFound.mainTenant.resourceGroup : null,
          userLicense: userFound.mainTenant.userLicense,
          userPermissions: userFound.mainTenant.userPermissions,
          plataformaModelerLicense: userFound.mainTenant.plataformaModelerLicense === 'Enterprise',
        },
      }));
      setPermissions({
        bot: userFound.mainTenant.userPermissions?.filter(x => x.startsWith('Bot') && !x.startsWith('BotExecution')),
        file: userFound.mainTenant.userPermissions?.filter(x => x.startsWith('File')),
        license: userFound.mainTenant.userPermissions?.filter(x => x.startsWith('License')),
        notification: userFound.mainTenant.userPermissions?.filter(
          x => x.startsWith('MailNotification') || x.startsWith('WhatsappNotification'),
        ),
        tenant: userFound.mainTenant.userPermissions?.filter(x => x.startsWith('Tenant')),
        transaction: userFound.mainTenant.userPermissions?.filter(
          x => x.startsWith('BotExecution') || x.startsWith('CustomReport') || x.startsWith('Realtime'),
        ),
        user: userFound.mainTenant.userPermissions?.filter(x => x.startsWith('User')),
        workFlow: userFound.mainTenant.userPermissions?.filter(x => x.startsWith('WorkFlow')),
      });
    },
    [form1],
  );

  useEffect(() => {
    if (
      !currentLoading &&
      (!currentTenant?.userPermissions?.includes(UserPermissions.UserFindOne) ||
        !currentTenant?.userPermissions?.includes(UserPermissions.UserUpdate))
    ) {
      history.push('/admin/user');
      notificationError(t(`codeResponse.403`));
    }

    dispatch(userFindOneBegin());

    if (!loading && data) {
      if (data.userFindOne.success) {
        dispatch(userFindOneSuccess(data.userFindOne.data));
        updateForm(data.userFindOne.data);
      } else {
        dispatch(userFindOneError(error));
        userNotFound(data.userFindOne.code);
      }
    }
  }, [dispatch, data, loading, error, currentTenant, currentLoading, history, t, updateForm, userNotFound]);

  const handleSubmit = useCallback(() => {
    dispatch(userUpdateBegin());

    userUpdate({
      variables: {
        userUpdateInput: {
          _id: user._id,
          name: state.form.name,
          email: state.form.email,
          ...(state.form.password && { password: state.form.password }),
          phoneNumber: state.form.phone.phone !== '' ? parseInt(state.form.phone.phone) : null,
          phoneCode: state.form.phone.code !== '' ? parseInt(state.form.phone.code) : null,
          enabled: state.form.userEnabled,
          mainTenant: {
            userLicense: state.form.userLicense,
            resourceGroup: state.form.resourceGroup._id,
            plataformaModelerLicense: state.form.plataformaModelerLicense ? 'Enterprise' : 'None',
            userPermissions: state.form.userPermissions,
          },
        },
      },
    })
      .then(({ data }) => {
        if (data.userUpdate.success === true) {
          dispatch(userUpdateSuccess(data.userUpdate.data));
          notificationSuccess(t('labels.backend.access.users.success_edited'));
          history.push(`/admin/user/`);
        } else {
          dispatch(userUpdateError(data.userUpdate.code));
          notificationError(t(`codeResponse.${data.userUpdate.code}`));
        }
      })
      .catch(e => {
        console.log('User Edit Error ', e);
        dispatch(userUpdateError());

        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
      });
  }, [state.form, dispatch, history, t, user._id, userUpdate]);

  const handleStatusSwitch = e => {
    setIsCompleted(false);
    setState({
      form: {
        ...state.form,
        userEnabled: e,
      },
    });
  };

  const handleChangeSelect = e => {
    setState({
      form: {
        ...state.form,
        userLicense: e,
      },
    });
  };

  const handleChangePhone = ({ phone, code }) => {
    setIsCompleted(false);
    if (!code || phone.length !== 10 || !/^\d+$/.test(phone)) {
      form1.setFields([
        {
          name: 'phone',
          errors: [t('labels.backend.access.users.length_phonenumber')],
        },
      ]);
    } else {
      form1.setFields([
        {
          name: 'phone',
          errors: [],
        },
      ]);
    }
    setState({
      form: {
        ...state.form,
        phone: {
          phone,
          code,
        },
      },
    });
  };

  const handleChange = e => {
    setIsCompleted(false);
    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleChangeResourceGroup = id => {
    resourceGroupFindOne({
      variables: {
        id,
      },
    });
  };

  useEffect(() => {
    if (submitted) {
      handleSubmit();
      setSubmitted(false);
    }
  }, [submitted, handleSubmit]);

  useEffect(() => {
    if (resetFields) {
      form1.resetFields();
      setResetFields(false);
    }
  }, [resetFields, form1]);

  useEffect(() => {
    if (dataResourceGroupFindOne?.resourceGroupFindOne.success) {
      const resourceGroupFound = dataResourceGroupFindOne?.resourceGroupFindOne;
      setState(x => ({
        form: {
          ...x.form,
          resourceGroup: resourceGroupFound.data,
          plataformaModelerLicense: false,
          userLicense: null,
        },
      }));
      form2.setFieldsValue({
        userLicense: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataResourceGroupFindOne]);

  if (userLoading) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <PageHeader ghost title={t('labels.backend.access.users.edit')} />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/admin/user`}>
                <span>{t('labels.backend.access.users.management')}</span>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">
                <NavLink to="#">{t('labels.backend.access.users.edit')}</NavLink>
              </NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24}>
            <AddUser>
              <Cards
                title={
                  <div>
                    <div className="card-nav">
                      <ul>
                        <li>
                          <NavLink to={`${match.url}/info`}>
                            <FeatherIcon icon="user" size={14} />
                            {t('personalInfo')}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink disabled={!isCompleted} to={`${match.url}/permissions`}>
                            <FeatherIcon icon="briefcase" size={14} />
                            {t('permissions')}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                }
              >
                <Switch>
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >
                    <Redirect exact from={`${match.url}`} to={`${match.url}/info`} />
                    <Route
                      exact
                      path={`${match.url}/info`}
                      render={() => (
                        <UserCreateInfo
                          action="update"
                          form={form1}
                          state={state}
                          path={match.url}
                          validateMessages={validateMessages}
                          handleChangePhone={handleChangePhone}
                          handleChange={handleChange}
                          handleStatusSwitch={handleStatusSwitch}
                          handleNextStep={handleNextStep}
                          handleResetFields={handleResetFields}
                        />
                      )}
                    />
                    {isCompleted && (
                      <Route
                        exact
                        path={`${match.url}/permissions`}
                        render={() => (
                          <UserCreatePermissions
                            action="update"
                            resourceGroups={dataResourceGroupFindAll?.resourceGroupFindAll.data.filter(x => x.enabled)}
                            loadingResourceGroup={loadingResourceGroup}
                            form={form2}
                            state={state}
                            userLoading={userLoading}
                            validateMessages={validateMessages}
                            permissions={permissions}
                            initialStateTenantDetail={data?.userFindOne.data.mainTenant}
                            handleChangeSelect={handleChangeSelect}
                            setSubmitted={setSubmitted}
                            setState={setState}
                            setPermissions={setPermissions}
                            handleChangeResourceGroup={handleChangeResourceGroup}
                          />
                        )}
                      />
                    )}
                  </Suspense>
                </Switch>
              </Cards>
            </AddUser>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default UserEdit;
