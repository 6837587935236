import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { Table, Button, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { QUERY_USER_FIND_ALL, QUERY_USER_RELATED_TENANT_REMOVE, QUERY_USER_REMOVE } from '../../../queries';
import { timestampToDate } from '../../../components/utilities/utilities';
import { alertModal } from '../../../components/modals/antd-modals';
import { TableWrapper, UserTableStyleWrapper } from '../styled';
import actions from '../../../redux/user/actions';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

const {
  userRemoveBegin,
  userRemoveSuccess,
  userRemoveError,
  userFindAllBegin,
  userFindAllSuccess,
  userFindAllError,
} = actions;

const UserList = () => {
 const darkmode = useSelector(state => state.changeLayoutMode.data);
  const { t } = useTranslation();
  const loadingUsers = useSelector(state => state.user.loading);
  const users = useSelector(state => state.user.usersFiltered);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const currentUser = useSelector(state => state.currentUserTenant.currentUser);
  const history = useHistory();
  const typeViewUser = 'View';
  const typeEditUser = 'Edit';
  const typeEditGuestUser = 'EditGuestUser';
  const dispatch = useDispatch();
  const [userRemove] = useMutation(QUERY_USER_REMOVE);
  const [userRelatedTenantRemove] = useMutation(QUERY_USER_RELATED_TENANT_REMOVE);
  const [mounted, setMounted] = useState(false);
  const [state, setState] = useState({
    userDataTable: [],
  });
  const { loading, data, error } = useQuery(QUERY_USER_FIND_ALL, {
    variables: {
      tenantId: currentTenant?.tenant?._id,
    },
    fetchPolicy: 'network-only',
  });

  const handleActionTable = useCallback(
    (user, type) => {
      switch (type) {
        case typeViewUser:
          history.push(`/admin/user/view/${user._id}`);
          break;
        case typeEditUser:
          history.push(`/admin/user/edit/${user._id}`);
          break;
        case typeEditGuestUser:
          history.push(`/admin/user/guest/edit/${user._id}`);
          break;
        default:
          break;
      }
    },
    [history],
  );

  const handleUserRelatedTenantRemove = useCallback(
    id => {
      alertModal.confirm({
        title: t('user.confirmRemoveGuest'),
        onOk() {
          dispatch(userRemoveBegin());

          userRelatedTenantRemove({
            variables: {
              input: {
                _id: id,
                tenantId: currentTenant?.tenant._id,
              },
            },
          })
            .then(({ data }) => {
              if (data.userRelatedTenantRemove.success) {
                dispatch(userRemoveSuccess(data.userRelatedTenantRemove.data._id));
                notificationSuccess(t('user.successRemovedGuest'));
              } else {
                dispatch(userRemoveError(data.userRelatedTenantRemove.code));
                notificationError(t(`codeResponse.${data.userRelatedTenantRemove.code}`));
              }
            })
            .catch(e => {
              console.log('User Guest Removed Error ', e);
              dispatch(userRemoveError());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [dispatch, t, currentTenant?.tenant?._id, userRelatedTenantRemove],
  );

  const deleteUser = useCallback(
    id => {
      alertModal.confirm({
        title: t('labels.backend.access.users.confirm_delete'),
        onOk() {
          dispatch(userRemoveBegin());

          userRemove({
            variables: { id },
          })
            .then(({ data }) => {
              if (data.userRemove.success) {
                dispatch(userRemoveSuccess(data.userRemove.data._id));
                notificationSuccess(t('labels.backend.access.users.success_deleted'));
              } else {
                dispatch(userRemoveError(data.userRemove.code));
                notificationError(t(`codeResponse.${data.userRemove.code}`));
              }
            })
            .catch(e => {
              console.log('User Delete Error ', e);
              dispatch(userRemoveError());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [dispatch, userRemove, t],
  );

  useEffect(() => {
    if (users?.length > 0) {
      setState({
        userDataTable: users.map((user, index) => {
          const { name, email, createdAt, enabled, mainTenant } = user;
          const textClassName = enabled ? 'active' : 'blocked';
          return {
            key: user._id,
            name,
            email,
            license:
              mainTenant.tenant?._id !== currentTenant?.tenant._id
                ? user.relatedTenants[0].userLicense === 'Pro'
                  ? 'Plus'
                  : user.relatedTenants[0].userLicense
                : mainTenant.userLicense === 'Pro'
                ? 'Plus'
                : mainTenant.userLicense,
            createdAt: timestampToDate(createdAt),
            guest: mainTenant.tenant?._id !== currentTenant?.tenant._id ? t('general.yes') : t('general.no'),
            statusValue: enabled,
            status: (
              <span className={`status-text ${textClassName}`}>
                {enabled ? t('general.active') : t('general.inactive')}
              </span>
            ),
            actions: (
              <div key={index} className="table-actions">
                <>
                  <Button
                    className="btn-icon"
                    type="info"
                    shape="circle"
                    onClick={() =>
                      currentTenant?.tenant._id === mainTenant.tenant?._id
                        ? handleActionTable(user, typeEditUser)
                        : handleActionTable(user, typeEditGuestUser)
                    }
                  >
                    <FeatherIcon icon="edit" size={16} />
                  </Button>
                  {currentUser?._id !== user._id && (
                    <Button
                      className="btn-icon"
                      type="danger"
                      shape="circle"
                      onClick={() =>
                        currentTenant?.tenant._id === mainTenant.tenant?._id
                          ? deleteUser(user._id)
                          : handleUserRelatedTenantRemove(user._id)
                      }
                    >
                      <FeatherIcon icon="trash-2" size={16} />
                    </Button>
                  )}
                </>
              </div>
            ),
          };
        }),
      });
    } else {
      setState({
        userDataTable: [],
      });
    }
  }, [users, handleActionTable, currentUser, currentTenant, t, deleteUser, handleUserRelatedTenantRemove]);

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.UserFindAll)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted && data) {
      dispatch(userFindAllBegin());
      if (data.userFindAll.success) {
        dispatch(userFindAllSuccess(data.userFindAll.data));
      } else {
        dispatch(userFindAllError(data.userFindAll.code));
        history.push('/admin');
        notificationError(t(`codeResponse.${data.userFindAll.code}`));
      }
      setMounted(true);
    }
  }, [dispatch, data, loading, error, history, currentTenant, currentLoading, mounted, t]);

  const columns = [
    {
      title: t('labels.backend.access.users.table.name'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: t('labels.backend.access.users.table.email'),
      dataIndex: 'email',
      key: 'email',
      width: '25%',
    },
    {
      title: t('labels.backend.access.users.license'),
      dataIndex: 'license',
      key: 'license',
      width: '10%',
    },
    {
      title: t('labels.backend.access.users.table.created'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
    },
    {
      title: t('general.guest'),
      dataIndex: 'guest',
      key: 'guest',
      width: '5%',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      sorter: (a, b) => {
        if (a.statusValue) return 1;
        if (b.statusValue) return -1;
        return 0;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
    },
  ]
  
  if (!loadingUsers && mounted) {
    return (
      <>
        <div className="table-bordered leaderboard-table table-responsive">
          <UserTableStyleWrapper darkmode={darkmode}>
            <TableWrapper className="table-responsive" darkmode={darkmode}>
              <Table
              darkmode={darkmode}
                columns={columns}
                dataSource={state.userDataTable}
                pagination={{
                  defaultPageSize: 10,
                  total: state.userDataTable?.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
                }}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </div>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default UserList;
