const actions = {
  BOT_VAULT_GET_ALL_BEGIN: 'BOT_VAULT_GET_ALL_BEGIN',
  BOT_VAULT_GET_ALL_SUCCESS: 'BOT_VAULT_GET_ALL_SUCCESS',
  BOT_VAULT_GET_ALL_ERROR: 'BOT_VAULT_GET_ALL_ERROR',

  BOT_VAULT_CREATE_BEGIN: 'BOT_VAULT_CREATE_BEGIN',
  BOT_VAULT_CREATE_SUCCESS: 'BOT_VAULT_CREATE_SUCCESS',
  BOT_VAULT_CREATE_ERROR: 'BOT_VAULT_CREATE_ERROR',

  BOT_VAULT_UPDATE_BEGIN: 'BOT_VAULT_UPDATE_BEGIN',
  BOT_VAULT_UPDATE_SUCCESS: 'BOT_VAULT_UPDATE_SUCCESS',
  BOT_VAULT_UPDATE_ERROR: 'BOT_VAULT_UPDATE_ERROR',

  BOT_VAULT_DELETE_BEGIN: 'BOT_VAULT_DELETE_BEGIN',
  BOT_VAULT_DELETE_SUCCESS: 'BOT_VAULT_DELETE_SUCCESS',
  BOT_VAULT_DELETE_ERROR: 'BOT_VAULT_DELETE_ERROR',

  botVaultGetAllBegin: () => {
    return {
      type: actions.BOT_VAULT_GET_ALL_BEGIN,
    };
  },

  botVaultGetAllSuccess: data => {
    return {
      type: actions.BOT_VAULT_GET_ALL_SUCCESS,
      data,
    };
  },

  botVaultGetAllError: error => {
    return {
      type: actions.BOT_VAULT_GET_ALL_ERROR,
      error,
    };
  },

  botVaultCreateBegin: () => {
    return {
      type: actions.BOT_VAULT_CREATE_BEGIN,
    };
  },

  botVaultCreateSuccess: data => {
    return {
      type: actions.BOT_VAULT_CREATE_SUCCESS,
      data,
    };
  },

  botVaultCreateError: error => {
    return {
      type: actions.BOT_VAULT_CREATE_ERROR,
      error,
    };
  },

  botVaultUpdateBegin: () => {
    return {
      type: actions.BOT_VAULT_UPDATE_BEGIN,
    };
  },

  botVaultUpdateSuccess: data => {
    return {
      type: actions.BOT_VAULT_UPDATE_SUCCESS,
      data,
    };
  },

  botVaultUpdateError: error => {
    return {
      type: actions.BOT_VAULT_UPDATE_ERROR,
      error,
    };
  },

  botVaultDeleteBegin: () => {
    return {
      type: actions.BOT_VAULT_DELETE_BEGIN,
    };
  },

  botVaultDeleteSuccess: data => {
    return {
      type: actions.BOT_VAULT_DELETE_SUCCESS,
      data,
    };
  },

  botVaultDeleteError: error => {
    return {
      type: actions.BOT_VAULT_DELETE_ERROR,
      error,
    };
  },
};

export default actions;
