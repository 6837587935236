import { gql } from '@apollo/client';

export const QUERY_PRICING_FIND_ALL = gql`
  query pricingFindAll {
    pricingFindAll {
      success
      code
      data {
        _id
        name
        slug
      }
    }
  }
`;
