import { useSubscription } from '@apollo/client';
import React, { Suspense, useEffect } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Dashboard from './dashboard';
import Users from './users';
import Files from './files';
import Bots from './bots';
import Licenses from './licenses';
import BotLicenses from './bot-licenses';
import BotVault from './bot-vault';
import BotSchedule from './bot-schedule';
import CustomReports from './custom-reports';
import Reports from './reports';
import Microservices from './microservices';
import WorkFlows from './work-flows';
import OrchestratorRoutes from './orchestrator';
import OrchestratorStatesRoutes from './orchestrator-states';
import NotFound from '../../utils/404';
import IdleTimerContainer from '../../components/idleTimer/idleTimerComponent';
import AdminLayout from '../../layout/admin/AdminLayout';
import { USER_SUBSCRIPTION } from '../../queries';
import { notificationError, notificationSuccess } from '../../components/utilities/notification';
import email from '../../components/mail-notification/Email';
import TenantVaultManagement from '../../pages/admin/tenant-vault';

const Admin = () => {
  const { t } = useTranslation();
  const userId = useSelector(state => state.currentUserTenant.currentUser?._id);
  const currentUserLoading = useSelector(state => state.currentUserTenant.loading);
  const { path } = useRouteMatch();
  const { data, loading } = useSubscription(USER_SUBSCRIPTION, { variables: { userId } });

  useEffect(() => {
    if (!loading && data.userSubscription) {
      if (data.userSubscription.success) notificationSuccess(t(`codeResponse.${data.userSubscription.code}`));
      else notificationError(t(`codeResponse.${data.userSubscription.code}`));
    }
  }, [loading, data, t]);

  if (currentUserLoading)
    return (
      <div className="spin">
        <Spin />
      </div>
    );

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <IdleTimerContainer />
      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route path={`${path}/user`} component={Users} />
        <Route path={`${path}/email/:page`} component={email} />
        <Route path={`${path}/file`} component={Files} />
        <Route path={`${path}/license`} component={Licenses} />
        <Route path={`${path}/bot/license/:botId`} component={BotLicenses} />
        <Route path={`${path}/bot/vault/:botId`} component={BotVault} />
        <Route path={`${path}/bot/:botId/schedule`} component={BotSchedule} />
        <Route path={`${path}/bot/custom-report/:botId`} component={CustomReports} />
        <Route path={`${path}/bot`} component={Bots} />
        <Route path={`${path}/work-flow`} component={WorkFlows} />
        <Route path={`${path}/orchestrator/:orchestratorId/states`} component={OrchestratorStatesRoutes} />
        <Route path={`${path}/orchestrator`} component={OrchestratorRoutes} />
        <Route path={`${path}/report`} component={Reports} />
        <Route path={`${path}/microservices`} component={Microservices} />
        <Route path={`${path}/tenant/variables`} component={TenantVaultManagement} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default AdminLayout(Admin);
