const actions = {
  BOT_SCHEDULE_GET_ALL_BEGIN: 'BOT_SCHEDULE_GET_ALL_BEGIN',
  BOT_SCHEDULE_GET_ALL_SUCCESS: 'BOT_SCHEDULE_GET_ALL_SUCCESS',
  BOT_SCHEDULE_GET_ALL_ERROR: 'BOT_SCHEDULE_GET_ALL_ERROR',

  BOT_SCHEDULE_CREATE_BEGIN: 'BOT_SCHEDULE_CREATE_BEGIN',
  BOT_SCHEDULE_CREATE_SUCCESS: 'BOT_SCHEDULE_CREATE_SUCCESS',
  BOT_SCHEDULE_CREATE_ERROR: 'BOT_SCHEDULE_CREATE_ERROR',

  BOT_SCHEDULE_FIND_ONE_RESET: 'BOT_SCHEDULE_FIND_ONE_RESET',
  BOT_SCHEDULE_FIND_ONE_SUCCESS: 'BOT_SCHEDULE_FIND_ONE_SUCCESS',

  BOT_SCHEDULE_UPDATE_BEGIN: 'BOT_SCHEDULE_UPDATE_BEGIN',
  BOT_SCHEDULE_UPDATE_SUCCESS: 'BOT_SCHEDULE_UPDATE_SUCCESS',
  BOT_SCHEDULE_UPDATE_ERROR: 'BOT_SCHEDULE_UPDATE_ERROR',

  BOT_SCHEDULE_TOGGLE_BEGIN: 'BOT_SCHEDULE_TOGGLE_BEGIN',
  BOT_SCHEDULE_TOGGLE_SUCCESS: 'BOT_SCHEDULE_TOGGLE_SUCCESS',
  BOT_SCHEDULE_TOGGLE_ERROR: 'BOT_SCHEDULE_TOGGLE_ERROR',

  BOT_SCHEDULE_REMOVE_BEGIN: 'BOT_SCHEDULE_REMOVE_BEGIN',
  BOT_SCHEDULE_REMOVE_SUCCESS: 'BOT_SCHEDULE_REMOVE_SUCCESS',
  BOT_SCHEDULE_REMOVE_ERROR: 'BOT_SCHEDULE_REMOVE_ERROR',

  BOT_SCHEDULE_GET_SERVER_INFO_BEGIN: 'BOT_SCHEDULE_GET_SERVER_INFO_BEGIN',
  BOT_SCHEDULE_GET_SERVER_INFO_SUCCESS: 'BOT_SCHEDULE_GET_SERVER_INFO_SUCCESS',
  BOT_SCHEDULE_GET_SERVER_INFO_ERROR: 'BOT_SCHEDULE_GET_SERVER_INFO_ERROR',
  BOT_SCHEDULE_RESET_SERVER_INFO: 'BOT_SCHEDULE_RESET_SERVER_INFO',

  BOT_SCHEDULE_DEACTIVATE_REDRAW_TABLE: 'BOT_SCHEDULE_DEACTIVATE_REDRAW_TABLE',

  botScheduleGetAllBegin: () => {
    return {
      type: actions.BOT_SCHEDULE_GET_ALL_BEGIN,
    };
  },

  botScheduleGetAllSuccess: data => {
    return {
      type: actions.BOT_SCHEDULE_GET_ALL_SUCCESS,
      data,
    };
  },

  botScheduleGetAllError: error => {
    return {
      type: actions.BOT_SCHEDULE_GET_ALL_ERROR,
      error,
    };
  },

  botScheduleCreateBegin: () => {
    return {
      type: actions.BOT_SCHEDULE_CREATE_BEGIN,
    };
  },

  botScheduleCreateSuccess: data => {
    return {
      type: actions.BOT_SCHEDULE_CREATE_SUCCESS,
      data,
    };
  },

  botScheduleCreateError: error => {
    return {
      type: actions.BOT_SCHEDULE_CREATE_ERROR,
      error,
    };
  },

  botScheduleFindOneSuccess: data => {
    return {
      type: actions.BOT_SCHEDULE_FIND_ONE_SUCCESS,
      data,
    };
  },

  botScheduleFindOneReset: () => {
    return {
      type: actions.BOT_SCHEDULE_FIND_ONE_RESET,
    };
  },

  botScheduleUpdateBegin: () => {
    return {
      type: actions.BOT_SCHEDULE_UPDATE_BEGIN,
    };
  },

  botScheduleUpdateSuccess: data => {
    return {
      type: actions.BOT_SCHEDULE_UPDATE_SUCCESS,
      data,
    };
  },

  botScheduleUpdateError: error => {
    return {
      type: actions.BOT_SCHEDULE_UPDATE_ERROR,
      error,
    };
  },

  botScheduleToggleBegin: () => {
    return {
      type: actions.BOT_SCHEDULE_TOGGLE_BEGIN,
    };
  },

  botScheduleToggleSuccess: data => {
    return {
      type: actions.BOT_SCHEDULE_TOGGLE_SUCCESS,
      data,
    };
  },

  botScheduleToggleError: error => {
    return {
      type: actions.BOT_SCHEDULE_TOGGLE_ERROR,
      error,
    };
  },

  botScheduleRemoveBegin: () => {
    return {
      type: actions.BOT_SCHEDULE_REMOVE_BEGIN,
    };
  },

  botScheduleRemoveSuccess: data => {
    return {
      type: actions.BOT_SCHEDULE_REMOVE_SUCCESS,
      data,
    };
  },

  botScheduleRemoveError: error => {
    return {
      type: actions.BOT_SCHEDULE_REMOVE_ERROR,
      error,
    };
  },

  botScheduleDeactivateReDraw: () => {
    return {
      type: actions.BOT_SCHEDULE_DEACTIVATE_REDRAW_TABLE,
    };
  },

  botScheduleGetServerInfoBegin: () => {
    return {
      type: actions.BOT_SCHEDULE_GET_SERVER_INFO_BEGIN,
    };
  },

  botScheduleGetServerInfoSuccess: data => {
    return {
      type: actions.BOT_SCHEDULE_GET_SERVER_INFO_SUCCESS,
      data,
    };
  },

  botScheduleGetServerInfoError: error => {
    return {
      type: actions.BOT_SCHEDULE_GET_SERVER_INFO_ERROR,
      error,
    };
  },

  botScheduleResetServerInfo: () => {
    return {
      type: actions.BOT_SCHEDULE_RESET_SERVER_INFO,
    };
  },
};

export default actions;
