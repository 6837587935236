import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { Form, Row, Col, Button, Input, Upload, Checkbox, Breadcrumb } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import fileActions from '../../../redux/file/actions';
import { QUERY_FILE_CREATE } from '../../../queries';
import { formatBytes } from '../../../components/utilities/utilities';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';
const { fileCreateBegin, fileCreateSuccess, fileCreateError } = fileActions;

const FileCreate = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loadingFiles = useSelector(state => state.file.loading);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [form] = Form.useForm();
  const [fileCreate] = useMutation(QUERY_FILE_CREATE);
  const { TextArea } = Input;
  const [state, setState] = useState({
    form: {
      tenant: currentTenant?.tenant._id !== null ? currentTenant?.tenant._id : '',
      description: '',
      runVersion: '',
      sign: false,
      autoSync: false,
    },
  });
  const [datafile, setDataFile] = useState({
    size: 0,
    base64: '',
    name: '',
    error: false,
    errorMsg: '',
  });
  const maxSizeAllowed = 2097152;
  const mimeTypeAllowed =
    'application/x-zip-compressed, application/zip, application/x-rar-compressed, application/x-msdownload, application/x-dosexec';
  const alertSuccess = 'Success';
  const alertError = 'Error';

  const handleSubmit = async () => {
    if (datafile.base64 === '') {
      showAlertResult(t('labels.bussiness.rpa.files_shared.error_select_file'), alertError);
      return false;
    }

    if (datafile.error) {
      showAlertResult(datafile.errorMsg, alertError);
      return false;
    }

    dispatch(fileCreateBegin());
    // dispatch(currentTenantUpdateBegin());

    fileCreate({
      variables: {
        fileCreateInput: {
          name: datafile.name,
          file: datafile.base64,
          fileName: datafile.name,
          isPlataformaFile: false,
          size: datafile.size,
          sign: state.form.sign,
          description: state.form.description,
          runVersion: state.form.runVersion,
          tenant: currentTenant?.tenant._id,
          autoSync: state.form.autoSync,
          awsId: '',
        },
      },
    })
      .then(({ data }) => {
        if (data.fileCreate.success) {
          dispatch(fileCreateSuccess(data.fileCreate.data));

          showAlertResult(t('labels.bussiness.rpa.files_shared.success_upload'), alertSuccess);

          history.push('/admin/file');
        } else {
          dispatch(fileCreateError(data.fileCreate.code));

          showAlertResult(t(`codeResponse.${data.fileCreate.code}`), alertError);
        }
      })
      .catch(e => {
        console.log('File Create Error ', e);

        dispatch(fileCreateError());
        showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
      });
  };

  const beforeUpload = file => {
    let errorMsg = '';

    const errorTypeFile = mimeTypeAllowed.indexOf(file.type) > -1;
    if (!errorTypeFile) {
      errorMsg = t('labels.bussiness.rpa.files_shared.extension_not_allowed');
      showAlertResult(errorMsg, alertError);
    }

    const errorMaxSize = file.size <= maxSizeAllowed;
    if (!errorMaxSize) {
      errorMsg = t('labels.bussiness.rpa.files_shared.error_max_size', { value: formatBytes(maxSizeAllowed) });
      showAlertResult(errorMsg, alertError);
    }

    if (!errorMaxSize || !errorTypeFile) {
      setDataFile({
        ...datafile,
        error: true,
        errorMsg,
      });
    } else {
      setDataFile({
        ...datafile,
        error: false,
        errorMsg: '',
      });
    }

    return false;
  };

  // Captura base64 of File
  const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      setDataFile({
        ...datafile,
        error: true,
      });
    };
  };

  const handleChangeFile = info => {
    if (!datafile.error) {
      getBase64(info.file, imageUrl =>
        setDataFile({
          ...datafile,
          size: info.file.size,
          base64: imageUrl,
          name: info.file.name,
          error: false,
        }),
      );
    } else {
      setDataFile({
        ...datafile,
        base64: '',
        name: '',
      });
    }
  };

  const handleChange = e => {
    if (e.target.props?.name === 'description') e.target.name = 'description';
    if (e.target.type === 'checkbox') e.target.value = e.target.checked;
    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1'),
    types: {
      email: t('type.email')
    }
  };
  /* eslint-enable */

  const showAlertResult = (msg, type) => {
    if (type === alertError) notificationError(msg);
    else notificationSuccess(msg);
  };

  if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.FileCreate)) {
    history.push('/admin/file');
    notificationError(t(`codeResponse.403`));
    return null;
  }

  return (
    <>
      <PageHeader ghost title={t('file.create')} />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/admin/file`}>
                <span>{t('file.management')}</span>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">{t('file.create')} </NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24}>
            <Cards>
              <Form
                name="createFile"
                form={form}
                validateMessages={validateMessages}
                onFinish={handleSubmit}
                layout="vertical"
                size="middle"
                scrollToFirstError
              >
                <Row gutter={30}>
                  {/* File */}
                  <Col md={6} xs={24}>
                    <Form.Item label={t('labels.bussiness.rpa.files_shared.file')} rules={[{ required: true }]}>
                      <Upload
                        style={{ background: 'red' }}
                        name="file"
                        multiple={false}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleChangeFile}
                      >
                        <Button className="btn-outlined" size="large" type="light">
                          <UploadOutlined />
                          {datafile.name !== '' && !datafile.error
                            ? `${datafile.name.substring(0, 16)}...`
                            : t('labels.bussiness.rpa.files_shared.select')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>

                  {/* RunVersion */}
                  <Col md={6} xs={24}>
                    {/* Password */}
                    <Form.Item
                      name="runVersion"
                      rules={[{ required: true }]}
                      label={t('labels.bussiness.rpa.files_shared.table.version')}
                    >
                      <Input
                        name="runVersion"
                        onChange={handleChange}
                        value={state.form.runVersion}
                        placeholder={t('labels.bussiness.rpa.files_shared.table.version')}
                      />
                    </Form.Item>
                  </Col>

                  {/* Sign File */}
                  <Col md={6} xs={24}>
                    <Form.Item
                      name="sign"
                      valuePropName="checked"
                      label={t('labels.bussiness.rpa.files_shared.table.signFile')}
                    >
                      <Checkbox name="sign" onChange={handleChange}>
                        {t('labels.bussiness.rpa.files_shared.table.sign')}
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  {/* Auto Sync */}
                  <Col md={6} xs={24}>
                    <Form.Item
                      name="autoSync"
                      valuePropName="checked"
                      label={t('file.autoSync')}
                    >
                      <Checkbox name="autoSync" onChange={handleChange}>
                        {t('file.autoSync')}
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  {/* Description */}
                  <Col xs={12}>
                    <Form.Item
                      name="description"
                      label={t('labels.bussiness.rpa.files_shared.table.description')}
                    >
                      {/* <Input name="description" onChange={handleChange} value={state.form.description} placeholder={t('labels.bussiness.rpa.files_shared.table.description')} /> */}
                      <TextArea
                        name="description"
                        onChange={handleChange}
                        value={state.form.description}
                        placeholder={t('labels.bussiness.rpa.files_shared.table.description')}
                        className="sDash_unresizable"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button style={{ height: '44px' }} htmlType="submit" type="primary">
                    {loadingFiles ? t('loading.1') : t('general.create')}
                  </Button>
                </Form.Item>
              </Form>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default FileCreate;
