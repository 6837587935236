import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Row, Col, Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import BotLicenseList from './BotLicenseList';
import BotLicenseAssign from './BotLicenseAssign';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { Main } from '../styled';

const BotLicenseManagement = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;

  return (
    <>
      <PageHeader ghost title={t('botLicense.assigned')} />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/admin/bot`}>
                <span>{t('labels.bussiness.rpa.bots.title')}</span>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">{t('botLicense.assigned')}</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24}>
            <Cards
              isbutton={
                <div key="1" className="page-header-actions">
                  <Button className="btn-signin" size="small" key="2" type="primary" onClick={() => setShowModal(true)}>
                    <FeatherIcon icon="plus" size={14} />
                    {t('botLicense.add')}
                  </Button>
                </div>
              }
            >
              <BotLicenseList />
              <Modal
                type={'primary'}
                title={t('botLicense.add')}
                visible={showModal}
                style={{
                  top: 30,
                  background: '#000'
                }}
                width="80%"
                footer={null}
                onCancel={() => setShowModal(false)}
              >
                <BotLicenseAssign />
              </Modal>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default BotLicenseManagement;
