import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Spin, Table } from 'antd';

import { TableWrapper, UserTableStyleWrapper } from '../../pages/admin/styled';

import { AddUser } from '../../pages/admin/user/style';

const OrchestratorDashboardTable = ({ mounted, tableRedraw, orchestratorModel }) => {
  const { t } = useTranslation();

  const dashboardData = useSelector(state => state.orchestrator.dashboard);

  const tableData = useMemo(() => {
    const dataSource = [];

    if (dashboardData?.[0]?.total === 0) {
      return dataSource;
    }

    // eslint-disable-next-line no-unused-expressions
    dashboardData?.forEach(data => {
      const { recordId, stateName, updatedAt: date, recordData } = data;

      let modelData = {};

      // Build the data based on the model fields
      Array.from(orchestratorModel).forEach(field => {
        modelData = {
          key: recordId,
          date,
          recordId,
          stateName,
          ...modelData,
          [field]: recordData?.[field] ?? '-',
        };
      });

      dataSource.push(modelData);
    });

    return dataSource;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  const columns = useMemo(() => {
    const columns = [
      {
        key: 'recordId',
        width: '10%',
        title: t('orchestrator.dashboard.recordId'),
        dataIndex: 'recordId',
      },
      {
        key: 'stateName',
        width: '10%',
        title: t('orchestrator.dashboard.stateName'),
        dataIndex: 'stateName',
      },
      {
        key: 'date',
        width: '5%',
        title: t('orchestrator.dashboard.date'),
        dataIndex: 'date',
      },
    ];

    // As the model contains custom fields we need to cast and add them to the table columns
    Array.from(orchestratorModel ?? [])
      .sort()
      .forEach(model => {
        columns.push({
          key: model,
          width: '5%',
          title: model,
          dataIndex: model,
        });
      });

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orchestratorModel]);

  if (mounted) {
    if (tableRedraw) {
      return (
        <div className="spin">
          <Spin />
        </div>
      );
    }

    return (
      <Col xs={24}>
        <AddUser>
          <div className="table-bordered leaderboard-table table-responsive">
            <UserTableStyleWrapper>
              <TableWrapper className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={tableData}
                  pagination={{
                    total: tableData?.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
                    defaultPageSize: 10,
                  }}
                />
              </TableWrapper>
            </UserTableStyleWrapper>
          </div>
        </AddUser>
      </Col>
    );
  }
};

OrchestratorDashboardTable.propTypes = {
  mounted: PropTypes.bool.isRequired,
  tableRedraw: PropTypes.bool.isRequired,
  orchestratorModel: PropTypes.array.isRequired,
};

export default OrchestratorDashboardTable;
