import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Table, Spin, Col, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

import { TableWrapper, UserTableStyleWrapper } from '../styled';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { alertModal } from '../../../components/modals/antd-modals';
import { AddUser } from '../user/style';

import { QUERY_ORCHESTRATOR_STATE_REMOVE, QUERY_ORCHESTRATOR_STATE_FIND_ALL } from '../../../queries';

import orchestratorStateActions from '../../../redux/orchestrator-states/actions';

const {
  orchestratorStateGetAllBegin,
  orchestratorStateGetAllSuccess,
  orchestratorStateGetAllError,
  orchestratorStateRemoveBegin,
  orchestratorStateRemoveSuccess,
  orchestratorStateRemoveError,
  orchestratorStateDeactivateReDraw,
} = orchestratorStateActions;

const OrchestratorStateList = ({ setOrchestratorName }) => {
  const { t } = useTranslation();
  const { orchestratorId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { states, reDrawTable } = useSelector(state => state.orchestratorState);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);

  const [orchestratorStateRemove] = useMutation(QUERY_ORCHESTRATOR_STATE_REMOVE);

  const [mounted, setMounted] = useState(false);
  const [state, setState] = useState({
    orchestratorStatesData: [],
  });

  const queryArgs = {
    variables: {
      orchestratorId,
    },
    fetchPolicy: 'network-only',
  };

  const { loading, data, error } = useQuery(QUERY_ORCHESTRATOR_STATE_FIND_ALL, queryArgs);

  const handleOrchestratorStateRemove = useCallback(
    id => {
      alertModal.confirm({
        title: t('orchestrator.states.confirmRemove'),
        onOk() {
          dispatch(orchestratorStateRemoveBegin());

          orchestratorStateRemove({
            variables: {
              orchestratorStateId: id,
            },
          })
            .then(({ data }) => {
              const { success, code } = data.orchestratorStateRemove;

              if (success) {
                dispatch(orchestratorStateRemoveSuccess(id));

                notificationSuccess(t('orchestrator.states.successDeleted'));

                setTimeout(() => {
                  orchestratorStateDeactivateReDraw();
                }, 500);

                return;
              }

              dispatch(orchestratorStateRemoveError(code));
              notificationError(t(`codeResponse.${code}`));
            })
            .catch(e => {
              console.error('Orchestrator State Delete Error ', e);

              dispatch(orchestratorStateRemoveError());
              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [t, dispatch, orchestratorStateRemove],
  );

  const formatLicenses = useCallback(
    licenses =>
      licenses.length
        ? licenses.map((license, index) => (
            <ul key={index}>
              <li>{license.computerInfo?.name || t('general.none')}</li>
            </ul>
          ))
        : t('general.none'),
    [t],
  );

  const updateTable = useCallback(
    orchestratorStatesData => {
      const dataSource = [];

      if (orchestratorStatesData) {
        orchestratorStatesData.map(state => {
          const { _id, name, alias, isEnd, isFirst, timeout, waitTime, licenses, nextState, assignable } = state;

          dataSource.push({
            key: _id,
            name,
            alias,
            isEnd: (
              <span className={`status-text ${isEnd ? 'active' : 'blocked'}`}>
                {isEnd ? t('general.yes') : t('general.no')}
              </span>
            ),
            isFirst: (
              <span className={`status-text ${isFirst ? 'active' : 'blocked'}`}>
                {isFirst ? t('general.yes') : t('general.no')}
              </span>
            ),
            timeout: timeout !== null && timeout >= 0 ? `${timeout} mins` : t('general.no'),
            waitTime: waitTime !== null && waitTime >= 0 ? `${waitTime} mins` : t('general.no'),
            licenses: formatLicenses(licenses),
            nextState: nextState.alias?.length ? nextState.alias : 'N/A',
            assignable: (
              <span className={`status-text ${assignable ? 'active' : 'blocked'}`}>
                {assignable ? t('general.yes') : t('general.no')}
              </span>
            ),
            actions: (
              <div className="table-actions">
                <>
                  <Tooltip title={t('general.buttons.edit')}>
                    <Button
                      type="info"
                      shape="circle"
                      onClick={() => history.push(`/admin/orchestrator/${orchestratorId}/states/${_id}/edit`)}
                      className="btn-icon"
                    >
                      <FeatherIcon icon="edit" size={16} />
                    </Button>
                  </Tooltip>

                  <Tooltip title={t('general.buttons.delete')}>
                    <Button
                      className="btn-icon"
                      type="danger"
                      shape="circle"
                      onClick={() => handleOrchestratorStateRemove(_id)}
                    >
                      <FeatherIcon icon="trash-2" size={16} />
                    </Button>
                  </Tooltip>
                </>
              </div>
            ),
          });

          return true;
        });
      }

      setState({
        orchestratorStatesData: dataSource,
      });
    },
    [t, history, formatLicenses, orchestratorId, handleOrchestratorStateRemove],
  );

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotOrchestratorFindAll)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted) {
      dispatch(orchestratorStateGetAllBegin());

      if (typeof data === 'undefined') {
        dispatch(orchestratorStateGetAllError(error));
        notificationError(t('codeResponse.MICROSERVICE_UNAVAILABLE'));

        history.push('/admin/orchestrator');

        return;
      }

      const { code, data: states, success } = data.orchestratorStateFindAll;

      if (success) {
        dispatch(orchestratorStateGetAllSuccess(states));
        updateTable(states);
        setOrchestratorName(states?.length ? states[0].orchestrator : '');
      } else {
        dispatch(orchestratorStateGetAllError(code));
      }

      setMounted(true);
    }
  }, [
    t,
    data,
    error,
    loading,
    history,
    mounted,
    dispatch,
    updateTable,
    currentTenant,
    currentLoading,
    setOrchestratorName,
  ]);

  useEffect(() => {
    if (reDrawTable) {
      updateTable(states);
    }
  }, [states, reDrawTable, updateTable]);

  const columns = [
    {
      title: t('orchestrator.states.fields.name'),
      dataIndex: 'name',
      key: 'name',
      width: '10%',
    },
    {
      title: t('orchestrator.states.fields.alias'),
      dataIndex: 'alias',
      key: 'alias',
      width: '25%',
    },
    {
      title: t('orchestrator.states.fields.isEnd'),
      dataIndex: 'isEnd',
      key: 'isEnd',
      width: '5%',
    },
    {
      title: t('orchestrator.states.fields.isFirst'),
      dataIndex: 'isFirst',
      key: 'isFirst',
      width: '5%',
    },
    {
      title: t('orchestrator.states.fields.timeout'),
      dataIndex: 'timeout',
      key: 'timeout',
      width: '10%',
    },
    {
      title: t('orchestrator.states.fields.waitTime'),
      dataIndex: 'waitTime',
      key: 'waitTime',
      width: '10%',
    },
    {
      title: t('orchestrator.states.fields.licenses'),
      dataIndex: 'licenses',
      key: 'licenses',
      width: '10%',
    },
    {
      title: t('orchestrator.states.fields.nextState'),
      dataIndex: 'nextState',
      key: 'nextState',
      width: '10%',
    },
    {
      title: t('orchestrator.states.fields.assignable'),
      dataIndex: 'assignable',
      key: 'assignable',
      width: '5%',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
    },
  ];

  if (mounted) {
    return (
      <Col xs={24}>
        <AddUser>
          <div className="table-bordered leaderboard-table table-responsive">
            <UserTableStyleWrapper>
              <TableWrapper className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={state.orchestratorStatesData}
                  pagination={{
                    defaultPageSize: 10,
                    total: state.orchestratorStatesData?.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
                  }}
                />
              </TableWrapper>
            </UserTableStyleWrapper>
          </div>
        </AddUser>
      </Col>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default OrchestratorStateList;
