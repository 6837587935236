import { Col, Form, Input, Row, Spin, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import { Checkbox } from '../checkbox/checkbox';
import { QUERY_BOT_LICENSE_FIND_BY_TENANT } from '../../queries';
import { Button } from '../buttons/buttons';
import workFlowActions from '../../redux/workFlow/actions';

const { nodePropertiesUpdate } = workFlowActions;
const { TextArea } = Input;
const { Option } = Select;

const ReactFlowProperties = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector(state => state.workFlow.nodeSelectedLoading);
  const nodeSelected = useSelector(state => state.workFlow.nodeSelectedData);
  const nodeListProperties = useSelector(state => state.workFlow.nodeListProperties);
  const [botLicenseState, setBotLicenseState] = useState({
    bots: [],
    botLicenses: [],
  });
  const [nodeProperties, setNodeProperties] = useState({
    id: '',
    name: '',
    description: '',
    bot: '',
    botLicenses: [],
  });
  const { data: botLicensesData, loading: botLicensesLoading } = useQuery(QUERY_BOT_LICENSE_FIND_BY_TENANT, {
    fetchPolicy: 'network-only',
  });

  const handleSubmit = () => {
    dispatch(
      nodePropertiesUpdate({
        id: nodeSelected.id,
        ...nodeProperties,
      }),
    );
    message.success(t('general.saved'));
  };

  const onChange = e => {
    const { name, value } = e.target;
    if (!name)
      setNodeProperties(x => ({
        ...x,
        description: value,
      }));
    else
      setNodeProperties(x => ({
        ...x,
        [name]: value,
      }));
  };

  const onChangeSelect = value => {
    setNodeProperties(x => ({
      ...x,
      bot: value,
    }));
  };

  const onChangeCheckbox = values => {
    setNodeProperties(x => ({
      ...x,
      botLicenses: values,
    }));
  };

  useEffect(() => {
    if (botLicensesData?.botLicenseFindByTenant.data?.length > 0) {
      setBotLicenseState({
        bots: botLicensesData?.botLicenseFindByTenant.data
          .reduce((items, item) => (items.find(x => x.bot._id === item.bot._id) ? [...items] : [...items, item]), [])
          .map(botLicense => {
            return (
              <Option key={botLicense.bot._id} value={botLicense.bot._id}>
                {botLicense.bot.name}
              </Option>
            );
          }),
        botLicenses: [],
      });
    } else {
      setBotLicenseState([]);
    }
  }, [botLicensesData]);

  useEffect(() => {
    if (nodeProperties.bot) {
      setBotLicenseState(x => ({
        ...x,
        botLicenses: botLicensesData?.botLicenseFindByTenant.data
          .filter(botLicense => botLicense.bot._id === nodeProperties.bot)
          .map(botLicense => ({
            label: botLicense.license.computerInfo?.name || botLicense.license.serial,
            value: botLicense._id,
          })),
      }));
    } else {
      setBotLicenseState(x => ({
        ...x,
        botLicenses: [],
      }));
    }
  }, [nodeProperties.bot, botLicensesData]);

  useEffect(() => {
    if (nodeSelected) {
      setNodeProperties({
        id: nodeSelected.id,
        name: '',
        description: '',
        bot: null,
        botLicenses: [],
      });
      form.setFieldsValue({
        name: '',
        description: '',
        bot: null,
        botLicenses: [],
      });
      const props = nodeListProperties.find(x => x.id === nodeSelected.id);
      if (props) {
        setNodeProperties({ ...props });
        form.setFieldsValue({ ...props });
      }
    }
  }, [nodeSelected, nodeListProperties, form]);

  if (loading || botLicensesLoading)
    return (
      <div className="spin">
        <Spin />
      </div>
    );

  return (
    <center>
      <Form name="propertiesInfo" form={form} layout="vertical" onFinish={handleSubmit} scrollToFirstError>
        {nodeSelected ? (
          <>
            <Row justify="center" gutter={10}>
              <Col xs={24}>
                <Form.Item name="name" label={t('properties.name.1')}>
                  <Input
                    style={{ marginBottom: '15px' }}
                    onChange={onChange}
                    value={nodeProperties.name}
                    name="name"
                    size="small"
                    placeholder={t('properties.name.1')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: '-20px' }} justify="center" gutter={10}>
              <Col xs={24}>
                <Form.Item name="description" label={t('properties.description.1')}>
                  <TextArea
                    rows={4}
                    style={{ marginBottom: '15px' }}
                    onChange={onChange}
                    value={nodeProperties.description}
                    name="description"
                    placeholder={t('properties.description.1')}
                  />
                </Form.Item>
              </Col>
            </Row>
            {nodeSelected?.type === 'default' && (
              <>
                <Row style={{ marginTop: '-20px' }} gutter={10}>
                  <Col xs={24}>
                    <Form.Item name="bot" label={t('bot.name')} initialValue={nodeProperties.bot || undefined}>
                      <Select
                        className="sDash_fullwidth-select"
                        onChange={onChangeSelect}
                        value={nodeProperties.bot || undefined}
                        placeholder={t('bot.name')}
                      >
                        {botLicenseState.bots}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col xs={24}>
                    <div style={{ marginBottom: '15px', textAlign: 'left' }}>
                      <Form.Item name="botLicenses" label={t('properties.machine.plural')}>
                        <Checkbox
                          defaultSelect={nodeProperties.botLicenses}
                          multiple
                          onChangeTriger={onChangeCheckbox}
                          item={botLicenseState.botLicenses}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <Row style={{ marginTop: '-20px', textAlign: 'right' }} gutter={10}>
              <Col xs={24}>
                <Form.Item>
                  <Button htmlType="submit" type="primary">
                    {t('general.buttons.save')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          t('general.selectNode')
        )}
      </Form>
    </center>
  );
};

export default ReactFlowProperties;
