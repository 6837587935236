import React, { useCallback, useEffect, useState } from 'react';
import { Form, Row, Col, Button, Input, Select, InputNumber, Avatar, Upload } from 'antd';
import { ClockCircleOutlined, DollarOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { formatBytes } from '../../../../../components/utilities/utilities';
import { notificationError, notificationSuccess } from '../../../../../components/utilities/notification';
import { UserPermissions } from '../../../../../utils/enums/permissions.enum';
import { QUERY_BOT_UPDATE_CV, QUERY_FILE_CREATE, QUERY_FILE_DOWNLOAD } from '../../../../../queries';
import fileActions from '../../../../../redux/file/actions';

const { TextArea } = Input;
const { Option } = Select;
const { fileDownloadBegin, fileDownloadSuccess, fileDownloadError, fileCreateSuccess, fileCreateError } = fileActions;

const CvForm = ({ cv }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [form] = Form.useForm();
  const [updateCv] = useMutation(QUERY_BOT_UPDATE_CV);
  const [fileDownload] = useMutation(QUERY_FILE_DOWNLOAD);
  const [fileCreate] = useMutation(QUERY_FILE_CREATE);
  const [botImage, setBotImage] = useState('');
  const [state, setState] = useState({
    form: {
      alias: '',
      description: '',
      manager: '',
      executionConditional: '',
      executionTime: 0,
      savingMethod: '',
      saving: 0,
      image: '',
    },
  });
  const [datafile, setDataFile] = useState({
    size: 0,
    base64: '',
    name: '',
    error: false,
    errorMsg: '',
  });
  const [allowEdit, setAllowEdit] = useState(false);
  const { botId } = useParams();
  const maxSizeAllowed = 2097152;
  const mimeTypeAllowed = 'image/jpeg, image/png';
  const alertSuccess = 'Success';
  const alertError = 'Error';

  const showAlertResult = (msg, type) => {
    if (type === alertError) notificationError(msg);
    else notificationSuccess(msg);
  };

  const updateForm = useCallback(
    botFound => {
      // botFound.image ??= { _id: '' };
      form.setFieldsValue({
        alias: botFound.alias,
        manager: botFound.manager,
        description: botFound.description,
        executionConditional: botFound.executionConditional,
        executionTime: botFound.executionTime,
        savingMethod: botFound.savingMethod,
        saving: botFound.saving,
        image: botFound.image !== null ? botFound.image._id : '',
      });

      setState(x => ({
        form: {
          ...x.form,
          alias: botFound.alias,
          manager: botFound.manager,
          description: botFound.description,
          executionConditional: botFound.executionConditional,
          executionTime: botFound.executionTime,
          savingMethod: botFound.savingMethod,
          saving: botFound.saving,
          image: botFound.image !== null ? botFound.image._id : '',
        },
      }));
    },
    [form],
  );

  const handleChange = e => {
    if (e.target.props?.name === 'description') e.target.name = 'description';
    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleChangeSelect = (e, field) => {
    form.setFieldsValue({
      [field]: e,
    });

    setState({
      form: {
        ...state.form,
        [field]: e,
      },
    });
  };

  const handleDownloadFile = useCallback(
    id => {
      dispatch(fileDownloadBegin());

      return fileDownload({
        variables: { id },
      })
        .then(({ data }) => {
          if (data.fileDownload.success) {
            dispatch(fileDownloadSuccess());
            // window.open(data.fileDownload.data, '_blank');
            return data.fileDownload.data;
          }
          dispatch(fileDownloadError(data.fileDownload.code));
          notificationError(t(`codeResponse.${data.fileDownload.code}`));
        })
        .catch(e => {
          console.log('File Download Error ', e);
          dispatch(fileDownloadError());

          notificationError(t('codeResponse.UNEXPECTED_ERROR'));
        });
    },
    [dispatch, fileDownload, t],
  );

  const beforeUpload = file => {
    let errorMsg = '';

    const errorTypeFile = mimeTypeAllowed.indexOf(file.type) > -1;
    if (!errorTypeFile) {
      errorMsg = t('labels.bussiness.rpa.files_shared.extension_not_allowed');
      showAlertResult(errorMsg, alertError);
    }

    const errorMaxSize = file.size <= maxSizeAllowed;
    if (!errorMaxSize) {
      errorMsg = t('labels.bussiness.rpa.files_shared.error_max_size', { value: formatBytes(maxSizeAllowed) });
      showAlertResult(errorMsg, alertError);
    }

    if (!errorMaxSize || !errorTypeFile) {
      setDataFile({
        ...datafile,
        error: true,
        errorMsg,
      });
    } else {
      setDataFile({
        ...datafile,
        error: false,
        errorMsg: '',
      });
    }

    return false;
  };

  // Captura base64 of File
  const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
      setDataFile({
        ...datafile,
        error: true,
      });
    };
  };

  const handleChangeFile = info => {
    if (!datafile.error) {
      getBase64(info.file, imageUrl =>
        setDataFile({
          ...datafile,
          size: info.file.size,
          base64: imageUrl,
          name: info.file.name,
          error: false,
        }),
      );
    } else {
      setDataFile({
        ...datafile,
        base64: '',
        name: '',
      });
    }
  };

  const handleSubmit = async () => {
    // eslint-disable-next-line react/prop-types
    const { __typename, _id, ...actualCv } = cv;
    console.log('actualCv', actualCv);
    actualCv.files = actualCv.files.map(item => item._id);

    updateCv({
      variables: {
        data: {
          bot: botId,
          ...actualCv,
          ...state.form,
        },
      },
    })
      .then(({ data }) => {
        if (data.botUpdateCv.success) {
          notificationSuccess(t('labels.bussiness.rpa.bots.table.success_edited'));
          setAllowEdit(false);
          history.push('/admin/bot');
        } else {
          notificationError(t(`codeResponse.${data.botUpdateCv.code}`));
        }
      })
      .catch(e => {
        console.log('Bot Edit Error ', e);
        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
      });

    console.log(datafile.name !== undefined && datafile.name !== '');

    if (datafile.name !== undefined && datafile.name !== '') {
      fileCreate({
        variables: {
          fileCreateInput: {
            name: `bot-image-${botId}`,
            fileName: `bot-image-${botId}`,
            file: datafile.base64,
            isPlataformaFile: false,
            size: datafile.size,
            description: 'Image of the bot attached.',
            tenant: currentTenant?.tenant._id,
            awsId: '',
          },
        },
      })
        .then(({ data }) => {
          if (data.fileCreate.success) {
            dispatch(fileCreateSuccess(data.fileCreate.data));

            showAlertResult(t('labels.bussiness.rpa.files_shared.success_upload'), alertSuccess);

            updateCv({
              variables: {
                data: {
                  bot: botId,
                  ...actualCv,
                  image: data.fileCreate.data._id,
                },
              },
            });
          } else {
            dispatch(fileCreateError(data.fileCreate.code));

            showAlertResult(t(`codeResponse.${data.fileCreate.code}`), alertError);
          }
        })
        .catch(e => {
          console.log('File Create Error ', e);

          dispatch(fileCreateError());
          showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
        });
    }
    // history.push(`/admin/bot/`);
  };

  useEffect(() => {
    if (cv.image !== null && cv.image._id !== '') {
      handleDownloadFile(cv.image._id).then(r => setBotImage(r));
    }
    updateForm(cv);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cv]);

  return (
    <>
      <Form name="updateBotCv" layout="vertical" form={form} onFinish={handleSubmit} scrollToFirstError>
        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item name="description" rules={[]} label={t('labels.bussiness.rpa.bots.curriculum.description')}>
              <TextArea
                name="description"
                rows={10}
                disabled={!allowEdit}
                onChange={handleChange}
                className="sDash_unresizable"
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Row gutter={30}>
              <Col md={4} xs={24}>
                <Avatar shape="square" size={64} icon={<UserOutlined />} src={botImage} />
              </Col>
              <Col md={20} xs={24}>
                <Form.Item name="image" rules={[]} label={t('labels.bussiness.rpa.bots.curriculum.image')}>
                  <Upload
                    style={{ background: 'red' }}
                    name="image"
                    value={state.form.alias}
                    disabled={!allowEdit}
                    multiple={false}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleChangeFile}
                  >
                    <Button disabled={!allowEdit} className="btn-outlined" size="large" type="light">
                      <UploadOutlined />
                      {datafile.name !== '' && !datafile.error
                        ? `${datafile.name.substring(0, 16)}...`
                        : t('labels.bussiness.rpa.files_shared.select')}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} xs={24}>
                <Form.Item name="alias" rules={[]} label={t('labels.bussiness.rpa.bots.curriculum.alias')}>
                  <Input name="alias" disabled={!allowEdit} onChange={handleChange} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} xs={24}>
                <Form.Item name="manager" rules={[]} label={t('labels.bussiness.rpa.bots.curriculum.resp_operation')}>
                  <Input name="manager" disabled={!allowEdit} onChange={handleChange} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item
              name="executionConditional"
              rules={[]}
              label={t('labels.bussiness.rpa.bots.curriculum.exec_condition')}
            >
              <TextArea
                name="executionConditional"
                rows={4}
                disabled={!allowEdit}
                onChange={handleChange}
                className="sDash_unresizable"
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  name="executionTime"
                  rules={[]}
                  label={t('labels.bussiness.rpa.bots.curriculum.executionTime')}
                >
                  <InputNumber
                    name="executionTime"
                    addonAfter={<ClockCircleOutlined />}
                    defaultValue={100}
                    disabled={!allowEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item name="saving" rules={[]} label={t('labels.bussiness.rpa.bots.curriculum.saving')}>
                  <InputNumber
                    name="saving"
                    addonAfter={<DollarOutlined />}
                    defaultValue={100}
                    disabled={!allowEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} xs={24}>
                <Form.Item
                  name="savingMethod"
                  rules={[]}
                  label={t('labels.bussiness.rpa.bots.curriculum.savingMethod')}
                >
                  <Select
                    showSearch
                    name="savingMethod"
                    disabled={!allowEdit}
                    onChange={e => handleChangeSelect(e, 'savingMethod')}
                    placeholder={t('labels.bussiness.rpa.bots.table.select_file')}
                    size="middle"
                    className="sDash_fullwidth-select"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="EXECUTION">Ejecución</Option>
                    <Option value="SECOND_SHORT_OF_GOAL">Segundos antes de la meta</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col>
            {currentTenant?.userPermissions?.includes(UserPermissions.BotCVUpdate) && (
              <Form.Item>
                <Button
                  style={{ height: '44px' }}
                  onClick={() => setAllowEdit(!allowEdit)}
                  htmlType="button"
                  type="primary"
                >
                  {!allowEdit ? t('general.edit') : t('general.cancel')}
                </Button>
              </Form.Item>
            )}
          </Col>
          <Col>
            {allowEdit && (
              <Form.Item>
                <Button style={{ height: '44px' }} htmlType="submit" type="primary">
                  {t('general.update')}
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CvForm;
