import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const OrchestratorStateFormData = () => {
  const { state } = useSelector(x => x.orchestratorState);

  const [formValues, setFormValues] = useState({
    name: state?.name || '',
    alias: state?.alias || '',
    isEnd: state?.isEnd || false,
    timeout: state?.timeout || 1,
    waitTime: state?.timeout || 1,
    licenses: state?.licenses || [],
    nextState: state?.nextState || '',
    assignable: state?.assignable || false,
    description: state?.description || '',
  });

  useEffect(() => {
    if (typeof state !== 'undefined') {
      setFormValues(state);
    }
  }, [state]);

  return [formValues, setFormValues];
};

export default OrchestratorStateFormData;
