import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import WorkFlowManagement from '../../pages/admin/work-flow';
import WorkFlowCreate from '../../pages/admin/work-flow/WorkFlowCreate';
import WorkFlowEdit from '../../pages/admin/work-flow/WorkFlowEdit';
import NotFound from '../../utils/404';

const WorkFlowsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/edit/:workFlowId`} component={WorkFlowEdit} />
      <Route exact path={`${path}/create`} component={WorkFlowCreate} />
      <Route exact path={`${path}`} component={WorkFlowManagement} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default WorkFlowsRoute;
