import $ from 'jquery';
import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Form, Input, Button, Row, Col } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import MicrosoftLogin from 'react-microsoft-login';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from './style';
import actionsPasswordReset from '../../redux/passwordReset/actions';
import Heading from '../../components/heading/heading';
import Alert from '../../components/alerts/alerts';
import actions from '../../redux/authentication/actions';
import { QUERY_LOGIN, QUERY_LOGIN_BY_SOCIAL } from '../../queries/auth.queries';
import { Social, Source } from '../../utils/enums/types.enum';
const { loginBegin, loginSuccess, loginErr } = actions;
const { passwordResetSuccess, passwordResetErr } = actionsPasswordReset;

const SignIn = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.auth.loading);
  const error = useSelector(state => state.auth.error);
  const errorToken = useSelector(state => state.passwordReset.error);
  const successToken = useSelector(state => state.passwordReset.data);
  const [login] = useMutation(QUERY_LOGIN);
  const [socialLogin] = useMutation(QUERY_LOGIN_BY_SOCIAL);
  const reRef = useRef();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { language } = i18n;
  const [state, setState] = useState({
    form: {
      username: '',
      password: '',
      token: '',
    }
  });

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1', { value: '${label}' }),
    types: {
      email: t('type.email', { value: '${label}' })
    }
  };
  /* eslint-enable */

  const authHandler = async (err, data, msalInstance) => {
    if (err || !data) return;
    socialLogin({
      variables: {
        loginInput: {
          accessToken: data.idToken.rawIdToken,
          source: Source.MANAGER,
          machine: 'DESKTOP',
          provider: Social.MICROSOFT
        },
      }
    }).then(({ data }) => {
      if (data.socialLogin) {
        if (data.socialLogin.success === true) {
          history.push('/admin');
        } else {
          dispatch(loginErr(`codeResponse.${data.socialLogin.code}`));
        }
      }
    }).finally(() => {
      msalInstance.clearCacheForScope();
      sessionStorage.clear();
    });
  }

  const googleDone = async (data) => {
    dispatch(loginBegin());
    socialLogin({
      variables: {
        loginInput: {
          accessToken: data.credential,
          source: Source.MANAGER,
          machine: 'DESKTOP',
          provider: Social.GOOGLE
        },
      }
    }).then(({ data }) => {
      if (data.socialLogin) {
        if (data.socialLogin.success === true) {
          dispatch(loginSuccess());
          history.push('/admin');
        } else {
          dispatch(loginErr(`codeResponse.${data.socialLogin.code}`));
        }
      }
    });
  }

  const handleSubmit = async () => {
    if (state.form.token === '' && process.env.REACT_APP_REGISTRATION_CAPTCHA_STATUS === 'true') {
      dispatch(loginErr(t('captcha.resolveCaptcha.1')));
      return;
    }

    dispatch(loginBegin());

    login({
      variables: {
        loginInput: {
          email: state.form.username,
          password: state.form.password,
          source: Source.MANAGER,
          machine: 'DESKTOP',
          provider: Social.LOGIN
        },
      },
    }).then(({ data }) => {
      if (data.login) {
        if (data.login.success === true) {
          console.log(data.login.success);
          console.log(data.login);
          dispatch(loginSuccess());
          history.push('/admin');
        } else {
          dispatch(loginErr(`codeResponse.${data.login.code}`));
        }
      }
    });

    if (reRef.current) reRef.current.reset();

    setState({
      form: {
        ...state.form,
        token: '',
      },
    });
  };

  const handleChange = e => {
    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  const onChangeCaptcha = response => {
    setState({
      form: {
        ...state.form,
        token: response,
      },
    });
    dispatch(loginErr(null));
  };

  setInterval(() => {
    if ($('.alert-login').length && $(".alert-login [role='alert']").length <= 0) {
      dispatch(loginErr(null));
    } else if ($('.alert-resetPass').length && $(".alert-resetPass [role='alert']").length <= 0) {
      dispatch(passwordResetSuccess(null));
      dispatch(passwordResetErr(null));
    }
  }, [500]);

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical" validateMessages={validateMessages}>
          <div className="title-app">
            <img style={{ width: '350px' }} src={require('../../static/img/logo3.png')} alt="" />
          </div>
          <Heading as="h3">
            {t('signIn.2')} <span className="color-secondary">{t('admin.1')}</span>
          </Heading>
          {error ? (
            <Form.Item className="alert-login">
              <Alert closable message="" description={t(error)} type="error" />
            </Form.Item>
          ) : (
            ''
          )}
          {errorToken ? (
            <Form.Item className="alert-resetPass">
              <Alert closable message="" description={t(errorToken)} type="error" />
            </Form.Item>
          ) : (
            ''
          )}
          {successToken ? (
            <Form.Item className="alert-resetPass">
              <Alert closable message="" description={t(successToken)} type="success" />
            </Form.Item>
          ) : (
            ''
          )}
          <Form.Item name="username" rules={[{ required: true, type: 'email' }]} label={t('properties.username.1')}>
            <Input
              name="username"
              onChange={handleChange}
              value={state.form.username}
              placeholder={t('properties.username.1')}
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }]} label={t('properties.password.1')}>
            <Input.Password
              name="password"
              onChange={handleChange}
              value={state.form.password}
              placeholder={t('properties.password.1')}
            />
          </Form.Item>
          {process.env.REACT_APP_REGISTRATION_CAPTCHA_STATUS === 'true' ? (
            <Form.Item>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_NOCAPTCHA_SITEKEY}
                size="normal"
                ref={reRef}
                onChange={onChangeCaptcha}
                hl={language}
              />
            </Form.Item>
          ) : (
            ''
          )}
          <div className="auth-form-action">
            {/* <Checkbox onChange={onChange}>Keep me logged in</Checkbox> */}
            <NavLink className="forgot-pass-link" to="/forgot/password">
              {t('password.forgotPassword')}
            </NavLink>
          </div>
          <Form.Item>
            <Button className="btn-signin" htmlType="submit" type="primary" size="large">
              {isLoading ? t('loading.1') : t('signIn.1')}
            </Button>
          </Form.Item>
          <p className="form-divider">
            <span>Or</span>
          </p>
          <Row className="social-login">
            <Col xs={24} lg={12} style={{ marginBottom: '-10px' }}>
              <Form.Item>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                  <GoogleLogin
                    locale={language}
                    text={'continue_with'}
                    className="btn-google"
                    onSuccess={googleDone}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                    useOneTap
                  />
                </GoogleOAuthProvider>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ marginBottom: '-10px' }}>
              <Form.Item>
                <MicrosoftLogin
                  className="btn-microsoft"
                  forceLogin
                  clientId={process.env.REACT_APP_AZURE_CLIENT_ID}
                  prompt='select_account'
                  authCallback={authHandler}
                >
                  <Button type='light' style={{ height: '40px', paddingLeft: '5px' }}>
                    <div>
                      <img style={{ width: '22px' }} src={require('../../static/img/icon-microsoft.png')} alt="" />
                      <span>{t('general.continueWithMicrosoft')}</span>
                    </div>
                  </Button>
                </MicrosoftLogin>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default SignIn;
