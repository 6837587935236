import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { alertModal } from '../modals/antd-modals';
import actions from '../../redux/authentication/actions';
import { QUERY_LOGOUT } from '../../queries/auth.queries';

const { logoutBegin, logoutSuccess, logoutErr } = actions;

const IdleTimerContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const sessionTimeoutRef = useRef(null);
  const idleTimerRef = useRef(null);
  const [logout] = useMutation(QUERY_LOGOUT);
  const idleTimeout = process.env.REACT_APP_IDLE_TIMEOUT_TO_NOTIFY;

  const timeout = 1000 * 60 * idleTimeout;

  const SignOut = async () => {
    dispatch(logoutBegin());
    logout()
      .then(({ data }) => {
        if (data.logout.success) dispatch(logoutSuccess());
      })
      .catch(err => {
        dispatch(logoutErr(err));
        // eslint-disable-next-line no-console
        console.log('Idle Error: ', err);
      }).finally(() => history.push('/'));
  };

  const onIdleHandler = () => {
    idleTimerRef.current.reset();

    const modal = alertModal.confirm({
      title: t('idle_timer.atention'),
      content: (
        <div>
          <p>{t('idle_timer.msg_inactivity')}</p>
          <p>{t('idle_timer.ask_user')}</p>
        </div>
      ),
      onOk() {
        clearTimeout(sessionTimeoutRef.current);
      },
      onCancel() {
        SignOut();
        clearTimeout(sessionTimeoutRef.current);
      },
      okText: t('idle_timer.keep_online'),
      cancelText: t('idle_timer.log_out'),
    });

    sessionTimeoutRef.current = setTimeout(() => {
      modal.destroy();
      SignOut();
      alertModal.error({
        title: t('idle_timer.info_log_out'),
      });
    }, timeout / 2);
  };

  return (
    <div>
      <IdleTimer ref={idleTimerRef} timeout={timeout} onIdle={onIdleHandler} />
    </div>
  );
};

export default IdleTimerContainer;
