import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Select } from 'antd';

import { useHistory, useParams } from 'react-router-dom';
import { UserPermissions } from '../../utils/enums/permissions.enum';
import { notificationError } from '../../components/utilities/notification';

import {
  QUERY_LICENSE_FIND_ALL,
  QUERY_ORCHESTRATOR_STATE_FIND_ALL,
  QUERY_ORCHESTRATOR_STATE_FIND_ONE,
} from '../../queries';

import licenseActions from '../../redux/license/actions';
import orchestratorStateActions from '../../redux/orchestrator-states/actions';

const { licenseFindAllError, licenseFindAllBegin, licenseFindAllSuccess } = licenseActions;

const {
  orchestratorStateGetAllBegin,
  orchestratorStateGetAllSuccess,
  orchestratorStateGetAllError,
  orchestratorStateFindOneReset,
  orchestratorStateFindOneSuccess,
  orchestratorStateFindOneError,
} = orchestratorStateActions;

const OrchestratorStateForm = ({
  isNewState,
  currentAlias,
  currentTenant,
  currentLoading,
  updateFormData,
  stateIsAssignable,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: licenses } = useSelector(state => state.license);
  const { states } = useSelector(state => state.orchestratorState);
  const dispatch = useDispatch();

  const { orchestratorId, orchestratorStateId } = useParams();

  const [mounted, setMounted] = useState(false);
  const [tenantId] = useState(currentTenant?.tenant._id !== null ? currentTenant?.tenant._id : '');
  const [skipFindOneCall, setSkipFindOneCall] = useState(isNewState);
  const formIsLoading = useMemo(() => currentLoading, [currentLoading]);

  const skipGetLicensesCall = stateIsAssignable === false;
  const skipOrchestratorStatesCall = isNewState ? stateIsAssignable === true : false;

  const permissionToCheck = isNewState ? UserPermissions.BotOrchestratorCreate : UserPermissions.BotOrchestratorUpdate;

  const userDoesNotHavePermission = useMemo(() => !currentTenant?.userPermissions?.includes(permissionToCheck), [
    currentTenant,
    permissionToCheck,
  ]);

  const orchestratorStatesUrl = `/admin/orchestrator/${orchestratorId}/states`;

  const { Option } = Select;

  const { data, error, loading } = useQuery(QUERY_LICENSE_FIND_ALL, {
    skip: skipGetLicensesCall,
    variables: {
      tenantId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: stateToUpdate, error: stateToUpdateError, loading: loadingStateToUpdate } = useQuery(
    QUERY_ORCHESTRATOR_STATE_FIND_ONE,
    {
      skip: skipFindOneCall,
      variables: {
        orchestratorStateId,
      },
      fetchPolicy: 'network-only',
    },
  );

  const { data: nextStates, error: nextStateError, loading: loadingNextStates } = useQuery(
    QUERY_ORCHESTRATOR_STATE_FIND_ALL,
    {
      skip: skipOrchestratorStatesCall,
      variables: {
        orchestratorId,
      },
      fetchPolicy: 'network-only',
    },
  );

  const setupDataForForm = useCallback(state => {
    const {
      _id,
      name,
      alias,
      isEnd,
      timeout,
      waitTime,
      // eslint-disable-next-line no-shadow
      licenses,
      nextState,
      assignable,
      description,
      orchestrator,
    } = state;

    let formData = {
      _id,
      name,
      alias,
      isEnd,
      timeout,
      licenses,
      assignable,
      description,
      orchestrator,
    };

    if (!assignable) {
      formData = {
        _id,
        name,
        alias,
        isEnd,
        waitTime,
        nextState: nextState._id,
        assignable,
        description,
        orchestrator,
      };
    }

    return {
      ...formData,
      licenses: licenses.map(license => license.serial),
    };
  }, []);

  // Fetch the licenses when it's necessary
  const fetchLicenses = useCallback(() => {
    if (!formIsLoading && userDoesNotHavePermission) {
      history.push(orchestratorStatesUrl);
      notificationError(t(`codeResponse.403`));

      return;
    }

    if (!skipGetLicensesCall && !loading) {
      setMounted(false);

      dispatch(licenseFindAllBegin());

      const { data: licensesData, code, success } = data.licenseFindAll;

      if (success) {
        dispatch(licenseFindAllSuccess(licensesData));
      } else {
        dispatch(licenseFindAllError(error));
        notificationError(t(`codeResponse.${code}`));
      }

      setMounted(true);
    }
  }, [
    t,
    data,
    error,
    history,
    loading,
    dispatch,
    setMounted,
    formIsLoading,
    skipGetLicensesCall,
    orchestratorStatesUrl,
    userDoesNotHavePermission,
  ]);

  useEffect(() => {
    fetchLicenses();
  }, [fetchLicenses]);

  // Fetch to get the state to update when it's necessary
  const fetchStateToUpdate = useCallback(() => {
    if (!skipFindOneCall && !loadingStateToUpdate) {
      setMounted(false);

      if (typeof stateToUpdateError !== 'undefined') {
        // eslint-disable-next-line no-console
        console.error('Something went wrong getting the state to update.', stateToUpdateError);
        dispatch(orchestratorStateFindOneError(t('codeResponse.UNEXPECTED_ERROR')));
        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
        history.push(orchestratorStatesUrl);

        return;
      }

      if (typeof stateToUpdate === 'undefined') {
        dispatch(orchestratorStateFindOneError(t('codeResponse.ORCHESTRATOR_STATE_NOT_FOUND')));
        notificationError(t('codeResponse.ORCHESTRATOR_STATE_NOT_FOUND'));

        history.push(orchestratorStatesUrl);

        return;
      }

      const { data: state, success } = stateToUpdate.orchestratorStateFindOne;

      if (success) {
        const formattedStateToUpdate = setupDataForForm(state);
        updateFormData(formattedStateToUpdate);
        dispatch(orchestratorStateFindOneSuccess(formattedStateToUpdate));
      } else {
        dispatch(orchestratorStateFindOneError(t('codeResponse.ORCHESTRATOR_STATE_NOT_FOUND')));
        notificationError(t('codeResponse.ORCHESTRATOR_STATE_NOT_FOUND'));

        history.push(orchestratorStatesUrl);
      }

      setMounted(true);
    }
  }, [
    t,
    history,
    dispatch,
    setMounted,
    stateToUpdate,
    updateFormData,
    skipFindOneCall,
    stateToUpdateError,
    setupDataForForm,
    loadingStateToUpdate,
    orchestratorStatesUrl,
  ]);

  useEffect(() => {
    fetchStateToUpdate();
  }, [fetchStateToUpdate]);

  // Fetch the orchestrator states when it's necessary
  const fetchOrchestratorStates = useCallback(() => {
    if (typeof nextStateError !== 'undefined') {
      // eslint-disable-next-line no-console
      console.error('Something went wrong getting the next states', nextStateError);
      dispatch(orchestratorStateGetAllError(t('codeResponse.UNEXPECTED_ERROR')));
      notificationError(t('codeResponse.UNEXPECTED_ERROR'));

      return;
    }

    if (!skipOrchestratorStatesCall && !loadingNextStates) {
      dispatch(orchestratorStateGetAllBegin());
      setMounted(false);

      const { code: responseCode, data: orchestratorStates, success } = nextStates.orchestratorStateFindAll;

      if (success) {
        dispatch(orchestratorStateGetAllSuccess(orchestratorStates));
      } else {
        dispatch(orchestratorStateGetAllError(responseCode));
        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
      }

      setMounted(true);
    }
  }, [t, dispatch, setMounted, nextStates, nextStateError, loadingNextStates, skipOrchestratorStatesCall]);

  useEffect(() => {
    fetchOrchestratorStates();
  }, [fetchOrchestratorStates]);

  useEffect(() => {
    if (typeof orchestratorStateId !== 'undefined') {
      setSkipFindOneCall(false);
    }

    return () => {
      dispatch(orchestratorStateFindOneReset());
      setSkipFindOneCall(true);
    };
  }, [dispatch, orchestratorStateId]);

  // Clean licenses so the data isn't outdated
  useEffect(() => {
    if (skipGetLicensesCall) {
      dispatch(licenseFindAllSuccess([]));
    }
  }, [dispatch, skipGetLicensesCall]);

  // Clean orchestrator states so the data isn't outdated
  useEffect(() => {
    if (skipOrchestratorStatesCall) {
      dispatch(orchestratorStateGetAllSuccess([]));
    }
  }, [dispatch, skipOrchestratorStatesCall]);

  // Generate the licenses dropdown options
  const generateLicenses = useCallback(() => {
    return licenses
      ?.filter(({ computerInfo }) => computerInfo !== null)
      ?.map(({ serial, computerInfo }, index) => (
        <Option key={index} value={serial}>
          {computerInfo.name}
        </Option>
      ));
  }, [licenses]);

  // Generate the next state dropdown options
  const generateOrchestratorStates = useCallback(() => {
    let availableStates = states;

    // Exclude the same alias from the list on update
    if (!isNewState && currentAlias.length) {
      availableStates = availableStates.filter(state => state.alias !== currentAlias);
    }

    return availableStates?.map(({ _id, alias }, index) => (
      <Option key={index} value={_id}>
        {alias}
      </Option>
    ));
  }, [states, isNewState, currentAlias]);

  const isNextStateRequired = useCallback(() => {
    if (currentAlias?.length) {
      return states.filter(state => state.alias !== currentAlias)?.length > 0;
    }

    return states.length > 0;
  }, [states, currentAlias]);

  return {
    mounted,
    dropdown: {
      states: {
        generate: generateOrchestratorStates,
        nextStateIsRequired: isNextStateRequired(),
      },
      generateLicenses,
    },
  };
};

export default OrchestratorStateForm;
