import { gql } from '@apollo/client';

export const QUERY_FILE_FIND_ALL = gql`
  query fileFindAll($fileListInput: FileListInput!) {
    fileFindAll(fileListInput: $fileListInput) {
      success
      code
      data {
        _id
        name
        runVersion
        awsId
        description
        size
      }
    }
  }
`;

export const QUERY_FILE_DOWNLOAD = gql`
  mutation fileDownload($id: String!) {
    fileDownload(id: $id) {
      success
      code
      data
    }
  }
`;

export const QUERY_FILE_REMOVE = gql`
  mutation fileRemove($id: String!) {
    fileRemove(id: $id) {
      success
      code
      data {
        _id
        name
        tenant {
          _id
          diskUsage {
            available
            occupied
          }
        }
      }
    }
  }
`;

export const QUERY_FILE_CREATE = gql`
  mutation fileCreate($fileCreateInput: FileCreateInput!) {
    fileCreate(fileCreateInput: $fileCreateInput) {
      success
      code
      data {
        _id
        name
        runVersion
        awsId
        description
        size
        tenant {
          _id
          diskUsage {
            available
            occupied
          }
        }
      }
    }
  }
`;
