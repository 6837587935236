import Styled from 'styled-components';
import { Modal } from 'antd';

const ModalStyledColord = (type, theme,color) => `
  .ant-modal-content, .ant-modal-header {
    background:${theme} ;
    color: ${color};
  }
  .ant-modal-title , .ant-modal-content {
    color: ${color};
  }
  

  .ant-modal-confirm .ant-modal-body {
    background:${theme};
  }
`;


const ModalStyled = Styled(Modal)`    
  ${({ theme, type }) => type && ModalStyledColord(type, theme)}
`;

export { ModalStyled, ModalStyledColord};
