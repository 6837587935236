import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Spin } from 'antd';
import App from './App';
import './i18next';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <Suspense
    fallback={
      <div className="spin">
        <Spin />
      </div>
    }
  >
    <App />
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
