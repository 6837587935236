import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams ,NavLink} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Spin, Switch, Input, Button ,Breadcrumb} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import actions from '../../../redux/customReport/actions';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { QUERY_CUSTOM_REPORT_UPDATE, QUERY_CUSTOM_REPORT_FIND_ONE } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';
const {
  customReportUpdateBegin,
  customReportUpdateSuccess,
  customReportUpdateError,
  customReportFindOneBegin,
  customReportFindOneSuccess,
  customReportFindOneError,
} = actions;

const CustomReportEdit = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const customReportState = useSelector(state => state.customReport);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [form] = Form.useForm();
  const { customReportId, botId } = useParams();
  const [customReportUpdate] = useMutation(QUERY_CUSTOM_REPORT_UPDATE);
  const [mounted, setMounted] = useState(false);
  const { data, loading, error } = useQuery(QUERY_CUSTOM_REPORT_FIND_ONE, {
    variables: { id: customReportId ?? '' },
    fetchPolicy: 'network-only',
  });
  const [state, setState] = useState({
    form: {
      name: '',
      enabled: false,
    },
  });

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1')
  };
  /* eslint-enable */

  const handleSubmit = () => {
    dispatch(customReportUpdateBegin());
    customReportUpdate({
      variables: {
        input: {
          _id: customReportId,
          displayName: state.form.name,
          enabled: state.form.enabled,
        },
      },
    })
      .then(({ data }) => {
        if (data.customReportUpdate.success === true) {
          dispatch(customReportUpdateSuccess(data.customReportUpdate.data));
          notificationSuccess(t('transaction.customReport.successUpdated'));
          history.push(`/admin/bot/custom-report/${botId}`);
        } else {
          dispatch(customReportUpdateError(data.customReportUpdate.code));
          notificationError(t(`codeResponse.${data.customReportUpdate.code}`));
        }
      })
      .catch(e => {
        console.log('Custom Report Update Error ', e);
        dispatch(customReportUpdateError());
        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
      });
  };

  const updateForm = useCallback(
    customReport => {
      form.setFieldsValue({
        name: customReport.displayName,
      });
      setState(x => ({
        form: {
          ...x.form,
          name: customReport.displayName,
          enabled: customReport.enabled,
        },
      }));
    },
    [form],
  );

  const handleStatusSwitch = e => {
    setState({
      form: {
        ...state.form,
        enabled: e,
      },
    });
  };

  const handleChange = e => {
    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  useEffect(() => {
    if (
      !currentLoading &&
      (!currentTenant?.userPermissions?.includes(UserPermissions.CustomReportFindOne) ||
        !currentTenant?.userPermissions?.includes(UserPermissions.CustomReportUpdate))
    ) {
      history.push(`/admin/bot/custom-report/${botId}`);
      notificationError(t(`codeResponse.403`));
      return null;
    }

    if (!loading && !mounted && data) {
      dispatch(customReportFindOneBegin());
      if (data.customReportFindOne.success) {
        dispatch(customReportFindOneSuccess(data.customReportFindOne.data));
        updateForm(data.customReportFindOne.data);
      } else {
        dispatch(customReportFindOneError(data.customReportFindOne.code));
        history.push(`/admin/bot/custom-report/${botId}`);
        notificationError(t(`codeResponse.${data.customReportFindOne.code}`));
      }
      setMounted(true);
    }
  }, [currentLoading, currentTenant, data, error, dispatch, history, loading, mounted, t, updateForm, botId]);

  if (!customReportState.loading && mounted) {
    return (
      <>
        <PageHeader ghost title={t('transaction.customReport.update')} />
        <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
                      <Breadcrumb>
                        <Breadcrumb.Item>
                          <NavLink to={`/admin/`}>
                            <HomeOutlined />
                          </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <NavLink to={`/admin/bot`}>
                            <span>{t('labels.bussiness.rpa.bots.title')}</span>
                          </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <NavLink to={`/admin/bot/custom-report/${botId}`}>
                            <span>{t('transaction.customReport.management')}</span>
                          </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <NavLink to="#">{t('transaction.customReport.update')}</NavLink>
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </BreadcrumbWrapperStyle>
          <Row gutter={15}>
            <Col xs={24}>
              <Cards>
                <Form
                  align="center"
                  name="customReport"
                  form={form}
                  validateMessages={validateMessages}
                  onFinish={handleSubmit}
                  layout="vertical"
                  size="middle"
                  scrollToFirstError
                >
                  <Row align="center" gutter={30}>
                    {/* Name */}
                    <Col md={8} xs={24}>
                      <Form.Item name="name" rules={[{ required: true }]} label={t('properties.name.1')}>
                        <Input
                          name="name"
                          onChange={handleChange}
                          value={state.form.name}
                          placeholder={t('properties.name.1')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row align="center" gutter={30}>
                    {/* Enabled */}
                    <Col md={8} xs={24}>
                      <Form.Item name="enabled" label={t('general.status')}>
                        <Switch
                          style={{ float: 'left' }}
                          checked={!!state.form.enabled}
                          onChange={handleStatusSwitch}
                          checkedChildren={t('general.active')}
                          unCheckedChildren={t('general.inactive')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button style={{ height: '44px' }} htmlType="submit" type="primary">
                      {loading ? t('loading.1') : t('general.update')}
                    </Button>
                  </Form.Item>
                </Form>
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default CustomReportEdit;
