const actions = {
  CURRENT_USER_TENANT_BEGIN: 'CURRENT_USER_TENANT_BEGIN',
  CURRENT_USER_TENANT_SUCCESS: 'CURRENT_USER_TENANT_SUCCESS',
  CURRENT_USER_TENANT_ERR: 'CURRENT_USER_TENANT_ERR',

  CURRENT_TENANT_UPDATE_BEGIN: 'CURRENT_TENANT_UPDATE_BEGIN',
  CURRENT_TENANT_UPDATE_SUCCESS: 'CURRENT_TENANT_UPDATE_SUCCESS',
  CURRENT_TENANT_UPDATE_ERR: 'CURRENT_TENANT_UPDATE_ERR',

  currentUserTenantBegin: () => {
    return {
      type: actions.CURRENT_USER_TENANT_BEGIN,
    };
  },

  currentUserTenantSuccess: data => {
    return {
      type: actions.CURRENT_USER_TENANT_SUCCESS,
      data,
    };
  },

  currentUserTenantErr: error => {
    return {
      type: actions.CURRENT_USER_TENANT_ERR,
      error,
    };
  },

  currentTenantUpdateBegin: () => {
    return {
      type: actions.CURRENT_TENANT_UPDATE_BEGIN,
    };
  },

  currentTenantUpdateSuccess: data => {
    return {
      type: actions.CURRENT_TENANT_UPDATE_SUCCESS,
      data,
    };
  },

  currentTenantUpdateErr: error => {
    return {
      type: actions.CURRENT_TENANT_UPDATE_ERR,
      error,
    };
  },
};

export default actions;
