import React, { useEffect } from 'react';
import { Badge } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { textRefactor } from '../utilities';
import Heading from '../../heading/heading';
import actions from '../../../redux/mailNotification/actions';
import { MUTATION_MAIL_NOTIFICATION_READED, QUERY_MAIL_NOTIFICATION_FIND_ALL } from '../../../queries/mail-notification/mail-notification.queries';
import { Empty } from 'antd';


const MessageBox = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const state = useSelector(state => state.mailNotificationReducer.data);
  const { data } = useQuery(QUERY_MAIL_NOTIFICATION_FIND_ALL);
  const [mutationMailNotification] = useMutation(MUTATION_MAIL_NOTIFICATION_READED);

  useEffect(() => {
    dispatch(actions.mailNotificationFindAllSuccess(data?.mailNotificationLogFindAll?.data));
    // eslint-disable-next-line
  }, data);

  const show = item => {
    dispatch(actions.mailNotificationUpdateReadedSuccess(item))
    mutationMailNotification({ variables: { _id: item._id } })
    dispatch(actions.mailNotificationFindOne(item))
  }

  const { rtl } = useSelector(state => {
    return {
      rtl: state.changeLayoutMode.rtlData,
    };
  });

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  const renderView = ({ style, ...props }) => {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  const content = (
    <AtbdTopDropdwon  darkMode={darkMode} className="atbd-top-dropdwon">
      <div className={darkMode?'ant-popover-inner-dark':'ant-popover-inner-light'}> 
      <Heading className={!darkMode? "atbd-top-dropdwon__title": "atbd-top-dropdwon__title_dark"} as="h5">
        <span className="title-text"  style={{ 'padding-right':'10px'}}>{t(`notification.mailNotification.title`)}</span>
        <Badge className="badge-success" count={state?.filter(item => !item.readed).length ?? 0} />
      </Heading>
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        <div className="atbd-top-dropdwon-menu">
          <ul className="atbd-top-dropdwon__nav" style={{ height: 'auto' }}>
            {(state?.length <= 0 || state === null) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {state && state.slice(-5).map(data => (<li key={data._id}  >
              <Link to={`/admin/email/single/${data._id}`} onClick={() => show(data)}>
                <figure className="atbd-top-dropdwon__content">
                  <div className="notification-icon bg-primary">
                    <FeatherIcon icon="mail"></FeatherIcon>
                  </div>
                  <figcaption style={{ marginLeft: '0px' }}>
                    <Heading as="h5">
                      {textRefactor(data.subject, 3)}
                      <span style={{ color: [data.readed === true ? "#adb4d2" : "#20c997"] }}>&nbsp;{new Date(data.createdAt).toDateString()}</span>
                    </Heading>
                    <div>
                      <span className="atbd-top-dropdwonText">{`${data.body.substring(0, 30)}...`}</span>
                      {data.readed === false && <Badge dot />}
                    </div>
                  </figcaption>
                </figure>
              </Link>
            </li>))}
            <ul />
          </ul>
        </div>
      </Scrollbars>
      <Link className={!darkMode? "btn-seeAll": "btn-seeAll-dark"} to={`/admin/email/inbox`}>
        {t(`notification.mailNotification.seeallnotifications`)}
      </Link>
      </div>
    </AtbdTopDropdwon>
  );


  return (
    <div className="message">
      <Popover placement="bottomLeft" content={content} action="click">
        <Badge dot={state?.find(item => !item.readed)} offset={[-8, -5]}>
          <Link to="#" className="head-example">
            <FeatherIcon icon="mail" size={20} />
          </Link>
        </Badge>
      </Popover>
    </div>
  );
};

MessageBox.propTypes = {
  rtl: PropTypes.bool,
};

export default MessageBox;
