import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { Table, Button, Spin, Tooltip, message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router';
import { alertModal } from '../../../components/modals/antd-modals';
import { TableWrapper, UserTableStyleWrapper } from '../styled';
import { GET_API_KEYS_BY_TENANT, DELETE_API_KEY } from '../../../queries';
import apiKeyActions from '../../../redux/apiKey/actions';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

const {
  apiKeyDeleteBegin,
  apiKeyDeleteSuccess,
  apiKeyDeleteError,
  apiKeyGetAllBegin,
  apiKeyGetAllSuccess,
  apiKeyGetAllError,
} = apiKeyActions;

const ApiKeyList = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingApiKeys = useSelector(state => state.apiKey.loading);
  const loadingApiKeyCreate = useSelector(state => state.apiKey.loadingCreate);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [apiKeyRemove] = useMutation(DELETE_API_KEY);
  const [mounted, setMounted] = useState(false);

  const [state, setState] = useState({
    apiKeysDataTable: [],
  });

  const { loading, data, error, refetch } = useQuery(GET_API_KEYS_BY_TENANT, {
    variables: {
      apiKeyListInput: {
        tenant: currentTenant?.tenant._id,
        limit: 0,
        offset: 0,
      },
    },
    fetchPolicy: 'network-only',
  });

  const deleteApiKey = useCallback(
    id => {
      alertModal.confirm({
        title: t('microservices.remove'),
        onOk() {
          dispatch(apiKeyDeleteBegin());

          apiKeyRemove({
            variables: { apiKeyId: id },
          })
            .then(({ data }) => {
              if (data.apiKeyDelete.success) {
                dispatch(apiKeyDeleteSuccess(id));

                setState(prevState => {
                  const apiKeysDataTable = prevState.apiKeysDataTable.filter(item => item.key !== id);
                  return { apiKeysDataTable };
                });

                notificationSuccess(t('microservices.successRemoved'));
              } else {
                dispatch(apiKeyDeleteError(data.apiKeyDelete.code));

                notificationError(t(`codeResponse.${data.apiKeyDelete.code}`));
              }
            })
            .catch(e => {
              console.log('Api Key Delete Error ', e);
              dispatch(apiKeyDeleteError());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [dispatch, apiKeyRemove, t],
  );

  const updateTable = useCallback(
    apiKeysData => {
      const dataSource = [];
      if (apiKeysData)
        apiKeysData.map((apiKey, index) => {
          const { key, type, description, alertEmail, limit, used } = apiKey;
          dataSource.push({
            key: apiKey._id,
            name: (
              <Tooltip title="Copy to clipboard">
                <CopyToClipboard text={key} onCopy={() => message.success('API Key Copiada')}>
                  <span>{key}</span>
                </CopyToClipboard>
              </Tooltip>
            ),
            type,
            description,
            alertEmail,
            limit,
            used,
            actions: (
              <div className="table-actions">
                <>
                  <Button className="btn-icon" type="danger" shape="circle" onClick={() => deleteApiKey(apiKey._id)}>
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                </>
              </div>
            ),
          });
          return true;
        });
      setState({
        apiKeysDataTable: dataSource,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteApiKey, history, t],
  );

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotFindAll)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted && data) {
      dispatch(apiKeyGetAllBegin());
      if (data.apiKeyGetAllByTenant.success) {
        dispatch(apiKeyGetAllSuccess(data.apiKeyGetAllByTenant.data));
        updateTable(data.apiKeyGetAllByTenant.data);
      } else {
        dispatch(apiKeyGetAllError(data.apiKeyGetAllByTenant.code));
      }
      setMounted(true);
    }
  }, [dispatch, data, loading, error, updateTable, history, currentTenant, currentLoading, t, mounted]);

  useEffect(() => {
    dispatch(apiKeyGetAllBegin());
    refetch().then(({ data: dataRefresh }) => {
      if (dataRefresh.apiKeyGetAllByTenant.success) {
        dispatch(apiKeyGetAllSuccess(dataRefresh.apiKeyGetAllByTenant.data));
        updateTable(dataRefresh.apiKeyGetAllByTenant.data);
      } else {
        dispatch(apiKeyGetAllError(dataRefresh.apiKeyGetAllByTenant.code));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingApiKeyCreate]);

  const columns = [
    {
      title: t('microservices.fields.key'),
      dataIndex: 'key',
      key: 'key',
      width: '45%',
    },
    {
      title: t('microservices.fields.service'),
      dataIndex: 'type',
      key: 'type',
      width: '15%',
    },
    {
      title: t('microservices.fields.limit'),
      dataIndex: 'limit',
      key: 'limit',
    },
    {
      title: t('microservices.fields.usage'),
      dataIndex: 'used',
      key: 'used',
    },
    {
      title: t('microservices.fields.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('microservices.fields.alertTo'),
      dataIndex: 'alertEmail',
      key: 'alertEmail',
      width: '40%',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
    },
  ];

  if (!loadingApiKeys && mounted) {
    return (
      <div className="table-bordered leaderboard-table table-responsive">
        <UserTableStyleWrapper darkMode={darkMode} >
          <TableWrapper className="table-responsive">
            <Table
              columns={columns}
              dataSource={state.apiKeysDataTable}
              pagination={{
                defaultPageSize: 10,
                total: state.apiKeysDataTable.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
      </div>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default ApiKeyList;
