import React, { useEffect, useState } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { useHistory, NavLink } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Modal, Form, Input, Breadcrumb } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import UserList from './UserList';
import { CardStyleUserList } from './style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { ExportStyleWrap, Main } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { AutoComplete } from '../../../components/autoComplete/autoComplete';
import actions from '../../../redux/user/actions';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';

const { userFilterBegin, userFilterSuccess } = actions;

const UserManagement = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector(state => state.user.usersFiltered);
  const [filterTable, setFilterTable] = useState({
    email: '',
    userLicense: '',
  });
  const [stateExport, setStateExport] = useState({
    isModalVisible: false,
    fileName: 'User Report',
  });

  const csvData = [['User Json Object'], ...users.map(x => [JSON.stringify(x)])];

  const handleFilterBy = x => `${t('general.filterBy')} ${x[0].toUpperCase() + x.substring(1)}`;

  const handleModal = () => {
    setStateExport({
      ...stateExport,
      isModalVisible: !stateExport.isModalVisible,
    });
  };

  const handleSearch = (searchText, filter) => {
    setFilterTable(x => ({
      ...x,
      [filter]: searchText,
    }));
  };

  const { isModalVisible, fileName } = stateExport;

  const updateFileName = e => {
    setStateExport({
      ...stateExport,
      fileName: e.target.value,
    });
  };

  useEffect(() => {
    dispatch(userFilterBegin());
    dispatch(userFilterSuccess(filterTable));
  }, [filterTable, dispatch]);

  return (
    <>
      <PageHeader ghost title={t('labels.backend.access.users.management')} />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">{t('labels.backend.access.users.management')}</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24}>
            <CardStyleUserList>
              <Cards
                isbutton={
                  <>
                    <div key="6" className="page-header-actions btn-export">
                      <Button
                        size="small"
                        key="1"
                        type="dark"
                        disabled={!(users.length > 0)}
                        className="btn-export"
                        onClick={handleModal}
                      >
                        <FeatherIcon icon="download" size={14} />
                        {t('general.export')}
                      </Button>
                      <Modal
                        title={t('general.exportFile')}
                        wrapClassName="sDash_export-wrap"
                        visible={isModalVisible}
                        footer={null}
                        onCancel={handleModal}
                      >
                        <Form name="export">
                          <Form.Item name="f_name">
                            <Input placeholder={t('properties.fileName')} value={fileName} onChange={updateFileName} />
                          </Form.Item>
                          <div className="sDash-button-grp">
                            <CSVLink filename={`${fileName}.csv`} data={csvData}>
                              <Button onClick={handleModal} className="btn-export" type="primary">
                                {t('general.export')}
                              </Button>
                            </CSVLink>

                            <Button onClick={handleModal} size="default" type="white" outlined>
                              {t('general.cancel')}
                            </Button>
                          </div>
                        </Form>
                      </Modal>
                    </div>
                    <div key="7" className="page-header-actions actions-left">
                      <Button size="small" key="2" type="primary" onClick={() => history.push('/admin/user/create')}>
                        <FeatherIcon icon="user-plus" size={14} />
                        {t('user.create')}
                      </Button>
                      <Button size="small" key="3" type="success" onClick={() => history.push('/admin/user/invite')}>
                        <FeatherIcon icon="users" size={14} />
                        {t('user.invite')}
                      </Button>
                    </div>
                  </>
                }
              >
                <Row>
                  <Col md={12} xs={24}>
                    <ExportStyleWrap>
                      <div className="sDash_export-box">
                        <AutoComplete
                          onSearch={x => handleSearch(x, 'userLicense')}
                          // dataSource={notData}
                          placeholder={handleFilterBy(t('labels.backend.access.users.license'))}
                          width="100%"
                          patterns
                        />
                        <AutoComplete
                          onSearch={x => handleSearch(x, 'email')}
                          // dataSource={notData}
                          placeholder={handleFilterBy(t('properties.email.2'))}
                          width="100%"
                          patterns
                        />
                      </div>
                    </ExportStyleWrap>
                  </Col>
                </Row>
                <UserList />
              </Cards>
            </CardStyleUserList>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default UserManagement;
