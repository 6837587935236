// Libs
import { ApolloClient, ApolloLink, createHttpLink, split, InMemoryCache } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/client/link/utils';
import { WebSocketLink } from '@apollo/client/link/ws';
import { QUERY_USER_TOKEN_REFRESH } from '../queries/auth.queries';

// eslint-disable-next-line prefer-const,import/no-mutable-exports
let apolloClient;
let isRefreshing = false;
let pendingRequests = [];

const getNewTokens = async () =>
  apolloClient.mutate({ mutation: QUERY_USER_TOKEN_REFRESH }).then(res => {
    const {
      success,
      data: { refreshToken },
    } = res.data;
    if (success) return refreshToken;
    return null;
  });

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const hhpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  fetch,
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WS,
  options: {
    reconnect: false,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  hhpLink,
);

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line consistent-return
    graphQLErrors.forEach(error => {
      // @ts-expect-error
      if (error.statusCode === 401) {
        // error code is set to UNAUTHENTICATED
        // when AuthenticationError thrown in resolver
        let forward$;

        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            getNewTokens()
              .then(({ accessToken }) => {
                // Store the new tokens for your auth link
                resolvePendingRequests();
                return accessToken;
              })
              .catch(() => {
                pendingRequests = [];
                // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
              })
              .finally(() => {
                isRefreshing = false;
              }),
          ).filter(value => Boolean(value));
        } else {
          // Will only emit once the Promise is resolved
          forward$ = fromPromise(
            new Promise(resolve => {
              pendingRequests.push(() => resolve());
            }),
          );
        }
        return forward$.flatMap(() => forward(operation));
      }
    });
  }
  if (networkError) {
    // eslint-disable-next-line
    console.log(`[Network error]: ${networkError}`);
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry
  }
});

apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, splitLink]),
  cache: new InMemoryCache(),
});
export const initApolloClient = apolloClient;
export const useApollo = () => apolloClient;
// export const useApollo = () => useMemo(() => apolloClient, [])
