import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { Col, Row, Input, Spin, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../styled';
import { QUERY_WORK_FLOW_CREATE } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { Cards } from '../../../components/cards/frame/cards-frame';
import ReactFlowObjects from '../../../components/react-flow/react-flow-objects';
import ReactFlowDiagram from '../../../components/react-flow/react-flow-diagram';
import ReactFlowProperties from '../../../components/react-flow/react-flow-properties';
import { Button } from '../../../components/buttons/buttons';
import { TypeNode } from '../../../utils/enums/types.enum';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const WorkFlowCreate = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const history = useHistory();
  const loading = useSelector(state => state.workFlow.loading);
  const nodeListProperties = useSelector(state => state.workFlow.nodeListProperties);
  const nodeList = useSelector(state => state.workFlow.nodeListData);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [workFlowCreate] = useMutation(QUERY_WORK_FLOW_CREATE);
  const [workFlow, setWorkFlow] = useState({
    name: '',
    description: '',
  });

  const onChange = e => {
    const { name, value } = e.target;
    setWorkFlow(x => ({
      ...x,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (nodeList.length <= 0) {
      notificationError(t('workFlow.errorCreated'));
      return null;
    }

    if (!workFlow.name) {
      notificationError(t('workFlow.nameRequired'));
      return null;
    }

    const nodesSelector = nodeList.filter(node => node.type === 'selector');
    let check = true;

    /* Get all nodes with their respective properties */
    const nodes = nodeList
      .filter(node => node.type !== 'selector')
      .map(node => {
        const props = nodeListProperties.find(prop => prop.id === node.id);
        const nextNode = nodesSelector.find(sel => sel.source === node.id)?.target || null;

        return {
          nodeId: node.id,
          name: props?.name || '',
          type:
            node.type === 'input'
              ? TypeNode.StartNode
              : node.type === 'output'
                ? TypeNode.EndNode
                : TypeNode.ExecutionNode,
          description: props?.description || '',
          nextNode,
          position: node.position,
          botLicenses: props?.botLicenses,
        };
      });

    /* Check all validations to an INCORRECT process flow */
    for (const node of nodes) {
      const botLicenses = node.botLicenses || [];
      if (node.type !== TypeNode.EndNode && !node.nextNode) {
        check = false;
        notificationError(t('workFlow.errorCreated'));
        break;
      }
      if (node.type === TypeNode.ExecutionNode && botLicenses.length <= 0) {
        check = false;
        notificationError(t('workFlow.botLicensesRequired'));
        break;
      }
    }

    if (check) {
      workFlowCreate({
        variables: {
          workFlowCreateInput: {
            ...workFlow,
            nodes,
          },
        },
      })
        .then(({ data }) => {
          if (data.workFlowCreate.success) {
            notificationSuccess(t('workFlow.successCreated'));
            history.push(`/admin/work-flow`);
          } else notificationError(t(`codeResponse.${data.workFlowCreate.code}`));
        })
        .catch(e => {
          console.log('Work Flow Create Error ', e);
          notificationError(t('codeResponse.UNEXPECTED_ERROR'));
        });
    }
  };

  if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.WorkFlowCreate)) {
    history.push(`/admin/work-flow`);
    notificationError(t(`codeResponse.403`));
    return null;
  }

  if (loading)
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  return (
    <>
      <PageHeader
        ghost
        title={t('workFlow.create')}
        buttons={[
          <div key="6" className="page-header-actions">
            <Button onClick={handleSubmit} size="small" key="1" type="primary">
              {t('general.create')}
            </Button>
          </div>,
        ]}
      />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle arkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/admin/work-flow/`}>
                <span>{t('workFlow.management')}</span>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">{t('workFlow.create')}</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24} md={6}>
            <Cards title={<div style={{ textAlign: 'center' }}>{t('general.objects')}</div>}>
              <ReactFlowObjects />
            </Cards>
          </Col>
          <Col xs={24} md={12}>
            <Cards
              title={
                <>
                  <div style={{ textAlign: 'center' }}>
                    <Input
                      onChange={onChange}
                      value={workFlow.name}
                      style={{ textAlign: 'center' }}
                      size="small"
                      name="name"
                      placeholder={t('workFlow.name.2')}
                    />
                  </div>
                </>
              }
            >
              <ReactFlowDiagram />
            </Cards>
          </Col>
          <Col xs={24} md={6}>
            <Cards title={<div style={{ textAlign: 'center' }}>{t('general.properties')}</div>}>
              <ReactFlowProperties />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default WorkFlowCreate;
