const TypeNodeEnum = {
  StartNode: 'StartNode',
  ExecutionNode: 'ExecutionNode',
  EndNode: 'EndNode',
};

const SocialEnum = {
  MICROSOFT: 'MICROSOFT',
  GOOGLE: 'GOOGLE',
  DEFAULT:'DEFAULT'
}

const SourceEnum = {
  MANAGER: 'MANAGER',
  PARTNER: 'PARTNER',
  MODELER: 'MODELER',
};
const WebNotificationTypeEnum = {
  Success: 'Success',
  Error: 'Error'
}

export const WebNotificationType = Object.freeze(WebNotificationTypeEnum);
export const TypeNode = Object.freeze(TypeNodeEnum);
export const Source = Object.freeze(SourceEnum);
export const Social = Object.freeze(SocialEnum);
