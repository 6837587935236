import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorWrapper } from './style';
import { Main } from '../pages/admin/styled';
import Heading from '../components/heading/heading';
import { Button } from '../components/buttons/buttons';
const NotFound = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const currentUser = useSelector(state => state.currentUserTenant.currentUser);
  const { t } = useTranslation();
  return (
    <Main darkMode={darkMode}>
      <ErrorWrapper>
        <img src={require(`../static/img/pages/404.svg`)} alt="404" />
        <Heading className="error-text" as="h3">
          404
        </Heading>
        <p>{t('page404')}</p>
        <NavLink to={currentUser ? '/admin' : '/'}>
          <Button size="default" type="primary">
            {`${t('return')} ${currentUser ? 'Admin' : 'Login'}`}
          </Button>
        </NavLink>
      </ErrorWrapper>
    </Main>
  );
};

export default NotFound;
