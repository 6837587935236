import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import NotFound from '../../utils/404';
import OrchestratorStateForm from '../../pages/admin/orchestrator-states/OrchestratorStateForm';
import OrchestratorStateManagement from '../../pages/admin/orchestrator-states';

const OrchestratorStatesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={OrchestratorStateManagement} />
      <Route exact path={`${path}/create`} component={OrchestratorStateForm} />
      <Route path={`${path}/:orchestratorStateId/edit`} component={OrchestratorStateForm} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default OrchestratorStatesRoutes;
