import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Select } from 'antd';

import { useHistory, useParams } from 'react-router-dom';
import { QUERY_BOT_SCHEDULE_FIND_ONE, QUERY_BOT_SCHEDULE_SERVER_INFO } from '../../queries';
import { UserPermissions } from '../../utils/enums/permissions.enum';
import botScheduleActions from '../../redux/botSchedule/actions';
import { BotScheduleType } from '../../utils/enums/bot-schedule.enum';
import { notificationError } from '../../components/utilities/notification';
import { BotScheduleHelper } from '../../helpers/BotSchedule.helper';

const {
  botScheduleGetServerInfoBegin,
  botScheduleGetServerInfoSuccess,
  botScheduleGetServerInfoError,
  botScheduleFindOneSuccess,
  botScheduleFindOneReset,
} = botScheduleActions;

const BotScheduleForm = (currentTenant, currentLoading, scheduleType, updateForm, actionIsCreateSchedule) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { botId, scheduleId } = useParams();
  const dispatch = useDispatch();
  const { serverInfo } = useSelector(state => state.botSchedule);

  const [mounted, setMounted] = useState(false);
  const [skipFindOneCall, setSkipFindOneCall] = useState(true);
  const fromScheduleType = useMemo(() => scheduleType, [scheduleType]);
  const formIsLoading = useMemo(() => currentLoading, [currentLoading]);

  const permissionToCheck = actionIsCreateSchedule
    ? UserPermissions.BotScheduleCreate
    : UserPermissions.BotScheduleUpdate;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userDoesNotHavePermission = useMemo(() => !currentTenant?.userPermissions?.includes(permissionToCheck), [
    currentTenant,
  ]);

  const { loading, data, error } = useQuery(QUERY_BOT_SCHEDULE_SERVER_INFO, {
    variables: { botId },
    fetchPolicy: 'network-only',
  });

  const { loading: loadingScheduleToUpdate, data: scheduleToUpdate } = useQuery(QUERY_BOT_SCHEDULE_FIND_ONE, {
    skip: skipFindOneCall,
    variables: { id: scheduleId },
    fetchPolicy: 'network-only',
  });

  const { Option } = Select;

  const setScheduleDataIntoForm = useCallback(
    dataSchedule => {
      const { type } = dataSchedule;
      const repeat = dataSchedule.repeat ? 'indefinitely' : 'once';
      const licenses = dataSchedule.licenses.map(license => license.serial);
      const schedule = dataSchedule.schedule.map((scheduleItem, index) => ({
        key: index,
        value: scheduleItem,
        moment: BotScheduleHelper.generaMomentFromDate(type, scheduleItem),
      }));

      return {
        _id: dataSchedule._id,
        type,
        action: dataSchedule.action,
        repeat,
        schedule,
        licenses,
        scheduleToUpdate,
        enabled: dataSchedule.enabled,
      };
    },
    [scheduleToUpdate],
  );

  // Dispatch the call to fetch the server information
  const fetchInformation = useCallback(() => {
    if (!formIsLoading && userDoesNotHavePermission) {
      history.push('/admin/bot');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted) {
      dispatch(botScheduleGetServerInfoBegin());

      if (typeof data === 'undefined') {
        dispatch(botScheduleGetServerInfoError(error));
        notificationError(t('codeResponse.MICROSERVICE_UNAVAILABLE'));

        history.push(`/admin/bot/${botId}/schedule`);

        return;
      }

      const { success, data: serverInf, code } = data.botScheduleServerInfo;

      if (success) {
        dispatch(botScheduleGetServerInfoSuccess(serverInf));
      } else {
        dispatch(botScheduleGetServerInfoError(error));
        notificationError(t(`codeResponse.${code}`));
      }

      setMounted(true);
    }
  }, [
    t,
    data,
    botId,
    error,
    history,
    mounted,
    loading,
    dispatch,
    setMounted,
    formIsLoading,
    userDoesNotHavePermission,
  ]);

  useEffect(() => {
    fetchInformation();
  }, [fetchInformation]);

  // Handle the fetch to format the schedule to update
  const fetchScheduleToUpdate = useCallback(() => {
    if (!skipFindOneCall && !loadingScheduleToUpdate) {
      if (typeof scheduleToUpdate === 'undefined') {
        dispatch(botScheduleGetServerInfoError(t('codeResponse.SCHEDULE_NOT_FOUND')));
        notificationError(t('codeResponse.SCHEDULE_NOT_FOUND'));

        history.push(`/admin/bot/${botId}/schedule`);

        return;
      }

      const { success, data: dataScheduleFindOne } = scheduleToUpdate.botScheduleFindOne;

      if (success) {
        const formattedSchedule = setScheduleDataIntoForm(dataScheduleFindOne);
        updateForm(formattedSchedule);
        dispatch(botScheduleFindOneSuccess(formattedSchedule));
      } else {
        dispatch(botScheduleGetServerInfoError(t('codeResponse.SCHEDULE_NOT_FOUND')));
        notificationError(t('codeResponse.SCHEDULE_NOT_FOUND'));

        history.push(`/admin/bot/${botId}/schedule`);
      }
    }
  }, [
    t,
    botId,
    history,
    dispatch,
    updateForm,
    skipFindOneCall,
    scheduleToUpdate,
    setScheduleDataIntoForm,
    loadingScheduleToUpdate,
  ]);

  useEffect(() => {
    fetchScheduleToUpdate();
  }, [fetchScheduleToUpdate]);

  // Clear the schedule on component destroy
  useEffect(() => {
    if (typeof scheduleId !== 'undefined') {
      setSkipFindOneCall(false);
    }

    return () => {
      setSkipFindOneCall(true);
      dispatch(botScheduleFindOneReset());
    };
  }, [dispatch, scheduleId]);

  // Methods to generate the dropdown values
  const generateScheduleFrequencies = useCallback(() => {
    return ['indefinitely', 'once'].reduce((frequencies, current, index) => {
      const isDateSelected = fromScheduleType === BotScheduleType.DATE;

      if (isDateSelected && current !== 'once') {
        return frequencies;
      }

      frequencies.push(
        <Option key={index} value={current}>
          {t(`botSchedule.${current}`)}
        </Option>,
      );

      return frequencies;
    }, []);
  }, [t, fromScheduleType]);

  const generateScheduleLicenses = useCallback(() => {
    const { availableLicenses } = serverInfo;

    return availableLicenses?.map((license, index) => (
      <Option key={index} value={license.serial}>
        {license.computerInfo.name}
      </Option>
    ));
  }, [serverInfo]);

  const generateScheduleTypes = useCallback(() => {
    const { scheduleTypes } = serverInfo;

    return scheduleTypes?.map((type, index) => (
      <Option key={index} value={type.key}>
        {t(`botSchedule.vars.types.${type.key}`)}
      </Option>
    ));
  }, [serverInfo, t]);

  const generateScheduleActions = useCallback(() => {
    const { scheduleActions } = serverInfo;

    return scheduleActions?.map((action, index) => (
      <Option key={index} value={action.key}>
        {t(`botSchedule.vars.actions.${action.key}`)}
      </Option>
    ));
  }, [serverInfo, t]);

  return {
    mounted,
    serverInfo,
    dropdown: {
      generateScheduleTypes,
      generateScheduleActions,
      generateScheduleLicenses,
      generateScheduleFrequencies,
    },
  };
};

export default BotScheduleForm;
