import actions from './actions';

const {
  ORCHESTRATOR_STATE_GET_ALL_BEGIN,
  ORCHESTRATOR_STATE_GET_ALL_SUCCESS,
  ORCHESTRATOR_STATE_GET_ALL_ERROR,
  ORCHESTRATOR_STATE_CREATE_BEGIN,
  ORCHESTRATOR_STATE_CREATE_SUCCESS,
  ORCHESTRATOR_STATE_CREATE_ERROR,
  ORCHESTRATOR_STATE_UPDATE_BEGIN,
  ORCHESTRATOR_STATE_FIND_ONE_BEGIN,
  ORCHESTRATOR_STATE_FIND_ONE_SUCCESS,
  ORCHESTRATOR_STATE_FIND_ONE_ERROR,
  ORCHESTRATOR_STATE_FIND_ONE_RESET,
  ORCHESTRATOR_STATE_UPDATE_SUCCESS,
  ORCHESTRATOR_STATE_UPDATE_ERROR,
  ORCHESTRATOR_STATE_REMOVE_BEGIN,
  ORCHESTRATOR_STATE_REMOVE_SUCCESS,
  ORCHESTRATOR_STATE_REMOVE_ERROR,
  ORCHESTRATOR_STATE_DEACTIVATE_REDRAW_TABLE,
} = actions;

const initialState = {
  state: undefined,
  states: [],
  error: false,
  loading: false,
  reDrawTable: false,
};

const orchestratorStateReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case ORCHESTRATOR_STATE_GET_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ORCHESTRATOR_STATE_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        states: data ?? [],
      };

    case ORCHESTRATOR_STATE_GET_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    case ORCHESTRATOR_STATE_REMOVE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ORCHESTRATOR_STATE_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        reDrawTable: true,
        states: state.states.filter(x => x._id !== data),
      };

    case ORCHESTRATOR_STATE_REMOVE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    case ORCHESTRATOR_STATE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ORCHESTRATOR_STATE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ORCHESTRATOR_STATE_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    case ORCHESTRATOR_STATE_FIND_ONE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case ORCHESTRATOR_STATE_FIND_ONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        state: data,
      };
    }

    case ORCHESTRATOR_STATE_FIND_ONE_RESET:
      return {
        ...state,
        state: undefined,
      };

    case ORCHESTRATOR_STATE_FIND_ONE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ORCHESTRATOR_STATE_UPDATE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case ORCHESTRATOR_STATE_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case ORCHESTRATOR_STATE_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ORCHESTRATOR_STATE_DEACTIVATE_REDRAW_TABLE: {
      return {
        ...state,
        reDrawTable: false,
      };
    }

    default:
      return state;
  }
};

export default orchestratorStateReducer;
