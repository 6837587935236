const actions = {
  PASSWORD_RESET_BEGIN: 'PASSWORD_RESET_BEGIN',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_ERR: 'PASSWORD_RESET_ERR',

  passwordResetBegin: () => {
    return {
      type: actions.PASSWORD_RESET_BEGIN,
    };
  },

  passwordResetSuccess: data => {
    return {
      type: actions.PASSWORD_RESET_SUCCESS,
      data,
    };
  },

  passwordResetErr: err => {
    return {
      type: actions.PASSWORD_RESET_ERR,
      err,
    };
  },
};

export default actions;
