import React, { useState, lazy, useEffect, useCallback } from 'react';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Spin, Breadcrumb } from 'antd';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import actions from '../../../redux/license/actions';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import {
  QUERY_RESOURCE_GROUP_FIND_ALL,
  QUERY_RESOURCE_GROUP_FIND_ONE,
  QUERY_LICENSE_UPDATE,
  QUERY_LICENSE_FIND_ONE,
} from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const {
  licenseUpdateBegin,
  licenseUpdateSuccess,
  licenseUpdateError,
  licenseFindOneBegin,
  licenseFindOneSuccess,
  licenseFindOneError,
} = actions;

const LicenseCreateAddons = lazy(() => import('./LicenseCreateAddons'));

const LicenseEdit = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const licenseState = useSelector(state => state.license);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [form] = Form.useForm();
  const { licenseId } = useParams();
  const [licenseUpdate] = useMutation(QUERY_LICENSE_UPDATE);
  const [mounted, setMounted] = useState(false);
  const { data: dataResourceGroupFindAll } = useQuery(QUERY_RESOURCE_GROUP_FIND_ALL, {
    variables: {
      tenantId: currentTenant?.tenant._id,
    },
    fetchPolicy: 'network-only',
  });
  const [resourceGroupFindOne, { data: dataResourceGroupFindOne, loading: loadingResourceGroup }] = useLazyQuery(
    QUERY_RESOURCE_GROUP_FIND_ONE,
    {
      fetchPolicy: 'network-only',
    },
  );
  const { data, loading, error } = useQuery(QUERY_LICENSE_FIND_ONE, {
    variables: { id: licenseId ?? '' },
    fetchPolicy: 'network-only',
  });
  const [state, setState] = useState({
    form: {
      enabled: false,
      machineLicense: '',
      addons: [],
      resourceGroup: null,
    },
  });

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1')
  };
  /* eslint-enable */

  const handleSubmit = () => {
    dispatch(licenseUpdateBegin());
    licenseUpdate({
      variables: {
        input: {
          serial: licenseState.current.serial,
          machineLicense: state.form.machineLicense,
          enabled: state.form.enabled,
          addons: state.form.addons,
          resourceGroup: state.form.resourceGroup._id,
        },
      },
    })
      .then(({ data }) => {
        if (data.licenseUpdate.success === true) {
          dispatch(licenseUpdateSuccess(data.licenseUpdate.data));
          notificationSuccess(t('license.successUpdated'));
          history.push('/admin/license');
        } else {
          dispatch(licenseUpdateError(data.licenseUpdate.code));
          notificationError(t(`codeResponse.${data.licenseUpdate.code}`));
        }
      })
      .catch(e => {
        console.log('License Update Error ', e);
        dispatch(licenseUpdateError());

        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
      });
  };

  const onChangeAddons = e => {
    setState(x => ({
      form: {
        ...x.form,
        addons: e,
      },
    }));
  };

  const handleSwitch = e => {
    setState(x => ({
      form: {
        ...x.form,
        enabled: e,
      },
    }));
  };

  const handleChangeSelect = e => {
    setState(x => ({
      form: {
        ...x.form,
        machineLicense: e,
      },
    }));
  };

  const handleChangeResourceGroup = id => {
    resourceGroupFindOne({
      variables: {
        id,
      },
    });
  };

  const updateForm = useCallback(
    license => {
      form.setFieldsValue({
        enabled: license.enabled,
        machineLicense: license.machineLicense,
        resourceGroup: license.resourceGroup.enabled ? license.resourceGroup._id : null,
      });
      setState(x => ({
        form: {
          ...x.form,
          enabled: license.enabled,
          machineLicense: license.machineLicense,
          addons: license.addons,
          resourceGroup: license.resourceGroup.enabled ? license.resourceGroup : null,
        },
      }));
    },
    [form],
  );

  useEffect(() => {
    if (
      !currentLoading &&
      (!currentTenant?.userPermissions?.includes(UserPermissions.LicenseFindOne) ||
        !currentTenant?.userPermissions?.includes(UserPermissions.LicenseUpdate))
    ) {
      history.push('/admin/license');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted && data) {
      dispatch(licenseFindOneBegin());
      if (data.licenseFindOne.success) {
        dispatch(licenseFindOneSuccess(data.licenseFindOne.data));
        updateForm(data.licenseFindOne.data);
      } else {
        dispatch(licenseFindOneError(data.licenseFindOne.code));
        history.push('/admin/license');
        notificationError(t(`codeResponse.${data.licenseFindOne.code}`));
      }
      setMounted(true);
    }
  }, [currentLoading, currentTenant, data, error, dispatch, history, loading, mounted, t, updateForm]);

  useEffect(() => {
    if (dataResourceGroupFindOne?.resourceGroupFindOne.success) {
      const resourceGroupFound = dataResourceGroupFindOne?.resourceGroupFindOne;
      setState(x => ({
        form: {
          ...x.form,
          resourceGroup: resourceGroupFound.data,
          machineLicense: null,
        },
      }));
      form.setFieldsValue({
        machineLicense: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataResourceGroupFindOne]);
  if (!licenseState.loading && mounted) {
    return (
      <>
        <PageHeader ghost title={t('license.update')} />
        <Main darkMode={darkMode}>
          <BreadcrumbWrapperStyle darkMode={darkMode}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to={`/admin/`}>
                  <HomeOutlined />
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to={`/admin/license`}>
                  <span>{t('license.management')}</span>
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="#">{t('license.update')}</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </BreadcrumbWrapperStyle>
          <Row gutter={15}>
            <Col xs={24}>
              <Cards
                title={
                  <>
                    {`${t('properties.serial.1')}: ${licenseState?.current?.serial}`}
                  </>
                }
              >
                <LicenseCreateAddons
                  action="update"
                  resourceGroups={dataResourceGroupFindAll?.resourceGroupFindAll.data.filter(x => x.enabled)}
                  loadingResourceGroup={loadingResourceGroup}
                  licenseLoading={licenseState.loading}
                  form={form}
                  state={state}
                  validateMessages={validateMessages}
                  initialState={data?.licenseFindOne.data}
                  onChangeAddons={onChangeAddons}
                  handleChangeSelect={handleChangeSelect}
                  handleSubmit={handleSubmit}
                  handleSwitch={handleSwitch}
                  handleChangeResourceGroup={handleChangeResourceGroup}
                />
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default LicenseEdit;
