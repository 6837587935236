import $ from 'jquery';
import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Form, Input, Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router';
import { AuthWrapper } from './style';
import Heading from '../../components/heading/heading';
import Alert from '../../components/alerts/alerts';
import actions from '../../redux/passwordReset/actions';
import { QUERY_PASSWORD_RESET, QUERY_PASSWORD_TOKEN_VALIDATE } from '../../queries';

const { passwordResetSuccess, passwordResetErr, passwordResetBegin } = actions;

const PasswordReset = () => {
  const { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();
  const error = useSelector(state => state.passwordReset.error);
  const [passwordReset] = useMutation(QUERY_PASSWORD_RESET);
  const { loading: passwordTokenLoading, data: passwordTokenData, error: passwordTokenError } = useQuery(
    QUERY_PASSWORD_TOKEN_VALIDATE,
    {
      variables: {
        token,
      },
      fetchPolicy: 'network-only',
    },
  );
  const [state, setState] = useState({
    password: '',
    confirm: '',
    token,
    email: '',
    success: false,
  });

  useEffect(() => {
    dispatch(passwordResetBegin());
    if (!passwordTokenLoading) {
      if (passwordTokenData.passwordTokenValidate.success === true) {
        setState(x => {
          return {
            ...x,
            success: true,
            email: passwordTokenData.passwordTokenValidate.data,
          };
        });
      } else {
        dispatch(passwordResetErr(`codeResponse.${passwordTokenData.passwordTokenValidate.code}`));
        history.push('/');
      }
    }
  }, [dispatch, history, passwordTokenData, passwordTokenLoading, passwordTokenError]);

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1', { value: '${label}' })
  };
  /* eslint-enable */

  const handleSubmit = () => {
    passwordReset({
      variables: {
        passwordResetInput: {
          token,
          password: state.password,
        },
      },
    })
      .then(({ data }) => {
        if (data.passwordReset) {
          if (data.passwordReset.success === true) {
            dispatch(passwordResetSuccess('password.resetSuccess'));
            history.push('/');
          } else {
            dispatch(passwordResetErr(`codeResponse.${data.passwordReset.code}`));
          }
        }
      })
      .catch(e => {
        dispatch(passwordResetErr(e));
      });
  };

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  setInterval(() => {
    if ($('.alert-resetPassErr').length && $(".alert-resetPassErr [role='alert']").length <= 0) {
      dispatch(passwordResetErr(null));
    }
  }, [500]);

  if (state.success) {
    return (
      <AuthWrapper>
        <div className="auth-contents">
          <Form
            form={form}
            name="resetPass"
            onFinish={handleSubmit}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <Heading as="h3">{t('password.resetPassword')}</Heading>
            {error ? (
              <Form.Item className="alert-resetPassErr">
                <Alert closable message="" description={t(error)} type="error" />
              </Form.Item>
            ) : (
              ''
            )}
            <Form.Item
              name="password"
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    const regexUpper = new RegExp('^(?=.*[A-Z])');
                    const regexLower = new RegExp('^(?=.*[a-z])');
                    const regexNumber = new RegExp('(?=.*[0-9])');
                    const regexSpecial = new RegExp('(?=.*[!@#$%^&*])');
                    const regexLength = new RegExp('(?=.{8,})');

                    if (value) {
                      if (!regexUpper.test(value)) {
                        return Promise.reject(new Error(t('password.regex.1')));
                      }
                      if (!regexLower.test(value)) {
                        return Promise.reject(new Error(t('password.regex.2')));
                      }
                      if (!regexNumber.test(value)) {
                        return Promise.reject(new Error(t('password.regex.3')));
                      }
                      if (!regexSpecial.test(value)) {
                        return Promise.reject(new Error(t('password.regex.4')));
                      }
                      if (!regexLength.test(value)) {
                        return Promise.reject(new Error(t('password.regex.5')));
                      }

                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                }),
              ]}
              label={t('properties.passwordNew.1')}
              hasFeedback
            >
              <Input.Password
                name="password"
                onChange={handleChange}
                value={state.password}
                placeholder={t('properties.passwordNew.1')}
              />
            </Form.Item>
            <Form.Item
              name="confirm"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(t('password.passwordsNotMatch')));
                  },
                }),
              ]}
              label={t('properties.passwordConfirm.1')}
              dependencies={['password']}
              hasFeedback
            >
              <Input.Password
                name="passwordConfirm"
                onChange={handleChange}
                value={state.confirm}
                placeholder={t('properties.passwordConfirm.1')}
              />
            </Form.Item>
            <Form.Item>
              <Button className="btn-reset" htmlType="submit" type="primary" size="large">
                {t('reset')}
              </Button>
            </Form.Item>
            <p className="return-text">
              {t('return')} <NavLink to="/">{t('signIn.1')}</NavLink>
            </p>
          </Form>
        </div>
      </AuthWrapper>
    );
  }
  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default PasswordReset;
