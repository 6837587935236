import { useQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Button, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { QUERY_WORK_FLOW_FIND_ALL, QUERY_WORK_FLOW_REMOVE, QUERY_WORK_FLOW_START } from '../../../queries';
import { alertModal } from '../../../components/modals/antd-modals';
import { TableWrapper, UserTableStyleWrapper } from '../styled';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

const WorkFlowList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [workFlowStart] = useMutation(QUERY_WORK_FLOW_START);
  const [workFlowRemove] = useMutation(QUERY_WORK_FLOW_REMOVE);
  const [workFlowState, setWorkFlowState] = useState({
    data: [],
    loading: false,
    error: null,
  });
  const [state, setState] = useState({
    workFlowsDataTable: [],
  });
  const { loading, data, error, refetch } = useQuery(QUERY_WORK_FLOW_FIND_ALL, {
    variables: {
      tenantId: currentTenant.tenant?._id,
    },
    fetchPolicy: 'network-only',
  });

  const handleWorkFlowStart = useCallback(
    id => {
      alertModal.confirm({
        title: t('workFlow.confirmStart'),
        onOk() {
          workFlowStart({
            variables: { id },
          })
            .then(({ data }) => {
              if (!data.workFlowStart.success) {
                setWorkFlowState(x => ({
                  ...x,
                  error: data.workFlowStart.code,
                  loading: false,
                }));
                notificationError(t(`codeResponse.${data.workFlowStart.code}`));
              }
              refetch();
            })
            .catch(e => {
              setWorkFlowState(x => ({
                ...x,
                error: e,
                loading: false,
              }));
              console.log('Work Flow Start Error ', e);
              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [workFlowStart, refetch, t],
  );

  const handleWorkFlowRemove = useCallback(
    id => {
      alertModal.confirm({
        title: t('workFlow.confirmRemove'),
        onOk() {
          setWorkFlowState(x => ({
            ...x,
            loading: true,
          }));
          workFlowRemove({
            variables: { id },
          })
            .then(({ data }) => {
              if (data.workFlowRemove.success) {
                setWorkFlowState(x => ({
                  ...x,
                  loading: false,
                }));
                notificationSuccess(t('workFlow.successRemoved'));
              } else {
                setWorkFlowState(x => ({
                  ...x,
                  error: data.workFlowRemove.code,
                  loading: false,
                }));
                notificationError(t(`codeResponse.${data.workFlowRemove.code}`));
              }
              refetch();
            })
            .catch(e => {
              setWorkFlowState(x => ({
                ...x,
                error: e,
                loading: false,
              }));
              console.log('Work Flow Remove Error ', e);
              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [workFlowRemove, refetch, t],
  );

  useEffect(() => {
    if (workFlowState.data?.length > 0) {
      setState({
        workFlowsDataTable: workFlowState.data.map((workFlow, index) => {
          const { _id, name } = workFlow;
          return {
            key: _id,
            name,
            actions: (
              <div className="table-actions">
                <>
                  <Button
                    disabled={!!workFlow.connection?._id}
                    className="btn-icon"
                    type="primary"
                    shape="circle"
                    onClick={() => handleWorkFlowStart(_id)}
                  >
                    <FeatherIcon icon="skip-forward" size={16} />
                  </Button>
                  <Button
                    className="btn-icon"
                    type="info"
                    shape="circle"
                    onClick={() => history.push(`/admin/work-flow/edit/${_id}`)}
                  >
                    <FeatherIcon icon="edit" size={16} />
                  </Button>
                  <Button className="btn-icon" type="danger" shape="circle" onClick={() => handleWorkFlowRemove(_id)}>
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                </>
              </div>
            ),
          };
        }),
      });
    } else {
      setState({
        workFlowsDataTable: [],
      });
    }
  }, [workFlowState.data, handleWorkFlowRemove, handleWorkFlowStart, history, t]);

  useEffect(() => {
    if (!loading && data?.workFlowFindAll.success) {
      setWorkFlowState(x => ({
        ...x,
        data: data.workFlowFindAll.data,
      }));
    }
    if (error) console.log(error);
  }, [data, loading, error, t, history]);

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.WorkFlowFindAll)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }
  }, [history, currentTenant, currentLoading, t]);

  const columns = [
    {
      title: t('labels.bussiness.rpa.bots.table.name'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
    },
  ];

  if (!loading && !workFlowState.loading) {
    return (
      <div className="table-bordered leaderboard-table table-responsive">
        <UserTableStyleWrapper>
          <TableWrapper className="table-responsive">
            <Table
              columns={columns}
              dataSource={state.workFlowsDataTable}
              pagination={{
                defaultPageSize: 10,
                total: state.workFlowsDataTable.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
      </div>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default WorkFlowList;
