import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import { Table, Button, Spin, Progress, Tooltip } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { QUERY_FILE_REMOVE, QUERY_FILE_DOWNLOAD, QUERY_FILE_FIND_ALL } from '../../../queries';
import fileActions from '../../../redux/file/actions';
import currentActions from '../../../redux/currentUserTenant/actions';
import { alertModal } from '../../../components/modals/antd-modals';
import { TableWrapper } from '../styled';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

const {
  fileDownloadBegin,
  fileDownloadSuccess,
  fileDownloadError,
  fileRemoveBegin,
  fileRemoveSuccess,
  fileRemoveError,
  fileFindAllBegin,
  fileFindAllSuccess,
  fileFindAllError,
} = fileActions;

const { currentTenantUpdateBegin, currentTenantUpdateSuccess, currentTenantUpdateErr } = currentActions;

const FileList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const files = useSelector(state => state.file.files);
  const loadingFiles = useSelector(state => state.file.loading);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [fileDownload] = useMutation(QUERY_FILE_DOWNLOAD);
  const [fileRemove] = useMutation(QUERY_FILE_REMOVE);
  const [mounted, setMounted] = useState(false);
  const [state, setState] = useState({
    filesDataTable: [],
    consumedSpace: 0,
  });

  const { loading, data } = useQuery(QUERY_FILE_FIND_ALL, {
    variables: {
      fileListInput: { isPlataformaFile: false, tenant: currentTenant?.tenant._id },
    },
    fetchPolicy: 'network-only',
  });

  const handleDownloadFile = useCallback(
    id => {
      dispatch(fileDownloadBegin());

      fileDownload({
        variables: { id },
      })
        .then(({ data }) => {
          if (data.fileDownload.success) {
            dispatch(fileDownloadSuccess());
            window.open(data.fileDownload.data, '_blank');
          } else {
            dispatch(fileDownloadError(data.fileDownload.code));
            notificationError(t(`codeResponse.${data.fileDownload.code}`));
          }
        })
        .catch(e => {
          console.log('File Download Error ', e);
          dispatch(fileDownloadError());

          notificationError(t('codeResponse.UNEXPECTED_ERROR'));
        });
    },
    [dispatch, fileDownload, t],
  );

  const deleteFile = useCallback(
    (id, size) => {
      alertModal.confirm({
        title: t('labels.bussiness.rpa.files_shared.confirm_delete'),
        onOk() {
          dispatch(fileRemoveBegin());
          dispatch(currentTenantUpdateBegin());

          fileRemove({
            variables: { id },
          })
            .then(({ data }) => {
              if (data.fileRemove.success) {
                dispatch(fileRemoveSuccess(data.fileRemove.data._id));
                dispatch(currentTenantUpdateSuccess({ ...currentTenant, tenant: data.fileRemove.data.tenant }));

                // Delete item from table
                setState(prevState => {
                  const filesDataTable = prevState.filesDataTable.filter(item => item.key !== id);
                  const consumedSpace = prevState.consumedSpace - size;
                  return { filesDataTable, consumedSpace };
                });

                // Notify result
                notificationSuccess(t('labels.bussiness.rpa.files_shared.success_deleted'));
              } else {
                dispatch(fileRemoveError(data.fileRemove.code));
                dispatch(currentTenantUpdateErr());

                // Notify result
                notificationError(t(`codeResponse.${data.fileRemove.code}`));
              }
            })
            .catch(e => {
              console.log('Remove File Error ', e);
              dispatch(fileRemoveError());
              dispatch(currentTenantUpdateErr());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [dispatch, fileRemove, t, currentTenant],
  );

  useEffect(() => {
    if (files?.length > 0) {
      setState(x => ({
        ...x,
        filesDataTable: files.map((file, index) => {
          const { name, runVersion: version, description, size } = file;
          return {
            key: file._id,
            name,
            version,
            description,
            size: `${Number(size).toPrecision(4)} MB`,
            actions: (
              <div className="table-actions">
                <>
                  <Button
                    className="btn-icon"
                    type="primary"
                    shape="circle"
                    onClick={() => handleDownloadFile(file._id)}
                  >
                    <FeatherIcon icon="download" size={16} />
                  </Button>
                  <Button className="btn-icon" type="danger" shape="circle" onClick={() => deleteFile(file._id, size)}>
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                </>
              </div>
            ),
          };
        }),
      }));
      setState(x => ({
        ...x,
        consumedSpace: currentTenant?.tenant.diskUsage.occupied,
      }));
    } else {
      setState(x => ({
        ...x,
        filesDataTable: [],
      }));
    }
  }, [deleteFile, files, handleDownloadFile, currentTenant]);

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.FileFindAll)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted && data) {
      dispatch(fileFindAllBegin());
      if (data.fileFindAll.success) {
        dispatch(fileFindAllSuccess(data.fileFindAll.data));
      } else {
        dispatch(fileFindAllError(data.fileFindAll.code));
        history.push('/admin');
        notificationError(t(`codeResponse.${data.fileFindAll.code}`));
      }
      setMounted(true);
    }
  }, [dispatch, data, loading, currentTenant, currentLoading, t, history, mounted]);

  const columns = [
    {
      title: t('labels.bussiness.rpa.files_shared.table.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('labels.bussiness.rpa.files_shared.table.version'),
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: t('labels.bussiness.rpa.files_shared.table.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('labels.bussiness.rpa.files_shared.table.size'),
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
    },
  ];

  if (!loadingFiles && mounted) {
    const { consumedSpace } = state;
    let progress = Number(
      consumedSpace <= 0
        ? 0
        : (consumedSpace * 100) /
            (currentTenant?.tenant.diskUsage?.available + currentTenant?.tenant.diskUsage?.occupied),
    ).toPrecision(4);
    if (progress < 0) progress *= -1;
    const statusProgress = progress > 90 ? 'red' : progress > 75 ? 'orange' : 'green';

    return (
      <div className="table-bordered leaderboard-table table-responsive">
        <div className="flex-grid-child">
          <p>
            {t('file.spaceOccupied')} {`${Number(progress >= 100 ? 100 : progress)}%`}
          </p>
        </div>

        <Tooltip
          title={`
              ${t('file.spaceAvailable')}: ${Number(currentTenant?.tenant.diskUsage?.available).toPrecision(4)} MB
              ${t('file.spaceOccupied')}: ${Number(currentTenant?.tenant.diskUsage?.occupied).toPrecision(4)} MB
            `}
        >
          <Progress percent={progress} style={{ marginBottom: '15px' }} strokeColor={statusProgress} showInfo={false} />
        </Tooltip>

        <TableWrapper className="table-responsive">
          <Table
            columns={columns}
            dataSource={state.filesDataTable}
            pagination={{
              defaultPageSize: 10,
              total: state.filesDataTable.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </div>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default FileList;
