import { useMutation, useQuery } from '@apollo/client';
import FeatherIcon from 'feather-icons-react';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, Spin, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/buttons/buttons';
import { alertModal } from '../../../components/modals/antd-modals';
import licenseActions from '../../../redux/license/actions';
import { TableWrapper, UserTableStyleWrapper } from '../styled';
import { QUERY_LICENSE_COMPUTER_INFO_CLEAN, QUERY_LICENSE_FIND_ALL, QUERY_LICENSE_REMOVE, QUERY_LICENSE_SEND_SYNC } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

const {
  licenseFindAllBegin,
  licenseFindAllSuccess,
  licenseFindAllError,
  licenseRemoveBegin,
  licenseRemoveSuccess,
  licenseRemoveError,
  licenseUpdateBegin,
  licenseUpdateSuccess,
  licenseUpdateError,
} = licenseActions;

const LicenseList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const licenseState = useSelector(state => state.license);
  const currentLoading = useSelector(state => state.currentUserTenant.currentLoading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [licenseRemove] = useMutation(QUERY_LICENSE_REMOVE);
  const [licenseComputerInfoClean] = useMutation(QUERY_LICENSE_COMPUTER_INFO_CLEAN);
  const [licenseSendSync] = useMutation(QUERY_LICENSE_SEND_SYNC);
  const [state, setState] = useState({
    licenseDataTable: [],
  });
  const [mounted, setMounted] = useState(false);

  const { loading, data, error } = useQuery(QUERY_LICENSE_FIND_ALL, {
    variables: {
      tenantId: currentTenant?.tenant._id,
    },
    fetchPolicy: 'network-only',
  });

  const handleComputerInfoClean = useCallback(
    id => {
      alertModal.confirm({
        title: t('license.confirmCleanUp'),
        onOk() {
          dispatch(licenseUpdateBegin());

          licenseComputerInfoClean({
            variables: { id },
          })
            .then(({ data }) => {
              if (data.licenseComputerInfoClean.success) {
                dispatch(licenseUpdateSuccess(data.licenseComputerInfoClean.data));
                notificationSuccess(t('license.successCleanedUp'));
              } else {
                dispatch(licenseUpdateError(data.licenseComputerInfoClean.code));
                notificationError(t(`codeResponse.${data.licenseComputerInfoClean.code}`));
              }
            })
            .catch(e => {
              console.log('License Clean Up Computer Info Error ', e);
              dispatch(licenseUpdateError());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() { },
        okText: t('general.confirm'),
      });
    },
    [dispatch, licenseComputerInfoClean, t],
  );

  const handleSendSync = useCallback(
    id => {
      licenseSendSync({
        variables: { id },
      })
        .then(({ data }) => {
          if (data.licenseSendSync.success) 
            notificationSuccess(t('botActions.successSentAction'));
          else
            notificationError(t(`codeResponse.${data.licenseSendSync.code}`));
        })
        .catch(e => {
          console.log('License Sync Error', e);

          notificationError(t('codeResponse.UNEXPECTED_ERROR'));
        });
    },
    [licenseSendSync, t],
  );

  const handleLicenseRemove = useCallback(
    id => {
      alertModal.confirm({
        title: t('license.confirmRemove'),
        onOk() {
          dispatch(licenseRemoveBegin());

          licenseRemove({
            variables: { id },
          })
            .then(({ data }) => {
              if (data.licenseRemove.success) {
                dispatch(licenseRemoveSuccess(data.licenseRemove.data.serial));
                notificationSuccess(t('license.successRemoved'));
              } else {
                dispatch(licenseRemoveError(data.licenseRemove.code));
                notificationError(t(`codeResponse.${data.licenseRemove.code}`));
              }
            })
            .catch(e => {
              console.log('License Delete Error ', e);
              dispatch(licenseRemoveError());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() { },
        okText: t('general.confirm'),
      });
    },
    [dispatch, licenseRemove, t],
  );

  useEffect(() => {
    if (licenseState.data?.length > 0) {
      setState({
        licenseDataTable: licenseState.data.map(x => {
          const textClassName = x.enabled ? 'active' : 'blocked';

          return {
            key: x._id,
            serial: x.serial,
            machineLicense: x.machineLicense === 'Pro' ? 'Plus' : x.machineLicense,
            computerName: x.computerInfo?.name,
            domain: x.computerInfo?.domain,
            statusValue: x.enabled,
            status: (
              <span className={`status-text ${textClassName}`}>
                {x.enabled ? t('general.active') : t('general.inactive')}
              </span>
            ),
            actions: (
              <div className="table-actions">
                <>
                  <Popover content={t('license.sync')}>
                    <Button
                      className="btn-icon"
                      type="primary"
                      shape="circle"
                      onClick={() => handleSendSync(x._id)}
                    >
                      <FeatherIcon icon="refresh-cw" size={16} />
                    </Button>
                  </Popover>
                  {x.computerInfo && (
                    <Popover content={t('license.cleanUp')}>
                      <Button
                        className="btn-icon"
                        type="primary"
                        shape="circle"
                        onClick={() => handleComputerInfoClean(x._id)}
                      >
                        <FeatherIcon icon="loader" size={16} />
                      </Button>
                    </Popover>
                  )}
                  <Button
                    className="btn-icon"
                    type="info"
                    shape="circle"
                    onClick={() => history.push(`/admin/license/edit/${x._id}`)}
                  >
                    <FeatherIcon icon="edit" size={16} />
                  </Button>
                  {/* <Button className="btn-icon" type="danger" shape="circle" onClick={() => handleLicenseRemove(x._id)}>
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button> */}
                </>
              </div>
            ),
          };
        }),
      });
    } else {
      setState({
        licenseDataTable: [],
      });
    }
  }, [licenseState, handleLicenseRemove, handleComputerInfoClean, handleSendSync, t, history]);

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.LicenseFindAll)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted && data) {
      dispatch(licenseFindAllBegin());
      if (data.licenseFindAll.success) dispatch(licenseFindAllSuccess(data.licenseFindAll.data));
      else {
        dispatch(licenseFindAllError(data.licenseFindAll.code));
        history.push('/admin');
        notificationError(t(`codeResponse.${data.licenseFindAll.code}`));
      }
      setMounted(true);
    }
  }, [dispatch, data, loading, error, history, currentTenant, currentLoading, t, mounted]);

  const columns = [
    {
      title: t('properties.serial.1'),
      dataIndex: 'serial',
      key: 'serial',
      width: '30%',
    },
    {
      title: t('properties.machineLicense.1'),
      dataIndex: 'machineLicense',
      key: 'machineLicense',
      width: '20%',
    },
    {
      title: t('properties.computerName.1'),
      dataIndex: 'computerName',
      key: 'computerName',
      width: '20%',
    },
    {
      title: t('properties.domain.1'),
      dataIndex: 'domain',
      key: 'domain',
      width: '20%',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      sorter: (a, b) => {
        if (a.statusValue) return 1;
        if (b.statusValue) return -1;
        return 0;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '5%',
    },
  ];

  if (!licenseState.loading && mounted) {
    return (
      <>
        <div className="table-bordered leaderboard-table table-responsive">
          <UserTableStyleWrapper>
            <TableWrapper className="table-responsive">
              <Table
                columns={columns}
                dataSource={state.licenseDataTable}
                pagination={{
                  defaultPageSize: 10,
                  total: state.licenseDataTable?.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
                }}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </div>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default LicenseList;
