import React from 'react';
import { Row, Col, Spin } from 'antd';
// import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Focard, CardGroup } from '../../dashboard/style';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import Heading from '../../../../components/heading/heading';
import { ChartjsAreaChart } from '../../../../components/charts/chartjs';
import { chartLinearGradient } from '../../../../components/utilities/utilities';

const TotalExecutionsChart = () => {
  const { t } = useTranslation();
  const { totalExecutions, foIsLoading } = useSelector(state => {
    return {
      totalExecutions: state.botGoals.data.totalExecutions,
      foIsLoading: state.botGoals.loading,
    };
  });

  return (
    <CardGroup>
      <div className="forcast-overview">
        {totalExecutions !== null && (
          <Cards title="" size="large">
            {foIsLoading ? (
              <div className="sd-spin">
                <Spin />
              </div>
            ) : (
              <Row gutter={25}>
                <Col xl={24} md={24}>
                  <Row className="focard-wrapper">
                    <Col md={24} sm={24} xs={24}>
                      <Focard>
                        <div className="focard-details growth-upward">
                          <Heading as="h1">{totalExecutions.total}</Heading>
                          <p className="subtitle">{t('labels.bussiness.rpa.bots.goals.details.totalExecutions')}</p>
                          {/* <p className="focard-status"> */}
                          {/*  <span className="focard-status__percentage"> */}
                          {/*    <FeatherIcon icon="trending-up" /> */}
                          {/*    25% */}
                          {/*  </span> */}
                          {/*  <span> 20,641 (prev)</span> */}
                          {/* </p> */}
                        </div>
                        <div className="focard-chart">
                          <ChartjsAreaChart
                            id="engaged"
                            labels={totalExecutions.labels}
                            datasets={[
                              {
                                data: totalExecutions.data,
                                borderColor: '#20C997',
                                borderWidth: 3,
                                fill: true,
                                backgroundColor: () =>
                                  chartLinearGradient(document.getElementById('engaged'), 165, {
                                    start: '#20C99710',
                                    end: '#20C99701',
                                  }),
                                pointHoverRadius: 0,
                                pointHoverBorderColor: 'transparent',
                              },
                            ]}
                            height={window.innerWidth <= 1199 ? 100 : 165}
                          />
                        </div>
                      </Focard>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Cards>
        )}
      </div>
    </CardGroup>
  );
};

export default TotalExecutionsChart;
