import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {  Table } from 'antd';
import { Modal } from '../modals/antd-modals';
import { useMutation } from '@apollo/client';
import { Button } from '../buttons/buttons';
import { QUERY_USER_TOKEN_REFRESH } from '../../queries/auth.queries';
import { notificationError } from '../utilities/notification';
import { TableWrapper } from '../../pages/admin/styled';

const SelectTenant = ({ showModal, setShowModal }) => {
  const { t } = useTranslation();
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { tenants, currentTenantId } = useSelector(state => ({
    tenants: [
      state.currentUserTenant?.currentUser?.mainTenant.tenant ?? [],
      ...(state.currentUserTenant?.currentUser?.relatedTenants?.map(relatedTenant => relatedTenant.tenant) ?? []),
    ],
    currentTenantId: state.currentUserTenant?.currentTenant?.tenant._id,
  }));
  const [tenantsTable, setTenantsTable] = useState([]);
  const [userTokenRefresh] = useMutation(QUERY_USER_TOKEN_REFRESH);

  const columns = [
    {
      title: t('properties.name.1'),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
    },
    {
      title: t('properties.nit'),
      dataIndex: 'nit',
      key: 'nit',
      width: '40%',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
    },
  ];

  const changeTenant = tenantId => {
    userTokenRefresh({
      variables: {
        tenantId,
      },
    }).then(({ data }) => {
      if (data.userTokenRefresh) {
        if (data.userTokenRefresh.success === true) {
          window.location.reload();
        } else {
          notificationError(t(`codeResponse.${data.userTokenRefresh.code}`));
        }
      }
    });
  };

  useEffect(() => {
    if (showModal) {
      if (tenants?.length > 0) {
        setTenantsTable(
          tenants?.map(tenant => {
            const { _id, name, nit } = tenant;
            return {
              key: _id,
              name,
              nit,
              actions:
                currentTenantId?.toString() !== _id.toString() ? (
                  <div key={_id} className="table-actions">
                    <Button className="btn-icon" type="info" shape="circle" onClick={() => changeTenant(_id)}>
                      <FeatherIcon icon="log-in" size={16} />
                    </Button>
                  </div>
                ) : (
                  <span className="text-success">{t(`general.currentSession`)}</span>
                ),
            };
          }),
        );
      } else setTenantsTable([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <Modal
      title={t('general.selectTenant')}
      visible={showModal}
      style={{ top: 30 }}
      width="50%"
      footer={null}
      onCancel={() => setShowModal(false)}
      type={'primary'}
    >
      <TableWrapper className="table-responsive" darkMode={darkMode}>
        
        <Table
          columns={columns}
          dataSource={tenantsTable}
          pagination={{
            defaultPageSize: 10,
            total: tenantsTable?.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
          }}
        />
      </TableWrapper>
    </Modal>
  );
};

export default SelectTenant;
