import { gql } from '@apollo/client';

export const GET_API_KEYS_BY_TENANT = gql`
  query ApiKeyGetAllByTenant($apiKeyListInput: ApiKeyListInput!) {
    apiKeyGetAllByTenant(apiKeyListInput: $apiKeyListInput) {
      success
      data {
        _id
        key
        type
        description
        alertEmail
        limit
        used
      }
    }
  }
`;

export const CREATE_API_KEY = gql`
  mutation ApiKeyCreate($apiKeyCreateInput: ApiKeyCreateInput!) {
    apiKeyCreate(data: $apiKeyCreateInput) {
      success
      code
      errors {
        field
        description
      }
    }
  }
`;

export const DELETE_API_KEY = gql`
  mutation ApiKeyDelete($apiKeyId: String!) {
    apiKeyDelete(apiKeyId: $apiKeyId) {
      success
    }
  }
`;
