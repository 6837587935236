import React, { Suspense, useState, lazy, useEffect, useCallback } from 'react';
import { NavLink, useHistory, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { Form, Row, Col, Spin, Breadcrumb } from 'antd';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { AddUser } from './style';
import actions from '../../../redux/user/actions';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { QUERY_RESOURCE_GROUP_FIND_ALL, QUERY_RESOURCE_GROUP_FIND_ONE, QUERY_USER_CREATE } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';
const { userCreateBegin, userCreateSuccess, userCreateError } = actions;

const UserCreateInfo = lazy(() => import('./UserCreateInfo'));
const UserCreatePermissions = lazy(() => import('./UserCreatePermissions'));

const UserCreate = ({ match }) => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userLoading = useSelector(state => state.user.loading);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [resetFields, setResetFields] = useState(false);
  const [userCreate] = useMutation(QUERY_USER_CREATE);
  const { data: dataResourceGroupFindAll } = useQuery(QUERY_RESOURCE_GROUP_FIND_ALL, {
    variables: {
      tenantId: currentTenant?.tenant._id,
    },
    fetchPolicy: 'network-only',
  });
  const [resourceGroupFindOne, { data: dataResourceGroupFindOne, loading: loadingResourceGroup }] = useLazyQuery(
    QUERY_RESOURCE_GROUP_FIND_ONE,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [state, setState] = useState({
    form: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: {
        phone: '',
        code: 0,
      },
      plataformaModelerLicense: false,
      userLicense: '',
      userPermissions: [],
      resourceGroup: null,
    },
  });
  const [permissions, setPermissions] = useState({
    bot: [],
    file: [],
    license: [],
    notification: [],
    tenant: [],
    transaction: [],
    user: [],
    workFlow: [],
  });

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1'),
    types: {
      email: t('type.email')
    }
  };
  /* eslint-enable */

  const handleNextStep = () => {
    const phoneErrors = form1.getFieldsError(['phone']);
    if (phoneErrors[0].errors.length > 0) return;
    setIsCompleted(true);
    history.push(`${match.path}/permissions`);
  };

  const handleSubmit = useCallback(() => {
    dispatch(userCreateBegin());
    userCreate({
      variables: {
        userCreateInput: {
          name: state.form.name,
          email: state.form.email,
          password: state.form.password,
          phoneNumber: state.form.phone.phone !== '' ? parseInt(state.form.phone.phone) : null,
          phoneCode: state.form.phone.code !== '' ? parseInt(state.form.phone.code) : null,
          mainTenant: {
            tenant: currentTenant?.tenant._id,
            resourceGroup: state.form.resourceGroup._id,
            userLicense: state.form.userLicense,
            userPermissions: state.form.userPermissions,
           plataformaModelerLicense: state.form.plataformaModelerLicense ? 'Enterprise' : 'None',
          },
        },
      },
    })
      .then(({ data }) => {
        if (data.userCreate.success === true) {
          dispatch(userCreateSuccess(data.userCreate.data));
          notificationSuccess(t('labels.backend.access.users.success_created'));
          history.push('/admin/user');
        } else {
          dispatch(userCreateError(data.userCreate.code));
          notificationError(t(`codeResponse.${data.userCreate.code}`));
        }
      })
      .catch(e => {
        console.log('User Create Error ', e);
        dispatch(userCreateError());

        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
      });
  }, [currentTenant.tenant._id, dispatch, history, state, t, userCreate]);

  const handleChange = e => {
    setIsCompleted(false);
    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleChangeSelect = e => {
    setState({
      form: {
        ...state.form,
        userLicense: e,
      },
    });
  };

  const handleChangePhone = ({ phone, code }) => {
    setIsCompleted(false);
    if (!code || phone.length !== 10 || !/^\d+$/.test(phone)) {
      form1.setFields([
        {
          name: 'phone',
          errors: [t('labels.backend.access.users.length_phonenumber')],
        },
      ]);
    } else {
      form1.setFields([
        {
          name: 'phone',
          errors: [],
        },
      ]);
    }
    setState({
      form: {
        ...state.form,
        phone: {
          phone,
          code,
        },
      },
    });
  };

  const handleResetFields = () => {
    setState({
      form: {
        ...state.form,
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phone: {
          phone: '',
          code: 0,
        },
      },
    });
    setIsCompleted(false);
    setResetFields(true);
  };

  const handleChangeResourceGroup = id => {
    resourceGroupFindOne({
      variables: {
        id,
      },
    });
  };

  useEffect(() => {
    if (submitted) {
      handleSubmit();
      setSubmitted(false);
    }
  }, [submitted, handleSubmit]);

  useEffect(() => {
    if (resetFields) {
      form1.resetFields();
      setResetFields(false);
    }
  }, [resetFields, form1]);

  useEffect(() => {
    setPermissions({
      bot: Object.keys(UserPermissions).filter(x => x.startsWith('Bot') && !x.startsWith('BotExecution')),
      file: Object.keys(UserPermissions).filter(x => x.startsWith('File')),
      license: Object.keys(UserPermissions).filter(x => x.startsWith('License')),
      notification: Object.keys(UserPermissions).filter(
        x => x.startsWith('MailNotification') || x.startsWith('WhatsappNotification'),
      ),
      tenant: Object.keys(UserPermissions).filter(x => x.startsWith('Tenant')),
      transaction: Object.keys(UserPermissions).filter(
        x => x.startsWith('BotExecution') || x.startsWith('CustomReport') || x.startsWith('Realtime'),
      ),
      user: Object.keys(UserPermissions).filter(x => x.startsWith('User')),
      workFlow: Object.keys(UserPermissions).filter(x => x.startsWith('WorkFlow')),
    });
  }, []);

  useEffect(() => {
    if (dataResourceGroupFindOne?.resourceGroupFindOne.success) {
      const resourceGroupFound = dataResourceGroupFindOne?.resourceGroupFindOne;
      setState(x => ({
        form: {
          ...x.form,
          resourceGroup: resourceGroupFound.data,
          plataformaModelerLicense: false,
          userLicense: null,
        },
      }));
      form2.setFieldsValue({
        userLicense: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataResourceGroupFindOne]);

  if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.UserCreate)) {
    history.push('/admin/user');
    notificationError(t(`codeResponse.403`));
    return null;
  }


  return (
    <>
      <PageHeader ghost title={t('user.create')} />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/admin/user`}>
                <span>{t('labels.backend.access.users.management')}</span>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">
                <NavLink to="#">{t('user.create')}</NavLink>
              </NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24}>
            <AddUser>
              <Cards
                title={
                  <div>
                    <div className="card-nav">
                      <ul>
                        <li>
                          <NavLink to={`${match.path}/info`}>
                            <FeatherIcon icon="user" size={14} />
                            {t('personalInfo')}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink disabled={!isCompleted} to={isCompleted && `${match.path}/permissions`}>
                            <FeatherIcon icon="briefcase" size={14} />
                            {t('permissions')}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                }
              >
                <Switch>
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >
                    <Redirect exact from={`${match.path}`} to={`${match.path}/info`} />
                    <Route
                      exact
                      path={`${match.path}/info`}
                      render={() => (
                        <UserCreateInfo
                          action="create"
                          form={form1}
                          state={state}
                          path={match.path}
                          validateMessages={validateMessages}
                          handleChangePhone={handleChangePhone}
                          handleChange={handleChange}
                          handleNextStep={handleNextStep}
                          handleResetFields={handleResetFields}
                        />
                      )}
                    />
                    {isCompleted && (
                      <Route
                        exact
                        path={`${match.path}/permissions`}
                        render={() => (
                          <UserCreatePermissions
                            action="create"
                            resourceGroups={dataResourceGroupFindAll?.resourceGroupFindAll.data.filter(x => x.enabled)}
                            loadingResourceGroup={loadingResourceGroup}
                            form={form2}
                            state={state}
                            userLoading={userLoading}
                            validateMessages={validateMessages}
                            permissions={permissions}
                            handleChangeSelect={handleChangeSelect}
                            setSubmitted={setSubmitted}
                            setState={setState}
                            setPermissions={setPermissions}
                            handleChangeResourceGroup={handleChangeResourceGroup}
                          />
                        )}
                      />
                    )}
                  </Suspense>
                </Switch>
              </Cards>
            </AddUser>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default UserCreate;
