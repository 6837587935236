import { gql } from '@apollo/client';



export const QUERY_LOGIN = gql`
  mutation login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      success
      code
      data {
        accessToken
      }
    }
  }
`;

export const QUERY_LOGIN_BY_SOCIAL = gql`
  mutation socialLogin($loginInput: LoginInput!) {
    socialLogin(loginInput: $loginInput) {
      success
      code
      data {
        accessToken
      }
    }
  }
`;

export const QUERY_LOGOUT = gql`
  mutation logout {
    logout {
      success
      code
    }
  }
`;

export const QUERY_USER_TOKEN_REFRESH = gql`
  mutation userTokenRefresh($tenantId: String) {
    userTokenRefresh(tenantId: $tenantId) {
      success
      code
      data {
        accessToken
      }
    }
  }
`;

export const QUERY_FORGOT_PASSWORD = gql`
  mutation forgotPassword($forgotPasswordInput: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $forgotPasswordInput) {
      success
      code
      errors {
        field
        description
      }
    }
  }
`;

export const QUERY_PASSWORD_TOKEN_VALIDATE = gql`
  query passwordTokenValidate($token: String!) {
    passwordTokenValidate(token: $token) {
      success
      code
      data
      errors {
        field
        description
      }
    }
  }
`;

export const QUERY_PASSWORD_RESET = gql`
  mutation passwordReset($passwordResetInput: PasswordResetInput!) {
    passwordReset(passwordResetInput: $passwordResetInput) {
      success
      code
      errors {
        field
        description
      }
      data {
        email
      }
    }
  }
`;
