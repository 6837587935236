import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form, Input, Modal, Select, Button, DatePicker } from 'antd';
import moment from 'moment';

import { DatePickerWrapper } from '../../pages/admin/styled';
import { notificationError } from '../utilities/notification';

// Component Upper Variables
const { Option } = Select;
const { RangePicker } = DatePicker;

const OrchestratorDashboardFilters = ({
  mounted,
  formSubmitted,
  orchestratorModel,
  orchestratorStates,
  setDashboardFilters,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [showModal, setShowModal] = useState(false);

  const [filters, setFilters] = useState({
    model: {},
    stateId: '',
    endDate: moment().endOf('day'),
    startDate: moment().startOf('day'),
  });

  const handleModalStatus = () => setShowModal(!showModal);

  const handleStateNameChange = useCallback(event => {
    setFilters(previousState => ({
      ...previousState,
      stateId: event,
    }));
  }, []);

  const onChangeRange = useCallback(
    date => {
      if (date) {
        try {
          const [startDate, endDate] = date;

          if (startDate.diff(endDate, 'months') === -1) {
            notificationError(t('orchestrator.dashboard.dateRageCannotBeGreaterThanAMonth'));

            return;
          }

          setFilters(previousState => ({
            ...previousState,
            endDate: endDate.endOf('day'),
            startDate: startDate.startOf('day'),
          }));
        } catch (error) {
          console.info('Something went wrong handling the date range', error);
        }
      }
    },
    [t],
  );

  const handleClearModelFilters = useCallback(event => {
    event.persist();

    setFilters(previousState => ({
      ...previousState,
      model: {},
    }));
  }, []);

  const handleModelFiltersChange = event => {
    event.persist();

    setFilters(previousState => ({
      ...previousState,
      model: {
        ...previousState.model,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleSubmit = () => {
    setDashboardFilters({
      ...filters,
      // Force the hook to dispatch the action to set the retrieved data
      tableRedraw: true,
      // Just to generate a different value for the filter each time users click on Apply button so we can fetch new data.
      time: Math.random(),
    });
  };

  const monthDateRange = useMemo(
    () => [
      moment()
        .startOf('month')
        .startOf('day'),
      moment()
        .endOf('month')
        .startOf('day'),
    ],
    [],
  );
  const defaultDateRange = useMemo(() => [moment().startOf('day'), moment().endOf('day')], []);

  const renderBaseFilters = () => (
    <Col xs={24} md={12}>
      <Form
        form={form}
        size="medium"
        name="orchestratorDashboardFilters"
        layout="horizontal"
        onFinish={handleSubmit}
        autoComplete="off"
        scrollToFirstError
      >
        {/* State Name Input */}
        <Col xs={24}>
          <Form.Item name="stateId" label={t('orchestrator.dashboard.stateName')} initialValue={filters.stateId}>
            <Select
              name="stateId"
              value={filters.stateId}
              disabled={!mounted}
              onChange={handleStateNameChange}
              className="sDash_fullwidth-select"
              allowClear
              placeholder={t('orchestrator.dashboard.stateName')}
              autoClearSearchValue
            >
              {orchestratorStates?.map(orchestratorState => (
                <Option key={orchestratorState.id} value={orchestratorState.id}>
                  {orchestratorState.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Date Range Input */}
        <Col xs={24}>
          <Form.Item name="dateRange" label={t('general.dateRange')} initialValue={defaultDateRange}>
            <DatePickerWrapper>
              <RangePicker
                name="dateRange"
                value={[filters.startDate, filters.endDate]}
                ranges={{
                  [t('general.today')]: defaultDateRange,
                  [t('general.thisMonth')]: monthDateRange,
                }}
                disabled={!mounted}
                onChange={onChangeRange}
              />
            </DatePickerWrapper>
          </Form.Item>
        </Col>

        {/* Footer Buttons */}
        <Col xs={24}>
          <Row>
            {/* Model Filters Button */}
            <Col xs={12}>
              <Form.Item>
                <Button
                  key="2"
                  type="info"
                  style={{ height: '44px' }}
                  onClick={handleModalStatus}
                  disabled={formSubmitted}
                  htmlType="button"
                  className="pull-left"
                >
                  {t('orchestrator.dashboard.modelFilters')}
                </Button>
              </Form.Item>
            </Col>

            {/* Apply Filters */}
            <Col xs={12}>
              <Form.Item>
                <Button
                  key="2"
                  type="primary"
                  style={{ height: '44px' }}
                  loading={formSubmitted}
                  htmlType="submit"
                  className="pull-right"
                >
                  {formSubmitted ? t('loading.1') : t('general.buttons.apply')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Form>
    </Col>
  );

  const renderModelBasedFilters = () => (
    <Col xs={24}>
      <Form
        form={form}
        size="medium"
        name="orchestratorDashboardModelFilters"
        layout="horizontal"
        autoComplete="off"
        scrollToFirstError
      >
        {orchestratorModel?.map(property => (
          <Form.Item key={property} name={property} label={property} initialValue={filters.model[property] ?? ''}>
            <Input
              name={property}
              value={filters.model[property] ?? ''}
              onChange={handleModelFiltersChange}
              placeholder="Filter"
            />
          </Form.Item>
        ))}

        {/* Footer Buttons */}
        <Row>
          {/* Model Filters Button */}
          <Col xs={12}>
            <Form.Item>
              <Button
                key="1"
                type="danger"
                style={{ height: '44px' }}
                onClick={handleClearModelFilters}
                htmlType="reset"
                className="pull-left"
              >
                {t('general.buttons.clear')}
              </Button>
            </Form.Item>
          </Col>

          {/* Apply Filters */}
          <Col xs={12}>
            <Form.Item>
              <Button
                key="2"
                type="primary"
                style={{ height: '44px' }}
                loading={formSubmitted}
                onClick={handleModalStatus}
                htmlType="button"
                className="pull-right"
              >
                {formSubmitted ? t('loading.1') : t('general.buttons.apply')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Col>
  );

  return (
    <>
      {renderBaseFilters()}

      <Modal
        title={t('orchestrator.dashboard.modelFilters')}
        width="50%"
        style={{ top: 30 }}
        footer={null}
        visible={showModal}
        onCancel={() => handleModalStatus()}
        destroyOnClose
      >
        {renderModelBasedFilters()}
      </Modal>
    </>
  );
};

OrchestratorDashboardFilters.propTypes = {
  mounted: PropTypes.bool.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  orchestratorModel: PropTypes.array.isRequired,
  orchestratorStates: PropTypes.array.isRequired,
  setDashboardFilters: PropTypes.func.isRequired,
};

export default OrchestratorDashboardFilters;
