import React from 'react';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Breadcrumb } from 'antd';
import CustomReportList from './CustomReportList';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
const CustomReportManagement = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const history = useHistory();
  const { botId } = useParams();

  return (
    <>
      <PageHeader ghost title={t('transaction.customReport.management')} />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/admin/bot`}>
                <span>{t('labels.bussiness.rpa.bots.title')}</span>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">{t('transaction.customReport.management')}</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24}>
            <Cards
              isbutton={
                <div key="6" className="page-header-actions">
                  <Button
                    className="btn-signin"
                    size="small"
                    key="1"
                    type="primary"
                    onClick={() => history.push(`/admin/bot/custom-report/${botId}/create`)}
                  >
                    <FeatherIcon icon="plus" size={14} />
                    {t('transaction.customReport.create')}
                  </Button>
                </div>
              }
            >
              <CustomReportList />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default CustomReportManagement;
