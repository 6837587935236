import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SelectTenant from '../../components/select-tenant';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [showModalSelectTenant, setShowModalSelectTenant] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'dashboard' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? 'dashboard'
                  : mainPathSplit.length >= 2 && mainPathSplit.filter((x, i) => i > 0).join('/')
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}`}>
              <FeatherIcon icon="home" />
            </NavLink>
          )
        }
        key="dashboard"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Dashboard
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/notes`}>
              <FeatherIcon icon="file-text" />
            </NavLink>
          )
        }
        key="notes"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          {t('notes.notes')}
        </NavLink>
      </Menu.Item> */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}#`}>
              <FeatherIcon icon="layers" />
            </NavLink>
          )
        }
        key="select-tenant"
      >
        <NavLink onClick={() => setShowModalSelectTenant(true)} to={`${path}#`}>
          {t('general.selectTenant')}
        </NavLink>
        <SelectTenant showModal={showModalSelectTenant} setShowModal={setShowModalSelectTenant} />
      </Menu.Item>
      {!topMenu && <p className="sidebar-nav-title">{t('menus.backend.sidebar.business')}</p>}
      {/* <SubMenu key="automation" icon={!topMenu && <FeatherIcon icon="refresh-cw" />} title={t('business.automation.automation')}> */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/bot`}>
              <FeatherIcon icon="award" />
            </NavLink>
          )
        }
        key="bot"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/bot`}>
          {t('sidebar.bots')}
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/orchestrator`}>
              <FeatherIcon icon="package" />
            </NavLink>
          )
        }
        key="orchestrator"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/orchestrator`}>
          {t('sidebar.orchestrators')}
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/file`}>
              <FeatherIcon icon="file" />
            </NavLink>
          )
        }
        key="file"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/file`}>
          {t('sidebar.files')}
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/license`}>
              <FeatherIcon icon="monitor" />
            </NavLink>
          )
        }
        key="license"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/license`}>
          {t('sidebar.licenses')}
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/work-flow`}>
              <FeatherIcon icon="git-pull-request" />
            </NavLink>
          )
        }
        key="work-flow"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/work-flow`}>
          {t('sidebar.workFlows')}
        </NavLink>
      </Menu.Item>
      <SubMenu key="report" icon={!topMenu && <FeatherIcon icon="flag" />} title={t('general.reports')}>
        <Menu.Item key="report/tenant">
          <NavLink onClick={toggleCollapsed} to={`${path}/report/tenant`}>
            {t('sidebar.tenant')}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="report/custom-report">
          <NavLink onClick={toggleCollapsed} to={`${path}/report/custom-report`}>
            {t('sidebar.customReports')}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="report/bot-execution">
          <NavLink onClick={toggleCollapsed} to={`${path}/report/bot-execution`}>
            {t('sidebar.botExecutions')}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="report/bot-goals-report">
          <NavLink onClick={toggleCollapsed} to={`${path}/report/bot-goals-report`}>
            {/* {t('transaction.botExecution.name.2')} */}
            Bots Goals
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="microservices" icon={!topMenu && <FeatherIcon icon="cloud" />} title={t('general.microservices')}>
        <Menu.Item key="microservices">
          <NavLink onClick={toggleCollapsed} to={`${path}/microservices`}>
            {t('microservices.management')}
          </NavLink>
        </Menu.Item>
      </SubMenu>
      {/* <Menu.Item key="groups-management">
          <NavLink onClick={toggleCollapsed} to={`${path}#`}>
            {t('business.automation.group_management')}
          </NavLink>
        </Menu.Item> */}
      {/* <Menu.Item key="curriculum">
          <NavLink onClick={toggleCollapsed} to={`${path}#`}>
            {t('business.automation.curriculum')}
          </NavLink>
        </Menu.Item> */}
      {/* <Menu.Item key="machine-management">
          <NavLink onClick={toggleCollapsed} to={`${path}#`}>
            {t('business.automation.machine_management')}
          </NavLink>
        </Menu.Item> */}
      {/* <Menu.Item key="menu-management">
          <NavLink onClick={toggleCollapsed} to={`${path}#`}>
            {t('business.automation.menu_management')}
          </NavLink>
        </Menu.Item> */}
      {/* <SubMenu key="machines-farm" icon={!topMenu && <FeatherIcon icon="plus-circle" />} title={t('business.automation.machines_farm')}>
          <Menu.Item key="farm-flows">
            <NavLink onClick={toggleCollapsed} to={`${path}#`}>
              Farm Flows
            </NavLink>
          </Menu.Item>
        </SubMenu> */}
      {/* </SubMenu> */}

      {/* System Menu */}
      {!topMenu && <p className="sidebar-nav-title">{t('menus.backend.sidebar.system')}</p>}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/user`}>
              <FeatherIcon icon="users" />
            </NavLink>
          )
        }
        key="user"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/user`}>
          {t('sidebar.users')}
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/tenant/variables`}>
              <FeatherIcon icon="tag" />
            </NavLink>
          )
        }
        key="tenant-variables"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/tenant/variables`}>
          {t('sidebar.tenantVariables')}
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
