import { useQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Button, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import customReportActions from '../../../redux/customReport/actions';
import { QUERY_CUSTOM_REPORT_FIND_ALL, QUERY_CUSTOM_REPORT_REMOVE } from '../../../queries';
import { alertModal } from '../../../components/modals/antd-modals';
import { TableWrapper, UserTableStyleWrapper } from '../styled';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

const {
  customReportRemoveBegin,
  customReportRemoveSuccess,
  customReportRemoveError,
  customReportFindAllBegin,
  customReportFindAllSuccess,
  customReportFindAllError,
} = customReportActions;

const CustomReportList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const customReportState = useSelector(state => state.customReport);
  const customReportLoading = useSelector(state => state.customReport.loading);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [customReportRemove] = useMutation(QUERY_CUSTOM_REPORT_REMOVE);
  const [mounted, setMounted] = useState(false);
  const { botId } = useParams();
  const [state, setState] = useState({
    customReportsDataTable: [],
  });

  const { loading, data, error } = useQuery(QUERY_CUSTOM_REPORT_FIND_ALL, {
    variables: {
      botId,
    },
    fetchPolicy: 'network-only',
  });

  const handleCustomReportRemove = useCallback(
    id => {
      alertModal.confirm({
        title: t('transaction.customReport.confirmRemove'),
        onOk() {
          dispatch(customReportRemoveBegin());

          customReportRemove({
            variables: { id },
          })
            .then(({ data }) => {
              if (data.customReportRemove.success) {
                dispatch(customReportRemoveSuccess(data.customReportRemove.data._id));
                notificationSuccess(t('transaction.customReport.successRemoved'));
              } else {
                dispatch(customReportRemoveError(data.customReportRemove.code));
                notificationError(t(`codeResponse.${data.customReportRemove.code}`));
              }
            })
            .catch(e => {
              console.log('Custom Report Remove Error ', e);
              dispatch(customReportRemoveError());
              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [dispatch, customReportRemove, t],
  );

  useEffect(() => {
    if (customReportState.customReports?.length > 0) {
      setState({
        customReportsDataTable: customReportState.customReports.map((customReport, index) => {
          const { _id, displayName, enabled } = customReport;
          const textClassName = enabled ? 'active' : 'blocked';
          return {
            key: _id,
            name: displayName,
            statusValue: enabled,
            status: (
              <span className={`status-text ${textClassName}`}>
                {enabled ? t('general.active') : t('general.inactive')}
              </span>
            ),
            actions: (
              <div className="table-actions">
                <>
                  <Button
                    className="btn-icon"
                    type="info"
                    shape="circle"
                    onClick={() => history.push(`/admin/bot/custom-report/${botId}/edit/${_id}`)}
                  >
                    <FeatherIcon icon="edit" size={16} />
                  </Button>
                  <Button
                    className="btn-icon"
                    type="danger"
                    shape="circle"
                    onClick={() => handleCustomReportRemove(_id)}
                  >
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                </>
              </div>
            ),
          };
        }),
      });
    } else {
      setState({
        customReportsDataTable: [],
      });
    }
  }, [customReportState.customReports, handleCustomReportRemove, history, t, botId]);

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.CustomReportFindAll)) {
      history.push('/admin/bot');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted && data) {
      dispatch(customReportFindAllBegin());
      if (data.customReportFindAll.success) dispatch(customReportFindAllSuccess(data.customReportFindAll.data));
      else {
        dispatch(customReportFindAllError(data.customReportFindAll.code));
        history.push('/admin/bot');
        notificationError(t(`codeResponse.${data.customReportFindAll.code}`));
      }
      setMounted(true);
    }
  }, [dispatch, data, loading, error, history, currentTenant, currentLoading, t, mounted]);

  const columns = [
    {
      title: t('labels.bussiness.rpa.bots.table.name'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      sorter: (a, b) => {
        if (a.statusValue) return 1;
        if (b.statusValue) return -1;
        return 0;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
    },
  ];

  if (!customReportLoading && mounted) {
    return (
      <div className="table-bordered leaderboard-table table-responsive">
        <UserTableStyleWrapper>
          <TableWrapper className="table-responsive">
            <Table
              columns={columns}
              dataSource={state.customReportsDataTable}
              pagination={{
                defaultPageSize: 10,
                total: state.customReportsDataTable?.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
      </div>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default CustomReportList;
