import { gql } from '@apollo/client';

export const QUERY_RESOURCE_GROUP_FIND_ALL = gql`
  query resourceGroupFindAll($tenantId: String!) {
    resourceGroupFindAll(tenantId: $tenantId) {
      success
      code
      data {
        _id
        name
        enabled
      }
    }
  }
`;

export const QUERY_RESOURCE_GROUP_FIND_ONE = gql`
  query resourceGroupFindOne($id: String!) {
    resourceGroupFindOne(id: $id) {
      success
      code
      data {
        _id
        name
        resources {
          pricing {
            _id
            name
            slug
          }
          quantity
          occupied
        }
        enabled
      }
    }
  }
`;
