import { gql } from '@apollo/client';

export const QUERY_TENANT_FIND_ONE = gql`
  query me {
    me {
      success
      code
      data {
        currentTenant {
          tenant {
            _id
            name
            address
            nit
            country
            partnerTenant {
              _id
            }
            diskUsage {
              available
              occupied
            }
            alerts {
              cpu {
                warning
                danger
              }
              ram {
                warning
                danger
              }
            }
            enabled
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
