import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../../queries/user.queries';

const GuestRoute = ({ ...rest }) => {
  const { data, loading } = useQuery(QUERY_ME, { fetchPolicy: 'network-only' });
  if (!loading) return !data ? <Route {...rest} /> : <Redirect to="/admin" />;
  return null;
};

export default GuestRoute;
