import { gql } from '@apollo/client';

export const QUERY_LICENSE_FIND_ALL = gql`
  query licenseFindAll($tenantId: String!) {
    licenseFindAll(tenantId: $tenantId) {
      success
      code
      data {
        _id
        serial
        machineLicense
        enabled
        computerInfo {
          name
          domain
        }
      }
    }
  }
`;

export const QUERY_LICENSE_FIND_ONE = gql`
  query licenseFindOne($id: String!) {
    licenseFindOne(id: $id) {
      success
      code
      data {
        _id
        serial
        machineLicense
        addons
        enabled
        computerInfo {
          name
          domain
        }
        tenant {
          _id
        }
        resourceGroup {
          _id
          name
          resources {
            pricing {
              _id
              name
              slug
            }
            quantity
            occupied
          }
          enabled
        }
      }
    }
  }
`;

export const QUERY_LICENSE_CREATE = gql`
  mutation licenseCreate($input: LicenseCreateInput!) {
    licenseCreate(licenseCreateInput: $input) {
      success
      code
      data {
        _id
        serial
        machineLicense
        enabled
        computerInfo {
          name
          domain
        }
        tenant {
          _id
        }
      }
    }
  }
`;

export const QUERY_LICENSE_UPDATE = gql`
  mutation licenseUpdate($input: LicenseUpdateInput!) {
    licenseUpdate(licenseUpdateInput: $input) {
      success
      code
      data {
        _id
        serial
        machineLicense
        enabled
        computerInfo {
          name
          domain
        }
        tenant {
          _id
        }
      }
    }
  }
`;

export const QUERY_LICENSE_REMOVE = gql`
  mutation licenseRemove($id: String!) {
    licenseRemove(id: $id) {
      success
      code
      data {
        _id
        serial
      }
    }
  }
`;

export const QUERY_LICENSE_COMPUTER_INFO_CLEAN = gql`
  mutation licenseComputerInfoClean($id: String!) {
    licenseComputerInfoClean(id: $id) {
      success
      code
      data {
        _id
        serial
        machineLicense
        enabled
        computerInfo {
          name
          domain
        }
      }
    }
  }
`;

export const QUERY_LICENSE_SEND_SYNC = gql`
  mutation licenseSendSync($id: String!) {
    licenseSendSync(id: $id) {
      success
      code
    }
  }
`;
