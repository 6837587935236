import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Cards } from '../cards/frame/cards-frame';

const { TextArea } = Input;
const { Option } = Select;

const PasswordVaultForm = ({
  action,
  initialForm,
  isTenantVault,
  loading,
  handleSubmit,
  sharedVariablesList = []
}) => {
  const createVariable = String(action).toUpperCase() === 'CREATE';
  
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isNonShared, setIsNonShared] = useState(false);

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1'),
    types: {
      email: t('type.email')
    }
  };
  /* eslint-enable */

  const onChangeShared = item => {
    form.resetFields(['name'])
    setIsNonShared(item === 'no')
  } 
    
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...initialForm,
      shared: initialForm.user === 'SHARED' ? 'yes' : 'no',
    });
    setIsNonShared(initialForm.user !== 'SHARED')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialForm]);

  const buildOptions = () => {
    return ['no', 'yes'].map(option => (
      <Option key={option} value={option}>
        {t(`general.${option}`)}
      </Option>
    ));
  };

  return (
    <>
      <Row gutter={15}>
        <Col xs={24}>
          <Cards headless>
            <Form
              name="botVaultForm"
              form={form}
              validateMessages={validateMessages}
              onFinish={handleSubmit}
              layout="vertical"
              size="middle"
              scrollToFirstError
              autoComplete="off"
            >
              <Row gutter={30}>
                {!isTenantVault && (
                  <>
                    {/* Is Shared */}
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="shared"
                        label={t('botVault.fields.isShared')}
                        rules={[{ required: true }]}
                        initialValue={initialForm.user === 'SHARED' ? 'yes' : 'no'}
                      >
                        <Select disabled={!createVariable} placeholder={t('botVault.fields.isShared')} className="sDash_fullwidth-select" onChange={onChangeShared}>
                          {buildOptions()}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}

                {/* Name */}
                <Col md={!isTenantVault ? 8 : 12} xs={24}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                      },
                      () => ({
                        validator(_, value) {
                          if (value) {
                            const invalidName = String(value).match(/^\w{3,20}$/g);

                            if (!invalidName) {
                              return Promise.reject(new Error(t('botVault.regexp.invalid_name')));
                            }

                            return Promise.resolve();
                          }

                          return Promise.reject();
                        },
                      }),
                    ]}
                    label={t('botVault.fields.name')}
                    initialValue={initialForm.name?.length ? initialForm.name : null}
                  >
                    {
                      isNonShared && !isTenantVault
                      ? <Select disabled={!createVariable} placeholder={t('general.selectOption')}>
                        {sharedVariablesList?.map(sharedVar => (
                          <Option key={sharedVar.name} value={sharedVar.name}>{sharedVar.name}</Option>
                        ))}
                      </Select>
                      : <Input disabled={!createVariable} placeholder={t('botVault.fields.name')} />
                    }
                  </Form.Item>
                </Col>

                {/* Value */}
                <Col md={!isTenantVault ? 8 : 12} xs={24}>
                  <Form.Item
                    name="value"
                    rules={[{ required: createVariable }]}
                    label={t('botVault.fields.value')}
                    initialValue={initialForm.value}
                  >
                    <Input.Password placeholder={t('botVault.fields.value')} />
                  </Form.Item>
                </Col>
                {/* Description */}
                <Col xs={24}>
                  <Form.Item
                    name="description"
                    label={t('botVault.fields.description')}
                    initialValue={initialForm.description}
                  >
                    <TextArea placeholder={t('botVault.fields.description')} className="sDash_unresizable" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button style={{ height: '44px' }} htmlType="submit" type="primary" loading={loading}>
                  {loading ? t('loading.1') : t(`general.${String(action).toLowerCase()}`)}
                </Button>
              </Form.Item>
            </Form>
          </Cards>
        </Col>
      </Row>
    </>
  );
};

PasswordVaultForm.propTypes = {
  action: PropTypes.string,
  initialForm: PropTypes.object,
  isTenantVault: PropTypes.bool,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  sharedVariablesList: PropTypes.array,
};

export default PasswordVaultForm;
