import update from 'immutability-helper';
import actions from './actions';

const {
  USER_FIND_ALL_BEGIN,
  USER_FIND_ALL_SUCCESS,
  USER_FIND_ALL_ERROR,
  USER_FIND_ONE_BEGIN,
  USER_FIND_ONE_SUCCESS,
  USER_FIND_ONE_ERROR,
  USER_CREATE_BEGIN,
  USER_CREATE_SUCCESS,
  USER_CREATE_ERROR,
  USER_UPDATE_BEGIN,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_REMOVE_BEGIN,
  USER_REMOVE_SUCCESS,
  USER_REMOVE_ERROR,
  USER_FILTER_BEGIN,
  USER_FILTER_SUCCESS,
  USER_FILTER_ERROR,
} = actions;

const initialState = {
  usersFiltered: [],
  users: [],
  user: {},
  loading: false,
  error: false,
};

const userReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case USER_FIND_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_FIND_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        users: data ?? [],
        usersFiltered: data ?? [],
      };
    case USER_FIND_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case USER_FIND_ONE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_FIND_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: data,
      };
    case USER_FIND_ONE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case USER_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, data],
      };
    case USER_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case USER_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_UPDATE_SUCCESS:
      const index = state.users.findIndex(x => x._id === data._id);
      return update(state, {
        users: {
          [index]: { $set: data },
        },
        loading: { $set: false },
      });
    case USER_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case USER_REMOVE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter(x => x._id !== data),
        usersFiltered: state.usersFiltered.filter(x => x._id !== data),
      };
    case USER_REMOVE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case USER_FILTER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        usersFiltered: state.users.filter(
          x =>
            x.email.toLowerCase().startsWith(data.email.toLowerCase()) &&
            (x.mainTenant.userLicense
              ? x.mainTenant.userLicense?.toLowerCase().startsWith(data.userLicense.toLowerCase())
              : x.relatedTenants[0]?.userLicense?.toLowerCase().startsWith(data.userLicense.toLowerCase())),
        ),
      };
    case USER_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export default userReducer;
