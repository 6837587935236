import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import LicenseManagement from '../../pages/admin/license';
import LicenseCreate from '../../pages/admin/license/LicenseCreate';
import LicenseEdit from '../../pages/admin/license/LicenseEdit';
import NotFound from '../../utils/404';

const LicensesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/create`} component={LicenseCreate} />
      <Route path={`${path}/edit/:licenseId`} component={LicenseEdit} />
      <Route exact path={`${path}`} component={LicenseManagement} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default LicensesRoute;
