import React, { useEffect, useState } from 'react';
import { Col, Row, Tabs, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CvForm from './components/CvForm';
import CvFilesTable from './components/CvFilesTable';
import CvExecutionReportTable from './components/CvExecutionReportTable';
import { PageHeader } from '../../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { QUERY_BOT_CV } from '../../../../queries';
import { UserPermissions } from '../../../../utils/enums/permissions.enum';
import { notificationError } from '../../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;

const BotSv = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { botId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [cv, setCv] = useState({
    alias: '',
    description: '',
    manager: '',
    executionConditional: '',
    executionTime: 0,
    savingMethod: '',
    saving: 0,
    image: {
      _id: '',
    },
  });
  const { data } = useQuery(QUERY_BOT_CV, {
    variables: { id: botId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotCVFindOne)) {
      history.push('/admin/bot');
      notificationError(t(`codeResponse.403`));
    }

    if (data !== undefined && data?.botCv.success) {
      setCv(data.botCv.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  return (
    <>
      <PageHeader ghost title={t('labels.bussiness.rpa.bots.curriculum.management')} />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/admin/bot`}>
                <span >{t('labels.bussiness.rpa.bots.title')}</span>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">{t('labels.bussiness.rpa.bots.curriculum.management')}</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Cards>
          <Tabs defaultActiveKey="1">
            <TabPane tab={t('labels.bussiness.rpa.bots.curriculum.detail')} key="1">
              <Row gutter={15}>
                <Col xs={24}>
                  <CvForm cv={cv} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={t('labels.bussiness.rpa.bots.curriculum.documentation')} key="2">
              <CvFilesTable cv={cv} />
            </TabPane>
            <TabPane tab={t('labels.bussiness.rpa.bots.curriculum.executions')} key="3">
              <CvExecutionReportTable />
            </TabPane>
          </Tabs>
        </Cards>
      </Main>
    </>
  );
};

export default BotSv;
