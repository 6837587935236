import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Table, Button, Spin, Tooltip } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router';
import { alertModal } from '../../../components/modals/antd-modals';
import { TableWrapper, UserTableStyleWrapper } from '../styled';
import { QUERY_BOT_FIND_ALL, QUERY_BOT_REMOVE, QUERY_BOT_CV, QUERY_BOT_CREATE_CV } from '../../../queries';
import botActions from '../../../redux/bot/actions';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

const {
  botRemoveBegin,
  botRemoveSuccess,
  botRemoveError,
  botGetAllBegin,
  botGetAllSuccess,
  botGetAllError,
} = botActions;

const BotList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingBots = useSelector(state => state.bot.loading);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [botRemove] = useMutation(QUERY_BOT_REMOVE);
  const [botCreateCv] = useMutation(QUERY_BOT_CREATE_CV);
  const [getBotCv, { data: lazyDataCV }] = useLazyQuery(QUERY_BOT_CV);
  const [mounted, setMounted] = useState(false);
  const [botStateId, setBotStateId] = useState();
  const [state, setState] = useState({
    botsDataTable: [],
  });

  const goToBotCv = useCallback(
    botId => {
      setBotStateId(botId);
      getBotCv({ variables: { id: botId } });
    },
    [getBotCv],
  );

  const { loading, data, error } = useQuery(QUERY_BOT_FIND_ALL, {
    variables: {
      tenantId: currentTenant?.tenant._id,
    },
    fetchPolicy: 'network-only',
  });

  const deleteBot = useCallback(
    id => {
      alertModal.confirm({
        title: t('labels.bussiness.rpa.bots.table.confirm_delete'),
        onOk() {
          dispatch(botRemoveBegin());

          botRemove({
            variables: { id },
          })
            .then(({ data: botRemoveData }) => {
              if (botRemoveData.botRemove.success) {
                dispatch(botRemoveSuccess(botRemoveData.botRemove.data._id));

                setState(prevState => {
                  const botsDataTable = prevState.botsDataTable.filter(item => item.key !== id);
                  return { botsDataTable };
                });

                notificationSuccess(t('labels.bussiness.rpa.bots.table.success_deleted'));
              } else {
                dispatch(botRemoveError(botRemoveData.botRemove.code));

                notificationError(t(`codeResponse.${botRemoveData.botRemove.code}`));
              }
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.log('Bot Delete Error ', e);
              dispatch(botRemoveError());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [dispatch, botRemove, t],
  );

  const updateTable = useCallback(
    botsData => {
      const dataSource = [];
      if (botsData)
        botsData.map(bot => {
          const { name, version, description, enabled } = bot;
          const textClassName = enabled ? 'active' : 'blocked';
          dataSource.push({
            key: bot._id,
            name,
            version,
            description,
            statusValue: enabled,
            status: (
              <span className={`status-text ${textClassName}`}>
                {enabled ? t('general.active') : t('general.inactive')}
              </span>
            ),
            actions: (
              <div className="table-actions">
                <>
                  <Tooltip title={t('transaction.customReport.name.2')}>
                    <Button
                      className="btn-icon"
                      type="primary"
                      shape="circle"
                      onClick={() => history.push(`/admin/bot/custom-report/${bot._id}`)}
                    >
                      <FeatherIcon icon="file-text" size={16} />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('labels.bussiness.rpa.bots.table.cv')}>
                    <Button className="btn-icon" type="primary" shape="circle" onClick={() => goToBotCv(bot._id)}>
                      <FeatherIcon icon="book" size={16} />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('license.assigned')}>
                    <Button
                      className="btn-icon"
                      type="primary"
                      shape="circle"
                      onClick={() => history.push(`/admin/bot/license/${bot._id}`)}
                    >
                      <FeatherIcon icon="list" size={16} />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('botSchedule.name')}>
                    <Button
                      className="btn-icon"
                      type="primary"
                      shape="circle"
                      onClick={() => history.push(`/admin/bot/${bot._id}/schedule`)}
                    >
                      <FeatherIcon icon="grid" size={16} />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('botVault.name')}>
                    <Button
                      className="btn-icon"
                      type="primary"
                      shape="circle"
                      onClick={() => history.push(`/admin/bot/vault/${bot._id}`)}
                    >
                      <FeatherIcon icon="pocket" size={16} />
                    </Button>
                  </Tooltip>
                  <Button
                    className="btn-icon"
                    type="info"
                    shape="circle"
                    onClick={() => history.push(`/admin/bot/edit/${bot._id}`)}
                  >
                    <FeatherIcon icon="edit" size={16} />
                  </Button>
                  <Button className="btn-icon" type="danger" shape="circle" onClick={() => deleteBot(bot._id)}>
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                </>
              </div>
            ),
          });

          setState({
            botsDataTable: dataSource,
          });
          return true;
        });
    },
    [deleteBot, history, t, goToBotCv],
  );

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotFindAll)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted && data) {
      dispatch(botGetAllBegin());
      if (data.botFindAll.success) {
        dispatch(botGetAllSuccess(data.botFindAll.data));
        updateTable(data.botFindAll.data);
      } else {
        dispatch(botGetAllError(data.botFindAll.code));
      }
      setMounted(true);
    }
  }, [dispatch, data, loading, error, updateTable, history, currentTenant, currentLoading, t, mounted]);

  useEffect(() => {
    if (lazyDataCV) {
      if (lazyDataCV.botCv.success) {
        history.push(`/admin/bot/cv/${botStateId}`);
      }

      if (!lazyDataCV.botCv.success) {
        notificationError(t(`codeResponse.${lazyDataCV.botCv.code}`));
        botCreateCv({ variables: { id: botStateId } }).then(({ data: dataCreateCV }) => {
          if (dataCreateCV.botCreateCv.success) {
            notificationSuccess(t('bot.cvSuccessCreated'));
            history.push(`/admin/bot/cv/${botStateId}`);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botStateId, lazyDataCV]);

  const columns = [
    {
      title: t('labels.bussiness.rpa.bots.table.name'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: t('labels.bussiness.rpa.bots.table.version'),
      dataIndex: 'version',
      key: 'version',
      width: '15%',
    },
    {
      title: t('labels.bussiness.rpa.bots.table.description'),
      dataIndex: 'description',
      key: 'description',
      width: '40%',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      sorter: (a, b) => {
        if (a.statusValue) return 1;
        if (b.statusValue) return -1;
        return 0;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
    },
  ];

  if (!loadingBots && mounted) {
    return (
      <div className="table-bordered leaderboard-table table-responsive">
        <UserTableStyleWrapper>
          <TableWrapper className="table-responsive">
            <Table
              columns={columns}
              dataSource={state.botsDataTable}
              pagination={{
                defaultPageSize: 10,
                total: state.botsDataTable.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
      </div>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default BotList;
