import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { QUERY_ME } from '../../queries/user.queries';
import actions from '../../redux/currentUserTenant/actions';

const { currentUserTenantBegin, currentUserTenantSuccess, currentUserTenantErr } = actions;

const ProtectedRoute = ({ ...rest }) => {
  const dispatch = useDispatch();

  const { data, loading } = useQuery(QUERY_ME, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    dispatch(currentUserTenantBegin());

    if (!loading) {
      if (data) {
        if (data.me.success) {
          dispatch(currentUserTenantSuccess(data.me.data));
        } else {
          dispatch(currentUserTenantErr(data.me.code));
        }
      }
    }
  }, [dispatch, data, loading]);

  if (loading) return <div />;

  if (!loading) return data ? <Route {...rest} /> : <Redirect to="/" />;
  return null;
};

export default ProtectedRoute;
