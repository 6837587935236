import { gql } from '@apollo/client';

export const QUERY_BOT_ACTION_SEND = gql`
  mutation botActionSend($input: BotActionInput!) {
    botActionSend(botActionInput: $input) {
      success
    }
  }
`;

export const USER_SUBSCRIPTION = gql`
  subscription userSubscription($userId: String!) {
    userSubscription(userId: $userId) {
      success
      code
    }
  }
`;
