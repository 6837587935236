import { gql } from '@apollo/client';

export const QUERY_BOT_SCHEDULE_FIND_ALL = gql`
  query botScheduleFindAll($id: String!) {
    botScheduleFindAll(botId: $id) {
      success
      code
      data {
        _id
        type
        repeat
        schedule
        licenses {
          serial
          computerInfo {
            name
          }
        }
        action
        bot {
          _id
          name
        }
        tenant {
          _id
          name
        }
        enabled
        localDateTime
        serverDateTime
        createdAt
        updatedAt
      }
    }
  }
`;

export const QUERY_BOT_SCHEDULE_FIND_ONE = gql`
  query botScheduleFindOne($id: String!) {
    botScheduleFindOne(botScheduleId: $id) {
      success
      code
      data {
        _id
        type
        repeat
        schedule
        licenses {
          serial
          computerInfo {
            name
          }
        }
        action
        bot {
          _id
          name
        }
        tenant {
          _id
          name
        }
        enabled
        localDateTime
        serverDateTime
        createdAt
        updatedAt
      }
    }
  }
`;

export const QUERY_BOT_SCHEDULE_CREATE = gql`
  mutation botScheduleCreate($botScheduleCreateInput: BotScheduleCreateInput!) {
    botScheduleCreate(botScheduleCreateInput: $botScheduleCreateInput) {
      success
      code
      data {
        _id
        type
        repeat
        schedule
        licenses {
          serial
          computerInfo {
            name
          }
        }
        action
        bot {
          _id
          name
        }
        tenant {
          _id
          name
        }
        enabled
        localDateTime
        serverDateTime
        createdAt
        updatedAt
      }
    }
  }
`;

export const QUERY_BOT_SCHEDULE_UPDATE = gql`
  mutation botScheduleUpdate($botScheduleUpdateInput: BotScheduleUpdateInput!) {
    botScheduleUpdate(botScheduleUpdateInput: $botScheduleUpdateInput) {
      success
      code
      data {
        _id
        type
        repeat
        schedule
        licenses {
          serial
          computerInfo {
            name
          }
        }
        action
        bot {
          _id
          name
        }
        tenant {
          _id
          name
        }
        enabled
        localDateTime
        serverDateTime
        createdAt
        updatedAt
      }
    }
  }
`;

export const QUERY_BOT_SCHEDULE_REMOVE = gql`
  mutation botScheduleRemove($botScheduleId: String!) {
    botScheduleRemove(botScheduleId: $botScheduleId) {
      success
      code
      data {
        _id
      }
    }
  }
`;

export const QUERY_BOT_SCHEDULE_TOGGLE = gql`
  mutation botScheduleToggle($botScheduleId: String!) {
    botScheduleToggle(botScheduleId: $botScheduleId) {
      success
      code
      data {
        _id
      }
    }
  }
`;

export const QUERY_BOT_SCHEDULE_SERVER_INFO = gql`
  query botScheduleServerInfo($botId: String!) {
    botScheduleServerInfo(botId: $botId) {
      success
      code
      data {
        serverDateTime
        scheduleTypes {
          key
          value
        }
        scheduleActions {
          key
          value
        }
        availableLicenses {
          serial
          computerInfo {
            name
          }
        }
      }
    }
  }
`;
