import { gql } from '@apollo/client';

export const QUERY_TENANT_VAULT_FIND_ALL = gql`
  query tenantPasswordVaultFindAll {
    tenantPasswordVaultFindAll {
      success
      code
      data {
        _id
        variables {
          name
          description
        }
      }
    }
  }
`;

export const QUERY_TENANT_VAULT_CREATE = gql`
  mutation tenantPasswordVaultCreate($input: PasswordVaultCreateInput!) {
    tenantPasswordVaultCreate(tenantPasswordVaultCreateInput: $input) {
      success
      code
      data {
        _id
        variables {
          name
          description
        }
      }
    }
  }
`;

export const QUERY_TENANT_VAULT_UPDATE = gql`
  mutation tenantPasswordVaultUpdate($input: PasswordVaultUpdateInput!) {
    tenantPasswordVaultUpdate(tenantPasswordVaultUpdateInput: $input) {
      success
      code
      data {
        _id
        variables {
          name
          description
        }
      }
    }
  }
`;

export const QUERY_TENANT_VAULT_REMOVE = gql`
  mutation tenantPasswordVaultRemove($input: PasswordVaultDeleteInput!) {
    tenantPasswordVaultRemove(tenantPasswordVaultDeleteInput: $input) {
      success
      code
      data {
        _id
      }
    }
  }
`;
