import { gql } from '@apollo/client';

export const QUERY_BOT_FIND_ALL = gql`
  query botFindAll($tenantId: String!) {
    botFindAll(tenantId: $tenantId) {
      success
      code
      data {
        _id
        name
        version
        description
        tenant {
          _id
        }
        file {
          _id
        }
        enabled
      }
    }
  }
`;

export const QUERY_BOT_FIND_ONE = gql`
  query botFindOne($id: String!) {
    botFindOne(id: $id) {
      success
      code
      data {
        _id
        name
        version
        description
        tenant {
          _id
        }
        file {
          _id
        }
        enabled
      }
    }
  }
`;

export const QUERY_BOT_REMOVE = gql`
  mutation botRemove($id: String!) {
    botRemove(id: $id) {
      success
      code
      data {
        _id
        name
      }
    }
  }
`;

export const QUERY_BOT_CREATE = gql`
  mutation botCreate($botCreateInput: BotCreateInput!) {
    botCreate(botCreateInput: $botCreateInput) {
      success
      code
      data {
        _id
        name
      }
    }
  }
`;

export const QUERY_BOT_UPDATE = gql`
  mutation botUpdate($botUpdateInput: BotUpdateInput!) {
    botUpdate(botUpdateInput: $botUpdateInput) {
      success
      code
      data {
        _id
        name
      }
    }
  }
`;

export const QUERY_BOT_CV = gql`
  query botCv($id: String!) {
    botCv(id: $id) {
      success
      code
      data {
        _id
        image {
          _id
          awsId
        }
        alias
        description
        executionConditional
        manager
        executionTime
        savingMethod
        saving
        files {
          _id
          name
          size
          description
        }
      }
      errors {
        field
        description
      }
    }
  }
`;

export const QUERY_BOT_CREATE_CV = gql`
  mutation botCreateCv($id: String!) {
    botCreateCv(id: $id) {
      success
      code
      data {
        _id
        alias
        description
        executionConditional
        manager
        executionTime
        savingMethod
        saving
        files {
          description
        }
      }
      errors {
        field
        description
      }
    }
  }
`;

export const QUERY_BOT_UPDATE_CV = gql`
  mutation botUpdateCv($data: BotCvUpdateInput!) {
    botUpdateCv(botCvUpdateInput: $data) {
      success
      code
      data {
        _id
        alias
        description
        executionConditional
        manager
        executionTime
        savingMethod
        saving
        files {
          description
        }
      }
      errors {
        field
        description
      }
    }
  }
`;
