/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useParams,NavLink } from 'react-router-dom';
import { Row, Col, Spin, Form, Input, Switch, Select, InputNumber ,Breadcrumb} from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Button } from '../../../components/buttons/buttons';
import { Main } from '../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

import { useOrchestratorStateForm, useOrchestratorStateFormData } from '../../../hooks/orchestrator-state';

import { QUERY_ORCHESTRATOR_STATE_CREATE, QUERY_ORCHESTRATOR_STATE_UPDATE } from '../../../queries';

import orchestratorStateActions from '../../../redux/orchestrator-states/actions';

const {
  orchestratorStateCreateBegin,
  orchestratorStateCreateSuccess,
  orchestratorStateCreateError,
  orchestratorStateUpdateBegin,
  orchestratorStateUpdateSuccess,
  orchestratorStateUpdateError,
} = orchestratorStateActions;

const OrchestratorStateForm = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { orchestratorId, orchestratorStateId } = useParams();
  const { loading: currentLoading, currentTenant } = useSelector(state => state.currentUserTenant);

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [isNewState, setIsNewState] = useState(!orchestratorStateId?.length);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [formValues, setFormValues] = useOrchestratorStateFormData();

  const redirectToIndex = () => history.push(`/admin/orchestrator/${orchestratorId}/states`);

  // Graphql actions
  const [orchestratorStateCreate] = useMutation(QUERY_ORCHESTRATOR_STATE_CREATE);
  const [orchestratorStateUpdate] = useMutation(QUERY_ORCHESTRATOR_STATE_UPDATE);

  const setupFormData = data => {
    const { name, alias, isEnd, timeout, waitTime, licenses, nextState, assignable, description } = data;

    const common = {
      name,
      alias,
      isEnd,
      assignable,
      description,
    };

    let formData = {
      ...common,
      timeout,
      licenses,
    };

    if (!assignable) {
      formData = {
        ...common,
        waitTime,
        nextState,
      };
    }

    return formData;
  };

  const updateFormData = useCallback(stateToUpdate => {
    if (typeof stateToUpdate !== 'undefined') {
      const formData = setupFormData(stateToUpdate);

      setFormValues(formData);
      form.setFieldsValue(formData);
    }
  }, []);

  const stateIsAssignable = formValues.assignable;

  // Setup the required information into the form
  const {
    mounted,
    dropdown: {
      generateLicenses,
      states: { generate: generateOrchestratorStates, nextStateIsRequired },
    },
  } = useOrchestratorStateForm({
    isNewState,
    currentAlias: formValues.alias,
    currentTenant,
    currentLoading,
    updateFormData,
    stateIsAssignable,
  });

  const alertError = 'Error';
  const alertSuccess = 'Success';

  const showAlertResult = (msg, type) => {
    if (type === alertError) {
      notificationError(msg);

      return;
    }

    notificationSuccess(msg);
  };

  // Set the form action to create or update according the orchestrator state value
  useEffect(() => {
    if (typeof orchestratorStateId !== 'undefined') {
      setIsNewState(false);
    }
  }, [orchestratorStateId]);

  const handleChangeSelect = (e, field) => {
    form.setFieldsValue({
      [field]: e,
    });

    setFormValues({
      ...formValues,
      [field]: e,
    });
  };

  const handleChange = e => {
    if (e.target?.props !== undefined && e.target.props?.name === 'description') {
      e.target.name = 'description';
    }

    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (e, input) => {
    setFormValues({
      ...formValues,
      [input]: e,
    });
  };

  const handleNumberChange = (e, input) => {
    setFormValues({
      ...formValues,
      [input]: e,
    });
  };

  const handleSubmit = async () => {
    setFormSubmitted(true);

    const payload = {
      ...setupFormData(formValues),
      orchestrator: orchestratorId,
    };

    if (!nextStateIsRequired) {
      delete payload.nextState;
    }

    if (isNewState) {
      storeOrchestratorState(payload);
    } else {
      updateOrchestratorState({
        ...payload,
        _id: orchestratorStateId,
      });
    }
  };

  const storeOrchestratorState = payload => {
    dispatch(orchestratorStateCreateBegin());

    orchestratorStateCreate({
      variables: {
        orchestratorStateCreateInput: payload,
      },
    })
      .then(({ data }) => {
        setFormSubmitted(false);

        const { code: responseCode, data: orchestratorState, success } = data.orchestratorStateCreate;

        if (success) {
          dispatch(orchestratorStateCreateSuccess(orchestratorState));
          showAlertResult(t('orchestrator.states.successCreated'), alertSuccess);

          return redirectToIndex();
        }

        dispatch(orchestratorStateCreateError(responseCode));
        showAlertResult(t(`codeResponse.${responseCode}`), 'Error');
      })
      .catch(e => {
        console.error('Orchestrator State Create Error ', e);

        setFormSubmitted(false);
        dispatch(orchestratorStateCreateError(e));
        showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
      });
  };

  const updateOrchestratorState = payload => {
    dispatch(orchestratorStateUpdateBegin());

    orchestratorStateUpdate({
      variables: {
        orchestratorStateUpdateInput: payload,
      },
    })
      .then(({ data }) => {
        setFormSubmitted(false);

        const { code: responseCode, data: orchestratorState, success } = data.orchestratorStateUpdate;

        if (success) {
          dispatch(orchestratorStateUpdateSuccess(orchestratorState));
          showAlertResult(t('orchestrator.states.successUpdated'), alertSuccess);

          return redirectToIndex();
        }

        dispatch(orchestratorStateUpdateError(responseCode));
        showAlertResult(t(`codeResponse.${responseCode}`), 'Error');
      })
      .catch(e => {
        console.error('Orchestrator State Update Error ', e);

        setFormSubmitted(false);
        dispatch(orchestratorStateUpdateError(e));
        showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
      });
  };

  const renderCommonFields = () => (
    <>
      {/* Orchestrator State Name */}
      <Col md={12} xs={24}>
        <Form.Item
          name="name"
          rules={[{ required: true }]}
          label={t('orchestrator.states.fields.name')}
          initialValue={formValues.name}
        >
          <Input
            name="name"
            value={formValues.name}
            onChange={handleChange}
            placeholder={t('orchestrator.states.fields.name')}
          />
        </Form.Item>
      </Col>

      {/* Orchestrator State Alias */}
      <Col md={12} xs={24}>
        <Form.Item
          name="alias"
          rules={[{ required: true }]}
          label={t('orchestrator.states.fields.alias')}
          initialValue={formValues.alias}
        >
          <Input
            name="alias"
            value={formValues.alias}
            disabled={!isNewState}
            onChange={handleChange}
            placeholder={t('orchestrator.states.fields.alias')}
          />
        </Form.Item>
      </Col>
    </>
  );

  const renderDescriptionField = () => (
    <>
      {/* Orchestrator Description */}
      <Col xs={24}>
        <Form.Item
          name="description"
          rules={[{}]}
          label={t('orchestrator.fields.description')}
          initialValue={formValues.description}
        >
          <TextArea
            name="description"
            value={formValues.description}
            onChange={handleChange}
            className="sDash_unresizable"
            placeholder={t('orchestrator.fields.description')}
          />
        </Form.Item>
      </Col>
    </>
  );

  const renderConditionalFields = () => {
    if (stateIsAssignable) {
      return (
        <>
          {/* Render the inputs name and alias */}
          {renderCommonFields()}

          {/* Orchestrator State Licenses */}
          <Col md={12} xs={24}>
            <Form.Item
              name="licenses"
              label={t('orchestrator.states.fields.licenses')}
              rules={[{ required: true }]}
              initialValue={formValues.licenses}
            >
              <Select
                name="licenses"
                mode="multiple"
                value={formValues.licenses}
                onChange={e => handleChangeSelect(e, 'licenses')}
                className="sDash_fullwidth-select"
                allowClear
                placeholder={t('orchestrator.states.fields.licenses')}
                autoClearSearchValue
              >
                {generateLicenses()}
              </Select>
            </Form.Item>
          </Col>

          {/* Orchestrator State Timeout */}
          <Col md={12} xs={24}>
            <Form.Item
              name="timeout"
              label={`${t('orchestrator.states.fields.timeout')} - (${t('general.minutes')})`}
              rules={[{ required: true }]}
              initialValue={formValues.timeout}
            >
              <InputNumber
                name="timeout"
                value={formValues.timeout}
                onChange={event => handleNumberChange(event, 'timeout')}
                min={1}
                maxLength={3}
                placeholder={t('orchestrator.states.fields.timeout')}
              />
            </Form.Item>
          </Col>

          {renderDescriptionField()}
        </>
      );
    }

    return (
      <>
        {/* Render the inputs name and alias */}
        {renderCommonFields()}

        {/* Orchestrator State NextState */}
        <Col md={12} xs={24}>
          <Form.Item
            name="nextState"
            label={t('orchestrator.states.fields.nextState')}
            rules={[{ required: nextStateIsRequired }]}
            initialValue={formValues.nextState}
          >
            <Select
              name="nextState"
              value={formValues.nextState}
              onChange={e => handleChangeSelect(e, 'nextState')}
              className="sDash_fullwidth-select"
              allowClear
              placeholder={t('orchestrator.states.fields.nextState')}
              autoClearSearchValue
            >
              {generateOrchestratorStates()}
            </Select>
          </Form.Item>
        </Col>

        {/* Orchestrator State WaitTime */}
        <Col md={12} xs={24}>
          <Form.Item
            name="waitTime"
            label={`${t('orchestrator.states.fields.waitTime')} - (${t('general.minutes')})`}
            rules={[{ required: true }]}
            initialValue={formValues.waitTime}
          >
            <InputNumber
              name="waitTime"
              value={formValues.waitTime}
              onChange={event => handleNumberChange(event, 'waitTime')}
              min={1}
              maxLength={3}
              placeholder={t('orchestrator.states.fields.waitTime')}
            />
          </Form.Item>
        </Col>

        {renderDescriptionField()}
      </>
    );
  };

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1'),
    types: {
      email: t('type.email')
    }
  };
  /* eslint-enable */

  // Clear the input data according the form assignability
  useEffect(() => {
    if (mounted) {
      let fieldsToReset = {
        ...formValues,
        timeout: isNewState ? 1 : formValues.timeout,
        licenses: isNewState ? [] : formValues.licenses,
      };

      if (stateIsAssignable) {
        fieldsToReset = {
          ...formValues,
          waitTime: isNewState ? 1 : formValues.waitTime,
          nextState: isNewState ? '' : formValues.nextState,
        };
      }

      form.setFieldsValue(fieldsToReset);
      setFormValues({
        ...formValues,
        ...fieldsToReset,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, stateIsAssignable]);

  if (mounted) {
    return (
      <>
        <PageHeader ghost title={t(`orchestrator.states.${isNewState ? 'create' : 'edit'}`)} />
        <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <NavLink to={`/admin/`}>
                        <HomeOutlined />
                      </NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <NavLink to={`/admin/orchestrator`}>
                        <span style={{ "font-size": "14px" }}> {t('orchestrator.management')}</span></NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                    <NavLink to={`/admin/orchestrator/:orchestratorId/states`}>
                        <span>{t('orchestrator.states.management')}</span></NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <NavLink to="#">{t(`orchestrator.states.${isNewState ? 'create' : 'edit'}`)}</NavLink>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </BreadcrumbWrapperStyle>
          <Row gutter={15}>
            <Col xs={24}>
              <Cards>
                <Form
                  form={form}
                  size="middle"
                  name="orchestratorStateForm"
                  layout="vertical"
                  onFinish={handleSubmit}
                  autoComplete="off"
                  scrollToFirstError
                  validateMessages={validateMessages}
                >
                  <Row gutter={30}>
                    {/* Orchestrator Assignable */}
                    <Col md={12} xs={24}>
                      <Form.Item
                        name="assignable"
                        rules={[{ required: true }]}
                        label={t('orchestrator.states.fields.assignable')}
                        initialValue={formValues.assignable}
                      >
                        <Switch
                          checked={formValues.assignable}
                          onChange={event => handleSwitch(event, 'assignable')}
                          checkedChildren={t('general.yes')}
                          unCheckedChildren={t('general.no')}
                        />
                      </Form.Item>
                    </Col>

                    {/* Orchestrator State IsEnd  */}
                    <Col md={12} xs={24}>
                      <Form.Item
                        name="isEnd"
                        rules={[{ required: true }]}
                        label={t('orchestrator.states.fields.isEnd')}
                        initialValue={formValues.isEnd}
                      >
                        <Switch
                          checked={formValues.isEnd}
                          onChange={event => handleSwitch(event, 'isEnd')}
                          checkedChildren={t('general.yes')}
                          unCheckedChildren={t('general.no')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={30}>
                    {/* Render the remaining fields according to the assignable option */}
                    {renderConditionalFields()}
                  </Row>

                  {/* Action Buttons */}
                  <Row gutter={30}>
                    {/* Submit Button */}
                    <Col md={12} xs={24}>
                      <Form.Item>
                        <Button
                          key="2"
                          type="primary"
                          style={{ height: '44px' }}
                          loading={formSubmitted}
                          htmlType="submit"
                          className="pull-left"
                        >
                          {formSubmitted ? t('loading.1') : t(`general.${isNewState ? 'create' : 'update'}`)}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default OrchestratorStateForm;
