import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import CustomReportManagement from '../../pages/admin/custom-report';
import CustomReportCreate from '../../pages/admin/custom-report/CustomReportCreate';
import CustomReportEdit from '../../pages/admin/custom-report/CustomReportEdit';
import NotFound from '../../utils/404';

const CustomReportsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/edit/:customReportId`} component={CustomReportEdit} />
      <Route exact path={`${path}/create`} component={CustomReportCreate} />
      <Route exact path={`${path}`} component={CustomReportManagement} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default CustomReportsRoute;
