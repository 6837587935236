import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Avatar from 'react-avatar';
import { CircleFlag } from 'react-circle-flags';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import MailNotification from './message';
import WebNotification from './WebNotification';
// import Settings from './settings';
// import Support from './support';

import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import Heading from '../../heading/heading';
import actions from '../../../redux/authentication/actions';
import { QUERY_LOGOUT } from '../../../queries/auth.queries';
import { changeLayoutMode } from '../../../redux/themeLayout/actionCreator';

const { logoutBegin, logoutSuccess, logoutErr } = actions;

const AuthInfo = () => {
  const { t } = useTranslation();
  const { userId, userName, userLicense, darkMode } = useSelector(state => ({
    userId: state.currentUserTenant.currentUser?._id ?? '',
    userName: state.currentUserTenant.currentUser?.name ?? 'N/A',
    userLicense: state.currentUserTenant.currentTenant?.userLicense ?? 'N/A',
    darkMode: state.changeLayoutMode.data,
  }));
  const [state, setState] = useState({
    flag: i18n.language === 'en' ? 'gb' : i18n.language,
  });
  const [switchTheme, setSwitchTheme] = useState(darkMode);
  const { flag } = state;
  const [logout] = useMutation(QUERY_LOGOUT);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSwitchTheme = value => {
    dispatch(changeLayoutMode(value));
    setSwitchTheme(value);
  };

  const SignOut = async e => {
    e.preventDefault();
    dispatch(logoutBegin());
    logout()
      .then(({ data }) => {
        if (data.logout.success) {
          dispatch(logoutSuccess());
          history.push('/');
        }
      })
      .catch(err => {
        dispatch(logoutErr(err));
        // eslint-disable-next-line no-console
        console.log('Sign Out Err: ', err);
      });
  };

  const userContent = (
    <UserDropDwon darkMode={darkMode} >
      <div className={darkMode?'ant-popover-inner-dark':'ant-popover-inner-light'}> 
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <Avatar name={userName} size={46} style={{ marginRight: '15px' }} />
          <figcaption>
            <Heading as="h5">{userName}</Heading>
            <p>{userLicense}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`/admin/user/edit/${userId}/info`}>
              <FeatherIcon icon="user" /> {t('general.profile')}
            </Link>
          </li>
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
          <li>
            <Link onClick={() => handleSwitchTheme(!switchTheme)} to="#">
              <FeatherIcon icon={switchTheme ? 'sun' : 'moon'} /> {t('general.switchTheme')}
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> {t('general.signOut')}
        </Link>
      </div>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    i18n.changeLanguage(value);
    setState({
      ...state,
      flag: value === 'en' ? 'gb' : value,
    });
  };

  const country = (
    <NavAuth darkMode={darkMode}>
      <Link onClick={() => onFlagChangeHandle('en')} to="#">
        <CircleFlag countryCode="gb" height="20" />
        <span>{t(`general.english`)}</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('es')} to="#">
        <CircleFlag countryCode="es" height="20" />
        <span>{t(`general.spanish`)}</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      <WebNotification />
      <MailNotification />
      {/* <Settings /> */}
      {/* <Support /> */}
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <CircleFlag countryCode={flag} height="20" />
          </Link>
        </Dropdown>
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar name={userName} size={32} round="20px" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
