const actions = {
  FILE_FIND_ALL_BEGIN: 'FILE_FIND_ALL_BEGIN',
  FILE_FIND_ALL_SUCCESS: 'FILE_FIND_ALL_SUCCESS',
  FILE_FIND_ALL_ERROR: 'FILE_FIND_ALL_ERROR',
  FILE_DOWNLOAD_BEGIN: 'FILE_DOWNLOAD_BEGIN',
  FILE_DOWNLOAD_SUCCESS: 'FILE_DOWNLOAD_SUCCESS',
  FILE_DOWNLOAD_ERROR: 'FILE_DOWNLOAD_ERROR',
  FILE_REMOVE_BEGIN: 'FILE_REMOVE_BEGIN',
  FILE_REMOVE_SUCCESS: 'FILE_REMOVE_SUCCESS',
  FILE_REMOVE_ERROR: 'FILE_REMOVE_ERROR',
  FILE_CREATE_BEGIN: 'FILE_CREATE_BEGIN',
  FILE_CREATE_SUCCESS: 'FILE_CREATE_SUCCESS',
  FILE_CREATE_ERROR: 'FILE_CREATE_ERROR',

  fileFindAllBegin: () => {
    return {
      type: actions.FILE_FIND_ALL_BEGIN,
    };
  },

  fileFindAllSuccess: data => {
    return {
      type: actions.FILE_FIND_ALL_SUCCESS,
      data,
    };
  },

  fileFindAllError: err => {
    return {
      type: actions.FILE_FIND_ALL_ERROR,
      err,
    };
  },

  fileDownloadBegin: () => {
    return {
      type: actions.FILE_DOWNLOAD_BEGIN,
    };
  },

  fileDownloadSuccess: data => {
    return {
      type: actions.FILE_DOWNLOAD_SUCCESS,
      data,
    };
  },

  fileDownloadError: err => {
    return {
      type: actions.FILE_DOWNLOAD_ERROR,
      err,
    };
  },

  fileRemoveBegin: () => {
    return {
      type: actions.FILE_REMOVE_BEGIN,
    };
  },

  fileRemoveSuccess: data => {
    return {
      type: actions.FILE_REMOVE_SUCCESS,
      data,
    };
  },

  fileRemoveError: err => {
    return {
      type: actions.FILE_REMOVE_ERROR,
      err,
    };
  },

  fileCreateBegin: () => {
    return {
      type: actions.FILE_CREATE_BEGIN,
    };
  },

  fileCreateSuccess: data => {
    return {
      type: actions.FILE_CREATE_SUCCESS,
      data,
    };
  },

  fileCreateError: err => {
    return {
      type: actions.FILE_CREATE_ERROR,
      err,
    };
  },
};

export default actions;
