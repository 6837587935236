import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { notificationError } from '../../components/utilities/notification';

const BotScheduleInput = () => {
  const { t } = useTranslation();
  const [scheduleList, setScheduleList] = useState([]);

  const addEmptyScheduleToList = useCallback(() => {
    const totalScheduleList = scheduleList.length;

    const canAddNewField = !totalScheduleList
      ? false
      : !scheduleList.filter(schedule => typeof schedule.moment === 'undefined' || schedule.moment === null).length;

    if (!canAddNewField) {
      notificationError(t('botSchedule.cannotAddScheduleField'));

      return;
    }

    const key = totalScheduleList > 0 ? scheduleList[totalScheduleList - 1].key + 1 : 0;

    const emptySchedule = {
      key,
    };

    setScheduleList([...scheduleList, emptySchedule]);
  }, [t, scheduleList]);

  const removeScheduleFromList = useCallback(
    key => {
      const remaining = scheduleList.filter(schedule => schedule.key !== key);

      setScheduleList([...remaining]);
    },
    [scheduleList],
  );

  const addOrUpdateScheduleFromList = useCallback(
    (key, moment, value) => {
      const valueAlreadyExists = scheduleList.filter(schedule => schedule.value === value);

      if (valueAlreadyExists.length) {
        notificationError(t('botSchedule.scheduleAlreadyAdded'));

        return;
      }

      const receivedSchedule = {
        key,
        moment,
        value,
      };

      const finalSchedule = scheduleList.length
        ? scheduleList.reduce((total, schedule, index) => {
            if (schedule.key === key) {
              // eslint-disable-next-line no-param-reassign
              total[index] = receivedSchedule;
            } else {
              total.push(schedule);
            }

            return total;
          }, [])
        : [receivedSchedule];

      setScheduleList([...finalSchedule]);
    },
    [t, scheduleList],
  );

  const handleScheduleButtonAction = (action, key, moment, value) => {
    if (action === 'ADD_EMPTY') {
      addEmptyScheduleToList();

      return;
    }

    if (action === 'REMOVE') {
      removeScheduleFromList(key);

      return;
    }

    addOrUpdateScheduleFromList(key, moment, value);
  };

  return {
    scheduleList,
    setScheduleList,
    handleScheduleButtonAction,
  };
};

export default BotScheduleInput;
