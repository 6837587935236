import React, { useState } from 'react';
import { Col, Form, Row, Select, InputNumber, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_API_KEY } from '../../../queries';
import apiKeyActions from '../../../redux/apiKey/actions';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
const { apiKeyCreateBegin, apiKeyCreateSuccess, apiKeyCreateError } = apiKeyActions;

const ApiKeyForm = ({ setModal }) => {
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [apiKeyCreate] = useMutation(CREATE_API_KEY);
  const [state, setState] = useState({
    form: {
      limit: 0,
      alertEmail: '',
      description: '',
      type: '',
    },
  });
  const handleChangeSelect = (e, field) => {
    form.setFieldsValue({
      [field]: e,
    });

    setState({
      form: {
        ...state.form,
        [field]: e,
      },
    });
  };
  const handleChange = (e, field = '') => {
    if (field !== '') {
      setState({
        form: {
          ...state.form,
          [field]: e,
        },
      });

      return;
    }

    if (e.target.props !== undefined && e.target.props.name === 'description') {
      e.target.name = 'description';
    }

    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };
  const buildOptions = () =>
    ['ocr', 'captcha'].map(item => (
      <Select.Option key={item} value={item}>
        {item.toUpperCase()}
      </Select.Option>
    ));
  const handleSubmit = async () => {
    setFormSubmitted(true);
    dispatch(apiKeyCreateBegin());

    apiKeyCreate({
      variables: {
        apiKeyCreateInput: {
          ...state.form,
          tenant: currentTenant?.tenant._id,
        },
      },
    })
      .then(({ data }) => {
        if (data.apiKeyCreate.success) {
          dispatch(apiKeyCreateSuccess({}));
          setFormSubmitted(false);
          setModal(false);
          notificationSuccess(t('microservices.successCreated'));
        }
      })
      .catch(e => {
        dispatch(apiKeyCreateError(e));
        setFormSubmitted(false);
        notificationError(t('microservices.errorCreated'));
      });
  };
  return (
    <>
      <Row gutter={15}>
        <Col xs={24}>
          <Form
            name="microservicesApiKeyCreate"
            form={form}
            layout="vertical"
            size="middle"
            scrollToFirstError
            autoComplete="off"
            onFinish={handleSubmit}
          >
            <Row gutter={30}>
              <Col md={12} xs={12}>
                <Form.Item
                  name="type"
                  label={t('microservices.fields.service')}
                  rules={[{ required: true }]}
                  initialValue={state.form.type}
                >
                  <Select
                    name="type"
                    onChange={e => handleChangeSelect(e, 'type')}
                    value={state.form.type}
                    placeholder={t('microservices.fields.service')}
                    className="sDash_fullwidth-select"
                  >
                    {buildOptions()}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item name="limit" label={t('microservices.fields.limit')} rules={[{ required: true }]}>
                  <InputNumber
                    name="limit"
                    min={0}
                    max={9999}
                    onChange={e => handleChange(e, 'limit')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col md={12} xs={12}>
                <Form.Item
                  name="alertEmail"
                  label={t('microservices.fields.alertTo')}
                  rules={[{ required: true }]}
                  initialValue={state.form.type}
                >
                  <Input
                    name="alertEmail"
                    onChange={handleChange}
                    value={state.form.alertEmail}
                    type="text"
                    placeholder="jhon.smith@enterprise.com.co"
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item
                  name="description"
                  label={t('microservices.fields.description')}
                  rules={[{ required: true }]}
                  initialValue={state.form.type}
                >
                  <Input
                    name="description"
                    onChange={handleChange}
                    value={state.form.alertEmail}
                    type="text"
                    placeholder={t('microservices.fields.description')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button style={{ height: '44px' }} htmlType="submit" type="primary" loading={formSubmitted}>
                {formSubmitted ? t('loading.1') : t(`general.create`)}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

ApiKeyForm.propTypes = {
  setModal: PropTypes.func,
};

export default ApiKeyForm;
