import React from 'react';
import { NodeObject } from './style';

const ReactFlowObjects = () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <center>
      <NodeObject type="success" onDragStart={event => onDragStart(event, 'input')} draggable>
        Start
      </NodeObject>
      <NodeObject type="primary" onDragStart={event => onDragStart(event, 'default')} draggable>
        Execution
      </NodeObject>
      <NodeObject
        style={{ marginBottom: '0px' }}
        type="error"
        onDragStart={event => onDragStart(event, 'output')}
        draggable
      >
        End
      </NodeObject>
    </center>
  );
};

export default ReactFlowObjects;
