import Styled, { css } from 'styled-components';

const BaseNodeStyle = css`
  border: 2px solid ${({ theme, type }) => theme[`${type}-color`]};
  margin-bottom: 15px;
  border-radius: ${({ type }) => (type !== 'primary' ? '50%' : '5px')};
  line-height: 43px;
  height: 50px;
  width: ${({ type }) => (type !== 'primary' ? '50px' : '75px')};
  text-align: center;
  cursor: grab;
`;

const NodeObject = Styled.div`
    ${BaseNodeStyle};
`;

const ReactFlowLayout = Styled.div`
    .react-flow__node-input {
        ${BaseNodeStyle}
        line-height: 25px;
        border: 2px solid ${({ theme }) => theme[`success-color`]};
        border-radius: 50%;
        width: 50px;
    }
    .react-flow__node-input.selected, .react-flow__node-input.selected:hover {
        box-shadow: 0 0 0 0.5px ${({ theme }) => theme[`success-color`]};
    }
    .react-flow__node-input .react-flow__handle {
        background: ${({ theme }) => theme[`success-color`]};
    }

    .react-flow__node-default {
        ${BaseNodeStyle}
        line-height: 25px;
        border: 2px solid ${({ theme }) => theme[`primary-color`]};
        border-radius: 5px;
        width: 75px;
    }
    .react-flow__node-default.selected, .react-flow__node-default.selected:hover {
        box-shadow: 0 0 0 0.5px ${({ theme }) => theme[`primary-color`]};
    }
    .react-flow__node-default .react-flow__handle {
        background: ${({ theme }) => theme[`primary-color`]};
    }

    .react-flow__node-output {
        ${BaseNodeStyle}
        line-height: 25px;
        border: 2px solid ${({ theme }) => theme[`error-color`]};
        border-radius: 50%;
        width: 50px;
    }
    .react-flow__node-output.selected, .react-flow__node-output.selected:hover {
        box-shadow: 0 0 0 0.5px ${({ theme }) => theme[`error-color`]};
    }
    .react-flow__node-output .react-flow__handle {
        background: ${({ theme }) => theme[`error-color`]};
    }
`;

export { NodeObject, BaseNodeStyle, ReactFlowLayout };
