import React from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { EmailNav } from './style';
import { useSelector} from 'react-redux';

const EmailNavbar = ({ path, toggleCollapsed}) => {
  const email = useSelector(state => state.mailNotificationReducer.data);
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  return (
    <>
      <EmailNav darkMode={darkMode} >
        <ul>
          <li>
            <NavLink to={`${path}inbox`} onClick={toggleCollapsed}>
              <FeatherIcon icon="inbox" size={18}  style={{color:(darkMode ?`#E1E0E4` : ``)}} />
              <span className="nav-text">
                <span>Inbox</span>
                <span className="badge badge-primary" style={{color:(darkMode ?`#E1E0E4` : ``)}}>{email?.length || 0}</span>
              </span>
            </NavLink>
          </li>
        </ul>
      </EmailNav>
    </>
  );
};

EmailNavbar.propTypes = {
  path: propTypes.string.isRequired,
};

export default EmailNavbar;
