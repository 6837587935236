import React from 'react';
import PropTypes, { object } from 'prop-types';
import { ModalStyled } from './styled';
import { Button } from '../buttons/buttons';
import { useSelector } from 'react-redux';
const Modal = props => {
  const { onCancel,  onOk, visible, title, type, footer, width, children } = props;
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
const themes = (darkMode ? '#37373B' : '#fff')
const color = (darkMode ? '#E1E0E4' : '#000000d9')
  return (
    <ModalStyled 
    theme={themes}
    colors={color}
    darkMode={darkMode}
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      type={type}
      width={width}
      footer={
        footer || footer === null
          ? footer
          : [
              <Button type="secondary" key="back" onClick={onCancel}>
                Cancel
              </Button>,
              <Button type={type} key="submit" onClick={onOk}>
                Save Change
              </Button>,
            ]
      }
    >
      {children}
    </ModalStyled>
  );
};

Modal.defaultProps = {
  width: 620,
  className: 'atbd-modal',
};

Modal.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  visible: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  footer: PropTypes.arrayOf(object),
  width: PropTypes.number,
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
  darkMode:PropTypes.bool,
};

const alertModal = ModalStyled;
export { Modal, alertModal };
