import { gql } from '@apollo/client';

export const QUERY_ORCHESTRATOR_STATE_FIND_ALL = gql`
  query orchestratorStateFindAll($orchestratorId: String!) {
    orchestratorStateFindAll(orchestratorId: $orchestratorId) {
      success
      code
      data {
        _id
        name
        alias
        isEnd
        isFirst
        timeout
        waitTime
        licenses {
          serial
          computerInfo {
            name
          }
        }
        nextState {
          _id
          name
          alias
        }
        assignable
        description
        orchestrator
      }
    }
  }
`;

export const QUERY_ORCHESTRATOR_STATE_FIND_ONE = gql`
  query orchestratorStateFindOne($orchestratorStateId: String!) {
    orchestratorStateFindOne(orchestratorStateId: $orchestratorStateId) {
      success
      code
      data {
        _id
        name
        isEnd
        alias
        isFirst
        timeout
        waitTime
        licenses {
          serial
          computerInfo {
            name
          }
        }
        nextState {
          _id
          name
          alias
        }
        assignable
        description
        orchestrator
      }
    }
  }
`;

export const QUERY_ORCHESTRATOR_STATE_CREATE = gql`
  mutation orchestratorStateCreate($orchestratorStateCreateInput: OrchestratorStateCreateInput!) {
    orchestratorStateCreate(orchestratorStateCreateInput: $orchestratorStateCreateInput) {
      success
      code
      data {
        _id
        name
        isEnd
        alias
        isFirst
        timeout
        waitTime
        licenses {
          serial
          computerInfo {
            name
          }
        }
        nextState {
          _id
          name
          alias
        }
        assignable
        description
      }
    }
  }
`;

export const QUERY_ORCHESTRATOR_STATE_UPDATE = gql`
  mutation orchestratorStateUpdate($orchestratorStateUpdateInput: OrchestratorStateUpdateInput!) {
    orchestratorStateUpdate(orchestratorStateUpdateInput: $orchestratorStateUpdateInput) {
      success
      code
      data {
        _id
        name
        alias
        isEnd
        isFirst
        timeout
        waitTime
        licenses {
          serial
          computerInfo {
            name
          }
        }
        nextState {
          _id
          name
          alias
        }
        assignable
        description
      }
    }
  }
`;

export const QUERY_ORCHESTRATOR_STATE_REMOVE = gql`
  mutation orchestratorStateRemove($orchestratorStateId: String!) {
    orchestratorStateRemove(orchestratorStateId: $orchestratorStateId) {
      success
      code
    }
  }
`;
