import actions from './actions';

const {
  CURRENT_USER_TENANT_BEGIN,
  CURRENT_USER_TENANT_SUCCESS,
  CURRENT_USER_TENANT_ERR,
  CURRENT_TENANT_UPDATE_BEGIN,
  CURRENT_TENANT_UPDATE_SUCCESS,
  CURRENT_TENANT_UPDATE_ERR,
} = actions;

const initState = {
  currentUser: null,
  currentTenant: null,
  loading: false,
  error: null,
};

const currentUserTenantReducer = (state = initState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case CURRENT_USER_TENANT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CURRENT_USER_TENANT_SUCCESS:
      return {
        ...state,
        currentUser: data.currentUser,
        currentTenant: data.currentTenant,
        loading: false,
      };
    case CURRENT_USER_TENANT_ERR:
      return {
        ...state,
        error,
        loading: false,
      };
    case CURRENT_TENANT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CURRENT_TENANT_UPDATE_SUCCESS:
      return {
        ...state,
        currentTenant: data,
        loading: false,
      };
    case CURRENT_TENANT_UPDATE_ERR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};
export default currentUserTenantReducer;
