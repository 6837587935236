import React, { useState, lazy, Suspense, useLayoutEffect } from 'react';
import { Row, Col, Spin ,Breadcrumb} from 'antd';
import { Switch, Route } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import EmailNavbar from './overview/Navbar';
import ComposeMail from './overview/Compose';
import { useSelector } from 'react-redux';
import { EmailWrapper, MailSideBar } from './overview/style';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from './styled';
import { Button } from '../../components/buttons/buttons';
import { useTranslation } from 'react-i18next';
import { BreadcrumbWrapperStyle } from '../ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
const Inbox = lazy(() => import('./overview/Inbox'));
const MailDetailView = lazy(() => import('./overview/MailDetailView'));

const Email = ({ match }) => {

  const { t } = useTranslation();
  const [isMailEditorOpen, setMailEditorStatus] = useState(false);
  const [state, setState] = useState({
    responsive: 0,
    collapsed: false,
  });
  const { responsive, collapsed } = state;
  const { path } = match;
  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      setState({ responsive: width });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };

  // const toggleMailComposer = () => {
  //   setMailEditorStatus(!isMailEditorOpen);
  // };

  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;

  const closeMailComposr = () => {
    setMailEditorStatus(false);
  };

  const pathName = path.split(':')[0];

  return (
    <>
      <PageHeader
        title={t(`notification.mailNotification.title`)}
      />
      {isMailEditorOpen && <ComposeMail close={closeMailComposr} />}

      <Main darkMode={darkMode}>
      <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">{t(`notification.mailNotification.name`)}</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <EmailWrapper darkMode={darkMode}>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={5} xl={7} lg={8} xs={24}>
              {responsive <= 991 && (
                <Button type="link" className="mail-sidebar-trigger" style={{ marginTop: 0 }} onClick={toggleCollapsed}>
                  <FeatherIcon icon={collapsed ? 'align-left' : 'align-right'} />
                </Button>
              )}

              {responsive > 991 ? (
                <div className="mail-sideabr">
                  <Cards headless>
                    <div className="mail-sidebar-bottom">
                      <EmailNavbar path={pathName} />
                    </div>
                  </Cards>
                </div>
              ) : (
                <MailSideBar className={collapsed ? 'mail-sideabr show' : 'mail-sideabr hide'}>
                  <Cards headless>
                    <Button
                      type="link"
                      className="mail-sidebar-trigger trigger-close"
                      style={{ marginTop: 0 }}
                      onClick={toggleCollapsed}
                    >
                      <FeatherIcon icon="x" />
                    </Button>
                    <div className="mail-sidebar-bottom">
                      <EmailNavbar path={pathName} toggleCollapsed={toggleCollapsed} />
                    </div>
                  </Cards>
                </MailSideBar>
              )}
            </Col>

            <Col xxl={19} xl={17} lg={16}>
              <Switch>
                <Suspense
                  fallback={
                    <div className="spin">
                      <Spin />
                    </div>
                  }
                >
                  <Route path={`${pathName}inbox`} component={Inbox} />
                  <Route path={`${pathName}single/:id`} component={MailDetailView} />
                </Suspense>
              </Switch>
            </Col>
          </Row>
        </EmailWrapper>

      </Main>
    </>
  );
};

Email.propTypes = {
  match: propTypes.object,
};

export default Email;
