const actions = {
  ORCHESTRATOR_STATE_GET_ALL_BEGIN: 'ORCHESTRATOR_STATE_GET_ALL_BEGIN',
  ORCHESTRATOR_STATE_GET_ALL_SUCCESS: 'ORCHESTRATOR_STATE_GET_ALL_SUCCESS',
  ORCHESTRATOR_STATE_GET_ALL_ERROR: 'ORCHESTRATOR_STATE_GET_ALL_ERROR',

  ORCHESTRATOR_STATE_CREATE_BEGIN: 'ORCHESTRATOR_STATE_CREATE_BEGIN',
  ORCHESTRATOR_STATE_CREATE_SUCCESS: 'ORCHESTRATOR_STATE_CREATE_SUCCESS',
  ORCHESTRATOR_STATE_CREATE_ERROR: 'ORCHESTRATOR_STATE_CREATE_ERROR',

  ORCHESTRATOR_STATE_FIND_ONE_BEGIN: 'ORCHESTRATOR_STATE_FIND_ONE_BEGIN',
  ORCHESTRATOR_STATE_FIND_ONE_SUCCESS: 'ORCHESTRATOR_STATE_FIND_ONE_SUCCESS',
  ORCHESTRATOR_STATE_FIND_ONE_ERROR: 'ORCHESTRATOR_STATE_FIND_ONE_ERROR',
  ORCHESTRATOR_STATE_FIND_ONE_RESET: 'BOT_SCHEDULE_FIND_ONE_RESET',

  ORCHESTRATOR_STATE_UPDATE_BEGIN: 'ORCHESTRATOR_STATE_UPDATE_BEGIN',
  ORCHESTRATOR_STATE_UPDATE_SUCCESS: 'ORCHESTRATOR_STATE_UPDATE_SUCCESS',
  ORCHESTRATOR_STATE_UPDATE_ERROR: 'ORCHESTRATOR_STATE_UPDATE_ERROR',

  ORCHESTRATOR_STATE_REMOVE_BEGIN: 'ORCHESTRATOR_STATE_REMOVE_BEGIN',
  ORCHESTRATOR_STATE_REMOVE_SUCCESS: 'ORCHESTRATOR_STATE_REMOVE_SUCCESS',
  ORCHESTRATOR_STATE_REMOVE_ERROR: 'ORCHESTRATOR_STATE_REMOVE_ERROR',

  ORCHESTRATOR_STATE_DEACTIVATE_REDRAW_TABLE: 'ORCHESTRATOR_STATE_DEACTIVATE_REDRAW_TABLE',

  orchestratorStateGetAllBegin: () => {
    return {
      type: actions.ORCHESTRATOR_STATE_GET_ALL_BEGIN,
    };
  },

  orchestratorStateGetAllSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_STATE_GET_ALL_SUCCESS,
      data,
    };
  },

  orchestratorStateGetAllError: error => {
    return {
      type: actions.ORCHESTRATOR_STATE_GET_ALL_ERROR,
      error,
    };
  },

  orchestratorStateCreateBegin: () => {
    return {
      type: actions.ORCHESTRATOR_STATE_CREATE_BEGIN,
    };
  },

  orchestratorStateCreateSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_STATE_CREATE_SUCCESS,
      data,
    };
  },

  orchestratorStateCreateError: error => {
    return {
      type: actions.ORCHESTRATOR_STATE_CREATE_ERROR,
      error,
    };
  },

  orchestratorStateFindOneBegin: () => {
    return {
      type: actions.ORCHESTRATOR_STATE_FIND_ONE_BEGIN,
    };
  },

  orchestratorStateFindOneSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_STATE_FIND_ONE_SUCCESS,
      data,
    };
  },

  orchestratorStateFindOneError: error => {
    return {
      type: actions.ORCHESTRATOR_STATE_FIND_ONE_ERROR,
      error,
    };
  },

  orchestratorStateFindOneReset: () => {
    return {
      type: actions.ORCHESTRATOR_STATE_FIND_ONE_RESET,
    };
  },

  orchestratorStateUpdateBegin: () => {
    return {
      type: actions.ORCHESTRATOR_STATE_UPDATE_BEGIN,
    };
  },

  orchestratorStateUpdateSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_STATE_UPDATE_SUCCESS,
      data,
    };
  },

  orchestratorStateUpdateError: error => {
    return {
      type: actions.ORCHESTRATOR_STATE_UPDATE_ERROR,
      error,
    };
  },

  orchestratorStateRemoveBegin: () => {
    return {
      type: actions.ORCHESTRATOR_STATE_REMOVE_BEGIN,
    };
  },

  orchestratorStateRemoveSuccess: data => {
    return {
      type: actions.ORCHESTRATOR_STATE_REMOVE_SUCCESS,
      data,
    };
  },

  orchestratorStateRemoveError: error => {
    return {
      type: actions.ORCHESTRATOR_STATE_REMOVE_ERROR,
      error,
    };
  },

  orchestratorStateDeactivateReDraw: () => {
    return {
      type: actions.ORCHESTRATOR_STATE_DEACTIVATE_REDRAW_TABLE,
    };
  },
};

export default actions;
