import { gql } from '@apollo/client';

export const QUERY_WEB_NOTIFICATION = gql `
query webNotificationFindAll {
    webNotificationFindAll {
      success,
      code,
      data {
        _id,
        message,
        createdAt,
        readed,
        type
      }
    }
  }`

  export const MUTATTION_WEB_NOTIFICATION_UPDATA = gql `
  mutation webNotificationUpdateReaded ($id: String!){
    webNotificationUpdateReaded(id: $id) {
      success
      code
      data {
        _id
        message
        readed
      }
    }
  }`


  export const MUTATTION_WEB_NOTIFICATION_DELETE = gql`
  mutation webNotificationRemove ($id:String!){
    webNotificationRemove(id: $id) {
      success,
      code,
      data {
        _id,
        message,
      readed,
      type,
      createdAt,
      }
    }
  }`



export const QUERY_WEB_NOTIFICATION_CREATE =gql`
mutation webNotificationCreate ($message:String!,$type:WebNotificationType!){
  webNotificationCreate(
    webNotificationCreateInput: {
      message: $message
      type: $type
    }
  ) {
    success
    code
    data {
      _id
      message,
      readed,
      type,
      createdAt,
    }
  }
}`