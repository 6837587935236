import React, { useEffect, useState } from 'react';
import { Button, Table, Col, Tooltip, Spin, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';

import { ExportStyleWrap } from './style';
import { AutoComplete } from '../autoComplete/autoComplete';
import { Cards } from '../cards/frame/cards-frame';
import { AddUser } from '../../pages/admin/user/style';
import { TableWrapper, UserTableStyleWrapper } from '../../pages/admin/styled';

const PasswordVaultList = ({
  variablesData,
  isTenantVault,
  handleSecureVariableUpdate,
  handleSecureVariableDestroy,
  loading,
}) => {
  const { t } = useTranslation();
  const [onlyShared, setOnlyShared] = useState(true);
  const [tableData, setTableData] = useState([]);

  const handleFilterBy = x => `${t('general.filterBy')} ${x[0].toUpperCase() + x.substring(1)}`;

  const handleTableVariablesFilter = () => {
    setOnlyShared(!onlyShared);
  };

  const updateBotVaultTable = variablesObj => {
    if (variablesObj?.length) {
      const variables = variablesObj;

      setTableData(
        variables
          .filter(secureVariable => (onlyShared ? secureVariable.user === 'SHARED' : secureVariable.user !== 'SHARED'))
          .map(secureVariable => {
            const { name, user, description, isTenantVariable } = secureVariable;
            const newSecureVariable = {
              key: name,
              name: (
                <>
                  <span>
                    {name}
                    {isTenantVariable && (
                      <Tooltip title={t('botVault.isTenantVariable')}>
                        <FeatherIcon
                          icon="alert-triangle"
                          className="color-secondary"
                          size={16}
                          style={{ verticalAlign: 'middle', marginLeft: '5px' }}
                        />
                      </Tooltip>
                    )}
                  </span>
                </>
              ),
              description: typeof description !== 'undefined' && description.length ? description : 'N/A',
              actions: (
                <div className="table-actions">
                  <>
                    <Tooltip title={t('general.buttons.edit')}>
                      <Button
                        className="btn-icon"
                        type="info"
                        shape="circle"
                        onClick={() => handleSecureVariableUpdate(secureVariable)}
                      >
                        <FeatherIcon icon="edit" size={16} />
                      </Button>
                    </Tooltip>

                    <Tooltip title={t('general.buttons.delete')}>
                      <Button
                        className="btn-icon"
                        type="danger"
                        shape="circle"
                        onClick={() => handleSecureVariableDestroy(name, user)}
                      >
                        <FeatherIcon icon="trash-2" size={16} />
                      </Button>
                    </Tooltip>
                  </>
                </div>
              ),
            };
            return newSecureVariable;
          }),
      );
    } else {
      setTableData([]);
    }
  };

  const updateTenantVaultTable = variablesObj => {
    if (variablesObj?.length) {
      const variables = variablesObj;

      setTableData(
        variables.map(secureVariable => {
          const { name, description } = secureVariable;
          const newSecureVariable = {
            key: name,
            name,
            description: typeof description !== 'undefined' && description.length ? description : 'N/A',
            actions: (
              <div className="table-actions">
                <>
                  <Tooltip title={t('general.buttons.edit')}>
                    <Button
                      className="btn-icon"
                      type="info"
                      shape="circle"
                      onClick={() => handleSecureVariableUpdate(secureVariable)}
                    >
                      <FeatherIcon icon="edit" size={16} />
                    </Button>
                  </Tooltip>

                  <Tooltip title={t('general.buttons.delete')}>
                    <Button
                      className="btn-icon"
                      type="danger"
                      shape="circle"
                      onClick={() => handleSecureVariableDestroy(name)}
                    >
                      <FeatherIcon icon="trash-2" size={16} />
                    </Button>
                  </Tooltip>
                </>
              </div>
            ),
          };
          return newSecureVariable;
        }),
      );
    } else {
      setTableData([]);
    }
  };

  const handleSearchName = name => {
    if (!isTenantVault)
      updateBotVaultTable(variablesData.filter(x => x.name.toLowerCase().includes(name.toLowerCase())));
    else updateTenantVaultTable(variablesData.filter(x => x.name.toLowerCase().includes(name.toLowerCase())));
  };

  useEffect(() => {
    if (!isTenantVault) updateBotVaultTable(variablesData);
    else updateTenantVaultTable(variablesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variablesData, isTenantVault, onlyShared]);

  const columns = [
    {
      title: t('botVault.fields.name'),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
    },
    {
      title: t('botVault.fields.description'),
      dataIndex: 'description',
      key: 'description',
      width: '40%',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
    },
  ];

  if (loading)
    return (
      <div className="spin">
        <Spin />
      </div>
    );

  return (
    <Col xs={24}>
      <AddUser>
        <Cards
          title={
            !isTenantVault && (
              <div className="card-nav">
                <ul>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      // eslint-disable-next-line no-script-url
                      href="javascript:void(0)"
                      className={onlyShared ? 'active' : ''}
                      onClick={() => handleTableVariablesFilter()}
                    >
                      <FeatherIcon icon="share" size={14} />
                      {t('botVault.shared')}
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      // eslint-disable-next-line no-script-url, jsx-a11y/anchor-is-valid
                      href="javascript:void(0)"
                      className={!onlyShared ? 'active' : ''}
                      onClick={() => handleTableVariablesFilter()}
                    >
                      <FeatherIcon icon="user" size={14} />
                      {t('botVault.ofUser')}
                    </a>
                  </li>
                </ul>
              </div>
            )
          }
          headless={isTenantVault}
        >
          {isTenantVault && (
            <Row style={{ marginTop: '-20px' }}>
              <Col md={12} xs={24}>
                <ExportStyleWrap>
                  <div className="sDash_export-box">
                    <AutoComplete
                      onSearch={x => handleSearchName(x)}
                      placeholder={handleFilterBy(t('properties.name.1'))}
                      width="100%"
                      patterns
                    />
                  </div>
                </ExportStyleWrap>
              </Col>
            </Row>
          )}
          <div className="table-bordered leaderboard-table table-responsive">
            <UserTableStyleWrapper>
              <TableWrapper className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={tableData}
                  pagination={{
                    defaultPageSize: 10,
                    total: tableData?.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
                  }}
                />
              </TableWrapper>
            </UserTableStyleWrapper>
          </div>
        </Cards>
      </AddUser>
    </Col>
  );
};

PasswordVaultList.propTypes = {
  variablesData: PropTypes.array,
  isTenantVault: PropTypes.bool,
  handleSecureVariableUpdate: PropTypes.func,
  handleSecureVariableDestroy: PropTypes.func,
  loading: PropTypes.bool,
};

export default PasswordVaultList;
