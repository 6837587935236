import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Modal, Form, Input, Spin, Select, DatePicker, Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { timestampToDate } from '../../../components/utilities/utilities';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, ExportStyleWrap, TableWrapper, UserTableStyleWrapper, DatePickerWrapper } from '../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';

import { AutoComplete } from '../../../components/autoComplete/autoComplete';
import actions from '../../../redux/botExecution/actions';
import { QUERY_BOT_EXECUTION_FIND_ALL, QUERY_BOT_FIND_ALL } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';


const {
  botExecutionFilterBegin,
  botExecutionFilterSuccess,
  botExecutionFindAllBegin,
  botExecutionFindAllSuccess,
  botExecutionFindAllError,
} = actions;

const { Option } = Select;

const { RangePicker } = DatePicker;

const BotExecutionReport = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadingBotExecutions = useSelector(state => state.botExecution.loading);
  const botExecutions = useSelector(state => state.botExecution.dataFiltered);
  const history = useHistory();
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [mounted, setMounted] = useState(false);
  const [mountedBots, setMountedBots] = useState(false);
  const [mountedBotExecutions, setMountedBotExecutions] = useState(false);
  const [botExecutionsTableData, setBotExecutionsTableData] = useState([]);
  const [botsSelectData, setBotsSelectData] = useState([]);
  const [filterTable, setFilterTable] = useState({
    computerName: '',
    serial: '',
  });
  const [state, setState] = useState({
    botId: '',
    start: moment()
      .startOf('day')
      .toISOString(),
    end: moment()
      .endOf('day')
      .toISOString(),
    isModalVisible: false,
    fileName: 'Bot Execution Report',
  });
  const { loading: loadingBots, data: dataBots, error: errorBots } = useQuery(QUERY_BOT_FIND_ALL, {
    variables: {
      tenantId: currentTenant?.tenant._id,
    },
    fetchPolicy: 'network-only',
  });
  const [botExecutionFindAll, { loading, data }] = useLazyQuery(QUERY_BOT_EXECUTION_FIND_ALL, {
    fetchPolicy: 'network-only',
    onCompleted: x => {
      if (x.botExecutionFindAll.success) dispatch(botExecutionFindAllSuccess(x.botExecutionFindAll.data));
      else dispatch(botExecutionFindAllError(x.botExecutionFindAll.code));
    },
    onError: err => dispatch(botExecutionFindAllError(err)),
  });

  const csvData = [['Bot Execution Json Object'], ...botExecutions.map(x => [JSON.stringify(x)])];

  const handleFilterBy = x => `${t('general.filterBy')} ${x[0].toUpperCase() + x.substring(1)}`;

  const handleModal = () => {
    setState({
      ...state,
      isModalVisible: !state.isModalVisible,
    });
  };

  const handleSearch = (searchText, filter) => {
    setFilterTable(x => ({
      ...x,
      [filter]: searchText,
    }));
  };

  const { isModalVisible } = state;

  const updateFileName = e => {
    setState({
      ...state,
      fileName: e.target.value,
    });
  };

  const handleChangeSelect = e => {
    setState({
      ...state,
      botId: e,
    });
    setMountedBotExecutions(false);
  };

  const updateSelect = bots => {
    if (bots?.length > 0) {
      setBotsSelectData(
        bots.map(bot => {
          return (
            <Option key={bot._id} value={bot._id}>
              {bot.name}
            </Option>
          );
        }),
      );
    } else setBotsSelectData([]);
  };

  const onChangeRange = date => {
    if (date) {
      setState({
        ...state,
        start: date[0].startOf('day').toISOString(),
        end: date[1].endOf('day').toISOString(),
      });
      setMountedBotExecutions(false);
    }
  };

  useEffect(() => {
    if (state.botId && !mountedBotExecutions) {
      dispatch(botExecutionFindAllBegin());
      botExecutionFindAll({
        variables: {
          input: {
            id: state.botId,
            start: state.start,
            end: state.end,
            showAll: false,
          },
        },
      });
      setMountedBotExecutions(true);
    }
  }, [botExecutionFindAll, mountedBotExecutions, state.botId, state.start, state.end, dispatch]);

  useEffect(() => {
    dispatch(botExecutionFilterBegin());
    dispatch(botExecutionFilterSuccess(filterTable));
  }, [filterTable, dispatch]);

  useEffect(() => {
    if (botExecutions?.length > 0) {
      setBotExecutionsTableData(
        botExecutions.map((botExecution, index) => {
          return {
            key: botExecution._id,
            serial: botExecution.licenseSerial,
            computerName: botExecution.computerInfo.name,
            status: botExecution.status,
            createdLocalAt: botExecution.createdLocalAt && timestampToDate(botExecution.createdLocalAt),
            createdAt: timestampToDate(botExecution.createdAt),
          };
        }),
      );
    } else {
      setBotExecutionsTableData([]);
    }
  }, [botExecutions]);

  useEffect(() => {
    if (
      !currentLoading &&
      !currentTenant?.userPermissions?.includes(UserPermissions.BotExecutionFindAll) &&
      !currentTenant?.userPermissions?.includes(UserPermissions.BotFindAll)
    ) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    if (!loadingBots && !mountedBots) {
      if (dataBots?.botFindAll.success) {
        updateSelect(dataBots?.botFindAll.data);
      } else {
        history.push('/admin');
        notificationError(t(`codeResponse.${dataBots?.botFindAll.code}`));
      }
      setMountedBots(true);
    }
  }, [dataBots, loadingBots, errorBots, history, currentTenant, currentLoading, mountedBots, t]);

  useEffect(() => {
    if (!loading && data && !mounted) {
      dispatch(botExecutionFindAllBegin());
      if (data.botExecutionFindAll.success) {
        dispatch(botExecutionFindAllSuccess(data.botExecutionFindAll.data));
      } else {
        dispatch(botExecutionFindAllError(data.botExecutionFindAll.code));
        history.push('/admin');
        notificationError(t(`codeResponse.${data.botExecutionFindAll.code}`));
      }
      setMounted(true);
    }
  }, [loading, data, dispatch, history, mounted, t]);

  const { fileName } = state;

  const columns = [
    {
      title: t('properties.serial.1'),
      dataIndex: 'serial',
      key: 'serial',
      width: '25%',
    },
    {
      title: t('properties.computerName.1'),
      dataIndex: 'computerName',
      key: 'computerName',
      width: '25%',
    },
    {
      title: t('properties.status'),
      dataIndex: 'status',
      key: 'status',
      width: '10%',
    },
    {
      title: t('properties.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
    },
    {
      title: t('properties.createdLocalAt'),
      dataIndex: 'createdLocalAt',
      key: 'createdLocalAt',
      width: '20%',
    },
  ];
  if (mountedBots) {
    return (
      <>
        <PageHeader title={t('transaction.botExecution.report')} />
        <Main darkMode={darkMode}>
          <BreadcrumbWrapperStyle darkMode={darkMode}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to={`/admin/`}>
                  <HomeOutlined />
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="#">{t('transaction.botExecution.report')}</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </BreadcrumbWrapperStyle>
          <Row gutter={25} align="center">
            <Col md={12}>
              <Cards title={<>
                {"Bots"}
              </>
              }>
                <Select
                  showSearch
                  name="bots"
                  onChange={handleChangeSelect}
                  placeholder={t('general.selectBot')}
                  className="sDash_fullwidth-select"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {botsSelectData}
                </Select>
              </Cards>
            </Col>
            <Col md={12}>
              <Cards title={t('general.dateRange')}>
                <DatePickerWrapper>
                  <RangePicker
                    disabled={!mounted}
                    defaultValue={[moment(), moment()]}
                    ranges={{
                      [t('general.today')]: [moment(), moment()],
                      [t('general.thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
                    }}
                    onChange={onChangeRange}
                  />
                </DatePickerWrapper>
              </Cards>
            </Col>
          </Row>
          {mounted && (
            <Row gutter={25}>
              <Col sm={24} xs={24}>
                <ExportStyleWrap>
                  <Cards headless>
                    <div className="sDash_export-box">
                      <>
                        <Button
                          disabled={!(botExecutionsTableData.length > 0)}
                          className="btn-export"
                          onClick={handleModal}
                          type="primary"
                        >
                          {t('general.export')}
                        </Button>
                        <Modal
                          title={t('general.exportFile')}
                          wrapClassName="sDash_export-wrap"
                          visible={isModalVisible}
                          footer={null}
                          onCancel={handleModal}
                        >
                          <Form name="export">
                            <Form.Item name="f_name">
                              <Input
                                placeholder={t('properties.fileName')}
                                value={fileName}
                                onChange={updateFileName}
                              />
                            </Form.Item>
                            <div className="sDash-button-grp">
                              <CSVLink filename={`${fileName}.csv`} data={csvData}>
                                <Button onClick={handleModal} className="btn-export" type="primary">
                                  {t('general.export')}
                                </Button>
                              </CSVLink>

                              <Button onClick={handleModal} size="default" type="white" outlined>
                                {t('general.cancel')}
                              </Button>
                            </div>
                          </Form>
                        </Modal>
                      </>

                      <AutoComplete
                        onSearch={x => handleSearch(x, 'serial')}
                        // dataSource={notData}
                        placeholder={handleFilterBy(t('properties.serial.1'))}
                        width="100%"
                        patterns
                      />
                      <AutoComplete
                        onSearch={x => handleSearch(x, 'computerName')}
                        // dataSource={notData}
                        placeholder={handleFilterBy(t('properties.computerName.1'))}
                        width="100%"
                        patterns
                      />
                    </div>
                    <h4 style={{ marginTop: '-10px', marginBottom: '20px' }}>{t('general.maximumExportedItems')}</h4>
                    {!loadingBotExecutions && !loading ? (
                      <div className="sDash_export-file-table table-bordered table-responsive">
                        <UserTableStyleWrapper>
                          <TableWrapper className="table-responsive">
                            <Table
                              columns={columns}
                              dataSource={botExecutionsTableData}
                              pagination={{
                                defaultPageSize: 10,
                                total: botExecutionsTableData?.length,
                                showTotal: (total, range) =>
                                  `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
                              }}
                            />
                          </TableWrapper>
                        </UserTableStyleWrapper>
                      </div>
                    ) : (
                      <div className="spin">
                        <Spin />
                      </div>
                    )}
                  </Cards>
                </ExportStyleWrap>
              </Col>
            </Row>
          )}
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default BotExecutionReport;
