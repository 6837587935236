import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Table, Spin, Col, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { CSVLink } from 'react-csv';

import { AddUser } from '../user/style';
import { alertModal } from '../../../components/modals/antd-modals';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { TableWrapper, UserTableStyleWrapper } from '../styled';

import orchestratorActions from '../../../redux/orchestrator/actions';

import {
  QUERY_ORCHESTRATOR_REMOVE,
  QUERY_ORCHESTRATOR_FIND_ALL,
  QUERY_ORCHESTRATOR_FIND_DOWNLOAD,
} from '../../../queries';

const {
  orchestratorGetAllBegin,
  orchestratorGetAllSuccess,
  orchestratorGetAllError,
  orchestratorRemoveBegin,
  orchestratorRemoveSuccess,
  orchestratorRemoveError,
  orchestratorDeactivateReDraw,
  orchestratorDownloadBegin,
  orchestratorDownloadSuccess,
  orchestratorDownloadError,
} = orchestratorActions;

const OrchestratorList = () => {
  const { t } = useTranslation();
  const { orchestratorId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { orchestrators, reDrawTable } = useSelector(state => state.orchestrator);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [tenant] = useState(currentTenant?.tenant._id !== null ? currentTenant?.tenant._id : '');
  const [orchestratorRecords, setOrchestratorRecords] = useState([]);
  const csvLink = useRef();

  const [orchestratorRemove] = useMutation(QUERY_ORCHESTRATOR_REMOVE);
  const [orchestratorDownload] = useMutation(QUERY_ORCHESTRATOR_FIND_DOWNLOAD);

  const [mounted, setMounted] = useState(false);
  const [state, setState] = useState({
    orchestratorsData: [],
  });

  const queryArgs = {
    variables: {
      tenantId: tenant,
    },
    fetchPolicy: 'network-only',
  };

  const { data, error, loading } = useQuery(QUERY_ORCHESTRATOR_FIND_ALL, queryArgs);

  const renderModelProperties = input => {
    if (!input.length) {
      return 'N/A';
    }

    return input?.split(',').map((info, index) => <p key={index}>{info}</p>);
  };

  const handleOrchestratorRemove = useCallback(
    id => {
      alertModal.confirm({
        title: t('orchestrator.confirmRemove'),
        onOk() {
          dispatch(orchestratorRemoveBegin());

          orchestratorRemove({
            variables: { orchestratorId: id },
          })
            .then(({ data }) => {
              const { success, code } = data.orchestratorRemove;

              if (success) {
                dispatch(orchestratorRemoveSuccess(id));

                notificationSuccess(t('orchestrator.successDeleted'));

                setTimeout(() => {
                  orchestratorDeactivateReDraw();
                }, 500);

                return;
              }

              dispatch(orchestratorRemoveError(code));

              notificationError(t(`codeResponse.${code}`));
            })
            .catch(e => {
              console.error('Orchestrator Delete Error ', e);

              dispatch(orchestratorRemoveError());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [t, dispatch, orchestratorRemove],
  );

  const handleOrchestratorDownload = useCallback(
    id => {
      setMounted(false);

      dispatch(orchestratorDownloadBegin());

      orchestratorDownload({
        variables: {
          orchestratorId: id,
        },
      })
        .then(({ data }) => {
          const { code, data: records, success } = data.orchestratorExternalDownload;

          if (success) {
            dispatch(orchestratorDownloadSuccess());

            if (records === null) {
              notificationError(t('orchestrator.no_records'));

              return;
            }

            setOrchestratorRecords(records);

            csvLink.current.link.click();

            setMounted(true);

            return;
          }

          dispatch(orchestratorDownloadError(t(`codeResponse.${code}`)));
          notificationError(t(`codeResponse.${code}`));
          setMounted(true);
        })
        .catch(e => {
          setMounted(true);
          console.error('Orchestrator Download Delete Error ', e);

          dispatch(orchestratorDownloadError());
          notificationError(t('codeResponse.UNEXPECTED_ERROR'));
        });
    },
    [t, dispatch, orchestratorDownload],
  );

  const updateTable = useCallback(
    orchestratorsData => {
      const dataSource = [];

      if (orchestratorsData) {
        orchestratorsData.map(orchestrator => {
          const { _id, name, alias, model, enabled, description } = orchestrator;
          const textClassName = enabled ? 'active' : 'blocked';

          dataSource.push({
            key: _id,
            name,
            alias,
            model: renderModelProperties(model),
            description,
            status: (
              <span className={`status-text ${textClassName}`}>
                {enabled ? t('general.active') : t('general.inactive')}
              </span>
            ),
            actions: (
              <div className="table-actions">
                <>
                  <Tooltip title={t('orchestrator.dashboard.title')}>
                    <Button
                      type="info"
                      shape="circle"
                      onClick={() => history.push(`/admin/orchestrator/${_id}/dashboard`)}
                      className="btn-icon"
                    >
                      <FeatherIcon icon="pie-chart" size={16} />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('orchestrator.states.add')}>
                    <Button
                      type="info"
                      shape="circle"
                      onClick={() => history.push(`/admin/orchestrator/${_id}/states`)}
                      disabled={enabled === false}
                      className={`${enabled ? 'btn-icon' : ''}`}
                    >
                      <FeatherIcon icon="git-branch" size={16} />
                    </Button>
                  </Tooltip>

                  <Tooltip title={t('orchestrator.download')}>
                    <Button
                      type="info"
                      shape="circle"
                      onClick={() => handleOrchestratorDownload(_id)}
                      className="btn-icon"
                    >
                      <FeatherIcon icon="save" size={16} />
                    </Button>
                  </Tooltip>

                  <Tooltip title={t('general.buttons.edit')}>
                    <Button
                      type="info"
                      shape="circle"
                      onClick={() => history.push(`/admin/orchestrator/${_id}/edit`)}
                      className="btn-icon"
                    >
                      <FeatherIcon icon="edit" size={16} />
                    </Button>
                  </Tooltip>

                  <Tooltip title={t('general.buttons.delete')}>
                    <Button
                      type="danger"
                      shape="circle"
                      onClick={() => handleOrchestratorRemove(_id)}
                      className="btn-icon"
                    >
                      <FeatherIcon icon="trash-2" size={16} />
                    </Button>
                  </Tooltip>
                </>
              </div>
            ),
          });

          return true;
        });
      }

      setState({
        orchestratorsData: dataSource,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, history, orchestratorId, handleOrchestratorRemove],
  );

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotOrchestratorFindAll)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted) {
      dispatch(orchestratorGetAllBegin());

      if (typeof data === 'undefined') {
        dispatch(orchestratorGetAllError(error));
        notificationError(t('codeResponse.MICROSERVICE_UNAVAILABLE'));

        history.push('/admin');

        return;
      }

      const { success, data: orchestrator, code } = data.orchestratorFindAll;

      if (success) {
        dispatch(orchestratorGetAllSuccess(orchestrator));
        updateTable(orchestrator);
      } else {
        dispatch(orchestratorGetAllError(code));
      }

      setMounted(true);
    }
  }, [t, data, error, mounted, loading, history, dispatch, updateTable, currentTenant, currentLoading]);

  useEffect(() => {
    if (reDrawTable && orchestrators) {
      updateTable(orchestrators);
    }
  }, [updateTable, reDrawTable, orchestrators]);

  const columns = [
    {
      title: t('orchestrator.fields.name'),
      dataIndex: 'name',
      key: 'type',
      width: '20%',
    },
    {
      title: t('orchestrator.fields.alias'),
      dataIndex: 'alias',
      key: 'alias',
      width: '20%',
    },
    {
      title: t('orchestrator.fields.model'),
      dataIndex: 'model',
      key: 'model',
      width: '20%',
    },
    {
      title: t('orchestrator.fields.description'),
      dataIndex: 'description',
      key: 'description',
      width: '25%',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      sorter: (a, b) => {
        if (a.statusValue) return 1;
        if (b.statusValue) return -1;
        return 0;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
    },
  ];

  if (mounted) {
    return (
      <Col xs={24}>
        <AddUser>
          <div className="table-bordered leaderboard-table table-responsive">
            <UserTableStyleWrapper>
              <TableWrapper className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={state.orchestratorsData}
                  pagination={{
                    defaultPageSize: 10,
                    total: state.orchestratorsData?.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
                  }}
                />
              </TableWrapper>
            </UserTableStyleWrapper>

            <CSVLink
              ref={csvLink}
              data={orchestratorRecords}
              target="_blank"
              filename="orchestrator_records.csv"
              className="hidden"
            />
          </div>
        </AddUser>
      </Col>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default OrchestratorList;
