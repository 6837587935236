const actions = {
  BOT_GOALS_FIND_BEGIN: 'BOT_GOALS_FIND_BEGIN',
  BOT_GOALS_FIND_SUCCESS: 'BOT_EXECUTION_FIND_ALL_SUCCESS',
  BOT_GOALS_FIND_ERROR: 'BOT_EXECUTION_FIND_ALL_ERROR',

  botGoalsFindBegin: () => {
    return {
      type: actions.BOT_GOALS_FIND_BEGIN,
    };
  },

  botGoalsFindSuccess: data => {
    return {
      type: actions.BOT_GOALS_FIND_SUCCESS,
      data,
    };
  },

  botGoalsFindError: error => {
    return {
      type: actions.BOT_GOALS_FIND_ERROR,
      error,
    };
  },
};

export default actions;
