import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, Select, Spin, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory ,NavLink} from 'react-router-dom';
import botActions from '../../../redux/bot/actions';
import fileActions from '../../../redux/file/actions';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { QUERY_BOT_CREATE, QUERY_FILE_FIND_ALL } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationSuccess, notificationError } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const { botCreateBegin, botCreateSuccess, botCreateError } = botActions;
const { fileFindAllBegin, fileFindAllSuccess, fileFindAllError } = fileActions;

const BotCreate = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const files = useSelector(state => state.file.files);
  const [form] = Form.useForm();
  const [botCreate] = useMutation(QUERY_BOT_CREATE);
  const { TextArea } = Input;
  const [state, setState] = useState({
    form: {
      name: '',
      version: '',
      tenant: currentTenant?.tenant._id !== null ? currentTenant?.tenant._id : '',
      file: '',
      description: '',
    },
  });
  const alertSuccess = 'Success';
  const alertError = 'Error';

  const { Option } = Select;
  const [optionsFiles, setOptionsFiles] = useState([]);

  const { loading, data } = useQuery(QUERY_FILE_FIND_ALL, {
    variables: {
      fileListInput: { isPlataformaFile: false, tenant: currentTenant?.tenant._id },
    },
    fetchPolicy: 'network-only',
  });

  const showAlertResult = (msg, type) => {
    if (type === alertError) notificationError(msg);
    else notificationSuccess(msg);
  };

  const buildSelectOptions = () => {
    const options = [];
    files.forEach(file => {
      const { _id, name } = file;
      options.push(
        <Option key={_id} value={_id}>
          {name}
        </Option>,
      );
    });
    setOptionsFiles(options);
  };

  useEffect(() => {
    if (files?.length > 0) buildSelectOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    dispatch(fileFindAllBegin());

    if (!loading) {
      if (data?.fileFindAll.success) {
        dispatch(fileFindAllSuccess(data?.fileFindAll.data));
      } else {
        dispatch(fileFindAllError(data?.fileFindAll.code));
        history.push('/admin/bot');
        notificationError(t(`codeResponse.${data?.fileFindAll.code}`));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = async () => {
    dispatch(botCreateBegin());

    botCreate({
      variables: {
        botCreateInput: {
          name: state.form.name,
          version: state.form.version,
          description: state.form.description,
          tenant: state.form.tenant,
          file: state.form.file,
          enabled: true,
        },
      },
    })
      .then(({ data: botCreateData }) => {
        if (botCreateData.botCreate.success) {
          dispatch(botCreateSuccess(botCreateData.botCreate.data));

          showAlertResult(t('labels.bussiness.rpa.bots.table.success_created'), alertSuccess);

          history.push('/admin/bot');
        } else {
          dispatch(botCreateError(botCreateData.botCreate.code));

          showAlertResult(t(`codeResponse.${botCreateData.botCreate.code}`), alertError);
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('Bot Create Error ', e);
        dispatch(botCreateError());

        showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
      });
  };

  const handleChange = e => {
    // Validation For Textarea
    if (e.target.props !== undefined && e.target.props.name === 'description') {
      e.target.name = 'description';
    }

    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleChangeSelect = (e, field) => {
    form.setFieldsValue({
      [field]: e,
    });

    setState({
      form: {
        ...state.form,
        [field]: e,
      },
    });
  };

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1'),
    types: {
      email: t('type.email')
    }
  };
  /* eslint-enable */

  if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotCreate)) {
    history.push('/admin/bot');
    notificationError(t(`codeResponse.403`));
    return null;
  }
  if (!loading) {
    return (
      <>
        <PageHeader ghost title={t('labels.bussiness.rpa.bots.table.create')} />
        <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
                      <Breadcrumb>
                        <Breadcrumb.Item>
                          <NavLink to={`/admin/`}>
                            <HomeOutlined />
                          </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <NavLink to={`/admin/bot`}>
                            <span>{t('labels.bussiness.rpa.bots.title')}</span>
                          </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <NavLink to="#">{t('labels.bussiness.rpa.bots.table.create')}</NavLink>
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </BreadcrumbWrapperStyle>
          <Row gutter={15}>
            <Col xs={24}>
              <Cards>
                <Form
                  name="createbot"
                  form={form}
                  validateMessages={validateMessages}
                  onFinish={handleSubmit}
                  layout="vertical"
                  size="middle"
                  scrollToFirstError
                >
                  <Row gutter={30}>
                    {/* Name */}
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="name"
                        rules={[{ required: true }]}
                        label={t('labels.bussiness.rpa.bots.table.name')}
                      >
                        <Input
                          name="name"
                          onChange={handleChange}
                          value={state.form.name}
                          placeholder={t('labels.bussiness.rpa.bots.table.name')}
                        />
                      </Form.Item>
                    </Col>

                    {/* Version */}
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="version"
                        rules={[{ required: true }]}
                        label={t('labels.bussiness.rpa.bots.table.version')}
                      >
                        <Input
                          name="version"
                          onChange={handleChange}
                          value={state.form.version}
                          placeholder={t('labels.bussiness.rpa.bots.table.version')}
                        />
                      </Form.Item>
                    </Col>

                    {/* File Select */}
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="file"
                        label={t('labels.bussiness.rpa.bots.table.file')}
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          name="file"
                          onChange={e => handleChangeSelect(e, 'file')}
                          value={state.form.file}
                          placeholder={t('labels.bussiness.rpa.bots.table.select_file')}
                          className="sDash_fullwidth-select"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {optionsFiles}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* Description */}
                    <Col xs={24}>
                      <Form.Item
                        name="description"
                        rules={[{}]}
                        label={t('labels.bussiness.rpa.bots.table.description')}
                      >
                        {/* <Input name="description" onChange={handleChange} value={state.form.description} placeholder={t('labels.bussiness.rpa.bots.table.description')} /> */}
                        <TextArea
                          name="description"
                          onChange={handleChange}
                          value={state.form.description}
                          placeholder={t('labels.bussiness.rpa.bots.table.description')}
                          className="sDash_unresizable"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button style={{ height: '44px' }} htmlType="submit" type="primary">
                      {loading ? t('loading.1') : t('general.create')}
                    </Button>
                  </Form.Item>
                </Form>
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default BotCreate;
