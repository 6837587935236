import React, { useState, useEffect } from 'react';
import { Col, Row, Space, Button, Tooltip, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';

import { Cards } from '../../../../components/cards/frame/cards-frame';
import { BotScheduleType } from '../../../../utils/enums/bot-schedule.enum';
import { DatePickerWrapper } from '../../styled';
import { BotScheduleHelper } from '../../../../helpers/BotSchedule.helper';
import { notificationError } from '../../../../components/utilities/notification';

const BotScheduleInputByType = props => {
  const { type, scheduleList, handleScheduleButtonAction } = props;

  const { t } = useTranslation();
  const [disabledButton, setDisabledButton] = useState(scheduleList.length === 0 || scheduleList.length === 1);

  useEffect(() => {
    const removeButtonStatus = scheduleList.length === 0 || scheduleList.length === 1;
    setDisabledButton(removeButtonStatus);
  }, [scheduleList]);

  const onDateRangeConfirm = (moment, dateString, key) => {
    if (moment !== null && ![0, 15, 30, 45].includes(parseInt(moment.format('mm')))) {
      notificationError(t('botSchedule.selectHourAndMinutes'));

      return;
    }

    handleScheduleButtonAction('NEW_OR_UPDATE', key, moment, dateString);
  };

  const generateInput = schedule => {
    const { format, placeholder } = BotScheduleHelper.getInputFormat(type);
    const key = schedule?.key || 0;
    const value = schedule?.moment || '';

    return (
      <Row key={key} style={{ marginTop: key > 0 ? '1rem' : '' }}>
        <Col xs={24} md={12}>
          <div style={{ width: '100%' }}>
            <Space size={12} direction="vertical" style={{ width: '100%' }}>
              <DatePickerWrapper>
                <DatePicker
                  value={value}
                  format={format}
                  showNow={false}
                  showTime={{
                    format: 'HH:mm',
                    minuteStep: 15,
                  }}
                  allowClear
                  onChange={(value, dateString) => onDateRangeConfirm(value, dateString, key)}
                  placeholder={t(placeholder)}
                />
              </DatePickerWrapper>
            </Space>
          </div>
        </Col>

        <Col md={12} xs={24} style={{ paddingLeft: '1rem' }}>
          <Tooltip title={t('general.buttons.delete')}>
            <Button
              type="primary"
              shape="circle"
              onClick={() => handleScheduleButtonAction('REMOVE', key)}
              disabled={disabledButton}
              className="btn-icon"
            >
              <FeatherIcon icon="minus" size={14} />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    );
  };

  return (
    <Cards
      title={t('botSchedule.fields.schedule')}
      size="large"
      border
      headStyle={{
        width: 'calc(100vw - 80vw)',
      }}
      isbutton={
        <div key="6" className="page-header-actions">
          <Tooltip title={t('botSchedule.addMore')}>
            <Button
              type="primary"
              shape="round"
              onClick={() => handleScheduleButtonAction('ADD_EMPTY')}
              className="btn-icon pull-left"
            >
              <FeatherIcon icon="plus" size={16} />
            </Button>
          </Tooltip>
        </div>
      }
    >
      {scheduleList.length ? scheduleList.map(schedule => generateInput(schedule)) : generateInput()}
    </Cards>
  );
};

BotScheduleInputByType.propTypes = {
  type: PropTypes.oneOf(Object.values(BotScheduleType)),
  scheduleList: PropTypes.array,
  handleScheduleButtonAction: PropTypes.func,
};

export default BotScheduleInputByType;
