const actions = {
  API_KEY_GET_ALL_BEGIN: 'API_KEY_GET_ALL_BEGIN',
  API_KEY_GET_ALL_SUCCESS: 'API_KEY_GET_ALL_SUCCESS',
  API_KEY_GET_ALL_ERROR: 'API_KEY_GET_ALL_ERROR',

  API_KEY_CREATE_BEGIN: 'API_KEY_CREATE_BEGIN',
  API_KEY_CREATE_SUCCESS: 'API_KEY_CREATE_SUCCESS',
  API_KEY_CREATE_ERROR: 'API_KEY_CREATE_ERROR',

  API_KEY_UPDATE_BEGIN: 'API_KEY_UPDATE_BEGIN',
  API_KEY_UPDATE_SUCCESS: 'API_KEY_UPDATE_SUCCESS',
  API_KEY_UPDATE_ERROR: 'API_KEY_UPDATE_ERROR',

  API_KEY_DELETE_BEGIN: 'API_KEY_DELETE_BEGIN',
  API_KEY_DELETE_SUCCESS: 'API_KEY_DELETE_SUCCESS',
  API_KEY_DELETE_ERROR: 'API_KEY_DELETE_ERROR',

  apiKeyGetAllBegin: () => ({
    type: actions.API_KEY_GET_ALL_BEGIN,
  }),

  apiKeyGetAllSuccess: apiKeys => ({
    type: actions.API_KEY_GET_ALL_SUCCESS,
    apiKeys,
  }),

  apiKeyGetAllError: error => ({
    type: actions.API_KEY_GET_ALL_ERROR,
    error,
  }),

  apiKeyCreateBegin: () => ({
    type: actions.API_KEY_CREATE_BEGIN,
  }),

  apiKeyCreateSuccess: apiKey => ({
    type: actions.API_KEY_CREATE_SUCCESS,
    apiKey,
  }),

  apiKeyCreateError: error => ({
    type: actions.API_KEY_CREATE_ERROR,
    error,
  }),

  apiKeyUpdateBegin: () => ({
    type: actions.API_KEY_UPDATE_BEGIN,
  }),

  apiKeyUpdateSuccess: apiKey => ({
    type: actions.API_KEY_UPDATE_SUCCESS,
    apiKey,
  }),

  apiKeyUpdateError: error => ({
    type: actions.API_KEY_UPDATE_ERROR,
    error,
  }),

  apiKeyDeleteBegin: () => ({
    type: actions.API_KEY_DELETE_BEGIN,
  }),

  apiKeyDeleteSuccess: apiKey => ({
    type: actions.API_KEY_DELETE_SUCCESS,
    apiKey,
  }),

  apiKeyDeleteError: error => ({
    type: actions.API_KEY_DELETE_ERROR,
    error,
  }),
};

export default actions;
