import React, { useEffect, useState } from 'react';
import { Table, Tag, DatePicker, Space } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { QUERY_BOT_EXECUTION_FIND_ALL } from '../../../../../queries';
import { TableWrapper } from '../../../styled';
import actions from '../../../../../redux/botExecution/actions';
import { notificationError } from '../../../../../components/utilities/notification';
import { timestampToDate } from '../../../../../components/utilities/utilities';

const { RangePicker } = DatePicker;

const { botExecutionFindAllBegin, botExecutionFindAllSuccess, botExecutionFindAllError } = actions;

const CvExecutionReportTable = () => {
  const { t } = useTranslation();
  const { botId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const botExecutions = useSelector(state => state.botExecution.dataFiltered);
  const [botExecutionsTableData, setBotExecutionsTableData] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [mountedBotExecutions, setMountedBotExecutions] = useState(false);
  const [state, setState] = useState({
    botId: '',
    start: moment()
      .startOf('day')
      .toISOString(),
    end: moment()
      .endOf('day')
      .toISOString(),
  });
  const [botExecutionFindAll, { loading, data }] = useLazyQuery(QUERY_BOT_EXECUTION_FIND_ALL, {
    fetchPolicy: 'network-only',
    onCompleted: x => {
      if (x.botExecutionFindAll.success) dispatch(botExecutionFindAllSuccess(x.botExecutionFindAll.data));
      else dispatch(botExecutionFindAllError(x.botExecutionFindAll.code));
    },
    onError: err => dispatch(botExecutionFindAllError(err)),
  });

  useEffect(() => {
    if (botId && !mountedBotExecutions) {
      dispatch(botExecutionFindAllBegin());
      botExecutionFindAll({
        variables: {
          input: {
            id: botId,
            start: state.start,
            end: state.end,
            showAll: false,
          },
        },
      });
      setMountedBotExecutions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botExecutionFindAll, mountedBotExecutions, state.start, state.end, dispatch]);

  useEffect(() => {
    if (botExecutions?.length > 0) {
      setBotExecutionsTableData(
        botExecutions.map(botExecution => {
          return {
            key: botExecution._id,
            ...botExecution,
            // serial: botExecution.licenseSerial,
            // computerName: botExecution.computerInfo.name,
            // status: botExecution.status,
            createdLocalAt: botExecution.createdLocalAt && timestampToDate(botExecution.createdLocalAt),
            createdAt: timestampToDate(botExecution.createdAt),
          };
        }),
      );
    } else {
      setBotExecutionsTableData([]);
    }
  }, [botExecutions]);

  useEffect(() => {
    if (!loading && data && !mounted) {
      dispatch(botExecutionFindAllBegin());
      if (data.botExecutionFindAll.success) {
        dispatch(botExecutionFindAllSuccess(data.botExecutionFindAll.data));
      } else {
        dispatch(botExecutionFindAllError(data.botExecutionFindAll.code));
        history.push(`/admin/bot/`);
        notificationError(t(`codeResponse.${data.botExecutionFindAll.code}`));
      }
      setMounted(true);
    }
  }, [loading, data, dispatch, history, mounted, t]);

  const onChangeRange = date => {
    if (date) {
      setState({
        ...state,
        start: date[0].startOf('day').toISOString(),
        end: date[1].endOf('day').toISOString(),
      });
      setMountedBotExecutions(false);
    }
  };
  const columns = [
    {
      title: t('labels.bussiness.rpa.bots.curriculum.table.execution.licenseSerial'),
      dataIndex: 'licenseSerial',
      key: 'licenseSerial',
    },
    {
      title: t('labels.bussiness.rpa.bots.curriculum.table.execution.computerInfo.title'),
      children: [
        {
          title: t('labels.bussiness.rpa.bots.curriculum.table.execution.computerInfo.name'),
          dataIndex: ['computerInfo', 'name'],
          key: 'computerInfo.name',
        },
        {
          title: t('labels.bussiness.rpa.bots.curriculum.table.execution.computerInfo.domain'),
          dataIndex: ['computerInfo', 'domain'],
          key: 'computerInfo.domain',
        },
        {
          title: t('labels.bussiness.rpa.bots.curriculum.table.execution.computerInfo.os'),
          dataIndex: ['computerInfo', 'os'],
          key: 'computerInfo.os',
        },
        {
          title: t('labels.bussiness.rpa.bots.curriculum.table.execution.computerInfo.ram'),
          dataIndex: ['computerInfo', 'ram'],
          key: 'computerInfo.ram',
        },
        {
          title: t('labels.bussiness.rpa.bots.curriculum.table.execution.computerInfo.disk'),
          dataIndex: ['computerInfo', 'disk'],
          key: 'computerInfo.disk',
        },
        {
          title: t('labels.bussiness.rpa.bots.curriculum.table.execution.computerInfo.architecture'),
          dataIndex: ['computerInfo', 'arquitecture'],
          key: 'computerInfo.arquitecture',
        },
      ],
    },
    {
      title: t('labels.bussiness.rpa.bots.curriculum.table.execution.status.title'),
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <div>
          {status === 'Start' && (
            <Tag color="success" style={{ color: '#20C997' }}>
              {t('labels.bussiness.rpa.bots.curriculum.table.execution.status.start')}
            </Tag>
          )}
          {status === 'Pause' && (
            <Tag color="default" style={{ color: '#323235' }}>
              {t('labels.bussiness.rpa.bots.curriculum.table.execution.status.pause')}
            </Tag>
          )}
          {status === 'Resume' && (
            <Tag color="processing" style={{ color: '#2C99FF' }}>
              {t('labels.bussiness.rpa.bots.curriculum.table.execution.status.resume')}
            </Tag>
          )}
          {status === 'End' && (
            <Tag color="default" style={{ color: '#323235' }}>
              {t('labels.bussiness.rpa.bots.curriculum.table.execution.status.end')}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: t('labels.bussiness.rpa.bots.curriculum.table.execution.createdLocalAt'),
      dataIndex: 'createdLocalAt',
      key: 'createdLocalAt',
    },
    {
      title: t('labels.bussiness.rpa.bots.curriculum.table.execution.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ];
  return (
    <>
      <Space direction="vertical" size={12} style={{ marginBottom: 10 }}>
        <RangePicker
          defaultValue={[moment(), moment()]}
          ranges={{
            [t('general.today')]: [moment(), moment()],
            [t('general.thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
          }}
          onChange={onChangeRange}
        />
      </Space>
      <TableWrapper className="table-responsive">
        <Table dataSource={botExecutionsTableData} columns={columns} pagination bordered />
      </TableWrapper>
    </>
  );
};

export default CvExecutionReportTable;
