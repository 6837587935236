import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Breadcrumb} from 'antd';
import { Modal } from '../../../components/modals/antd-modals';
import ApiKeyList from './ApiKeyList';
import ApiKeyForm from './ApiKeyForm';
import { Main } from '../styled';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import {useSelector} from 'react-redux';

const MicroservicesManagement = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const handleModalStatus = () => setShowModal(!showModal);
  return (
    <>
      <PageHeader ghost title={t('microservices.management')} />
      <Main darkMode={darkMode}>
      <BreadcrumbWrapperStyle darkMode={darkMode}>
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <NavLink to={`/admin/`}>
                          <HomeOutlined />
                        </NavLink>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <NavLink to="#">{t('microservices.management')}</NavLink>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24}>
            <Cards
              isbutton={
                <div key="6" className="page-header-actions">
                  <Button className="btn-signin" size="small" key="1" type="primary" onClick={() => setShowModal(true)}>
                    <FeatherIcon icon="plus" size={14} />
                    {t('microservices.create')}
                  </Button>
                </div>
              }
            >
              <ApiKeyList />

              <Modal 
              type={'primary'}
              darkMode={darkMode}
                destroyOnClose
                title={t('microservices.create')}
                visible={showModal}
                style={{ top: 30 }}
                width="60%"
                footer={null}
                onCancel={() => handleModalStatus()}
              >
                <ApiKeyForm setModal={setShowModal} />
              </Modal>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default MicroservicesManagement;
