import update from 'immutability-helper';
import actions from './actions';

const {
  NODE_SELECTED_BEGIN,
  NODE_SELECTED_LOADED,
  NODE_LIST_BEGIN,
  NODE_LIST_LOADED,
  NODE_PROPERTIES_REMOVE,
  NODE_PROPERTIES_UPDATE,
  NODE_LIST_PROPERTIES_LOADED,
} = actions;

const initialState = {
  nodeListProperties: [],
  nodeListData: [],
  nodeListLoading: false,
  nodeSelectedData: null,
  nodeSelectedLoading: false,
};

const workFlowReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case NODE_SELECTED_BEGIN: {
      return {
        ...state,
        nodeSelectedLoading: true,
      };
    }
    case NODE_SELECTED_LOADED: {
      return {
        ...state,
        nodeSelectedLoading: false,
        nodeSelectedData: state.nodeListData.find(x => x.id === data),
      };
    }
    case NODE_LIST_BEGIN: {
      return {
        ...state,
        nodeListLoading: true,
      };
    }
    case NODE_LIST_LOADED: {
      return {
        ...state,
        nodeListLoading: false,
        nodeListData: data,
      };
    }
    case NODE_PROPERTIES_UPDATE: {
      const index = state.nodeListProperties.findIndex(x => x.id === data.id);
      if (index >= 0)
        return update(state, {
          nodeListProperties: {
            [index]: {
              $set: data,
            },
          },
        });
      return {
        ...state,
        nodeListProperties: [...state.nodeListProperties, data],
      };
    }
    case NODE_PROPERTIES_REMOVE: {
      return {
        ...state,
        nodeListProperties: state.nodeListProperties.filter(x => x.id !== data.id),
      };
    }
    case NODE_LIST_PROPERTIES_LOADED: {
      return {
        ...state,
        nodeListProperties: data || [],
      };
    }
    default:
      return state;
  }
};

export default workFlowReducer;
