import React, { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  Col,
  Row,
  Spin,
  Breadcrumb
} from 'antd';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { useOrchestratorDashboard } from '../../../hooks/orchestrator';
import {
  OrchestratorDashboardChart,
  OrchestratorDashboardTable,
  OrchestratorDashboardFilters,
} from '../../../components/orchestrator-dashboard';

import { Main } from '../styled';

const OrchestratorDashboard = () => {
  const { t } = useTranslation();
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const dashboardData = useSelector(state => state.orchestrator.dashboard);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [dashboardFilters, setDashboardFilters] = useState({});

  const { recordsByState, orchestratorName, orchestratorModel, orchestratorStates } = useMemo(() => {
    let orchestratorName = '';
    let orchestratorModel = [];
    const orchestratorStates = [];
    const recordsByState = dashboardData?.[0]?.recordsByState ?? [];

    if (typeof dashboardData !== 'undefined') {
      // It'll be the same name for each record because it belongs to the parent orchestrator
      orchestratorName = dashboardData?.[0]?.orchestratorName ?? '';

      // It'll be the same model for each record because it belongs to the parent orchestrator
      orchestratorModel = Object.keys(dashboardData?.[0]?.model ?? {});

      // Setting up the states related to the record
      if (dashboardData?.[0]?.total > 0) {
        Array.from(dashboardData).forEach(record => {
          const { stateId, stateName } = record;

          const stateAlreadyExists = orchestratorStates.find(state => state.id === stateId);

          if (!stateAlreadyExists) {
            orchestratorStates.push({
              id: stateId,
              label: stateName,
            });
          }
        });
      }
    }

    return {
      recordsByState,
      orchestratorName,
      orchestratorModel,
      orchestratorStates,
    };
  }, [dashboardData]);

  // Fetch the orchestrator data
  const { mounted, tableRedraw } = useOrchestratorDashboard({
    currentTenant,
    setFormSubmitted,
    dashboardFilters,
  });

  const handleDashboardFilters = useCallback(filters => setDashboardFilters(filters), []);

  if (mounted) {
    return (
      <>
        <PageHeader
          ghost
          title={`${t('orchestrator.dashboard.title')} | ${orchestratorName}`}
        />
        <Main darkMode={darkMode}>
          <BreadcrumbWrapperStyle darkMode={darkMode}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to={`/admin/`}>
                  <HomeOutlined />
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to={`/admin/orchestrator`}>
                  <span> {t('orchestrator.management')}</span></NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="#">{`${t('orchestrator.dashboard.title')} | ${orchestratorName}`}</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </BreadcrumbWrapperStyle>
          <Row gutter={15}>
            <Col xs={24}>
              <Cards>
                <Row>
                  <OrchestratorDashboardFilters
                    mounted={mounted}
                    formSubmitted={formSubmitted}
                    orchestratorModel={orchestratorModel}
                    orchestratorStates={orchestratorStates}
                    setDashboardFilters={handleDashboardFilters}
                  />

                  <OrchestratorDashboardChart
                    mounted={mounted && formSubmitted === false}
                    tableRedraw={tableRedraw}
                    recordsByState={recordsByState}
                    orchestratorStates={orchestratorStates}
                  />
                </Row>

                <Row>
                  <OrchestratorDashboardTable
                    mounted={mounted && formSubmitted === false}
                    tableRedraw={tableRedraw}
                    orchestratorModel={orchestratorModel}
                  />
                </Row>
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default OrchestratorDashboard;
