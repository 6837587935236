import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import NotFound from '../../utils/404';

import UserManagement from '../../pages/admin/user/index';
import UserCreate from '../../pages/admin/user/UserCreate';
import UserInvitation from '../../pages/admin/user/UserInvitation';
import UserEdit from '../../pages/admin/user/UserEdit';
import UserGuestEdit from '../../pages/admin/user/UserGuestEdit';

const UsersRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/invite`} component={UserInvitation} />
      <Route path={`${path}/create`} component={UserCreate} />
      <Route path={`${path}/edit/:userId`} component={UserEdit} />
      <Route path={`${path}/guest/edit/:userId`} component={UserGuestEdit} />
      <Route exact path={`${path}`} component={UserManagement} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default UsersRoute;
