const UserPermissionsEnum = {
  BotCreate: 'BotCreate',
  BotRemove: 'BotRemove',
  BotUpdate: 'BotUpdate',
  BotFindAll: 'BotFindAll',
  BotFindOne: 'BotFindOne',
  BotCVCreate: 'BotCVCreate',
  BotCVUpdate: 'BotCVUpdate',
  BotCVFindOne: 'BotCVFindOne',
  FileCreate: 'FileCreate',
  FileRemove: 'FileRemove',
  FileFindOne: 'FileFindOne',
  FileFindAll: 'FileFindAll',
  FileDownload: 'FileDownload',
  LicenseCreate: 'LicenseCreate',
  LicenseUpdate: 'LicenseUpdate',
  LicenseComputerInfoClean: 'LicenseComputerInfoClean',
  LicenseRemove: 'LicenseRemove',
  LicenseFindAll: 'LicenseFindAll',
  LicenseFindOne: 'LicenseFindOne',
  MailNotificationCreate: 'MailNotificationCreate',
  MailNotificationUpdate: 'MailNotificationUpdate',
  MailNotificationRemove: 'MailNotificationRemove',
  MailNotificationFindAll: 'MailNotificationFindAll',
  MailNotificationFindOne: 'MailNotificationFindOne',
  MailNotificationMailSend: 'MailNotificationMailSend',
  WhatsappNotificationCreate: 'WhatsappNotificationCreate',
  WhatsappNotificationUpdate: 'WhatsappNotificationUpdate',
  WhatsappNotificationRemove: 'WhatsappNotificationRemove',
  WhatsappNotificationFindAll: 'WhatsappNotificationFindAll',
  WhatsappNotificationFindOne: 'WhatsappNotificationFindOne',
  TenantUpdate: 'TenantUpdate',
  TenantFindOne: 'TenantFindOne',
  BotExecutionFindAll: 'BotExecutionFindAll',
  BotExecutionFindOne: 'BotExecutionFindOne',
  CustomReportCreate: 'CustomReportCreate',
  CustomReportUpdate: 'CustomReportUpdate',
  CustomReportRemove: 'CustomReportRemove',
  CustomReportFindAll: 'CustomReportFindAll',
  CustomReportFindOne: 'CustomReportFindOne',
  CustomReportInfoFindOne: 'CustomReportInfoFindOne',
  CustomReportInfoFindAll: 'CustomReportInfoFindAll',
  RealtimeSessionFindAll: 'RealtimeSessionFindAll',
  RealtimeSessionFindOne: 'RealtimeSessionFindOne',
  RealtimeProgressFindAll: 'RealtimeProgressFindAll',
  RealtimeProgressFindOne: 'RealtimeProgressFindOne',
  UserCreate: 'UserCreate',
  UserUpdate: 'UserUpdate',
  UserRemove: 'UserRemove',
  UserFindAll: 'UserFindAll',
  UserFindOne: 'UserFindOne',
  UserRelatedTenantAdd: 'UserRelatedTenantAdd',
  UserRelatedTenantUpdate: 'UserRelatedTenantUpdate',
  UserRelatedTenantRemove: 'UserRelatedTenantRemove',
  BotLicenseAssign: 'BotLicenseAssign',
  BotLicenseDelete: 'BotLicenseDelete',
  BotLicenseGetAll: 'BotLicenseGetAll',
  BotVaultManager: 'BotVaultManager',
  BotVaultReader: 'BotVaultReader',
  WorkFlowCreate: 'WorkFlowCreate',
  WorkFlowUpdate: 'WorkFlowUpdate',
  WorkFlowRemove: 'WorkFlowRemove',
  WorkFlowFindAll: 'WorkFlowFindAll',
  WorkFlowFindOne: 'WorkFlowFindOne',
  BotScheduleCreate: 'BotScheduleCreate',
  BotScheduleUpdate: 'BotScheduleUpdate',
  BotScheduleRemove: 'BotScheduleRemove',
  BotScheduleFindAll: 'BotScheduleFindAll',
  BotScheduleFindOne: 'BotScheduleFindOne',
  BotOrchestratorCreate: 'BotOrchestratorCreate',
  BotOrchestratorUpdate: 'BotOrchestratorUpdate',
  BotOrchestratorRemove: 'BotOrchestratorRemove',
  BotOrchestratorFindAll: 'BotOrchestratorFindAll',
  BotOrchestratorFindOne: 'BotOrchestratorFindOne',
  BotOrchestratorDownload: 'BotOrchestratorDownload',
  TenantVaultCreate: 'TenantVaultCreate',
  TenantVaultUpdate: 'TenantVaultUpdate',
  TenantVaultRemove: 'TenantVaultRemove',
  TenantVaultFind: 'TenantVaultFind',
};

export const UserPermissions = Object.freeze(UserPermissionsEnum);

export const UserBasicPermissions = [
  UserPermissions.BotFindAll,
  UserPermissions.BotFindOne,
  UserPermissions.BotCVFindOne,
  UserPermissions.FileFindOne,
  UserPermissions.FileFindAll,
  UserPermissions.LicenseFindAll,
  UserPermissions.LicenseFindOne,
  UserPermissions.MailNotificationFindAll,
  UserPermissions.MailNotificationFindOne,
  UserPermissions.WhatsappNotificationFindAll,
  UserPermissions.WhatsappNotificationFindOne,
  UserPermissions.BotExecutionFindAll,
  UserPermissions.BotExecutionFindOne,
  UserPermissions.BotLicenseGetAll,
  UserPermissions.CustomReportFindAll,
  UserPermissions.CustomReportFindOne,
  UserPermissions.CustomReportInfoFindOne,
  UserPermissions.CustomReportInfoFindAll,
  UserPermissions.RealtimeSessionFindAll,
  UserPermissions.RealtimeSessionFindOne,
  UserPermissions.RealtimeProgressFindAll,
  UserPermissions.RealtimeProgressFindOne,
  UserPermissions.WorkFlowFindAll,
  UserPermissions.WorkFlowFindOne,
  UserPermissions.BotVaultReader,
  UserPermissions.BotScheduleFindAll,
  UserPermissions.BotScheduleFindOne,
  UserPermissions.BotOrchestratorFindAll,
  UserPermissions.BotOrchestratorFindOne,
];

export const UserProPermissions = [
  UserPermissions.BotCreate,
  UserPermissions.BotRemove,
  UserPermissions.BotUpdate,
  UserPermissions.BotFindAll,
  UserPermissions.BotFindOne,
  UserPermissions.BotCVCreate,
  UserPermissions.BotCVUpdate,
  UserPermissions.BotCVFindOne,
  UserPermissions.FileCreate,
  UserPermissions.FileRemove,
  UserPermissions.FileFindOne,
  UserPermissions.FileFindAll,
  UserPermissions.FileDownload,
  UserPermissions.LicenseCreate,
  UserPermissions.LicenseUpdate,
  UserPermissions.LicenseComputerInfoClean,
  UserPermissions.LicenseRemove,
  UserPermissions.LicenseFindAll,
  UserPermissions.LicenseFindOne,
  UserPermissions.MailNotificationCreate,
  UserPermissions.MailNotificationUpdate,
  UserPermissions.MailNotificationRemove,
  UserPermissions.MailNotificationFindAll,
  UserPermissions.MailNotificationFindOne,
  UserPermissions.MailNotificationMailSend,
  UserPermissions.WhatsappNotificationCreate,
  UserPermissions.WhatsappNotificationUpdate,
  UserPermissions.WhatsappNotificationRemove,
  UserPermissions.WhatsappNotificationFindAll,
  UserPermissions.WhatsappNotificationFindOne,
  UserPermissions.BotExecutionFindAll,
  UserPermissions.BotExecutionFindOne,
  UserPermissions.CustomReportCreate,
  UserPermissions.CustomReportUpdate,
  UserPermissions.CustomReportRemove,
  UserPermissions.CustomReportFindAll,
  UserPermissions.CustomReportFindOne,
  UserPermissions.CustomReportInfoFindOne,
  UserPermissions.CustomReportInfoFindAll,
  UserPermissions.RealtimeSessionFindAll,
  UserPermissions.RealtimeSessionFindOne,
  UserPermissions.RealtimeProgressFindAll,
  UserPermissions.RealtimeProgressFindOne,
  UserPermissions.BotLicenseAssign,
  UserPermissions.BotLicenseDelete,
  UserPermissions.BotLicenseGetAll,
  UserPermissions.BotVaultManager,
  UserPermissions.BotVaultReader,
  UserPermissions.WorkFlowCreate,
  UserPermissions.WorkFlowUpdate,
  UserPermissions.WorkFlowRemove,
  UserPermissions.WorkFlowFindAll,
  UserPermissions.WorkFlowFindOne,
  UserPermissions.BotScheduleCreate,
  UserPermissions.BotScheduleUpdate,
  UserPermissions.BotScheduleRemove,
  UserPermissions.BotScheduleFindAll,
  UserPermissions.BotScheduleFindOne,
  UserPermissions.BotOrchestratorCreate,
  UserPermissions.BotOrchestratorUpdate,
  UserPermissions.BotOrchestratorRemove,
  UserPermissions.BotOrchestratorFindAll,
  UserPermissions.BotOrchestratorFindOne,
  UserPermissions.BotOrchestratorDownload,
];
