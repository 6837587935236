import update from 'immutability-helper';
import actions from './actions';

const {
  LICENSE_FIND_ALL_BEGIN,
  LICENSE_FIND_ALL_SUCCESS,
  LICENSE_FIND_ALL_ERROR,
  LICENSE_FIND_ONE_BEGIN,
  LICENSE_FIND_ONE_SUCCESS,
  LICENSE_FIND_ONE_ERROR,
  LICENSE_CREATE_BEGIN,
  LICENSE_CREATE_SUCCESS,
  LICENSE_CREATE_ERROR,
  LICENSE_UPDATE_BEGIN,
  LICENSE_UPDATE_SUCCESS,
  LICENSE_UPDATE_ERROR,
  LICENSE_REMOVE_BEGIN,
  LICENSE_REMOVE_SUCCESS,
  LICENSE_REMOVE_ERROR,
} = actions;

const initialState = {
  current: {},
  data: [],
  loading: false,
  error: false,
};

const licenseReducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case LICENSE_FIND_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LICENSE_FIND_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data ?? [],
      };
    case LICENSE_FIND_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case LICENSE_FIND_ONE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LICENSE_FIND_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        current: data,
      };
    case LICENSE_FIND_ONE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case LICENSE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LICENSE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, data],
      };
    case LICENSE_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case LICENSE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LICENSE_UPDATE_SUCCESS:
      const index = state.data.findIndex(x => x.serial === data.serial);
      return update(state, {
        data: {
          [index]: { $set: data },
        },
        loading: { $set: false },
      });
    case LICENSE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case LICENSE_REMOVE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LICENSE_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(x => x.serial !== data),
      };
    case LICENSE_REMOVE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export default licenseReducer;
