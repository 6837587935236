import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import BotExecutionReport from '../../pages/admin/report/BotExecutionReport';
import CustomRepReport from '../../pages/admin/report/CustomRepReport';
import TenantReport from '../../pages/admin/report/TenantReport';
import BotGoalsReport from '../../pages/admin/report/BotGoalsReport';
import BotGoalsReportBotId from '../../pages/admin/report/BotGoalsReportBotId';

import NotFound from '../../utils/404';

const ReportsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/tenant`} component={TenantReport} />
      <Route exact path={`${path}/bot-execution`} component={BotExecutionReport} />
      <Route exact path={`${path}/custom-report`} component={CustomRepReport} />
      <Route exact path={`${path}/bot-goals-report`} component={BotGoalsReport} />
      <Route exact path={`${path}/bot-goals-report/:botId`} component={BotGoalsReportBotId} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default ReportsRoute;
