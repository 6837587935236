import actions from './actions';

const {
  BOT_LICENSE_GET_ALL_BEGIN,
  BOT_LICENSE_GET_ALL_SUCCESS,
  BOT_LICENSE_GET_ALL_ERROR,
  BOT_LICENSE_ASSIGN_BEGIN,
  BOT_LICENSE_ASSIGN_SUCCESS,
  BOT_LICENSE_ASSIGN_ERROR,
  BOT_LICENSE_DELETE_BEGIN,
  BOT_LICENSE_DELETE_SUCCESS,
  BOT_LICENSE_DELETE_ERROR,
} = actions;

const initialState = {
  data: [],
  loading: false,
  error: false,
};

const botLicenseReducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case BOT_LICENSE_GET_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BOT_LICENSE_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data ?? [],
      };
    case BOT_LICENSE_GET_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case BOT_LICENSE_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BOT_LICENSE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(x => x._id !== data),
      };
    case BOT_LICENSE_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case BOT_LICENSE_ASSIGN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BOT_LICENSE_ASSIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, data],
      };
    case BOT_LICENSE_ASSIGN_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export default botLicenseReducer;
