import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import $ from 'jquery';
import { useMutation } from '@apollo/client';
import { AuthWrapper } from './style';
import actions from '../../redux/forgotPassword/actions';
import { QUERY_FORGOT_PASSWORD } from '../../queries/auth.queries';
import Heading from '../../components/heading/heading';
import Alert from '../../components/alerts/alerts';
import { Source } from '../../utils/enums/types.enum';

const { forgotPasswordBegin, forgotPasswordSuccess, forgotPasswordErr } = actions;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reRef = useRef();
  const [form] = useForm();
  const success = useSelector(state => state.forgotPassword.data);
  const error = useSelector(state => state.forgotPassword.error);
  const [forgotPassword] = useMutation(QUERY_FORGOT_PASSWORD);
  const [state, setState] = useState({
    username: '',
    token: '',
  });

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1', { value: '${label}' }),
    types: {
      email: t('type.email', { value: '${label}' })
    }
  };
  /* eslint-enable */

  const handleSubmit = async () => {
    if (state.token === '' && process.env.REACT_APP_REGISTRATION_CAPTCHA_STATUS === 'true') {
      dispatch(forgotPasswordErr(t('captcha.resolveCaptcha.1')));
      return;
    }

    dispatch(forgotPasswordBegin());
    dispatch(forgotPasswordSuccess('password.sendMail'));

    forgotPassword({
      variables: {
        forgotPasswordInput: {
          origin: Source.MANAGER,
          receiver: state.username,
        },
      },
    });

    if (reRef.current) reRef.current.reset();

    setState({
      username: '',
      token: '',
    });

    form.setFieldsValue({ username: '' });
  };

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCaptcha = response => {
    setState({
      ...state,
      token: response,
    });
  };

  setInterval(() => {
    if (
      ($('.alert-forgotPass').length && $(".alert-forgotPass [role='alert']").length <= 0) ||
      ($('.alert-forgotPassErr').length && $(".alert-forgotPassErr [role='alert']").length <= 0)
    ) {
      dispatch(forgotPasswordSuccess(null));
      dispatch(forgotPasswordErr(null));
    }
  }, [500]);

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form
          form={form}
          name="forgotPass"
          onFinish={handleSubmit}
          layout="vertical"
          validateMessages={validateMessages}
        >
          <Heading as="h3">{t('password.forgotPassword')}</Heading>
          <p className="forgot-text">{t('password.forgotPasswordInstructions')}</p>
          {success ? (
            <Form.Item className="alert-forgotPass">
              <Alert closable message="" description={t(success)} type="success" />
            </Form.Item>
          ) : (
            ''
          )}
          {error ? (
            <Form.Item className="alert-forgotPassErr">
              <Alert closable message="" description={t(error)} type="error" />
            </Form.Item>
          ) : (
            ''
          )}
          {process.env.REACT_APP_REGISTRATION_CAPTCHA_STATUS === 'true' ? (
            <Form.Item>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_NOCAPTCHA_SITEKEY}
                size="normal"
                ref={reRef}
                onChange={onChangeCaptcha}
                hl={i18next.language}
              />
            </Form.Item>
          ) : (
            ''
          )}
          <Form.Item label={t('properties.username.1')} name="username" rules={[{ required: true, type: 'email' }]}>
            <Input
              name="username"
              onChange={handleChange}
              value={state.username}
              placeholder={t('properties.username.1')}
            />
          </Form.Item>
          <Form.Item>
            <Button className="btn-reset" htmlType="submit" type="primary" size="large">
              {t('password.sendLinkPassword')}
            </Button>
          </Form.Item>
          <p className="return-text">
            {t('return')} <NavLink to="/">{t('signIn.1')}</NavLink>
          </p>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
