const actions = {
  LICENSE_FIND_ALL_BEGIN: 'LICENSE_FIND_ALL_BEGIN',
  LICENSE_FIND_ALL_SUCCESS: 'LICENSE_FIND_ALL_SUCCESS',
  LICENSE_FIND_ALL_ERROR: 'LICENSE_FIND_ALL_ERROR',

  LICENSE_FIND_ONE_BEGIN: 'LICENSE_FIND_ONE_BEGIN',
  LICENSE_FIND_ONE_SUCCESS: 'LICENSE_FIND_ONE_SUCCESS',
  LICENSE_FIND_ONE_ERROR: 'LICENSE_FIND_ONE_ERROR',

  LICENSE_CREATE_BEGIN: 'LICENSE_CREATE_BEGIN',
  LICENSE_CREATE_SUCCESS: 'LICENSE_CREATE_SUCCESS',
  LICENSE_CREATE_ERROR: 'LICENSE_CREATE_ERROR',

  LICENSE_UPDATE_BEGIN: 'LICENSE_UPDATE_BEGIN',
  LICENSE_UPDATE_SUCCESS: 'LICENSE_UPDATE_SUCCESS',
  LICENSE_UPDATE_ERROR: 'LICENSE_UPDATE_ERROR',

  LICENSE_REMOVE_BEGIN: 'LICENSE_REMOVE_BEGIN',
  LICENSE_REMOVE_SUCCESS: 'LICENSE_REMOVE_SUCCESS',
  LICENSE_REMOVE_ERROR: 'LICENSE_REMOVE_ERROR',

  licenseFindAllBegin: () => {
    return {
      type: actions.LICENSE_FIND_ALL_BEGIN,
    };
  },

  licenseFindAllSuccess: data => {
    return {
      type: actions.LICENSE_FIND_ALL_SUCCESS,
      data,
    };
  },

  licenseFindAllError: error => {
    return {
      type: actions.LICENSE_FIND_ALL_ERROR,
      error,
    };
  },

  licenseFindOneBegin: () => {
    return {
      type: actions.LICENSE_FIND_ONE_BEGIN,
    };
  },

  licenseFindOneSuccess: data => {
    return {
      type: actions.LICENSE_FIND_ONE_SUCCESS,
      data,
    };
  },

  licenseFindOneError: error => {
    return {
      type: actions.LICENSE_FIND_ONE_ERROR,
      error,
    };
  },

  licenseCreateBegin: () => {
    return {
      type: actions.LICENSE_CREATE_BEGIN,
    };
  },

  licenseCreateSuccess: data => {
    return {
      type: actions.LICENSE_CREATE_SUCCESS,
      data,
    };
  },

  licenseCreateError: error => {
    return {
      type: actions.LICENSE_CREATE_ERROR,
      error,
    };
  },

  licenseUpdateBegin: () => {
    return {
      type: actions.LICENSE_UPDATE_BEGIN,
    };
  },

  licenseUpdateSuccess: data => {
    return {
      type: actions.LICENSE_UPDATE_SUCCESS,
      data,
    };
  },

  licenseUpdateError: error => {
    return {
      type: actions.LICENSE_UPDATE_ERROR,
      error,
    };
  },

  licenseRemoveBegin: () => {
    return {
      type: actions.LICENSE_REMOVE_BEGIN,
    };
  },

  licenseRemoveSuccess: data => {
    return {
      type: actions.LICENSE_REMOVE_SUCCESS,
      data,
    };
  },

  licenseRemoveError: error => {
    return {
      type: actions.LICENSE_REMOVE_ERROR,
      error,
    };
  },
};

export default actions;
