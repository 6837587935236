import { gql } from '@apollo/client';

export const QUERY_BOT_LICENSE_GET_ALL = gql`
  query botLicenseGetAll($botId: String!) {
    botLicenseGetAll(botId: $botId) {
      success
      code
      data {
        _id
        license {
          _id
          serial
          enabled
          computerInfo {
            name
            domain
          }
        }
        bot {
          _id
          name
        }
      }
    }
  }
`;

export const QUERY_BOT_LICENSE_FIND_BY_TENANT = gql`
  query botLicenseFindByTenant {
    botLicenseFindByTenant {
      success
      code
      data {
        _id
        license {
          _id
          serial
          enabled
          computerInfo {
            name
            domain
          }
        }
        bot {
          _id
          name
        }
      }
    }
  }
`;

export const QUERY_BOT_LICENSE_ASSIGN = gql`
  mutation botLicenseAssign($input: BotLicenseAssignInput!) {
    botLicenseAssign(botLicenseAssignInput: $input) {
      success
      code
      data {
        _id
        license {
          _id
          serial
          enabled
          computerInfo {
            name
            domain
          }
        }
      }
    }
  }
`;

export const QUERY_BOT_LICENSE_DELETE = gql`
  mutation botLicenseDelete($id: String!) {
    botLicenseDelete(id: $id) {
      success
      code
      data {
        _id
        license {
          _id
          serial
          enabled
          computerInfo {
            name
            domain
          }
        }
      }
    }
  }
`;
