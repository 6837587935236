/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';

import { UserPermissions } from '../../utils/enums/permissions.enum';
import { notificationError } from '../../components/utilities/notification';

import orchestratorActions from '../../redux/orchestrator/actions';
import { QUERY_ORCHESTRATOR_DASHBOARD } from '../../queries';

const {
  orchestratorDashboardBegin,
  orchestratorDashboardError,
  orchestratorDashboardSuccess,
  orchestratorDashboardReset,
} = orchestratorActions;

const useOrchestratorDashboard = ({ currentTenant, setFormSubmitted, dashboardFilters }) => {
  const { t } = useTranslation();
  const { orchestratorId } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const [mounted, setMounted] = useState(false);
  const [tableRedraw, setTableRedraw] = useState(dashboardFilters.tableRedraw ?? false);
  const [skipFetchCall, setSkipFetchCall] = useState(typeof orchestratorId === 'undefined');

  const userDoesNotHavePermission = useMemo(
    () => !currentTenant?.userPermissions?.includes(UserPermissions.BotOrchestratorFindAll),
    [currentTenant],
  );

  const { data, error, loading } = useQuery(QUERY_ORCHESTRATOR_DASHBOARD, {
    skip: skipFetchCall,
    variables: {
      orchestratorExternalDashboardInput: {
        filters: JSON.stringify(dashboardFilters),
        orchestratorId,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (dashboardFilters.tableRedraw) {
      setTableRedraw(true);
    }
  }, [dashboardFilters]);

  // Fetch the orchestrator data
  const fetchOrchestratorData = useCallback(() => {
    const redirectPath = '/admin/orchestrator';

    if (userDoesNotHavePermission) {
      history.push(redirectPath);
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && error) {
      dispatch(orchestratorDashboardError(error));
      notificationError(t('codes.auth.000'));

      history.push(redirectPath);

      return;
    }

    if (!loading && typeof data === 'undefined') {
      dispatch(orchestratorDashboardError(error));
      notificationError(t('codeResponse.MICROSERVICE_UNAVAILABLE'));

      history.push(redirectPath);

      return;
    }

    if (!loading && (!mounted || tableRedraw)) {
      setFormSubmitted(true);
      dispatch(orchestratorDashboardBegin());

      const { data: records, success } = data.orchestratorExternalDashboard;

      if (success) {
        if (records === null) {
          notificationError(t('orchestrator.no_records'));

          history.push(redirectPath);

          return;
        }

        try {
          dispatch(orchestratorDashboardSuccess(JSON.parse(records)));
        } catch (error) {
          console.error(`Expected output for endpoint was JSON, a different was received. Details: ${error}`);

          dispatch(orchestratorDashboardSuccess(records));
        }
      } else {
        console.error(`Orchestrator dashboard Delete Error: ${error ?? 'Uncaught'}`);

        dispatch(orchestratorDashboardError());
        notificationError(t('codeResponse.UNEXPECTED_ERROR'));

        history.push(redirectPath);
      }

      setMounted(true);
      setTableRedraw(false);
      setFormSubmitted(false);
    }
  }, [t, data, error, history, loading, dispatch, tableRedraw, orchestratorId, dashboardFilters]);

  useEffect(() => {
    fetchOrchestratorData();
  }, [fetchOrchestratorData]);

  // Fetch the orchestrator data only when an id is received within the url
  useEffect(() => {
    if (typeof orchestratorId !== 'undefined') {
      setSkipFetchCall(false);
    }

    return () => {
      setSkipFetchCall(true);
      dispatch(orchestratorDashboardReset());
    };
  }, [dispatch, orchestratorId]);

  return {
    mounted,
    tableRedraw,
  };
};

export default useOrchestratorDashboard;
