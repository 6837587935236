import React from 'react';
import { Row, Col, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LineChartWrapper, ChartContainer } from '../../dashboard/style';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import Heading from '../../../../components/heading/heading';
import { ChartjsLineChart } from '../../../../components/charts/chartjs';
import { customTooltips } from '../../../../components/utilities/utilities';

const ExecutionStateChart = () => {
  const { t } = useTranslation();
  const { executionStateChart, isLoading } = useSelector(state => {
    return {
      executionStateChart: state.botGoals.data.executionStateChart,
      isLoading: state.botGoals.loading,
    };
  });

  const chartOptions = {
    tooltips: {
      yAlign: 'bottom',
      xAlign: 'center',
      mode: 'nearest',
      position: 'nearest',
      intersect: false,
      enabled: false,
      custom: customTooltips,
      callbacks: {
        labelColor(_tooltipItem, _chart) {
          return {
            backgroundColor: '#20C997',
          };
        },
      },
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
    layout: {
      padding: {
        left: '0',
        right: 8,
        top: 15,
        bottom: -10,
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
            color: '#e5e9f2',
            borderDash: [8, 4],
            zeroLineColor: 'transparent',
            beginAtZero: true,
          },
          ticks: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
            color: '#e5e9f2',
            borderDash: [8, 4],
            zeroLineColor: 'transparent',
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  };

  const lineChartPointStyle = {
    borderColor: '#C6D0DC',
    borderWidth: 2,
    fill: false,
    pointRadius: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
    pointBackgroundColor: [
      'transparent',
      'transparent',
      'transparent',
      'transparent',
      'transparent',
      'transparent',
      'transparent',
      'transparent',
      'transparent',
      'transparent',
      'transparent',
      '#20C997',
    ],
    pointHoverBackgroundColor: '#20C997',
    pointHoverRadius: 6,
    pointBorderColor: 'transparent',
  };

  return (
    <LineChartWrapper>
      {executionStateChart !== null && (
        <Cards title={t('labels.bussiness.rpa.bots.goals.details.executions.title')} size="large">
          {isLoading ? (
            <div className="sd-spin">
              <Spin />
            </div>
          ) : (
            <div className="overview-container">
              <Row className="line-chart-row">
                <Col xxl={10} xs={24}>
                  <div className="growth-upward">
                    <p>{t('labels.bussiness.rpa.bots.goals.details.executions.start')}</p>
                    <Heading as="h4">
                      {executionStateChart.started.total}
                      {/* <sub> */}
                      {/*  <FeatherIcon icon="arrow-up" size={14} /> */}
                      {/*  108% */}
                      {/* </sub> */}
                    </Heading>
                  </div>
                </Col>
                <Col xxl={14} xs={24}>
                  <div className="border-linechart">
                    <ChartContainer className="parentContainer">
                      <ChartjsLineChart
                        height={55}
                        labels={executionStateChart.started.labels}
                        datasets={[
                          {
                            data: executionStateChart.started.data,
                            ...lineChartPointStyle,
                          },
                        ]}
                        options={chartOptions}
                      />
                    </ChartContainer>
                  </div>
                </Col>
              </Row>
              <Row className="line-chart-row">
                <Col xxl={10} xs={24}>
                  <div className="growth-downward">
                    <p>{t('labels.bussiness.rpa.bots.goals.details.executions.pause')}</p>
                    <Heading as="h4">
                      {executionStateChart.paused.total}
                      {/* <sub> */}
                      {/*  <FeatherIcon icon="arrow-down" size={14} /> */}
                      {/*  30% */}
                      {/* </sub> */}
                    </Heading>
                  </div>
                </Col>
                <Col xxl={14} xs={24}>
                  <div className="border-linechart">
                    <ChartContainer className="parentContainer">
                      <ChartjsLineChart
                        height={55}
                        labels={executionStateChart.paused.labels}
                        datasets={[
                          {
                            data: executionStateChart.paused.data,
                            ...lineChartPointStyle,
                          },
                        ]}
                        options={chartOptions}
                      />
                    </ChartContainer>
                  </div>
                </Col>
              </Row>
              <Row className="line-chart-row">
                <Col xxl={10} xs={24}>
                  <div className="growth-upward">
                    <p>{t('labels.bussiness.rpa.bots.goals.details.executions.resume')}</p>
                    <Heading as="h4">
                      {executionStateChart.resumed.total}
                      {/* <sub> */}
                      {/*  <FeatherIcon icon="arrow-up" size={14} /> */}
                      {/*  34% */}
                      {/* </sub> */}
                    </Heading>
                  </div>
                </Col>
                <Col xxl={14} xs={24}>
                  <div className="border-linechart">
                    <ChartContainer className="parentContainer">
                      <ChartjsLineChart
                        height={55}
                        labels={executionStateChart.resumed.labels}
                        datasets={[
                          {
                            data: executionStateChart.resumed.data,
                            ...lineChartPointStyle,
                          },
                        ]}
                        options={chartOptions}
                      />
                    </ChartContainer>
                  </div>
                </Col>
              </Row>
              <Row className="line-chart-row">
                <Col xxl={10} xs={24}>
                  <div className="growth-upward">
                    <p>{t('labels.bussiness.rpa.bots.goals.details.executions.end')}</p>
                    <Heading as="h4">
                      {executionStateChart.ended.total}
                      {/* <sub> */}
                      {/*  <FeatherIcon icon="arrow-up" size={14} /> */}
                      {/*  27% */}
                      {/* </sub> */}
                    </Heading>
                  </div>
                </Col>
                <Col xxl={14} xs={24}>
                  <div className="border-linechart">
                    <ChartContainer className="parentContainer">
                      <ChartjsLineChart
                        height={55}
                        labels={executionStateChart.ended.labels}
                        datasets={[
                          {
                            data: executionStateChart.ended.data,
                            ...lineChartPointStyle,
                          },
                        ]}
                        options={chartOptions}
                      />
                    </ChartContainer>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Cards>
      )}
    </LineChartWrapper>
  );
};

export default ExecutionStateChart;
