import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import NotFound from '../../utils/404';
import OrchestratorForm from '../../pages/admin/orchestrator/OrchestratorForm';
import OrchestratorManagement from '../../pages/admin/orchestrator';
import { OrchestratorDashboard } from '../../pages/admin/orchestrator-dashboard';

const OrchestratorRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={OrchestratorManagement} />
      <Route exact path={`${path}/create`} component={OrchestratorForm} />
      <Route path={`${path}/:orchestratorId/edit`} component={OrchestratorForm} />
      <Route path={`${path}/:orchestratorId/dashboard`} component={OrchestratorDashboard} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default OrchestratorRoutes;
