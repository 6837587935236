import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactFlow, { ReactFlowProvider, addEdge, removeElements, Controls, MiniMap, Handle } from 'react-flow-renderer';
import { useDispatch } from 'react-redux';
import { ReactFlowLayout } from './style';
import workFlowActions from '../../redux/workFlow/actions';
import config from '../../config/config';

const { theme } = config;

const { nodeSelectedBegin, nodeSelectedLoaded, nodeListBegin, nodeListLoaded, nodePropertiesRemove } = workFlowActions;

const isValidConnection = connection => {
  const { source, target } = connection;
  return (
    (source.includes('input') && target.includes('default')) ||
    (source.includes('default') && (target.includes('default') || target.includes('output')))
  );
};

const CustomInput = () => (
  <>
    <div>Start</div>
    <Handle type="source" position="right" isValidConnection={isValidConnection} />
  </>
);

const CustomOutput = () => (
  <>
    <div>End</div>
    <Handle type="target" position="left" isValidConnection={isValidConnection} />
  </>
);

const CustomNode = () => (
  <>
    <Handle type="target" position="left" isValidConnection={isValidConnection} />
    <div>Execution</div>
    <Handle type="source" position="right" isValidConnection={isValidConnection} />
  </>
);

const nodeTypes = {
  input: CustomInput,
  default: CustomNode,
  output: CustomOutput,
};

const ReactFlowDiagram = ({ initialElements }) => {
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const dispatch = useDispatch();
  const [elements, setElements] = useState(initialElements ?? []);
  const getId = type => `${type}_${moment().valueOf()}`;

  const onConnect = params => {
    setElements(els => {
      const elFind = els.find(
        el => el.type === 'selector' && (el.source === params.source || el.target === params.target),
      );
      if (!elFind)
        return addEdge({ ...params, id: getId('selector'), type: 'selector', arrowHeadType: 'arrowclosed' }, els);
      return els;
    });
  };

  const onElementsRemove = elementsToRemove => {
    setElements(els => removeElements(elementsToRemove, els));
    dispatch(nodePropertiesRemove(elementsToRemove[0].id));
    dispatch(nodeSelectedLoaded(null));
  };

  const onPaneClick = () => {
    dispatch(nodeSelectedBegin());
    dispatch(nodeSelectedLoaded(null));
  };

  const onElementClick = (event, node) => {
    dispatch(nodeSelectedBegin());
    if (node.type !== 'selector') dispatch(nodeSelectedLoaded(node.id));
    else dispatch(nodeSelectedLoaded(null));
  };

  const onLoad = _reactFlowInstance => setReactFlowInstance(_reactFlowInstance);

  const onDragOver = event => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = event => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const newNode = {
      id: getId(type),
      type,
      position,
    };

    setElements(els => {
      const elFind = t => els.find(el => el.type === t);
      let nodeFind;

      if (type === 'input') nodeFind = elFind('input');
      else if (type === 'output') nodeFind = elFind('output');
      if (nodeFind) return els;
      return els.concat(newNode);
    });
  };

  const onChange = useCallback(() => {
    if (reactFlowInstance) {
      setElements(reactFlowInstance.toObject().elements);
    }
  }, [reactFlowInstance]);

  useEffect(() => {
    dispatch(nodeListBegin());
    if (elements.length > 0) {
      dispatch(nodeListLoaded(elements));
    } else {
      dispatch(nodeListLoaded([]));
    }
  }, [elements, dispatch]);

  return (
    <ReactFlowLayout>
      <ReactFlowProvider>
        <div style={{ height: '50vh' }} className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            elements={elements}
            onConnect={onConnect}
            onElementClick={onElementClick}
            onPaneClick={onPaneClick}
            onElementsRemove={onElementsRemove}
            onLoad={onLoad}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeDragStop={onChange}
            nodeTypes={nodeTypes}
            deleteKeyCode={46}
          >
            <MiniMap
              nodeStrokeColor={n => {
                if (n.type === 'input') return theme['success-color'];
                if (n.type === 'default') return theme['primary-color'];
                if (n.type === 'output') return theme['error-color'];
                return theme['primary-color'];
              }}
            />
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </ReactFlowLayout>
  );
};

ReactFlowDiagram.propTypes = {
  initialElements: PropTypes.array,
};

export default ReactFlowDiagram;
