import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import actions from '../../../redux/webNotification/actions';
import { textRefactor } from '../../../components/utilities/utilities';
import Heading from '../../heading/heading';
import { Button } from '../../buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { MUTATTION_WEB_NOTIFICATION_DELETE, MUTATTION_WEB_NOTIFICATION_UPDATA } from '../../../queries/webnotification/webnotification.queries';
import { Style, EmailHeader } from './style';

const Content = ({ searchData, email }) => {
  const dispatch = useDispatch();
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const [descriptionState, setDescriptionState] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mutationNotify] = useMutation(MUTATTION_WEB_NOTIFICATION_UPDATA);
  const [deleteNotifyWeb] = useMutation(MUTATTION_WEB_NOTIFICATION_DELETE);

  const mystyles = {
    background: "#FF69A515",
    border: "none"
  }

  const [state, setState] = useState({
    selectedRowKeys: [],
    notData: searchData || [],
    emails: email || [],
    sort: true,
  });

  const showModal = (item) => {
    setIsModalVisible(true);
    setDescriptionState([item._id, item.message, item.createdAt, item.type])
    dispatch(actions.webNotificationUpdateReadedSuccess(item))
    mutationNotify({ variables: { id: item._id } })
  };
  const deleteNotify = item => {
    deleteNotifyWeb({ variables: { id: item._id } })
    dispatch(actions.webNotificationDeleteOne(item))
    const filtredData = state.emails.filter(emails => emails._id !== item._id)
    setState({
      emails: filtredData
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const data = [];

  state.emails.map((inbox, key) => {
    // eslint-disable-next-line no-shadow
    const { message, createdAt, readed, _id, type } = inbox;
    const date = new Date(createdAt)
    return data.push({
      key: _id,
      img: (
        <div className='atbd-top-dropdwon' style={{ width: "40px" }}>
          <div className='atbd-top-dropdwon__content'>
            <div className={type === "Success" ? "notification-icon bg-success" : "notification-icon bg-secondary"}>
              <FeatherIcon icon={type === "Success" ? "check" : "x"} />
            </div>
          </div>
        </div>
      ),
      trash: (<Button style={mystyles} className="btn-icon" type="error" shape="circle" onClick={() => deleteNotify(inbox)}>
        <FeatherIcon style={{ color: "#FF69A5" }} icon="trash-2" size={18} />
      </Button>
      ),
      content: (
        <EmailHeader>
          <Heading as="h5">
          </Heading>
          <p style={{ color: [readed === true ? "#adb4d2" : "#4bb3d8"], cursor: 'pointer' }} onClick={() => showModal(inbox)}>{textRefactor(message, 10)}</p>
        </EmailHeader>
      ),
      time: <span className="email-time">{date.toDateString()}</span>,
    });
  });

  const handleChange = (pagination, filters, sorter) => {
    setState({
      ...state,
      sortedInfo: sorter,
    });
  };

  useEffect(() => {
    setState((x) => ({
      ...x,
      emails: email || []
    }))
  }, [email]);

  // const onRowSelection = filterObj => {
  //   const { filter, byValue } = filterObj;

  //   const newSelectedRowKeys = emails
  //     .filter(value => {
  //       return value[filter] === byValue;
  //     })
  //     .map(item => item._id);

  //   setState({ ...state, selectedRowKeys: newSelectedRowKeys });
  // };

  // const onSelectChange = selectedRowKey => {
  //   setState({ ...state, selectedRowKeys: selectedRowKey });
  // };


  const columns = [
    {
      dataIndex: 'img',
      key: 'img'
    },
    {
      dataIndex: 'content',
      key: 'content',
    },
    {
      dataIndex: 'time',
      key: 'time',
    },
    {
      dataIndex: 'trash',
      key: 'trash'
    }
  ];

  return (
    <>
      <Heading as="h5" className="atbd-top-dropdwon__title">
        {t(`notification.webNotification.title`)}
      </Heading>
      <Style
        darkMode={darkMode}
        className="table-responsive"
        style={{ marginTop: '10px', marginBottom: '0px' }}
        pagination={false}
        //  rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        modal={Modal}
        showHeader={false}
      />
      <Modal visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} type={'primary'} footer={null}>
        <div className='body-tittle'>
          <div className='atbd-top-dropdwon' style={{ width: "40px" }}><div className='atbd-top-dropdwon__content'>
            <div className={descriptionState[3] === "Success" ? "notification-icon bg-success" : "notification-icon bg-secondary"}>
              <FeatherIcon icon={descriptionState[3] === "Success" ? "check" : "x"} />
            </div>
          </div>
          </div>
          <p>{descriptionState[3]}</p>
        </div>
        <br />
        <p>{descriptionState[1]}</p>
        <p style={{ color: "#adb4d2" }}>{new Date(descriptionState[2]).toDateString()}</p>
      </Modal>
    </>
  );
};

Content.propTypes = {
  searchData: propTypes.arrayOf(propTypes.object).isRequired,
  email: propTypes.arrayOf(propTypes.object).isRequired,
};

export default Content;
