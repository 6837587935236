import { gql } from '@apollo/client';

export const QUERY_USER_FIND_ALL = gql`
  query userFindAll($tenantId: String!) {
    userFindAll(tenantId: $tenantId) {
      success
      code
      data {
        _id
        name
        email
        createdAt
        enabled
        mainTenant {
          tenant {
            _id
          }
          userLicense
        }
        relatedTenants {
          tenant {
            _id
          }
          userLicense
        }
      }
    }
  }
`;

export const QUERY_USER_CREATE = gql`
  mutation userCreate($userCreateInput: UserCreateInput!) {
    userCreate(userCreateInput: $userCreateInput) {
      success
      code
      data {
        _id
        name
        email
        createdAt
        enabled
        mainTenant {
          tenant {
            _id
          }
          resourceGroup {
            _id
            enabled
          }
          userLicense
        }
      }
    }
  }
`;

export const QUERY_USER_FIND_ONE = gql`
  query userFindOne($id: String!) {
    userFindOne(id: $id) {
      success
      code
      data {
        _id
        name
        email
        phoneNumber
        phoneCode
        enabled
        mainTenant {
          tenant {
            _id
          }
          resourceGroup {
            _id
            name
            resources {
              pricing {
                _id
                name
                slug
              }
              quantity
              occupied
            }
            enabled
          }
          userLicense
          plataformaModelerLicense
          userPermissions
        }
      }
    }
  }
`;

export const QUERY_USER_GUEST_FIND_ONE = gql`
  query userGuestFindOne($id: String!) {
    userGuestFindOne(id: $id) {
      success
      code
      data {
        _id
        name
        email
        relatedTenants {
          tenant {
            _id
          }
          resourceGroup {
            _id
            name
            resources {
              pricing {
                _id
                name
                slug
              }
              quantity
              occupied
            }
            enabled
          }
          userLicense
          plataformaModelerLicense
          userPermissions
        }
      }
    }
  }
`;

export const QUERY_USER_UPDATE = gql`
  mutation userUpdate($userUpdateInput: UserUpdateInput!) {
    userUpdate(userUpdateInput: $userUpdateInput) {
      success
      code
      data {
        _id
        name
        email
        createdAt
        enabled
        mainTenant {
          tenant {
            _id
          }
          resourceGroup {
            _id
            enabled
          }
          userLicense
        }
      }
    }
  }
`;

export const QUERY_USER_REMOVE = gql`
  mutation userRemove($id: String!) {
    userRemove(id: $id) {
      success
      code
      data {
        _id
        name
      }
    }
  }
`;

export const QUERY_USER_RELATED_TENANT_ADD = gql`
  mutation userRelatedTenantAdd($userTenantInput: UserTenantInput!) {
    userRelatedTenantAdd(userTenantInput: $userTenantInput) {
      success
      code
      data {
        _id
        name
        email
        createdAt
        enabled
        mainTenant {
          tenant {
            _id
          }
          resourceGroup {
            _id
            enabled
          }
          userLicense
        }
        relatedTenants {
          tenant {
            _id
          }
          resourceGroup {
            _id
            enabled
          }
          userLicense
        }
      }
    }
  }
`;

export const QUERY_USER_RELATED_TENANT_UPDATE = gql`
  mutation userRelatedTenantUpdate($input: UserTenantInput!) {
    userRelatedTenantUpdate(userTenantInput: $input) {
      success
      code
      data {
        _id
        name
        email
        createdAt
        enabled
        mainTenant {
          tenant {
            _id
          }
          resourceGroup {
            _id
            enabled
          }
          userLicense
        }
        relatedTenants {
          tenant {
            _id
          }
          resourceGroup {
            _id
            enabled
          }
          userLicense
        }
      }
    }
  }
`;

export const QUERY_USER_RELATED_TENANT_REMOVE = gql`
  mutation userRelatedTenantRemove($input: UserTenantIdInput!) {
    userRelatedTenantRemove(userTenantIdInput: $input) {
      success
      code
      data {
        _id
        name
      }
    }
  }
`;

export const QUERY_ME = gql`
  query me {
    me {
      success
      code
      data {
        currentUser {
          _id
          name
          email
          mainTenant {
            tenant {
              _id
              name
              nit
            }
          }
          relatedTenants {
            tenant {
              _id
              name
              nit
            }
          }
        }
        currentTenant {
          tenant {
            _id
            diskUsage {
              available
              occupied
            }
          }
          userPermissions
          userLicense
        }
      }
    }
  }
`;

export const QUERY_REPORT_USER_FIND_ALL = gql`
  query userFindAll($tenantId: String!) {
    userFindAll(tenantId: $tenantId) {
      success
      code
      data {
        _id
        name
        email
        createdAt
        enabled
        mainTenant {
          tenant {
            _id
          }
          resourceGroup {
            _id
            enabled
          }
          userLicense
          plataformaModelerLicense
          userPermissions
        }
        relatedTenants {
          tenant {
            _id
          }
          resourceGroup {
            _id
            enabled
          }
          userLicense
          plataformaModelerLicense
          userPermissions
        }
      }
    }
  }
`;
