import update from 'immutability-helper';
import actions from './actions';

const {
  MAIL_NOTIFICATION_FIND_ALL,
  MAIL_NOTIFICATION_DELETE_ONE,
  MAIL_NOTIFICATION_UPDATE,
  MAIL_NOTIFICATION_FIND_ONE,
} = actions;

const initialState = {
  data: [],
  subdata: [],
  loading: false,
  error: false,
};

const mailNotificationReducer = (state = initialState, action) => {
  const { type, data, } = action;

  switch (type) {

    case MAIL_NOTIFICATION_DELETE_ONE: {
      const retu = state?.data?.filter(({ _id }) => _id !== action.payload._id);
      return {
        ...state,
        data: retu
      }
    };

    case MAIL_NOTIFICATION_FIND_ALL:
      return {
        ...state,
        loading: false,
        data,
      };

    case MAIL_NOTIFICATION_UPDATE:
      const index = state.data?.findIndex(x => x._id === data._id);
      return update(state, {
        data: {
          [index]: { $set: { ...state.data[index], readed: true } },
        },
        loading: { $set: false },
    });
    default:
      return state;

    case MAIL_NOTIFICATION_FIND_ONE:
      return{
        ...state,
        subdata:action.payload
      }
  };
};

export default mailNotificationReducer;
