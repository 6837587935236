const actions = {
  BOT_EXECUTION_FIND_ALL_BEGIN: 'BOT_EXECUTION_FIND_ALL_BEGIN',
  BOT_EXECUTION_FIND_ALL_SUCCESS: 'BOT_EXECUTION_FIND_ALL_SUCCESS',
  BOT_EXECUTION_FIND_ALL_ERROR: 'BOT_EXECUTION_FIND_ALL_ERROR',

  BOT_EXECUTION_FILTER_SUCCESS: 'BOT_EXECUTION_FILTER_SUCCESS',
  BOT_EXECUTION_FILTER_ERROR: 'BOT_EXECUTION_FILTER_ERROR',
  BOT_EXECUTION_FILTER_BEGIN: 'BOT_EXECUTION_FILTER_BEGIN',

  botExecutionFindAllBegin: () => {
    return {
      type: actions.BOT_EXECUTION_FIND_ALL_BEGIN,
    };
  },

  botExecutionFindAllSuccess: data => {
    return {
      type: actions.BOT_EXECUTION_FIND_ALL_SUCCESS,
      data,
    };
  },

  botExecutionFindAllError: error => {
    return {
      type: actions.BOT_EXECUTION_FIND_ALL_ERROR,
      error,
    };
  },

  botExecutionFilterBegin: () => {
    return {
      type: actions.BOT_EXECUTION_FILTER_BEGIN,
    };
  },

  botExecutionFilterSuccess: data => {
    return {
      type: actions.BOT_EXECUTION_FILTER_SUCCESS,
      data,
    };
  },

  botExecutionFilterError: error => {
    return {
      type: actions.BOT_EXECUTION_FILTER_ERROR,
      error,
    };
  },
};

export default actions;
