import { useMutation, useQuery } from '@apollo/client';
import FeatherIcon from 'feather-icons-react';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Table, Spin, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import botLicenseActions from '../../../redux/botLicense/actions';
import licenseActions from '../../../redux/license/actions';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, TableWrapper, UserTableStyleWrapper } from '../styled';
import { QUERY_BOT_LICENSE_ASSIGN, QUERY_LICENSE_FIND_ALL } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

const { botLicenseAssignBegin, botLicenseAssignSuccess, botLicenseAssignError } = botLicenseActions;
const {
  licenseFindAllBegin,
  licenseFindAllSuccess,
  licenseFindAllError,
  licenseRemoveBegin,
  licenseRemoveSuccess,
  licenseRemoveError,
} = licenseActions;

const BotLicenseAssign = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const { botId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const botLicenseState = useSelector(state => state.botLicense);
  const licenseState = useSelector(state => state.license);
  const currentLoading = useSelector(state => state.currentUserTenant.currentLoading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [botLicenseAssign] = useMutation(QUERY_BOT_LICENSE_ASSIGN);
  const [state, setState] = useState({
    licenseDataTable: [],
  });
  const [mounted, setMounted] = useState(false);

  const { loading, data, error } = useQuery(QUERY_LICENSE_FIND_ALL, {
    variables: {
      tenantId: currentTenant?.tenant._id,
    },
    fetchPolicy: 'network-only',
  });

  const handleBotLicenseAssign = useCallback(
    license => {
      dispatch(botLicenseAssignBegin());
      dispatch(licenseRemoveBegin());

      botLicenseAssign({
        variables: {
          input: {
            serial: license.serial,
            version: '1.0',
            bot: botId,
          },
        },
      })
        .then(({ data }) => {
          if (data.botLicenseAssign.success) {
            dispatch(botLicenseAssignSuccess(data.botLicenseAssign.data));
            dispatch(licenseRemoveSuccess(data.botLicenseAssign.data.license.serial));

            notificationSuccess(t('botLicense.successAssigned'));
          } else {
            dispatch(botLicenseAssignError(data.botLicenseAssign.code));
            dispatch(licenseRemoveError());

            notificationError(t(`codeResponse.${data.botLicenseAssign.code}`));
          }
        })
        .catch(e => {
          console.log('Bot License Added Error ', e);
          dispatch(botLicenseAssignError());
          dispatch(licenseRemoveError());

          notificationError(t('codeResponse.UNEXPECTED_ERROR'));
        });
    },
    [dispatch, botLicenseAssign, t, botId],
  );

  useEffect(() => {
    if (licenseState.data?.length > 0) {
      setState({
        licenseDataTable: licenseState.data.map(x => ({
          key: x._id,
          serial: x.serial,
          computerName: x.computerInfo?.name,
          domain: x.computerInfo?.domain,
          actions: (
            <div className="table-actions">
              <>
                <Button type="primary" shape="circle" onClick={() => handleBotLicenseAssign(x)}>
                  <FeatherIcon icon="plus" size={16} />
                </Button>
              </>
            </div>
          ),
        })),
      });
    } else {
      setState({
        licenseDataTable: [],
      });
    }
  }, [licenseState, handleBotLicenseAssign]);

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotLicenseAssign)) {
      history.push(`/admin/bot`);
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted && data) {
      dispatch(licenseFindAllBegin());
      if (data.licenseFindAll.success) {
        const dataFilter = data.licenseFindAll.data?.filter(
          x => !botLicenseState.data?.find(e => e.license.serial === x.serial) && x.enabled,
        );
        dispatch(licenseFindAllSuccess(dataFilter));
      } else {
        dispatch(licenseFindAllError(data.licenseFindAll.code));
        history.push(`/admin/bot`);
        notificationError(t(`codeResponse.${data.licenseFindAll.code}`));
      }
      setMounted(true);
    }
  }, [dispatch, data, loading, error, history, currentTenant, currentLoading, t, botLicenseState, mounted, botId]);

  const columns = [
    {
      title: t('properties.serial.1'),
      dataIndex: 'serial',
      key: 'serial',
      width: '30%',
    },
    {
      title: t('properties.computerName.1'),
      dataIndex: 'computerName',
      key: 'computerName',
      width: '30%',
    },
    {
      title: t('properties.domain.1'),
      dataIndex: 'domain',
      key: 'domain',
      width: '30%',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
    },
  ];

  if (!licenseState.loading) {
    return (
      <>
        <PageHeader ghost title={t('license.available')} />
        <Main darkMode={darkMode}>
          <div className="table-bordered leaderboard-table table-responsive">
            <UserTableStyleWrapper>
              <TableWrapper className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={state.licenseDataTable}
                  pagination={{
                    defaultPageSize: 10,
                    total: state.licenseDataTable?.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
                  }}
                />
              </TableWrapper>
            </UserTableStyleWrapper>
          </div>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default BotLicenseAssign;
