import { HomeOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Breadcrumb } from 'antd';
import PasswordVaultList from '../../../components/password-vault/PasswordVaultList';
import PasswordVaultForm from '../../../components/password-vault/PasswordVaultForm';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { alertModal, Modal } from '../../../components/modals/antd-modals';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import {
  QUERY_TENANT_VAULT_CREATE,
  QUERY_TENANT_VAULT_FIND_ALL,
  QUERY_TENANT_VAULT_REMOVE,
  QUERY_TENANT_VAULT_UPDATE,
} from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { Main } from '../styled';

const TenantVaultManagement = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [stateVariables, setStateVariables] = useState([]);
  const [stateVaultId, setStateVaultId] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const initialModalInfo = {
    action: 'CREATE',
    form: {
      name: '',
      value: '',
      description: '',
    },
  };
  const [modalInfo, setModalInfo] = useState(initialModalInfo);
  const queryArgs = {
    fetchPolicy: 'network-only',
  };
  const [tenantVaultCreate] = useMutation(QUERY_TENANT_VAULT_CREATE);
  const [tenantVaultUpdate] = useMutation(QUERY_TENANT_VAULT_UPDATE);
  const [tenantVaultRemove] = useMutation(QUERY_TENANT_VAULT_REMOVE);
  const { loading: vaultLoading, data: vaultData, refetch: vaultRefetch } = useQuery(
    QUERY_TENANT_VAULT_FIND_ALL,
    queryArgs,
  );

  const handleUpdateView = () => {
    vaultRefetch();
    setShowModal(false);
    setModalInfo(initialModalInfo);
  };

  const handleModalStatus = () => {
    setModalInfo(initialModalInfo);
    setShowModal(!showModal);
  };

  const handleSecureVariableUpdate = secureVariable => {
    setModalInfo({
      action: 'UPDATE',
      form: {
        ...secureVariable,
      },
    });
    setShowModal(!showModal);
  };

  const secureVariableCreate = payload => {
    if (!currentTenant?.userPermissions?.includes(UserPermissions.TenantVaultCreate)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    setIsSubmitted(true);
    tenantVaultCreate({
      variables: { input: payload },
    })
      .then(({ data }) => {
        const { success, code: responseCode } = data.tenantPasswordVaultCreate;
        if (success) {
          notificationSuccess(t('botVault.successAssigned'));
          handleUpdateView();
        } else notificationError(t(`codeResponse.${responseCode}`));
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('Tenant Vault Create Error ', e);
        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  const secureVariableUpdate = payload => {
    if (!currentTenant?.userPermissions?.includes(UserPermissions.TenantVaultUpdate)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    setIsSubmitted(true);
    tenantVaultUpdate({
      variables: { input: payload },
    })
      .then(({ data }) => {
        const { success, code: responseCode } = data.tenantPasswordVaultUpdate;
        if (success) {
          notificationSuccess(t('botVault.successUpdated'));
          handleUpdateView();
        } else notificationError(t(`codeResponse.${responseCode}`));
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('Tenant Vault Update Error ', e);
        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  const handleSecureVariableDestroy = oldName => {
    if (!currentTenant?.userPermissions?.includes(UserPermissions.TenantVaultDelete)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    alertModal.confirm({
      title: t('botVault.confirmRemove'),
      onOk() {
        tenantVaultRemove({
          variables: {
            input: {
              _id: stateVaultId,
              oldName,
            },
          },
        })
          .then(({ data }) => {
            const { success, code } = data.tenantPasswordVaultRemove;

            if (success) {
              setStateVariables(prevState => prevState.filter(item => item.name !== oldName));
              notificationSuccess(t('botVault.successDeleted'));
            } else {
              notificationError(t(`codeResponse.${code}`));
            }
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.log('Tenant Vault Delete Error ', e);
            notificationError(t('codeResponse.UNEXPECTED_ERROR'));
          })
          .finally(() => handleUpdateView());
      },
      okText: t('general.confirm'),
    });
  };

  const handleSubmit = formValues => {
    const payload = {
      name: formValues.name,
      value: formValues.value,
      description: formValues.description,
    };
    if (String(modalInfo.action).toUpperCase() === 'CREATE')
      secureVariableCreate({
        ...payload,
      });
    else
      secureVariableUpdate({
        ...payload,
        _id: stateVaultId,
        oldName: modalInfo.form.name,
      });
  };

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.TenantVaultFind)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    if (!vaultLoading) {
      const { success, data: passwordVault } = vaultData?.tenantPasswordVaultFindAll;
      if (success) {
        setStateVaultId(passwordVault?._id);
        setStateVariables(passwordVault?.variables ?? []);
      }
    }
  }, [history, currentTenant, currentLoading, t, vaultData, vaultLoading]);

  return (
    <>
      <PageHeader ghost title={t('tenantVault.management')} />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/admin/">
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">{t('tenantVault.management')}</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24}>
            <Cards
              isbutton={
                <div key="6" className="page-header-actions">
                  <Button
                    className="btn-signin"
                    size="small"
                    key="2"
                    type="primary"
                    onClick={() => handleModalStatus(true)}
                  >
                    <FeatherIcon icon="plus" size={14} />
                    {t('botVault.create')}
                  </Button>
                </div>
              }
            >
              <PasswordVaultList
                variablesData={stateVariables}
                isTenantVault
                loading={vaultLoading}
                handleSecureVariableUpdate={handleSecureVariableUpdate}
                handleSecureVariableDestroy={handleSecureVariableDestroy}
              />

              <Modal
                type="primary"
                title={modalInfo.action === 'CREATE' ? t('botVault.create') : t('botVault.edit')}
                visible={showModal}
                style={{ top: 30 }}
                width="60%"
                footer={null}
                onCancel={() => handleModalStatus()}
              >
                <PasswordVaultForm
                  action={modalInfo.action}
                  initialForm={modalInfo.form}
                  isTenantVault
                  loading={isSubmitted}
                  handleSubmit={handleSubmit}
                />
              </Modal>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default TenantVaultManagement;
