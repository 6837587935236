import actions from './actions';

const {
  ORCHESTRATOR_GET_ALL_BEGIN,
  ORCHESTRATOR_GET_ALL_SUCCESS,
  ORCHESTRATOR_GET_ALL_ERROR,
  ORCHESTRATOR_CREATE_BEGIN,
  ORCHESTRATOR_CREATE_SUCCESS,
  ORCHESTRATOR_CREATE_ERROR,
  ORCHESTRATOR_UPDATE_BEGIN,
  ORCHESTRATOR_FIND_ONE_BEGIN,
  ORCHESTRATOR_FIND_ONE_SUCCESS,
  ORCHESTRATOR_FIND_ONE_ERROR,
  ORCHESTRATOR_FIND_ONE_RESET,
  ORCHESTRATOR_UPDATE_SUCCESS,
  ORCHESTRATOR_UPDATE_ERROR,
  ORCHESTRATOR_REMOVE_BEGIN,
  ORCHESTRATOR_REMOVE_SUCCESS,
  ORCHESTRATOR_REMOVE_ERROR,
  ORCHESTRATOR_DASHBOARD_BEGIN,
  ORCHESTRATOR_DASHBOARD_SUCCESS,
  ORCHESTRATOR_DASHBOARD_ERROR,
  ORCHESTRATOR_DASHBOARD_RESET,
  ORCHESTRATOR_DEACTIVATE_REDRAW_TABLE,
} = actions;

const initialState = {
  error: false,
  loading: false,
  dashboard: [],
  reDrawTable: false,
  orchestrator: undefined,
  orchestrators: [],
};

const orchestratorReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case ORCHESTRATOR_GET_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ORCHESTRATOR_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        orchestrators: data ?? [],
      };

    case ORCHESTRATOR_GET_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    case ORCHESTRATOR_REMOVE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ORCHESTRATOR_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        reDrawTable: true,
        orchestrators: state.orchestrators.filter(x => x._id !== data),
      };

    case ORCHESTRATOR_REMOVE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    case ORCHESTRATOR_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ORCHESTRATOR_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ORCHESTRATOR_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    case ORCHESTRATOR_FIND_ONE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case ORCHESTRATOR_FIND_ONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        orchestrator: data,
      };
    }

    case ORCHESTRATOR_FIND_ONE_RESET:
      return {
        ...state,
        orchestrator: undefined,
      };

    case ORCHESTRATOR_FIND_ONE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ORCHESTRATOR_UPDATE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case ORCHESTRATOR_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case ORCHESTRATOR_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ORCHESTRATOR_DASHBOARD_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case ORCHESTRATOR_DASHBOARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        dashboard: data,
      };
    }

    case ORCHESTRATOR_DASHBOARD_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ORCHESTRATOR_DASHBOARD_RESET: {
      return {
        ...state,
        dashboard: [],
      };
    }

    case ORCHESTRATOR_DEACTIVATE_REDRAW_TABLE: {
      return {
        ...state,
        reDrawTable: false,
      };
    }

    default:
      return state;
  }
};

export default orchestratorReducer;
