import update from 'immutability-helper';
import actions from './actions';

const {
  API_KEY_GET_ALL_BEGIN,
  API_KEY_GET_ALL_SUCCESS,
  API_KEY_GET_ALL_ERROR,
  API_KEY_CREATE_BEGIN,
  API_KEY_CREATE_SUCCESS,
  API_KEY_CREATE_ERROR,
  API_KEY_DELETE_BEGIN,
  API_KEY_DELETE_SUCCESS,
  API_KEY_DELETE_ERROR,
  API_KEY_UPDATE_BEGIN,
  API_KEY_UPDATE_SUCCESS,
  API_KEY_UPDATE_ERROR,
} = actions;

const initialState = {
  data: [],
  loading: false,
  loadingCreate: false,
  error: false,
};

const apiKeyLicenseReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case API_KEY_GET_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case API_KEY_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data ?? [],
      };
    case API_KEY_GET_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case API_KEY_CREATE_BEGIN:
      return {
        ...state,
        loadingCreate: true,
      };
    case API_KEY_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
      };
    case API_KEY_CREATE_ERROR:
      return {
        ...state,
        loadingCreate: false,
        error,
      };
    case API_KEY_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case API_KEY_DELETE_SUCCESS:
      console.log(data);
      return {
        ...state,
        loading: false,
        data: state.data.filter(x => x._id !== data),
      };
    case API_KEY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case API_KEY_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case API_KEY_UPDATE_SUCCESS:
      const index = state.data.findIndex(x => x._id === data._id);
      return update(state, {
        data: {
          [index]: { $set: data },
        },
        loading: { $set: false },
      });
    case API_KEY_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export default apiKeyLicenseReducer;
