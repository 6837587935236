import { gql } from '@apollo/client';

export const QUERY_ORCHESTRATOR_FIND_ALL = gql`
  query orchestratorFindAll($tenantId: String!) {
    orchestratorFindAll(tenantId: $tenantId) {
      success
      code
      data {
        _id
        name
        alias
        model
        tenant {
          _id
        }
        enabled
        description
      }
    }
  }
`;

export const QUERY_ORCHESTRATOR_FIND_ONE = gql`
  query orchestratorFindOne($orchestratorId: String!) {
    orchestratorFindOne(orchestratorId: $orchestratorId) {
      success
      code
      data {
        _id
        name
        alias
        model
        tenant {
          _id
        }
        enabled
        description
      }
    }
  }
`;

export const QUERY_ORCHESTRATOR_CREATE = gql`
  mutation orchestratorCreate($orchestratorCreateInput: OrchestratorCreateInput!) {
    orchestratorCreate(orchestratorCreateInput: $orchestratorCreateInput) {
      success
      code
      data {
        _id
        name
        alias
        model
        tenant {
          _id
        }
        enabled
        description
      }
    }
  }
`;

export const QUERY_ORCHESTRATOR_UPDATE = gql`
  mutation orchestratorUpdate($orchestratorUpdateInput: OrchestratorUpdateInput!) {
    orchestratorUpdate(orchestratorUpdateInput: $orchestratorUpdateInput) {
      success
      code
      data {
        _id
        name
        alias
        model
        tenant {
          _id
          name
        }
        enabled
        description
      }
    }
  }
`;

export const QUERY_ORCHESTRATOR_REMOVE = gql`
  mutation orchestratorRemove($orchestratorId: String!) {
    orchestratorRemove(orchestratorId: $orchestratorId) {
      success
      code
      data {
        _id
        enabled
      }
    }
  }
`;

export const QUERY_ORCHESTRATOR_FIND_DOWNLOAD = gql`
  mutation orchestratorExternalDownload($orchestratorId: String!) {
    orchestratorExternalDownload(orchestratorId: $orchestratorId) {
      success
      code
      data
    }
  }
`;

export const QUERY_ORCHESTRATOR_DASHBOARD = gql`
  query orchestratorExternalDashboard($orchestratorExternalDashboardInput: OrchestratorExternalDashboardInput!) {
    orchestratorExternalDashboard(orchestratorExternalDashboardInput: $orchestratorExternalDashboardInput) {
      data
      code
      success
    }
  }
`;
