import { gql } from '@apollo/client';

export const QUERY_BOT_VAULT_FIND_ALL = gql`
  query passwordVaultFindAll($id: String!) {
    passwordVaultFindAll(botId: $id) {
      success
      code
      data {
        _id
        variables {
          name
          user
          description
          isTenantVariable
        }
      }
    }
  }
`;

export const QUERY_BOT_VAULT_CREATE = gql`
  mutation passwordVaultCreate($input: PasswordVaultCreateInput!) {
    passwordVaultCreate(passwordVaultCreateInput: $input) {
      success
      code
      data {
        _id
        variables {
          name
          user
          description
          isTenantVariable
        }
      }
    }
  }
`;

export const QUERY_BOT_VAULT_UPDATE = gql`
  mutation passwordVaultUpdate($input: PasswordVaultUpdateInput!) {
    passwordVaultUpdate(passwordVaultUpdateInput: $input) {
      success
      code
      data {
        _id
        variables {
          name
          user
          description
          isTenantVariable
        }
      }
    }
  }
`;

export const QUERY_BOT_VAULT_REMOVE = gql`
  mutation passwordVaultRemove($input: PasswordVaultDeleteInput!) {
    passwordVaultRemove(passwordVaultDeleteInput: $input) {
      success
      code
      data {
        _id
      }
    }
  }
`;
