import React, { useEffect, useState, lazy } from 'react';
import { Col, Row, Spin, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import TotalExecutionsChart from './BotGoalsCharts/TotalExecutions';
import ExecutionStateChart from './BotGoalsCharts/ExecutionStateChart';
import ReveneuChart from './BotGoalsCharts/Reveneu';
import TotalCustomReportsChart from './BotGoalsCharts/TotalCustomReports';
import { Main } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError } from '../../../components/utilities/notification';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { QUERY_BOT_FIND_ONE, QUERY_BOT_GOALS } from '../../../queries';
import botGoalsActions from '../../../redux/botGoals/actions';
import botActions from '../../../redux/bot/actions';
import { CalendarButtonPageHeader } from '../../../components/buttons/calendar-button/calendar-button';
import { CardChartStyle } from '../../../components/widgets/Style';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const { botGoalsFindBegin, botGoalsFindSuccess, botGoalsFindError } = botGoalsActions;
const { botGetOneBegin, botGetOneSuccess, botGetOneError } = botActions;

const SampleCardSix = lazy(() => import('../../../components/cards/sampleCard/SampleCardSix'));

const BotGoalsReportBotId = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const { botId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingBots = useSelector(state => state.bot.loading);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const averageExecutions = useSelector(state => state.botGoals.data.averageExecutions);
  const bot = useSelector(state => state.bot.bot);
  const [mounted, setMounted] = useState(false);
  const [mountedBotExecutions, setMountedBotExecutions] = useState(false);
  const [botGoalsFind, { data: tenantBots, loading, error }] = useLazyQuery(QUERY_BOT_GOALS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.botGoals.success) dispatch(botGoalsFindSuccess(data.botGoals.data));
      else dispatch(botGoalsFindError(data.botGoals.error));
    },
    onError: err => dispatch(botGoalsFindError(err)),
  });
  // const sylesBreadcrumb = {
  //   color: "#5f63f2",
  //   "font-size": "14px"
  // }
  const { data: botData, loading: loadingBot, error: errorBot } = useQuery(QUERY_BOT_FIND_ONE, {
    variables: { id: botId || '' },
    fetchPolicy: 'network-only',
  });
  const cardCounter = [
    {
      id: 4,
      title: averageExecutions ? averageExecutions.toString() : 0,
      content: t('labels.bussiness.rpa.bots.goals.details.averageExecutions'),
      img: 'static/img/icon/3.svg',
      className: 'info',
    },
  ];

  const onChangeCalendar = data => {
    const { startDate, endDate } = data;
    botGoalsFind({
      variables: {
        input: {
          id: botId,
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          showAll: true,
        },
      },
    });
  };

  const onRefresh = () => {
    console.log('refresh');
    botGoalsFind({
      variables: {
        input: {
          id: botId,
          start: moment()
            .subtract(1, 'days')
            .startOf('day')
            .toISOString(),
          end: moment()
            .endOf('day')
            .toISOString(),
          showAll: true,
        },
      },
    });
  };

  useEffect(() => {
    if (botId && !mountedBotExecutions) {
      dispatch(botGoalsFindBegin());
      botGoalsFind({
        variables: {
          input: {
            id: botId,
            start: moment()
              .subtract(1, 'days')
              .startOf('day')
              .toISOString(),
            end: moment()
              .endOf('day')
              .toISOString(),
            showAll: true,
          },
        },
      });
      setMountedBotExecutions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botGoalsFind, mountedBotExecutions, onChangeCalendar, dispatch]);

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotCVFindOne)) {
      history.push('/admin/bot');
      notificationError(t(`codeResponse.403`));
    }

    dispatch(botGetOneBegin());

    if (!loading && tenantBots && !mounted) {
      if (tenantBots.botGoals.success) {
        dispatch(botGoalsFindSuccess(tenantBots.botGoals.data));
      } else {
        dispatch(botGoalsFindError(tenantBots.botGoals.code));
      }
      setMounted(true);
    }

    if (!loadingBot && botData) {
      if (botData.botFindOne.success) {
        dispatch(botGetOneSuccess(botData.botFindOne.data));
      } else {
        dispatch(botGetOneError(error));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, tenantBots, loading, error, loadingBot, errorBot, currentLoading, t, mounted]);

  if (!loadingBots && mounted) {
    return (
      <>
        <PageHeader
          title={`Goals - ${bot.name}`}
          buttons={[
            <div key="1" className="page-header-actions">
              <CalendarButtonPageHeader onChange={onChangeCalendar} />
              <Button size="small" type="primary" onClick={onRefresh}>
                <FeatherIcon icon="refresh-ccw" size={14} />
                {t('labels.bussiness.rpa.bots.goals.details.btnUpdate')}
              </Button>
            </div>,
          ]}
        />
        <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle  darkMode={darkMode}>
                      <Breadcrumb>
                        <Breadcrumb.Item>
                          <NavLink to={`/admin/`}>
                            <HomeOutlined />
                          </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <NavLink to={`/admin/report/bot-goals-report`}><span style={{ "font-size": "14px" }}>Goals</span>
                          </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <NavLink to="#">{`Goals - ${bot.name}`}</NavLink>
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </BreadcrumbWrapperStyle>
          <CardChartStyle>
            <Row gutter={25}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <SampleCardSix item={cardCounter[0]} />
              </Col>
            </Row>
            <Row gutter={25}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Cards>
                  <TotalExecutionsChart />
                </Cards>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Cards headless>
                  <ExecutionStateChart />
                </Cards>
              </Col>
            </Row>
            <Row gutter={25}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Cards headless>
                  <TotalCustomReportsChart />
                </Cards>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Cards headless>
                  <ReveneuChart />
                </Cards>
              </Col>
            </Row>
          </CardChartStyle>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default BotGoalsReportBotId;
