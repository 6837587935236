import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Table, Spin, Col, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

import { TableWrapper, UserTableStyleWrapper } from '../styled';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { alertModal } from '../../../components/modals/antd-modals';
import botScheduleActions from '../../../redux/botSchedule/actions';
import { AddUser } from '../user/style';
import { BotScheduleHelper } from '../../../helpers';

import { QUERY_BOT_SCHEDULE_FIND_ALL, QUERY_BOT_SCHEDULE_TOGGLE, QUERY_BOT_SCHEDULE_REMOVE } from '../../../queries';

const {
  botScheduleGetAllBegin,
  botScheduleGetAllSuccess,
  botScheduleGetAllError,
  botScheduleToggleBegin,
  botScheduleToggleSuccess,
  botScheduleToggleError,
  botScheduleRemoveBegin,
  botScheduleRemoveSuccess,
  botScheduleRemoveError,
  botScheduleDeactivateReDraw,
} = botScheduleActions;

const BotScheduleList = () => {
  const { t } = useTranslation();
  const { botId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { tasks, reDrawTable } = useSelector(state => state.botSchedule);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);

  const [botScheduleRemove] = useMutation(QUERY_BOT_SCHEDULE_REMOVE);
  const [botScheduleToggle] = useMutation(QUERY_BOT_SCHEDULE_TOGGLE);
  const [mounted, setMounted] = useState(false);
  const [state, setState] = useState({
    botScheduleData: [],
  });

  const queryArgs = {
    variables: {
      id: botId,
    },
    fetchPolicy: 'network-only',
  };

  const { loading, data, error } = useQuery(QUERY_BOT_SCHEDULE_FIND_ALL, queryArgs);

  const printScheduleByTask = useCallback(
    (schedule, type) =>
      schedule.length
        ? schedule.map((schedule, index) => (
            <ul key={index}>
              <li>{BotScheduleHelper.formatDate(type, schedule)}</li>
            </ul>
          ))
        : t('general.none'),
    [t],
  );

  const printLicensesByTask = useCallback(
    licenses =>
      licenses.length
        ? licenses.map((license, index) => (
            <ul key={index}>
              <li>{license.computerInfo?.name || t('general.none')}</li>
            </ul>
          ))
        : t('general.none'),
    [t],
  );

  const handleBotScheduleRemove = useCallback(
    id => {
      alertModal.confirm({
        title: t('botSchedule.confirmRemove'),
        onOk() {
          dispatch(botScheduleRemoveBegin());

          botScheduleRemove({
            variables: { botScheduleId: id },
          })
            .then(({ data }) => {
              const { success, code } = data.botScheduleRemove;

              if (success) {
                dispatch(botScheduleRemoveSuccess(id));

                notificationSuccess(t('botSchedule.successDeleted'));

                setTimeout(() => {
                  botScheduleDeactivateReDraw();
                }, 500);

                return;
              }

              dispatch(botScheduleRemoveError(code));

              notificationError(t(`codeResponse.${code}`));
            })
            .catch(e => {
              console.error('Bot Schedule Delete Error ', e);

              dispatch(botScheduleRemoveError());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [dispatch, botScheduleRemove, t],
  );

  const handleBotScheduleToggle = useCallback(
    task => {
      alertModal.confirm({
        title: t('botSchedule.confirmToggle'),
        onOk() {
          dispatch(botScheduleToggleBegin());

          botScheduleToggle({
            variables: { botScheduleId: task._id },
          })
            .then(({ data }) => {
              const { success, code } = data.botScheduleToggle;

              if (success) {
                dispatch(
                  botScheduleToggleSuccess({
                    ...task,
                    enabled: !task.enabled,
                  }),
                );

                notificationSuccess(t('botSchedule.successUpdated'));

                setTimeout(() => {
                  botScheduleDeactivateReDraw();
                }, 500);

                return;
              }

              dispatch(botScheduleToggleError(code));
              notificationError(t(`codeResponse.${code}`));
            })
            .catch(e => {
              console.error('Bot Schedule Toggle Error ', e);

              dispatch(botScheduleToggleError());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        okText: t('general.confirm'),
      });
    },
    [dispatch, botScheduleToggle, t],
  );

  const updateTable = useCallback(
    botScheduleData => {
      const dataSource = [];

      if (botScheduleData) {
        botScheduleData.map(task => {
          const { type, repeat, schedule, licenses, action, enabled, localDateTime, serverDateTime } = task;
          const textClassName = enabled ? 'active' : 'blocked';

          dataSource.push({
            key: task._id,
            type: t(`botSchedule.vars.types.${type}`),
            repeat: repeat ? t('botSchedule.indefinitely') : t('botSchedule.once'),
            schedule: printScheduleByTask(schedule, type),
            licenses: printLicensesByTask(licenses),
            action: t(`botSchedule.vars.actions.${action}`),
            localDateTime: BotScheduleHelper.formatDate('', localDateTime),
            serverDateTime: BotScheduleHelper.formatDate('', serverDateTime),
            status: (
              <span className={`status-text ${textClassName}`}>
                {enabled ? t('general.active') : t('general.inactive')}
              </span>
            ),
            actions: (
              <div className="table-actions">
                <>
                  <Tooltip title={t(`general.${enabled ? 'deactivate' : 'enable'}`)}>
                    <Button
                      className="btn-icon"
                      type="info"
                      shape="circle"
                      onClick={() => handleBotScheduleToggle(task)}
                    >
                      <FeatherIcon icon={enabled ? 'toggle-left' : 'toggle-right'} size={16} />
                    </Button>
                  </Tooltip>

                  <Tooltip title={t('general.buttons.edit')}>
                    <Button
                      className="btn-icon"
                      type="info"
                      shape="circle"
                      onClick={() => history.push(`/admin/bot/${botId}/schedule/${task._id}/edit`)}
                    >
                      <FeatherIcon icon="edit" size={16} />
                    </Button>
                  </Tooltip>

                  <Tooltip title={t('general.buttons.delete')}>
                    <Button
                      className="btn-icon"
                      type="danger"
                      shape="circle"
                      onClick={() => handleBotScheduleRemove(task._id)}
                    >
                      <FeatherIcon icon="trash-2" size={16} />
                    </Button>
                  </Tooltip>
                </>
              </div>
            ),
          });

          return true;
        });
      }

      setState({
        botScheduleData: dataSource,
      });
    },
    [t, handleBotScheduleToggle, handleBotScheduleRemove, printLicensesByTask, printScheduleByTask, botId, history],
  );

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotScheduleFindAll)) {
      history.push('/admin/bot');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted) {
      dispatch(botScheduleGetAllBegin());

      if (typeof data === 'undefined') {
        dispatch(botScheduleGetAllError(error));
        notificationError(t('codeResponse.MICROSERVICE_UNAVAILABLE'));

        history.push('/admin/bot');

        return;
      }

      const { success, data: botSchedule, code } = data.botScheduleFindAll;

      if (success) {
        dispatch(botScheduleGetAllSuccess(botSchedule));
        updateTable(botSchedule);
      } else {
        dispatch(botScheduleGetAllError(code));
      }

      setMounted(true);
    }
  }, [dispatch, data, loading, error, history, currentTenant, currentLoading, t, mounted, updateTable]);

  useEffect(() => {
    if (reDrawTable) {
      updateTable(tasks);
    }
  }, [reDrawTable, tasks, updateTable]);

  const columns = [
    {
      title: t('botSchedule.fields.type'),
      dataIndex: 'type',
      key: 'type',
      width: '10%',
    },
    {
      title: t('botSchedule.fields.repeat'),
      dataIndex: 'repeat',
      key: 'repeat',
      width: '5%',
    },
    {
      title: t('botSchedule.fields.schedule'),
      dataIndex: 'schedule',
      key: 'schedule',
      width: '20%',
    },
    {
      title: t('botSchedule.fields.licenses'),
      dataIndex: 'licenses',
      key: 'licenses',
      width: '20%',
    },
    {
      title: t('botSchedule.fields.localDateTime'),
      dataIndex: 'localDateTime',
      key: 'localDateTime',
      width: '10%',
    },
    {
      title: t('botSchedule.fields.serverDateTime'),
      dataIndex: 'serverDateTime',
      key: 'serverDateTime',
      width: '10%',
    },
    {
      title: t('botSchedule.fields.action'),
      dataIndex: 'action',
      key: 'action',
      width: '10%',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      sorter: (a, b) => {
        if (a.statusValue) return 1;
        if (b.statusValue) return -1;
        return 0;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
    },
  ];

  if (mounted) {
    return (
      <Col xs={24}>
        <AddUser>
          <div className="table-bordered leaderboard-table table-responsive">
            <UserTableStyleWrapper>
              <TableWrapper className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={state.botScheduleData}
                  pagination={{
                    defaultPageSize: 10,
                    total: state.botScheduleData?.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
                  }}
                />
              </TableWrapper>
            </UserTableStyleWrapper>
          </div>
        </AddUser>
      </Col>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default BotScheduleList;
