import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const BotScheduleFormData = () => {
  const { task } = useSelector(state => state.botSchedule);

  const [formValues, setFormValues] = useState({
    type: task?.type || '',
    action: task?.action || '',
    repeat: task?.repeat || '',
    schedule: task?.schedule || [],
    licenses: task?.licenses || [],
  });

  useEffect(() => {
    if (typeof task !== 'undefined') {
      setFormValues(task);
    }
  }, [task]);

  return [formValues, setFormValues];
};

export default BotScheduleFormData;
