const actions = {
  TENANT_FIND_ONE_BEGIN: 'TENANT_FIND_ONE_BEGIN',
  TENANT_FIND_ONE_SUCCESS: 'TENANT_FIND_ONE_SUCCESS',
  TENANT_FIND_ONE_ERROR: 'TENANT_FIND_ONE_ERROR',

  tenantFindOneBegin: () => {
    return {
      type: actions.TENANT_FIND_ONE_BEGIN,
    };
  },

  tenantFindOneSuccess: data => {
    return {
      type: actions.TENANT_FIND_ONE_SUCCESS,
      data,
    };
  },

  tenantFindOneError: error => {
    return {
      type: actions.TENANT_FIND_ONE_ERROR,
      error,
    };
  },
};

export default actions;
