import React, { useEffect, useState } from 'react';
import { Badge } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import { useTranslation } from 'react-i18next';
import { QUERY_WEB_NOTIFICATION, MUTATTION_WEB_NOTIFICATION_UPDATA } from '../../../queries/webnotification/webnotification.queries';
import { useQuery, useMutation } from '@apollo/client';
import actions from '../../../redux/webNotification/actions';
import { useSelector, useDispatch } from 'react-redux';
import { Empty } from 'antd';
import { Modal } from '../../modals/antd-modals';
import Content from '../../email/mail-web-notification/Content';
//import { Button } from '../../buttons/buttons';


const WebNotificationBox = () => {

  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const state = useSelector(state => state.webNotification?.data);
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mutationNotify] = useMutation(MUTATTION_WEB_NOTIFICATION_UPDATA);
  const [descriptionState, setDescriptionState] = useState([]);
  const { data } = useQuery(QUERY_WEB_NOTIFICATION);
  const showModal = item => {

    setIsModalVisible(true);
    setDescriptionState([item._id, item.message, item.createdAt, item.type])
    dispatch(actions.webNotificationUpdateReadedSuccess(item))
    mutationNotify({ variables: { id: item._id } })
  };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(actions.webNotificationFindAllSuccess(data?.webNotificationFindAll?.data));
    // eslint-disable-next-line
  }, data);

  const { rtl } = useSelector(state => {
    return {
      rtl: state.changeLayoutMode.rtlData,
    };
  });

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  const renderView = ({ style, ...props }) => {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  const { t } = useTranslation();

  const content = (

    <AtbdTopDropdwon darkMode={darkMode} className="atbd-top-dropdwon">
      <div className={darkMode ? 'ant-popover-inner-dark' : 'ant-popover-inner-light'}>
        <Heading as="h5" className={!darkMode ? "atbd-top-dropdwon__title" : "atbd-top-dropdwon__title_dark"} >
          <span className="title-text" style={{ 'padding-right':'10px'}}>{t(`notification.webNotification.title`)  }</span>
          <Badge className="badge-success" count={ state?.filter(item => !item.readed).length ?? 0} />
        </Heading>

        <Scrollbars
          autoHeight
          autoHide
          renderThumbVertical={renderThumb}
          renderView={renderView}
          renderTrackVertical={renderTrackVertical}
        >
          <ul className="atbd-top-dropdwon__nav notification-list" style={{ height: 'auto' }}>
            {(state?.length <= 0 || state === null) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {state && state.slice(-5).map(data => (<li key={data._id}>
              <Link to='#' onClick={() => showModal(data)}>
                <div className="atbd-top-dropdwon__content notifications">
                  <div className={data.type === "Success" ? "notification-icon bg-success" : "notification-icon bg-secondary"}>
                    <FeatherIcon icon={data.type === "Success" ? "check" : "x"} />
                  </div>
                  <div className="notification-content d-flex">
                    <div className="notification-text">
                      <Heading as="h5">
                        <span style={{ color: [data.readed === true ? "#adb4d2" : "#4bb3d8"] }}>{`${data.message.substring(0, 60)}...`}</span>
                      </Heading>
                      <p style={{'color':'#adb4d2'}}>{new Date(data.createdAt).toDateString()}</p>
                    </div>
                    {/* <div className="notification-status">
                  <Button   className="btn-icon" style={mystyles} type="error" shape="circle" onClick={() => deleteWebNotification(data)}>
                    <FeatherIcon style={{color:"#FF69A5"}} icon="trash-2" size={18} />
                  </Button> 
                </div>*/}
                  </div>
                </div>
              </Link>
            </li>))}
          </ul>
        </Scrollbars>
        <Link className={!darkMode ? "btn-seeAll" : "btn-seeAll-dark4bb3d8"} to="#" onClick={() => setVisible(true)} >
          <div>{t(`notification.webNotification.seeallnotifications`)}</div>
        </Link>
      </div>
    </AtbdTopDropdwon>
  );

  return (
    <>
      {/* <Modal
        type={'primary'}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={950}
      >
        <Content email={state} />
      </Modal>
      <div className="notification">
        <Popover placement="bottomLeft" content={content} action="click">
          <Badge dot={state?.find(item => !item.readed)} offset={[-8, -5]}>
            <Link to="#" className="head-example">
              <FeatherIcon icon="bell" size={20} />
            </Link>
          </Badge>
        </Popover>
      </div>
      <Modal visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} type={'primary'} footer={null}>
        <div className='body-tittle'>
          <div className='atbd-top-dropdwon' style={{ width: "40px" }}><div className='atbd-top-dropdwon__content'>
            <div className={descriptionState[3] === "Success" ? "notification-icon bg-success" : "notification-icon bg-secondary"}>
              <FeatherIcon icon={descriptionState[3] === "Success" ? "check" : "x"} />
            </div>
          </div>
          </div>
          <p>{descriptionState[3]}</p>
        </div>
        <br />
        <p>{descriptionState[1]}</p>
        <p style={{ color: "#adb4d2" }}>{new Date(descriptionState[2]).toDateString()}</p>
      </Modal> */}
    </>
  );
};

export default WebNotificationBox;
