const actions = {
  NODE_SELECTED_BEGIN: 'NODE_SELECTED_BEGIN',
  NODE_SELECTED_LOADED: 'NODE_SELECTED_LOADED',

  NODE_LIST_BEGIN: 'NODE_LIST_BEGIN',
  NODE_LIST_LOADED: 'NODE_LIST_LOADED',

  NODE_PROPERTIES_REMOVE: 'NODE_PROPERTIES_REMOVE',
  NODE_PROPERTIES_UPDATE: 'NODE_PROPERTIES_UPDATE',

  NODE_LIST_PROPERTIES_LOADED: 'NODE_LIST_PROPERTIES_LOADED',

  nodeSelectedBegin: () => {
    return {
      type: actions.NODE_SELECTED_BEGIN,
    };
  },

  nodeSelectedLoaded: data => {
    return {
      type: actions.NODE_SELECTED_LOADED,
      data,
    };
  },

  nodeListBegin: () => {
    return {
      type: actions.NODE_LIST_BEGIN,
    };
  },

  nodeListLoaded: data => {
    return {
      type: actions.NODE_LIST_LOADED,
      data,
    };
  },

  nodePropertiesRemove: data => {
    return {
      type: actions.NODE_PROPERTIES_REMOVE,
      data,
    };
  },

  nodePropertiesUpdate: data => {
    return {
      type: actions.NODE_PROPERTIES_UPDATE,
      data,
    };
  },

  nodeListPropertiesLoaded: data => {
    return {
      type: actions.NODE_LIST_PROPERTIES_LOADED,
      data,
    };
  },
};

export default actions;
