import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import NotFound from '../../utils/404';
import MicroservicesManagement from '../../pages/admin/microservices';

const MicroservicesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={MicroservicesManagement} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default MicroservicesRoute;
