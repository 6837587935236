import actions from './actions';

const { BOT_GOALS_FIND_SUCCESS, BOT_GOALS_FIND_BEGIN, BOT_GOALS_FIND_ERROR } = actions;

const initialState = {
  data: {},
  loading: false,
  error: false,
};

const botGoalsReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case BOT_GOALS_FIND_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BOT_GOALS_FIND_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data ?? {},
      };
    case BOT_GOALS_FIND_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export default botGoalsReducer;
