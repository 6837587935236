import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Spin, Breadcrumb } from 'antd';
import { Modal } from '../../../components/modals/antd-modals';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, ExportStyleWrap } from '../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';
import actions from '../../../redux/tenant/actions';
import { QUERY_TENANT_FIND_ONE } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const { tenantFindOneBegin, tenantFindOneSuccess, tenantFindOneError } = actions;

const TenantReport = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadingTenant = useSelector(state => state.tenant.loading);
  const tenant = useSelector(state => state.tenant.data);
  const history = useHistory();
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [mounted, setMounted] = useState(false);
  const [state, setState] = useState({
    isModalVisible: false,
    fileName: 'Tenant Report',
  });
  const { loading, data, error } = useQuery(QUERY_TENANT_FIND_ONE, {
    fetchPolicy: 'network-only',
  });

  const csvData = [['Tenant Json Object'], [JSON.stringify(tenant)]];

  const handleModal = () => {
    setState({
      ...state,
      isModalVisible: !state.isModalVisible,
    });
  };

  const { isModalVisible } = state;

  const updateFileName = e => {
    setState({
      ...state,
      fileName: e.target.value,
    });
  };

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.TenantFindOne)) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }
    if (!loading && !mounted && data) {
      dispatch(tenantFindOneBegin());
      if (data.me.success) {
        dispatch(tenantFindOneSuccess(data.me.data.currentTenant.tenant));
      } else {
        dispatch(tenantFindOneError(data.me.code));
        history.push('/admin');
        notificationError(t(`codeResponse.${data.me.code}`));
      }
      setMounted(true);
    }
  }, [dispatch, data, loading, error, history, currentTenant, currentLoading, mounted, t]);

  const { fileName } = state;
  if (!loadingTenant && mounted) {
    return (
      <>
        <PageHeader title={t('tenant.report')} />
        <Main darkMode={darkMode}>
          <BreadcrumbWrapperStyle darkMode={darkMode}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to={`/admin/`}>
                  <HomeOutlined />
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="#">{t('tenant.report')}</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </BreadcrumbWrapperStyle>
          <Row gutter={25}>
            <Col sm={24} xs={24}>
              <ExportStyleWrap>
                <Cards>
                  <div className="sDash_export-box">
                    <>
                      <Button className="btn-export text-center" onClick={handleModal} type="primary">
                        {t('general.export')}
                      </Button>
                      <Modal
                        darkMode={darkMode}
                        type={'primary'}
                        title={t('general.exportFile')}
                        wrapClassName="sDash_export-wrap"
                        visible={isModalVisible}
                        footer={null}
                        onCancel={handleModal}
                      >
                        <Form name="export">
                          <Form.Item name="f_name">
                            <Input placeholder={t('properties.fileName')} value={fileName} onChange={updateFileName} />
                          </Form.Item>
                          <div className="sDash-button-grp">
                            <CSVLink filename={`${fileName}.csv`} data={csvData}>
                              <Button onClick={handleModal} className="btn-export" type="primary">
                                {t('general.export')}
                              </Button>
                            </CSVLink>

                            <Button onClick={handleModal} size="default" type="white" outlined>
                              {t('general.cancel')}
                            </Button>
                          </div>
                        </Form>
                      </Modal>
                    </>
                  </div>
                </Cards>
              </ExportStyleWrap>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default TenantReport;
