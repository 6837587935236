import { gql } from "@apollo/client"

export const MUTATION_MAIL_NOTIFICATION_READED = gql`
mutation mailNotificationLogUpdateReaded ($_id:String!){
    mailNotificationLogUpdateReaded(
      notifyUpdateInput: { _id: $_id, readed: true }
    ) {
      success
      data {
        _id,
        readed,
        userTarget,
        userSourse,
        subject,
        body,
        readed,
        enabled
        createdAt,
      }
    }
  }
`

export const QUERY_MAIL_NOTIFICATION_FIND_ALL = gql`
  query mailNotificationLogFindAll {
    mailNotificationLogFindAll{
      success,
      data {
        _id,
        readed,
        userTarget,
        userSourse,
        subject,
        body,
        readed,
        enabled,
        createdAt,
      }
    
    }
  }
  `

export const MUTATION_MAIL_NOTIFICATION_REMOVE = gql`
mutation mailNotificationRemoveLog($id:String!){
mailNotificationRemoveLog(id:$id){success}}`

