/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';

import { UserPermissions } from '../../utils/enums/permissions.enum';
import { notificationError } from '../../components/utilities/notification';

import orchestrator from '../../redux/orchestrator/actions';
import { QUERY_ORCHESTRATOR_FIND_ONE } from '../../queries';

const {
  orchestratorFindOneBegin,
  orchestratorFindOneError,
  orchestratorFindOneSuccess,
  orchestratorFindOneReset,
} = orchestrator;

const OrchestratorForm = (currentTenant, currentLoading, updateFormData, createOrchestrator) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { orchestratorId } = useParams();

  const [mounted, setMounted] = useState(createOrchestrator);
  const [skipFindOneCall, setSkipFindOneCall] = useState(createOrchestrator);
  const formIsLoading = useMemo(() => currentLoading, [currentLoading]);

  const permissionToCheck = createOrchestrator
    ? UserPermissions.BotOrchestratorCreate
    : UserPermissions.BotScheduleUpdate;

  const userDoesNotHavePermission = useMemo(() => !currentTenant?.userPermissions?.includes(permissionToCheck), [
    currentTenant,
  ]);

  const { data, error, loading } = useQuery(QUERY_ORCHESTRATOR_FIND_ONE, {
    skip: skipFindOneCall,
    variables: { orchestratorId },
    fetchPolicy: 'network-only',
  });

  const setOrchestratorIntoForm = useCallback(orches => {
    const { _id, name, alias, model, enabled, description } = orches;

    return {
      _id,
      name,
      alias,
      model,
      enabled,
      description,
    };
  }, []);

  // Fetch the orchestrator to update
  const fetchOrchestratorToUpdate = useCallback(() => {
    const redirectPath = '/admin/orchestrator';

    if (createOrchestrator) {
      return;
    }

    if (!formIsLoading && userDoesNotHavePermission) {
      history.push(redirectPath);
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && typeof data === 'undefined') {
      dispatch(orchestratorFindOneError(error));
      notificationError(t('codeResponse.MICROSERVICE_UNAVAILABLE'));

      history.push(redirectPath);

      return;
    }

    if (!loading && !mounted) {
      dispatch(orchestratorFindOneBegin());

      const { success, data: dataOrchestrator } = data.orchestratorFindOne;

      if (success) {
        const formattedOrchestrator = setOrchestratorIntoForm(dataOrchestrator);
        updateFormData(formattedOrchestrator);
        dispatch(orchestratorFindOneSuccess(formattedOrchestrator));
      } else {
        dispatch(orchestratorFindOneError(t('codeResponse.ORCHESTRATOR_NOT_FOUND')));
        notificationError(t('codeResponse.ORCHESTRATOR_NOT_FOUND'));

        history.push(redirectPath);
      }

      setMounted(true);
    }
  }, [
    t,
    data,
    error,
    history,
    loading,
    dispatch,
    orchestratorId,
    updateFormData,
    createOrchestrator,
    setOrchestratorIntoForm,
  ]);

  useEffect(() => {
    fetchOrchestratorToUpdate();
  }, [createOrchestrator, fetchOrchestratorToUpdate]);

  // Clear the orchestrator on component destroy
  useEffect(() => {
    if (typeof orchestratorId !== 'undefined') {
      setSkipFindOneCall(false);
    }

    return () => {
      setSkipFindOneCall(true);
      dispatch(orchestratorFindOneReset());
    };
  }, [dispatch, orchestratorId]);

  return [mounted];
};

export default OrchestratorForm;
