const actions = {
  BOT_GET_ALL_BEGIN: 'BOT_GET_ALL_BEGIN',
  BOT_GET_ALL_SUCCESS: 'BOT_GET_ALL_SUCCESS',
  BOT_GET_ALL_ERROR: 'BOT_GET_ALL_ERROR',
  BOT_GET_ONE_BEGIN: 'BOT_GET_ONE_BEGIN',
  BOT_GET_ONE_SUCCESS: 'BOT_GET_ONE_SUCCESS',
  BOT_GET_ONE_ERROR: 'BOT_GET_ONE_ERROR',
  BOT_CREATE_BEGIN: 'BOT_CREATE_BEGIN',
  BOT_CREATE_SUCCESS: 'BOT_CREATE_SUCCESS',
  BOT_CREATE_ERROR: 'BOT_CREATE_ERROR',
  BOT_REMOVE_BEGIN: 'BOT_REMOVE_BEGIN',
  BOT_REMOVE_SUCCESS: 'BOT_REMOVE_SUCCESS',
  BOT_REMOVE_ERROR: 'BOT_REMOVE_ERROR',
  BOT_UPDATE_BEGIN: 'BOT_UPDATE_BEGIN',
  BOT_UPDATE_SUCCESS: 'BOT_UPDATE_SUCCESS',
  BOT_UPDATE_ERROR: 'BOT_UPDATE_ERROR',

  botGetAllBegin: () => {
    return {
      type: actions.BOT_GET_ALL_BEGIN,
    };
  },

  botGetAllSuccess: data => {
    return {
      type: actions.BOT_GET_ALL_SUCCESS,
      data,
    };
  },

  botGetAllError: error => {
    return {
      type: actions.BOT_GET_ALL_ERROR,
      error,
    };
  },

  botGetOneBegin: () => {
    return {
      type: actions.BOT_GET_ONE_BEGIN,
    };
  },

  botGetOneSuccess: data => {
    return {
      type: actions.BOT_GET_ONE_SUCCESS,
      data,
    };
  },

  botGetOneError: error => {
    return {
      type: actions.BOT_GET_ONE_ERROR,
      error,
    };
  },

  botCreateBegin: () => {
    return {
      type: actions.BOT_CREATE_BEGIN,
    };
  },

  botCreateSuccess: data => {
    return {
      type: actions.BOT_CREATE_SUCCESS,
      data,
    };
  },

  botCreateError: error => {
    return {
      type: actions.BOT_CREATE_ERROR,
      error,
    };
  },

  botRemoveBegin: () => {
    return {
      type: actions.BOT_REMOVE_BEGIN,
    };
  },

  botRemoveSuccess: data => {
    return {
      type: actions.BOT_REMOVE_SUCCESS,
      data,
    };
  },

  botRemoveError: error => {
    return {
      type: actions.BOT_REMOVE_ERROR,
      error,
    };
  },

  botUpdateBegin: () => {
    return {
      type: actions.BOT_UPDATE_BEGIN,
    };
  },

  botUpdateSuccess: data => {
    return {
      type: actions.BOT_UPDATE_SUCCESS,
      data,
    };
  },

  botUpdateError: error => {
    return {
      type: actions.BOT_UPDATE_ERROR,
      error,
    };
  },
};

export default actions;
