import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import FilesManagement from '../../pages/admin/file';
import FileCreate from '../../pages/admin/file/FileCreate';
import NotFound from '../../utils/404';

const FilesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/create`} component={FileCreate} />
      <Route exact path={`${path}`} component={FilesManagement} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default FilesRoute;
