import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import { ApolloProvider } from '@apollo/client';
import store from './redux/store';
import config from './config/config';
import Admin from './routes/admin';
import Auth from './routes/auth';
import { useApollo } from './libs/Apollo';
import GuestRoute from './components/utilities/guestRoute';
import ProtectedRoute from './components/utilities/protectedRoute';
import './static/css/style.css';

const { theme } = config;

/**
 * Route manager of the project
 */
const ProviderConfig = () => {
  const { rtl, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.changeLayoutMode.data,
      rtl: state.changeLayoutMode.rtlData,
      topMenu: state.changeLayoutMode.topMenu,
    };
  });

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <BrowserRouter>
          <Switch>
            <ProtectedRoute path="/admin" component={Admin} />
            <GuestRoute path="/" component={Auth} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </ConfigProvider>
  );
};
function App() {
  const apolloClient = useApollo();
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ProviderConfig />
      </Provider>
    </ApolloProvider>
  );
}

export default hot(App);
