
import { notification } from 'antd';
import config from '../../config/config';
import { initApolloClient } from '../../libs/Apollo';
import { QUERY_WEB_NOTIFICATION_CREATE } from '../../queries/webnotification/webnotification.queries';
import actions from '../../redux/webNotification/actions';
import { WebNotificationType } from '../../utils/enums/types.enum';
import store from '../../redux/store';

const { theme } = config;
const notificationSuccess =  data => {

  notification.success({
    message: data,
    description: '',
    style: { backgroundColor: theme['notification-success-color'] },
  });


  initApolloClient.mutate({ mutation: QUERY_WEB_NOTIFICATION_CREATE, variables: { message: data, type: WebNotificationType.Success } }).then(res => {
    const {
      webNotificationCreate
    } = res.data;
    
  store.dispatch(actions.webNotificationUpdate(webNotificationCreate.data))
  });

 
};

const notificationError =  async data => {
  notification.error({
    message: 'Error',
    description: data,
    style: { backgroundColor: theme['notification-error-color'] },

  });
  initApolloClient.mutate({ mutation: QUERY_WEB_NOTIFICATION_CREATE, variables: { message: data, type: WebNotificationType.Error } }).then(res => {
    const {
      webNotificationCreate
    } = res.data;
  store.dispatch(actions.webNotificationUpdate(webNotificationCreate.data))
  });

 
};



export { notificationSuccess, notificationError };
