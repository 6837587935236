import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useParams, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spin, Form, Input, Switch, Breadcrumb } from 'antd';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Button } from '../../../components/buttons/buttons';
import { Main } from '../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

import { useOrchestratorForm, useOrchestratorFormData } from '../../../hooks/orchestrator';

import { QUERY_ORCHESTRATOR_CREATE, QUERY_ORCHESTRATOR_UPDATE } from '../../../queries';

import orchestratorActions from '../../../redux/orchestrator/actions';

const {
  orchestratorCreateBegin,
  orchestratorCreateSuccess,
  orchestratorCreateError,
  orchestratorUpdateBegin,
  orchestratorUpdateSuccess,
  orchestratorUpdateError,
} = orchestratorActions;

const OrchestratorForm = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { orchestratorId } = useParams();

  const { loading: currentLoading, currentTenant } = useSelector(state => state.currentUserTenant);
  const currentUser = useSelector(state => state.currentUserTenant.currentUser);
  const [tenant] = useState(currentTenant?.tenant._id !== null ? currentTenant?.tenant._id : '');

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [formAction, setFormAction] = useState(orchestratorId?.length ? 'edit' : 'create');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [formValues, setFormValues] = useOrchestratorFormData();

  const redirectToIndex = () => history.push('/admin/orchestrator');

  // Graphql actions
  const [orchestratorCreate] = useMutation(QUERY_ORCHESTRATOR_CREATE);
  const [orchestratorUpdate] = useMutation(QUERY_ORCHESTRATOR_UPDATE);

  const updateFormData = useCallback(orchestrator => {
    if (typeof orchestrator !== 'undefined') {
      const { _id, name, alias, model, enabled, description } = orchestrator;

      const formData = {
        _id,
        name,
        alias,
        model,
        enabled,
        description,
      };

      setFormValues(formData);
      form.setFieldsValue(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch the orchestrator to update if it's necessary
  const [mounted] = useOrchestratorForm(currentTenant, currentLoading, updateFormData, formAction === 'create');

  const alertSuccess = 'Success';
  const alertError = 'Error';

  const showAlertResult = (msg, type) => {
    if (type === alertError) {
      notificationError(msg);

      return;
    }

    notificationSuccess(msg);
  };

  // Set the form action to create or update according the scheduleId value
  useEffect(() => {
    if (typeof orchestratorId !== 'undefined') {
      setFormAction('edit');
    }
  }, [orchestratorId]);

  const handleChange = e => {
    if (e.target.props !== undefined && e.target.props.name === 'description') {
      e.target.name = 'description';
    }

    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = e => {
    setFormValues({
      ...formValues,
      enabled: e,
    });
  };

  const handleSubmit = async () => {
    setFormSubmitted(true);

    const payload = {
      ...formValues,
      tenant,
      user: currentUser?._id,
    };

    if (formAction === 'create') {
      storeOrchestrator(payload);
    } else {
      updatedOrchestrator({
        ...payload,
        _id: formValues._id,
      });
    }
  };

  const storeOrchestrator = payload => {
    dispatch(orchestratorCreateBegin());

    orchestratorCreate({
      variables: {
        orchestratorCreateInput: payload,
      },
    })
      .then(({ data }) => {
        setFormSubmitted(false);

        const { data: orchestrator, code: responseCode, success } = data.orchestratorCreate;

        if (success) {
          dispatch(orchestratorCreateSuccess(orchestrator));
          showAlertResult(t('orchestrator.successCreated'), alertSuccess);

          return redirectToIndex();
        }

        dispatch(orchestratorCreateError(responseCode));
        showAlertResult(t(`codeResponse.${responseCode}`), 'Error');
      })
      .catch(e => {
        console.error('Orchestrator Create Error ', e);

        setFormSubmitted(false);
        dispatch(orchestratorCreateError(e));
        showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
      });
  };

  const updatedOrchestrator = payload => {
    dispatch(orchestratorUpdateBegin());

    orchestratorUpdate({
      variables: {
        orchestratorUpdateInput: payload,
      },
    })
      .then(({ data }) => {
        setFormSubmitted(false);

        const { data: orchestrator, code: responseCode, success } = data.orchestratorUpdate;

        if (success) {
          dispatch(orchestratorUpdateSuccess(orchestrator));
          showAlertResult(t('orchestrator.successUpdated'), alertSuccess);

          return redirectToIndex();
        }

        dispatch(orchestratorUpdateError(responseCode));
      })
      .catch(e => {
        console.error('Orchestrator Update Error ', e);

        setFormSubmitted(false);
        dispatch(orchestratorCreateError(e));
        showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
      });
  };

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1'),
    types: {
      email: t('type.email')
    }
  };
  /* eslint-enable */

  const modelRegex = new RegExp(/^([a-z,_])+$/, 'gi');

  if (mounted) {
    return (
      <>
        <PageHeader ghost title={t(`orchestrator.${formAction}`)} />
        <Main darkMode={darkMode}>
          <BreadcrumbWrapperStyle darkMode={darkMode}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to={`/admin/`}>
                  <HomeOutlined />
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to={`/admin/orchestrator`}>
                  <span> {t('orchestrator.management')}</span></NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="#">{t(`orchestrator.${formAction}`)}</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </BreadcrumbWrapperStyle>
          <Row gutter={15}>
            <Col xs={24}>
              <Cards>
                <Form
                  form={form}
                  name="orchestratorForm"
                  size="middle"
                  layout="vertical"
                  onFinish={handleSubmit}
                  autoComplete="off"
                  validateMessages={validateMessages}
                  scrollToFirstError
                >
                  <Row gutter={30}>
                    {/* Orchestrator Name */}
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="name"
                        rules={[{ required: true }]}
                        label={t('orchestrator.fields.name')}
                        initialValue={formValues.name}
                      >
                        <Input
                          name="name"
                          value={formValues.name}
                          onChange={handleChange}
                          placeholder={t('orchestrator.fields.name')}
                        />
                      </Form.Item>
                    </Col>

                    {/* Orchestrator Alias */}
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="alias"
                        rules={[{ required: true }]}
                        label={t('orchestrator.fields.alias')}
                        initialValue={formValues.alias}
                      >
                        <Input
                          name="alias"
                          value={formValues.alias}
                          disabled={formAction === 'edit'}
                          onChange={handleChange}
                          placeholder={t('orchestrator.fields.alias')}
                        />
                      </Form.Item>
                    </Col>

                    {/* Orchestrator Model */}
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="model"
                        label={t('orchestrator.fields.model')}
                        rules={[
                          {
                            required: true,
                          },
                          {
                            pattern: modelRegex,
                            message: t('orchestrator.regexp.invalid_model'),
                          },
                        ]}
                        initialValue={formValues.model}
                      >
                        <Input
                          name="model"
                          value={formValues.model}
                          onChange={handleChange}
                          placeholder="name,value,description"
                        />
                      </Form.Item>
                    </Col>

                    {/* Orchestrator Description */}
                    <Col xs={24}>
                      <Form.Item
                        name="description"
                        rules={[{ required: false }]}
                        label={t('orchestrator.fields.description')}
                        initialValue={formValues.description}
                      >
                        <TextArea
                          name="description"
                          value={formValues.description}
                          onChange={handleChange}
                          className="sDash_unresizable"
                          placeholder={t('orchestrator.fields.description')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Orchestrator Is Active */}
                  <Row gutter={30}>
                    <Col xs={24}>
                      <Form.Item
                        name="enabled"
                        label={t('orchestrator.fields.enabled')}
                        initialValue={formValues.enabled}
                      >
                        <Switch
                          checked={formValues.enabled}
                          onChange={handleSwitch}
                          checkedChildren={t('general.active')}
                          unCheckedChildren={t('general.inactive')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Action Buttons */}
                  <Row gutter={30}>
                    {/* Submit Button */}
                    <Col md={12} xs={24}>
                      <Form.Item>
                        <Button
                          key="2"
                          type="primary"
                          style={{ height: '44px' }}
                          loading={formSubmitted}
                          htmlType="submit"
                          className="pull-left"
                        >
                          {formSubmitted ? t('loading.1') : t(`general.${formAction}`)}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default OrchestratorForm;
