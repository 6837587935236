import React, { useCallback, useState } from 'react';
import { Button, Col, Form, Input, Row, Table, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { TableWrapper } from '../../../styled';
import { notificationError, notificationSuccess } from '../../../../../components/utilities/notification';
import { QUERY_BOT_UPDATE_CV, QUERY_FILE_CREATE, QUERY_FILE_DOWNLOAD, QUERY_FILE_REMOVE } from '../../../../../queries';
import fileActions from '../../../../../redux/file/actions';
import { alertModal } from '../../../../../components/modals/antd-modals';
import { formatBytes } from '../../../../../components/utilities/utilities';
import { UserPermissions } from '../../../../../utils/enums/permissions.enum';

const {
  fileDownloadBegin,
  fileDownloadSuccess,
  fileDownloadError,
  fileRemoveBegin,
  fileRemoveSuccess,
  fileRemoveError,
  fileCreateBegin,
  fileCreateSuccess,
  fileCreateError,
} = fileActions;

const { TextArea } = Input;

const CvFilesTable = ({ cv }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const loadingFiles = useSelector(state => state.file.loading);
  const history = useHistory();
  const { botId } = useParams();
  const [form] = Form.useForm();
  const [fileDownload] = useMutation(QUERY_FILE_DOWNLOAD);
  const [fileRemove] = useMutation(QUERY_FILE_REMOVE);
  const [fileCreate] = useMutation(QUERY_FILE_CREATE);
  const [updateCv] = useMutation(QUERY_BOT_UPDATE_CV);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [state, setState] = useState({
    form: {
      description: '',
      file: '',
    },
    filesDataTable: [],
    consumedSpace: 0,
    fileList: [],
  });
  const [datafile, setDataFile] = useState({
    size: 0,
    base64: '',
    name: '',
    error: false,
    errorMsg: '',
  });
  const maxSizeAllowed = 2097152;
  const mimeTypeAllowed =
    'application/x-zip-compressed, application/zip, application/x-rar-compressed, application/x-msdownload, application/x-dosexec, image/jpeg, image/png, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const alertSuccess = 'Success';
  const alertError = 'Error';

  const showAlertResult = (msg, type) => {
    if (type === alertError) notificationError(msg);
    else notificationSuccess(msg);
  };

  const updateForm = useCallback(
    botFound => {
      form.setFieldsValue({
        description: botFound.description,
        file: botFound.file,
      });

      setState(x => ({
        ...x,
        fileList: [],
        form: {
          ...x.form,
          description: botFound.description,
          file: botFound.file,
        },
      }));
    },
    [form],
  );

  const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // eslint-disable-next-line func-names
    reader.onload = function() {
      cb(reader.result);
    };
    // eslint-disable-next-line func-names
    reader.onerror = function(error) {
      // eslint-disable-next-line no-console
      console.log('Get base 64 Error: ', error);
      setDataFile({
        ...datafile,
        error: true,
      });
    };
  };

  const beforeUpload = file => {
    let errorMsg = '';

    const errorTypeFile = mimeTypeAllowed.indexOf(file.type) > -1;
    if (!errorTypeFile) {
      errorMsg = t('labels.bussiness.rpa.files_shared.extension_not_allowed');
      showAlertResult(errorMsg, alertError);
    }

    const errorMaxSize = file.size <= maxSizeAllowed;
    if (!errorMaxSize) {
      errorMsg = t('labels.bussiness.rpa.files_shared.error_max_size', { value: formatBytes(maxSizeAllowed) });
      showAlertResult(errorMsg, alertError);
    }

    if (!errorMaxSize || !errorTypeFile) {
      setDataFile({
        ...datafile,
        error: true,
        errorMsg,
      });
    } else {
      setDataFile({
        ...datafile,
        error: false,
        errorMsg: '',
      });
    }

    return false;
  };
  const handleChangeFile = info => {
    if (!datafile.error) {
      getBase64(info.file, imageUrl =>
        setDataFile({
          ...datafile,
          size: info.file.size,
          base64: imageUrl,
          name: info.file.name,
          error: false,
        }),
      );
    } else {
      setDataFile({
        ...datafile,
        base64: '',
        name: '',
      });
    }

    // console.log(info)
    setState({
      ...state,
      fileList: [...info.fileList],
    });
  };

  const handleDownloadFile = useCallback(
    id => {
      dispatch(fileDownloadBegin());

      fileDownload({
        variables: { id },
      })
        .then(({ data }) => {
          if (data.fileDownload.success) {
            dispatch(fileDownloadSuccess());
            window.open(data.fileDownload.data, '_blank');
          } else {
            dispatch(fileDownloadError(data.fileDownload.code));
            notificationError(t(`codeResponse.${data.fileDownload.code}`));
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('File Download Error ', e);
          dispatch(fileDownloadError());

          notificationError(t('codeResponse.UNEXPECTED_ERROR'));
        });
    },
    [dispatch, fileDownload, t],
  );

  const deleteFile = useCallback(
    id => {
      alertModal.confirm({
        title: t('labels.bussiness.rpa.files_shared.confirm_delete'),
        onOk() {
          dispatch(fileRemoveBegin());
          // dispatch(currentTenantUpdateBegin());

          fileRemove({
            variables: { id },
          })
            .then(({ data }) => {
              if (data.fileRemove.success) {
                dispatch(fileRemoveSuccess(data.fileRemove.data._id));
                // dispatch(currentTenantUpdateSuccess({ ...currentTenant, tenant: data.fileRemove.data.tenant }));

                // Delete item from table
                // setState(prevState => {
                //   const filesDataTable = prevState.filesDataTable.filter(item => item.key !== id);
                //   const consumedSpace = prevState.consumedSpace - size;
                //   return { form: prevState.form, filesDataTable, consumedSpace };
                // });

                const newFiles = cv.files.filter(item => item._id !== id).map(item => item._id);
                const { __typename, _id, ...actualCv } = cv;

                updateCv({
                  variables: {
                    data: {
                      bot: botId,
                      ...actualCv,
                      files: newFiles,
                      image: actualCv.image._id,
                    },
                  },
                })
                  .then(({ data: updateCVData }) => {
                    if (updateCVData.botUpdateCv.success) {
                      notificationSuccess(t('labels.bussiness.rpa.bots.table.success_edited'));
                    } else {
                      notificationError(t(`codeResponse.${updateCVData.botUpdateCv.code}`));
                    }
                  })
                  .catch(e => {
                    // eslint-disable-next-line no-console
                    console.log('Bot Edit Error ', e);
                    notificationError(t('codeResponse.UNEXPECTED_ERROR'));
                  });

                // Notify result
                notificationSuccess(t('labels.bussiness.rpa.files_shared.success_deleted'));
              } else {
                dispatch(fileRemoveError(data.fileRemove.code));
                // dispatch(currentTenantUpdateErr());

                // Notify result
                notificationError(t(`codeResponse.${data.fileRemove.code}`));
              }
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.log('Remove File Error ', e);
              dispatch(fileRemoveError());
              // dispatch(currentTenantUpdateErr());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, fileRemove, t],
  );

  const showFormUpload = () => {
    setIsFormVisible(!isFormVisible);
  };

  const handleChange = e => {
    if (e.target.props?.name === 'description') e.target.name = 'description';
    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async () => {
    if (datafile.base64 === '') {
      showAlertResult(t('labels.bussiness.rpa.files_shared.error_select_file'), alertError);
      return false;
    }

    if (datafile.error) {
      showAlertResult(datafile.errorMsg, alertError);
      return false;
    }

    dispatch(fileCreateBegin());

    fileCreate({
      variables: {
        fileCreateInput: {
          name: datafile.name,
          file: datafile.base64,
          fileName: datafile.name,
          isPlataformaFile: false,
          size: datafile.size,
          description: state.form.description,
          runVersion: state.form.runVersion,
          tenant: currentTenant?.tenant._id,
          awsId: '',
        },
      },
    })
      .then(({ data }) => {
        if (data.fileCreate.success) {
          dispatch(fileCreateSuccess(data.fileCreate.data));

          showAlertResult(t('labels.bussiness.rpa.files_shared.success_upload'), alertSuccess);

          const { __typename, _id, ...actualCv } = cv;
          actualCv.files = actualCv.files.map(item => item._id);
          actualCv.files.push(data.fileCreate.data._id);

          updateCv({
            variables: {
              data: {
                bot: botId,
                ...actualCv,
                image: actualCv.image._id,
              },
            },
          })
            .then(({ data: botUpdateCVData }) => {
              if (botUpdateCVData.botUpdateCv.success) {
                notificationSuccess(t('labels.bussiness.rpa.bots.table.success_edited'));
              } else {
                notificationError(t(`codeResponse.${botUpdateCVData.botUpdateCv.code}`));
              }
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.log('Bot Edit Error ', e);
              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });

          setIsFormVisible(false);
          updateForm({ description: '', file: '' });
          history.push(`/admin/bot/cv/${botId}`);
        } else {
          dispatch(fileCreateError(data.fileCreate.code));

          showAlertResult(t(`codeResponse.${data.fileCreate.code}`), alertError);
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('File Create Error ', e);

        dispatch(fileCreateError());
        showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
      });
    return true;
  };

  const columns = [
    {
      title: t('labels.bussiness.rpa.bots.curriculum.table.files.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('labels.bussiness.rpa.bots.curriculum.table.files.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('labels.bussiness.rpa.bots.curriculum.table.files.action'),
      key: 'action',
      render: (text, record) => (
        <div className="table-actions">
          <Button className="btn-icon" type="primary" shape="circle" onClick={() => handleDownloadFile(record._id)}>
            <FeatherIcon icon="download" size={16} />
          </Button>
          <Button className="btn-icon" type="danger" shape="circle" onClick={() => deleteFile(record._id, record.size)}>
            <FeatherIcon icon="trash-2" size={16} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      {currentTenant?.userPermissions?.includes(UserPermissions.BotCVUpdate) && (
        <Button
          type="primary"
          icon={<UploadOutlined />}
          onClick={showFormUpload}
          size="large"
          style={{ marginBottom: 10 }}
        >
          {t('labels.bussiness.rpa.bots.curriculum.table.files.btn_addFile')}
        </Button>
      )}
      {isFormVisible && (
        <Row gutter={30}>
          <Col span={24}>
            <Form name="addFile" form={form} onFinish={handleSubmit} layout="vertical" size="middle" scrollToFirstError>
              <Row gutter={30}>
                <Col md={12} xs={24}>
                  <Form.Item
                    name="file"
                    label={t('labels.bussiness.rpa.files_shared.file')}
                    rules={[{ required: true }]}
                  >
                    <Upload
                      style={{ background: 'red' }}
                      name="file"
                      fileList={state.fileList}
                      multiple={false}
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleChangeFile}
                    >
                      <Button className="btn-outlined" size="large" type="light">
                        <UploadOutlined />
                        {datafile.name !== '' && !datafile.error
                          ? `${datafile.name.substring(0, 16)}...`
                          : t('labels.bussiness.rpa.files_shared.select')}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    name="description"
                    rules={[{}]}
                    label={t('labels.bussiness.rpa.files_shared.table.description')}
                  >
                    {/* <Input name="description" onChange={handleChange} value={state.form.description} placeholder={t('labels.bussiness.rpa.files_shared.table.description')} /> */}
                    <TextArea
                      name="description"
                      onChange={handleChange}
                      value={state.form.description}
                      placeholder={t('labels.bussiness.rpa.files_shared.table.description')}
                      className="sDash_unresizable"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button style={{ height: '44px' }} htmlType="submit" type="primary">
                  {loadingFiles ? t('loading.1') : t('general.create')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
      <TableWrapper className="table-responsive">
        <Table dataSource={cv.files} columns={columns} pagination />
      </TableWrapper>
    </>
  );
};

CvFilesTable.propTypes = {
  cv: PropTypes.object.isRequired,
};

export default CvFilesTable;
