import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Modal, Form, Input, Spin, Select, DatePicker, Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { timestampToDate } from '../../../components/utilities/utilities';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, ExportStyleWrap, TableWrapper, UserTableStyleWrapper, DatePickerWrapper } from '../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';

import { AutoComplete } from '../../../components/autoComplete/autoComplete';
import actions from '../../../redux/customReport/actions';
import { QUERY_CUSTOM_REPORT_INFO_FIND_ALL, QUERY_BOT_FIND_ALL, QUERY_CUSTOM_REPORT_FIND_ALL } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const {
  customReportInfoFilterBegin,
  customReportInfoFilterSuccess,
  customReportFindAllBegin,
  customReportFindAllSuccess,
  customReportFindAllError,
  customReportInfoFindAllBegin,
  customReportInfoFindAllSuccess,
  customReportInfoFindAllError,
} = actions;

const { Option } = Select;

const { RangePicker } = DatePicker;

const CustomRepReport = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadingCustomReportState = useSelector(state => state.customReport.loading);
  const customReportsInfo = useSelector(state => state.customReport.customReportsInfoFiltered);
  const history = useHistory();
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [mounted, setMounted] = useState(false);
  const [mountedBots, setMountedBots] = useState(false);
  const [mountedCustomReports, setMountedCustomReports] = useState(false);
  const [mountedCustomReportsInfo, setMountedCustomReportsInfo] = useState(false);
  const [customReportsInfoTableData, setCustomReportsInfoTableData] = useState([]);
  const [botsSelectData, setBotsSelectData] = useState([]);
  const [customReportsSelectData, setCustomReportsSelectData] = useState([]);
  const [filterTable, setFilterTable] = useState({
    computerName: '',
    serial: '',
  });
  const [state, setState] = useState({
    botId: '',
    customReportId: '',
    start: moment()
      .startOf('day')
      .toISOString(),
    end: moment()
      .endOf('day')
      .toISOString(),
    isModalVisible: false,
    fileName: 'Custom Report Info Report',
  });
  const { loading: loadingBots, data: dataBots, error: errorBots } = useQuery(QUERY_BOT_FIND_ALL, {
    variables: {
      tenantId: currentTenant?.tenant._id,
    },
    fetchPolicy: 'network-only',
  });
  const [
    customReportFindAll,
    { loading: loadingCustomReports, data: dataCustomReports, error: errorCustomReports },
  ] = useLazyQuery(QUERY_CUSTOM_REPORT_FIND_ALL, {
    fetchPolicy: 'network-only',
    onCompleted: x => {
      if (x.customReportFindAll.success) {
        dispatch(customReportFindAllSuccess(x.customReportFindAll.data));
        updateCustomReportSelect(x.customReportFindAll.data);
      } else dispatch(customReportFindAllError(x.customReportFindAll.code));
    },
    onError: err => dispatch(customReportFindAllError(err)),
  });
  const [customReportInfoFindAll, { loading, data }] = useLazyQuery(QUERY_CUSTOM_REPORT_INFO_FIND_ALL, {
    fetchPolicy: 'network-only',
    onCompleted: x => {
      if (x.customReportInfoFindAll.success) dispatch(customReportInfoFindAllSuccess(x.customReportInfoFindAll.data));
      else dispatch(customReportInfoFindAllError(x.customReportInfoFindAll.code));
    },
    onError: err => dispatch(customReportInfoFindAllError(err)),
  });

  const csvData = [['Custom Report Info Json Object'], ...customReportsInfo.map(x => [JSON.stringify(x)])];

  const handleFilterBy = x => `${t('general.filterBy')} ${x[0].toUpperCase() + x.substring(1)}`;

  const handleModal = () => {
    setState({
      ...state,
      isModalVisible: !state.isModalVisible,
    });
  };

  const handleSearch = (searchText, filter) => {
    setFilterTable(x => ({
      ...x,
      [filter]: searchText,
    }));
  };

  const { isModalVisible } = state;

  const updateFileName = e => {
    setState({
      ...state,
      fileName: e.target.value,
    });
  };

  const handleBotChangeSelect = e => {
    setState({
      ...state,
      botId: e,
    });
    setMounted(false);
    setMountedCustomReports(false);
    setState(x => ({
      ...x,
      customReportId: '',
      start: moment()
        .startOf('day')
        .toISOString(),
      end: moment()
        .endOf('day')
        .toISOString(),
    }));
    setCustomReportsSelectData([]);
  };

  const handleCustomChangeSelect = e => {
    setState({
      ...state,
      customReportId: e,
    });
    setMountedCustomReportsInfo(false);
  };

  const updateBotSelect = bots => {
    if (bots?.length > 0) {
      setBotsSelectData(
        bots.map(bot => {
          return (
            <Option key={bot._id} value={bot._id}>
              {bot.name}
            </Option>
          );
        }),
      );
    } else setBotsSelectData([]);
  };

  const updateCustomReportSelect = customReports => {
    if (customReports?.length > 0) {
      setCustomReportsSelectData(
        customReports.map(customReport => {
          return (
            <Option key={customReport._id} value={customReport._id}>
              {customReport.displayName}
            </Option>
          );
        }),
      );
    } else setCustomReportsSelectData([]);
  };

  const onChangeRange = date => {
    if (date) {
      setState({
        ...state,
        start: date[0].startOf('day').toISOString(),
        end: date[1].endOf('day').toISOString(),
      });
      setMountedCustomReportsInfo(false);
    }
  };

  useEffect(() => {
    if (state.botId && !mountedCustomReports) {
      dispatch(customReportFindAllBegin());
      customReportFindAll({
        variables: {
          botId: state.botId,
        },
      });
    }
  }, [customReportFindAll, mountedCustomReports, state.botId, dispatch]);

  useEffect(() => {
    if (state.customReportId && !mountedCustomReportsInfo) {
      dispatch(customReportInfoFindAllBegin());
      customReportInfoFindAll({
        variables: {
          input: {
            id: state.customReportId,
            start: state.start,
            end: state.end,
            showAll: false,
          },
        },
      });
      setMountedCustomReportsInfo(true);
    }
  }, [customReportInfoFindAll, mountedCustomReportsInfo, state.customReportId, state.start, state.end, dispatch]);

  useEffect(() => {
    if (customReportsInfo?.length > 0) {
      setCustomReportsInfoTableData(
        customReportsInfo.map((customReportInfo, index) => {
          return {
            key: customReportInfo._id,
            serial: customReportInfo.licenseSerial,
            computerName: customReportInfo.computerInfo.name,
            content: customReportInfo.content,
            createdLocalAt: customReportInfo.createdLocalAt && timestampToDate(customReportInfo.createdLocalAt),
            createdAt: timestampToDate(customReportInfo.createdAt),
          };
        }),
      );
    } else {
      setCustomReportsInfoTableData([]);
    }
  }, [customReportsInfo]);

  useEffect(() => {
    if (
      !currentLoading &&
      !currentTenant?.userPermissions?.includes(UserPermissions.CustomReportInfoFindAll) &&
      !currentTenant?.userPermissions?.includes(UserPermissions.CustomReportFindAll) &&
      !currentTenant?.userPermissions?.includes(UserPermissions.BotFindAll)
    ) {
      history.push('/admin');
      notificationError(t(`codeResponse.403`));
    }

    if (!loadingBots && !mountedBots && dataBots) {
      if (dataBots.botFindAll.success) {
        updateBotSelect(dataBots.botFindAll.data);
      } else {
        history.push('/admin');
        notificationError(t(`codeResponse.${dataBots.botFindAll.code}`));
      }
      setMountedBots(true);
    }
  }, [dataBots, loadingBots, errorBots, history, currentTenant, currentLoading, mountedBots, t]);

  useEffect(() => {
    if (!loadingCustomReports && dataCustomReports && !mountedCustomReports) {
      if (dataCustomReports.customReportFindAll.success) {
        updateCustomReportSelect(dataCustomReports.customReportFindAll.data);
      } else {
        history.push('/admin');
        notificationError(t(`codeResponse.${dataCustomReports.customReportFindAll.code}`));
      }
      setMountedCustomReports(true);
    }
  }, [
    dataCustomReports,
    loadingCustomReports,
    errorCustomReports,
    history,
    currentTenant,
    currentLoading,
    mountedCustomReports,
    t,
  ]);

  useEffect(() => {
    if (!loading && data && !mounted && state.customReportId) {
      dispatch(customReportInfoFindAllBegin());
      if (data.customReportInfoFindAll.success) {
        dispatch(customReportInfoFindAllSuccess(data.customReportInfoFindAll.data));
      } else {
        dispatch(customReportInfoFindAllError(data.customReportInfoFindAll.code));
        history.push('/admin');
        notificationError(t(`codeResponse.${data.customReportInfoFindAll.code}`));
      }
      setMounted(true);
    }
  }, [loading, data, dispatch, history, mounted, mountedCustomReports, state.customReportId, t]);

  useEffect(() => {
    dispatch(customReportInfoFilterBegin());
    dispatch(customReportInfoFilterSuccess(filterTable));
  }, [filterTable, dispatch]);

  const { fileName } = state;

  const columns = [
    {
      title: t('properties.serial.1'),
      dataIndex: 'serial',
      key: 'serial',
      width: '25%',
    },
    {
      title: t('properties.computerName.1'),
      dataIndex: 'computerName',
      key: 'computerName',
      width: '25%',
    },
    {
      title: t('properties.content'),
      dataIndex: 'content',
      key: 'content',
      width: '10%',
    },
    {
      title: t('properties.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
    },
    {
      title: t('properties.createdLocalAt'),
      dataIndex: 'createdLocalAt',
      key: 'createdLocalAt',
      width: '20%',
    },
  ];
  if (mountedBots) {
    return (
      <>
        <PageHeader title={t('transaction.customReport.report')} />
        <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle  darkMode={darkMode}>
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <NavLink to={`/admin/`}>
                          <HomeOutlined />
                        </NavLink>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <NavLink to="#">{t('transaction.customReport.report')}</NavLink>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </BreadcrumbWrapperStyle>
          <Row gutter={25} align="center">
            <Col md={12}>
              <Cards title={<>
                {"Bots"}
              </>
              }>
                <Select
                  showSearch
                  name="bots"
                  onChange={handleBotChangeSelect}
                  placeholder={t('general.selectBot')}
                  className="sDash_fullwidth-select"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {botsSelectData}
                </Select>
              </Cards>
            </Col>
          </Row>
          <Row gutter={25} align="center">
            <Col md={12}>
              <Cards title={t('transaction.customReport.name.2')}>
                <Select
                  loading={loadingCustomReports}
                  disabled={!mountedCustomReports}
                  {...(customReportsSelectData.length <= 0 && { value: null })}
                  showSearch
                  name="customReports"
                  onChange={handleCustomChangeSelect}
                  placeholder={t('transaction.customReport.select')}
                  className="sDash_fullwidth-select"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {customReportsSelectData}
                </Select>
              </Cards>
            </Col>
            <Col md={12}>
              <Cards title={t('general.dateRange')}>
                <DatePickerWrapper>
                  <RangePicker
                    disabled={!mounted}
                    defaultValue={[moment(), moment()]}
                    value={[moment(state.start), moment(state.end)]}
                    ranges={{
                      [t('general.today')]: [moment(), moment()],
                      [t('general.thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
                    }}
                    onChange={onChangeRange}
                  />
                </DatePickerWrapper>
              </Cards>
            </Col>
          </Row>
          {mounted && (
            <Row gutter={25}>
              <Col sm={24} xs={24}>
                <ExportStyleWrap>
                  <Cards headless>
                    <div className="sDash_export-box">
                      <>
                        <Button
                          disabled={!(customReportsInfoTableData?.length > 0)}
                          className="btn-export"
                          onClick={handleModal}
                          type="primary"
                        >
                          {t('general.export')}
                        </Button>
                        <Modal
                          title={t('general.exportFile')}
                          wrapClassName="sDash_export-wrap"
                          visible={isModalVisible}
                          footer={null}
                          onCancel={handleModal}
                        >
                          <Form name="export">
                            <Form.Item name="f_name">
                              <Input
                                placeholder={t('properties.fileName')}
                                value={fileName}
                                onChange={updateFileName}
                              />
                            </Form.Item>
                            <div className="sDash-button-grp">
                              <CSVLink filename={`${fileName}.csv`} data={csvData}>
                                <Button onClick={handleModal} className="btn-export" type="primary">
                                  {t('general.export')}
                                </Button>
                              </CSVLink>

                              <Button onClick={handleModal} size="default" type="white" outlined>
                                {t('general.cancel')}
                              </Button>
                            </div>
                          </Form>
                        </Modal>
                      </>

                      <AutoComplete
                        onSearch={x => handleSearch(x, 'serial')}
                        // dataSource={notData}
                        placeholder={handleFilterBy(t('properties.serial.1'))}
                        width="100%"
                        patterns
                      />
                      <AutoComplete
                        onSearch={x => handleSearch(x, 'computerName')}
                        // dataSource={notData}
                        placeholder={handleFilterBy(t('properties.computerName.1'))}
                        width="100%"
                        patterns
                      />
                    </div>
                    <h4 style={{ marginTop: '-10px', marginBottom: '20px' }}>{t('general.maximumExportedItems')}</h4>
                    {!loadingCustomReportState && !loading ? (
                      <div className="sDash_export-file-table table-bordered table-responsive">
                        <UserTableStyleWrapper>
                          <TableWrapper className="table-responsive">
                            <Table
                              columns={columns}
                              dataSource={customReportsInfoTableData}
                              pagination={{
                                defaultPageSize: 10,
                                total: customReportsInfoTableData?.length,
                                showTotal: (total, range) =>
                                  `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
                              }}
                            />
                          </TableWrapper>
                        </UserTableStyleWrapper>
                      </div>
                    ) : (
                      <div className="spin">
                        <Spin />
                      </div>
                    )}
                  </Cards>
                </ExportStyleWrap>
              </Col>
            </Row>
          )}
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default CustomRepReport;
