import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table, Button, Spin, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import {
  QUERY_BOT_EXECUTION_FIND_ALL,
  QUERY_BOT_ACTION_SEND,
  QUERY_BOT_LICENSE_DELETE,
  QUERY_BOT_LICENSE_GET_ALL,
} from '../../../queries';
import botLicenseActions from '../../../redux/botLicense/actions';
import botExecutionActions from '../../../redux/botExecution/actions';
import licenseActions from '../../../redux/license/actions';
import { alertModal } from '../../../components/modals/antd-modals';
import { TableWrapper, UserTableStyleWrapper } from '../styled';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { BotActions } from '../../../utils/enums/bot-actions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

const {
  botLicenseGetAllBegin,
  botLicenseGetAllSuccess,
  botLicenseGetAllError,
  botLicenseDeleteBegin,
  botLicenseDeleteSuccess,
  botLicenseDeleteError,
} = botLicenseActions;
const { botExecutionFindAllBegin, botExecutionFindAllSuccess, botExecutionFindAllError } = botExecutionActions;
const { licenseCreateBegin, licenseCreateSuccess, licenseCreateError } = licenseActions;

const BotLicenseList = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const { botId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const botLicenseState = useSelector(state => state.botLicense);
  const botExecutionList = useSelector(state => state.botExecution.data);
  const currentUser = useSelector(state => state.currentUserTenant.currentUser);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [botLicenseDelete] = useMutation(QUERY_BOT_LICENSE_DELETE);
  const [botActionSend] = useMutation(QUERY_BOT_ACTION_SEND);
  const [mounted, setMounted] = useState(false);
  const [state, setState] = useState({
    botLicenseDataTable: [],
  });

  const { loading, data, error } = useQuery(QUERY_BOT_LICENSE_GET_ALL, {
    variables: {
      botId,
    },
    fetchPolicy: 'network-only',
  });

  const { loading: botExecutionLoading, data: botExecutionData, error: botExecutionError } = useQuery(
    QUERY_BOT_EXECUTION_FIND_ALL,
    {
      variables: {
        input: {
          id: botId,
          start: moment()
            .subtract(1, 'days')
            .startOf('day')
            .toISOString(),
          end: moment()
            .endOf('day')
            .toISOString(),
          showAll: false,
        },
      },
      fetchPolicy: 'network-only',
      pollInterval: 2000,
    },
  );

  const handleBotActionSend = useCallback(
    (serial, action) => {
      botActionSend({
        variables: {
          input: {
            serial,
            action,
            botId,
            userId: currentUser?._id,
            startTime: moment().toISOString(),
          },
        },
      })
        .then(() => {
          notificationSuccess(t('botActions.successSentAction'));
        })
        .catch(e => {
          console.log('Bot Action Send Error ', e);
          notificationError(t('codeResponse.UNEXPECTED_ERROR'));
        });
    },
    [botActionSend, botId, currentUser, t],
  );

  const handleBotLicenseDelete = useCallback(
    id => {
      alertModal.confirm({
        title: t('botLicense.confirmRemove'),
        onOk() {
          dispatch(botLicenseDeleteBegin());
          dispatch(licenseCreateBegin());

          botLicenseDelete({
            variables: { id },
          })
            .then(({ data }) => {
              if (data.botLicenseDelete.success) {
                dispatch(botLicenseDeleteSuccess(id));
                dispatch(licenseCreateSuccess(data.botLicenseDelete.data.license));

                notificationSuccess(t('botLicense.successDeleted'));
              } else {
                dispatch(botLicenseDeleteError(data.botLicenseDelete.code));
                dispatch(licenseCreateError());

                notificationError(t(`codeResponse.${data.botLicenseDelete.code}`));
              }
            })
            .catch(e => {
              console.log('Bot License Delete Error ', e);
              dispatch(botLicenseDeleteError());
              dispatch(licenseCreateError());

              notificationError(t('codeResponse.UNEXPECTED_ERROR'));
            });
        },
        onCancel() {},
        okText: t('general.confirm'),
      });
    },
    [dispatch, botLicenseDelete, t],
  );

  useEffect(() => {
    if (botLicenseState.data?.length > 0) {
      setState({
        botLicenseDataTable: botLicenseState.data.map(x => {
          const botHandled = botExecutionList
            .filter(botExec => botExec.licenseSerial === x.license.serial)
            .sort((a, b) => {
              return new Date(b.createdLocalAt) - new Date(a.createdLocalAt);
            });

          const action = botHandled[0]?.status ?? BotActions.End;

          return {
            key: x._id,
            serial: x.license.serial,
            computerName: x.license.computerInfo?.name,
            domain: x.license.computerInfo?.domain,
            actions: (
              <div className="table-actions">
                <>
                  {action === BotActions.End && (
                    <Popover content={t('botActions.Start')}>
                      <Button
                        className="btn-icon"
                        type="primary"
                        shape="circle"
                        onClick={() => handleBotActionSend(x.license.serial, BotActions.Start)}
                      >
                        <FeatherIcon icon="skip-forward" size={16} />
                      </Button>
                    </Popover>
                  )}
                  {(action === BotActions.Start || action === BotActions.Pause || action === BotActions.Resume) && (
                    <Popover content={t('botActions.End')}>
                      <Button
                        className="btn-icon"
                        type="primary"
                        shape="circle"
                        onClick={() => handleBotActionSend(x.license.serial, BotActions.End)}
                      >
                        <FeatherIcon icon="square" size={16} />
                      </Button>
                    </Popover>
                  )}
                  {(action === BotActions.Start || action === BotActions.Resume) && (
                    <Popover content={t('botActions.Pause')}>
                      <Button
                        className="btn-icon"
                        type="primary"
                        shape="circle"
                        onClick={() => handleBotActionSend(x.license.serial, BotActions.Pause)}
                      >
                        <FeatherIcon icon="pause" size={16} />
                      </Button>
                    </Popover>
                  )}
                  {action === BotActions.Pause && (
                    <Popover content={t('botActions.Resume')}>
                      <Button
                        className="btn-icon"
                        type="primary"
                        shape="circle"
                        onClick={() => handleBotActionSend(x.license.serial, BotActions.Resume)}
                      >
                        <FeatherIcon icon="play" size={16} />
                      </Button>
                    </Popover>
                  )}
                  {action && action !== BotActions.End && (
                    <Popover content={t('botActions.PrintScreen')}>
                      <Button
                        className="btn-icon"
                        type="primary"
                        shape="circle"
                        onClick={() => handleBotActionSend(x.license.serial, BotActions.PrintScreen)}
                      >
                        <FeatherIcon icon="camera" size={16} />
                      </Button>
                    </Popover>
                  )}
                  <Button
                    className="btn-icon"
                    type="danger"
                    shape="circle"
                    onClick={() => handleBotLicenseDelete(x._id)}
                  >
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                </>
              </div>
            ),
          };
        }),
      });
    } else {
      setState({
        botLicenseDataTable: [],
      });
    }
  }, [botLicenseState, handleBotLicenseDelete, handleBotActionSend, t, botExecutionList]);

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotLicenseGetAll)) {
      history.push('/admin/bot');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted && data) {
      dispatch(botLicenseGetAllBegin());

      if (data.botLicenseGetAll.success) {
        dispatch(botLicenseGetAllSuccess(data.botLicenseGetAll.data));
      } else {
        dispatch(botLicenseGetAllError(data.botLicenseGetAll.code));
        history.push('/admin/bot');
        notificationError(t(`codeResponse.${data.botLicenseGetAll.code}`));
      }

      setMounted(true);
    }

    if (!botExecutionLoading && botExecutionData) {
      dispatch(botExecutionFindAllBegin());
      if (botExecutionData.botExecutionFindAll.success) {
        dispatch(botExecutionFindAllSuccess(botExecutionData.botExecutionFindAll.data));
      } else {
        dispatch(botExecutionFindAllError(botExecutionData.botExecutionFindAll.code));
        history.push('/admin/bot');
        notificationError(t(`codeResponse.${botExecutionData.botExecutionFindAll.code}`));
      }
    }
  }, [
    dispatch,
    data,
    loading,
    error,
    botExecutionData,
    botExecutionLoading,
    botExecutionError,
    history,
    currentTenant,
    currentLoading,
    t,
    mounted,
  ]);

  const columns = [
    {
      title: t('properties.serial.1'),
      dataIndex: 'serial',
      key: 'serial',
      width: '30%',
    },
    {
      title: t('properties.computerName.1'),
      dataIndex: 'computerName',
      key: 'computerName',
      width: '30%',
    },
    {
      title: t('properties.domain.1'),
      dataIndex: 'domain',
      key: 'domain',
      width: '30%',
    },
    {
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
    },
  ];

  if (!botLicenseState.loading && !botExecutionLoading && mounted) {
    return (

      <div className="table-bordered leaderboard-table table-responsive">
        <UserTableStyleWrapper darkMode={darkMode}>
          <TableWrapper  className="table-responsive">
            <Table         
              columns={columns}
              dataSource={state.botLicenseDataTable}
              pagination={{
                defaultPageSize: 10,
                total: state.botLicenseDataTable?.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${t('general.of')} ${total} ${t('general.items')}`,
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
      </div>
    );
  }
  
  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default BotLicenseList;
