const actions = {
  MAIL_NOTIFICATION_FIND_ALL: 'MAIL_NOTIFICATION_FIND_ALL',
  MAIL_NOTIFICATION_DELETE_ONE: 'MAIL_NOTIFICATION_DELETE_ONE',
  MAIL_NOTIFICATION_UPDATE: 'MAIL_NOTIFICATION_UPDATE',
  MAIL_NOTIFICATION_FIND_ONE: 'MAIL_NOTIFICATION_FIND_ONE',

  mailNotificationDeleteOne:data =>{
    return {
      type:actions.MAIL_NOTIFICATION_DELETE_ONE,
      payload: data
    };
  },

 mailNotificationFindAllSuccess: data => {
    return {
      type: actions.MAIL_NOTIFICATION_FIND_ALL,
      data,
    };
  },

  mailNotificationUpdateReadedSuccess: data => {
    return {
      type: actions.MAIL_NOTIFICATION_UPDATE,
      data,
    };
  },

  mailNotificationFindOne: data => {
    return {
      type: actions.MAIL_NOTIFICATION_FIND_ONE,
      payload: data
    };
  },
};

export default actions;
