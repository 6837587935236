import update from 'immutability-helper';

import actions from './actions';

const {
  BOT_SCHEDULE_GET_ALL_BEGIN,
  BOT_SCHEDULE_GET_ALL_SUCCESS,
  BOT_SCHEDULE_GET_ALL_ERROR,
  BOT_SCHEDULE_CREATE_BEGIN,
  BOT_SCHEDULE_CREATE_SUCCESS,
  BOT_SCHEDULE_CREATE_ERROR,
  BOT_SCHEDULE_UPDATE_BEGIN,
  BOT_SCHEDULE_UPDATE_SUCCESS,
  BOT_SCHEDULE_UPDATE_ERROR,
  BOT_SCHEDULE_TOGGLE_BEGIN,
  BOT_SCHEDULE_TOGGLE_SUCCESS,
  BOT_SCHEDULE_TOGGLE_ERROR,
  BOT_SCHEDULE_REMOVE_BEGIN,
  BOT_SCHEDULE_REMOVE_SUCCESS,
  BOT_SCHEDULE_REMOVE_ERROR,
  BOT_SCHEDULE_DEACTIVATE_REDRAW_TABLE,
  BOT_SCHEDULE_GET_SERVER_INFO_BEGIN,
  BOT_SCHEDULE_GET_SERVER_INFO_SUCCESS,
  BOT_SCHEDULE_GET_SERVER_INFO_ERROR,
  BOT_SCHEDULE_RESET_SERVER_INFO,
  BOT_SCHEDULE_FIND_ONE_RESET,
  BOT_SCHEDULE_FIND_ONE_SUCCESS,
} = actions;

const initialState = {
  task: undefined,
  tasks: [],
  serverInfo: [],
  error: false,
  loading: false,
  reDrawTable: false,
};

const botScheduleReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case BOT_SCHEDULE_GET_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BOT_SCHEDULE_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: data ?? [],
      };

    case BOT_SCHEDULE_GET_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    case BOT_SCHEDULE_FIND_ONE_SUCCESS:
      return {
        ...state,
        task: data,
        loading: false,
      };

    case BOT_SCHEDULE_FIND_ONE_RESET:
      return {
        ...state,
        task: undefined,
      };

    case BOT_SCHEDULE_REMOVE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BOT_SCHEDULE_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        reDrawTable: true,
        tasks: state.tasks.filter(x => x._id !== data),
      };

    case BOT_SCHEDULE_REMOVE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    case BOT_SCHEDULE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BOT_SCHEDULE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case BOT_SCHEDULE_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    case BOT_SCHEDULE_UPDATE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case BOT_SCHEDULE_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case BOT_SCHEDULE_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case BOT_SCHEDULE_TOGGLE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case BOT_SCHEDULE_TOGGLE_SUCCESS: {
      const index = state.tasks.findIndex(x => x._id === data._id);

      return update(state, {
        tasks: {
          [index]: {
            $set: data,
          },
        },
        loading: {
          $set: false,
        },
        reDrawTable: {
          $set: true,
        },
      });
    }

    case BOT_SCHEDULE_TOGGLE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case BOT_SCHEDULE_DEACTIVATE_REDRAW_TABLE: {
      return {
        ...state,
        reDrawTable: false,
      };
    }

    case BOT_SCHEDULE_GET_SERVER_INFO_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BOT_SCHEDULE_GET_SERVER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        serverInfo: data ?? [],
      };

    case BOT_SCHEDULE_GET_SERVER_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    case BOT_SCHEDULE_RESET_SERVER_INFO:
      return {
        ...state,
        serverInfo: [],
      };

    default:
      return state;
  }
};

export default botScheduleReducer;
