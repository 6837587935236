import { gql } from '@apollo/client';

export const QUERY_WEB_NOTIFICATION_FIND_ALL = gql`
  query webNotificationFindAll {
    webNotificationFindAll {
      success
      code
      data {
        _id
        message
        type
        readed
        createdAt
      }
    }
  }
`;

export const QUERY_WEB_NOTIFICATION_FIND_ONE = gql`
  query webNotificationFindOne($id: String!) {
    webNotificationFindOne(id: $id) {
      success
      code
      data {
        _id
        message
        type
        readed
        createdAt
      }
    }
  }
`;

export const QUERY_WEB_NOTIFICATION_UPDATE_READED = gql`
  mutation webNotificationUpdateReaded($id: String!) {
    webNotificationUpdateReaded(id: $id) {
      success
      code
      data {
        _id
        message
        type
        readed
        createdAt
      }
    }
  }
`;

export const QUERY_WEB_NOTIFICATION_REMOVE = gql`
  mutation webNotificationRemove($id: String!) {
    webNotificationRemove(id: $id) {
      success
      code
      data {
        _id
        message
        type
        readed
        createdAt
      }
    }
  }
`;
