const actions = {
  USER_FIND_ALL_BEGIN: 'USER_FIND_ALL_BEGIN',
  USER_FIND_ALL_SUCCESS: 'USER_FIND_ALL_SUCCESS',
  USER_FIND_ALL_ERROR: 'USER_FIND_ALL_ERROR',

  USER_FIND_ONE_BEGIN: 'USER_FIND_ONE_BEGIN',
  USER_FIND_ONE_SUCCESS: 'USER_FIND_ONE_SUCCESS',
  USER_FIND_ONE_ERROR: 'USER_FIND_ONE_ERROR',

  USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  USER_CREATE_ERROR: 'USER_CREATE_ERROR',
  USER_CREATE_BEGIN: 'USER_CREATE_BEGIN',

  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',
  USER_UPDATE_BEGIN: 'USER_UPDATE_BEGIN',

  USER_REMOVE_SUCCESS: 'USER_REMOVE_SUCCESS',
  USER_REMOVE_ERROR: 'USER_REMOVE_ERROR',
  USER_REMOVE_BEGIN: 'USER_REMOVE_BEGIN',

  USER_FILTER_SUCCESS: 'USER_FILTER_SUCCESS',
  USER_FILTER_ERROR: 'USER_FILTER_ERROR',
  USER_FILTER_BEGIN: 'USER_FILTER_BEGIN',

  userFindAllBegin: () => {
    return {
      type: actions.USER_FIND_ALL_BEGIN,
    };
  },

  userFindAllSuccess: data => {
    return {
      type: actions.USER_FIND_ALL_SUCCESS,
      data,
    };
  },

  userFindAllError: error => {
    return {
      type: actions.USER_FIND_ALL_ERROR,
      error,
    };
  },

  userFindOneBegin: () => {
    return {
      type: actions.USER_FIND_ONE_BEGIN,
    };
  },

  userFindOneSuccess: data => {
    return {
      type: actions.USER_FIND_ONE_SUCCESS,
      data,
    };
  },

  userFindOneError: error => {
    return {
      type: actions.USER_FIND_ONE_ERROR,
      error,
    };
  },

  userCreateBegin: () => {
    return {
      type: actions.USER_CREATE_BEGIN,
    };
  },

  userCreateSuccess: data => {
    return {
      type: actions.USER_CREATE_SUCCESS,
      data,
    };
  },

  userCreateError: error => {
    return {
      type: actions.USER_CREATE_ERROR,
      error,
    };
  },

  userUpdateBegin: () => {
    return {
      type: actions.USER_UPDATE_BEGIN,
    };
  },

  userUpdateSuccess: data => {
    return {
      type: actions.USER_UPDATE_SUCCESS,
      data,
    };
  },

  userUpdateError: error => {
    return {
      type: actions.USER_UPDATE_ERROR,
      error,
    };
  },

  userRemoveBegin: () => {
    return {
      type: actions.USER_REMOVE_BEGIN,
    };
  },

  userRemoveSuccess: data => {
    return {
      type: actions.USER_REMOVE_SUCCESS,
      data,
    };
  },

  userRemoveError: error => {
    return {
      type: actions.USER_REMOVE_ERROR,
      error,
    };
  },

  userFilterBegin: () => {
    return {
      type: actions.USER_FILTER_BEGIN,
    };
  },

  userFilterSuccess: data => {
    return {
      type: actions.USER_FILTER_SUCCESS,
      data,
    };
  },

  userFilterError: error => {
    return {
      type: actions.USER_FILTER_ERROR,
      error,
    };
  },
};

export default actions;
