import React, { useState, lazy, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Breadcrumb } from 'antd';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import actions from '../../../redux/license/actions';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { QUERY_RESOURCE_GROUP_FIND_ALL, QUERY_RESOURCE_GROUP_FIND_ONE, QUERY_LICENSE_CREATE } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const { licenseCreateBegin, licenseCreateSuccess, licenseCreateError } = actions;

const LicenseCreateAddons = lazy(() => import('./LicenseCreateAddons'));

const LicenseCreate = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const licenseLoading = useSelector(state => state.license.loading);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [form] = Form.useForm();
  const [licenseCreate] = useMutation(QUERY_LICENSE_CREATE);
  const { data: dataResourceGroupFindAll } = useQuery(QUERY_RESOURCE_GROUP_FIND_ALL, {
    variables: {
      tenantId: currentTenant?.tenant._id,
    },
    fetchPolicy: 'network-only',
  });
  const [resourceGroupFindOne, { data: dataResourceGroupFindOne, loading: loadingResourceGroup }] = useLazyQuery(
    QUERY_RESOURCE_GROUP_FIND_ONE,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [state, setState] = useState({
    form: {
      machineLicense: '',
      addons: [],
      resourceGroup: null,
    },
  });

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1')
  };
  /* eslint-enable */

  const handleSubmit = () => {
    dispatch(licenseCreateBegin());
    licenseCreate({
      variables: {
        input: {
          machineLicense: state.form.machineLicense,
          addons: state.form.addons,
          tenant: currentTenant?.tenant._id,
          resourceGroup: state.form.resourceGroup._id,
        },
      },
    })
      .then(({ data }) => {
        if (data.licenseCreate.success === true) {
          dispatch(licenseCreateSuccess(data.licenseCreate.data));
          notificationSuccess(t('license.successCreated'));
          history.push('/admin/license');
        } else {
          dispatch(licenseCreateError(data.licenseCreate.code));
          notificationError(t(`codeResponse.${data.licenseCreate.code}`));
        }
      })
      .catch(e => {
        console.log('License Create Error ', e);
        dispatch(licenseCreateError());
        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
      });
  };

  const onChangeAddons = e => {
    setState(x => ({
      form: {
        ...x.form,
        addons: e,
      },
    }));
  };

  const handleChangeSelect = e => {
    setState(x => ({
      form: {
        ...x.form,
        machineLicense: e,
      },
    }));
  };

  const handleChangeResourceGroup = id => {
    resourceGroupFindOne({
      variables: {
        id,
      },
    });
  };

  useEffect(() => {
    if (dataResourceGroupFindOne?.resourceGroupFindOne.success) {
      const resourceGroupFound = dataResourceGroupFindOne?.resourceGroupFindOne;
      setState(x => ({
        form: {
          ...x.form,
          resourceGroup: resourceGroupFound.data,
          machineLicense: null,
        },
      }));
      form.setFieldsValue({
        machineLicense: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataResourceGroupFindOne]);

  if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.LicenseCreate)) {
    history.push('/admin/license');
    notificationError(t(`codeResponse.403`));
    return null;
  }

  return (
    <>
      <PageHeader ghost title={t('license.create')} />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/admin/license`}>
                <span>{t('license.management')}</span>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#" >{t('license.create')}</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24}>
            <Cards>
              <LicenseCreateAddons
                action="create"
                resourceGroups={dataResourceGroupFindAll?.resourceGroupFindAll.data.filter(x => x.enabled)}
                loadingResourceGroup={loadingResourceGroup}
                licenseLoading={licenseLoading}
                form={form}
                state={state}
                validateMessages={validateMessages}
                onChangeAddons={onChangeAddons}
                handleChangeSelect={handleChangeSelect}
                handleSubmit={handleSubmit}
                handleChangeResourceGroup={handleChangeResourceGroup}
              />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default LicenseCreate;
