import actions from './actions';

const {
  FILE_FIND_ALL_BEGIN,
  FILE_FIND_ALL_SUCCESS,
  FILE_FIND_ALL_ERROR,
  FILE_CREATE_BEGIN,
  FILE_CREATE_SUCCESS,
  FILE_CREATE_ERROR,
  FILE_REMOVE_BEGIN,
  FILE_REMOVE_SUCCESS,
  FILE_REMOVE_ERROR,
  FILE_DOWNLOAD_BEGIN,
  FILE_DOWNLOAD_SUCCESS,
  FILE_DOWNLOAD_ERROR,
} = actions;

const initialState = {
  files: [],
  file: {},
  loading: false,
  error: false,
};

const fileReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case FILE_FIND_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FILE_FIND_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        files: data ?? [],
      };
    case FILE_FIND_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case FILE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FILE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        files: [...state.files, data],
      };
    case FILE_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case FILE_DOWNLOAD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FILE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FILE_DOWNLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case FILE_REMOVE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FILE_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        files: state.files.filter(x => x._id !== data),
      };
    case FILE_REMOVE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export default fileReducer;
