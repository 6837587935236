const actions = {
  CUSTOM_REPORT_FIND_ALL_BEGIN: 'CUSTOM_REPORT_FIND_ALL_BEGIN',
  CUSTOM_REPORT_FIND_ALL_SUCCESS: 'CUSTOM_REPORT_FIND_ALL_SUCCESS',
  CUSTOM_REPORT_FIND_ALL_ERROR: 'CUSTOM_REPORT_FIND_ALL_ERROR',

  CUSTOM_REPORT_FIND_ONE_BEGIN: 'CUSTOM_REPORT_FIND_ONE_BEGIN',
  CUSTOM_REPORT_FIND_ONE_SUCCESS: 'CUSTOM_REPORT_FIND_ONE_SUCCESS',
  CUSTOM_REPORT_FIND_ONE_ERROR: 'CUSTOM_REPORT_FIND_ONE_ERROR',

  CUSTOM_REPORT_CREATE_BEGIN: 'CUSTOM_REPORT_CREATE_BEGIN',
  CUSTOM_REPORT_CREATE_SUCCESS: 'CUSTOM_REPORT_CREATE_SUCCESS',
  CUSTOM_REPORT_CREATE_ERROR: 'CUSTOM_REPORT_CREATE_ERROR',

  CUSTOM_REPORT_REMOVE_BEGIN: 'CUSTOM_REPORT_REMOVE_BEGIN',
  CUSTOM_REPORT_REMOVE_SUCCESS: 'CUSTOM_REPORT_REMOVE_SUCCESS',
  CUSTOM_REPORT_REMOVE_ERROR: 'CUSTOM_REPORT_REMOVE_ERROR',

  CUSTOM_REPORT_UPDATE_BEGIN: 'CUSTOM_REPORT_UPDATE_BEGIN',
  CUSTOM_REPORT_UPDATE_SUCCESS: 'CUSTOM_REPORT_UPDATE_SUCCESS',
  CUSTOM_REPORT_UPDATE_ERROR: 'CUSTOM_REPORT_UPDATE_ERROR',

  CUSTOM_REPORT_INFO_FIND_ALL_BEGIN: 'CUSTOM_REPORT_INFO_FIND_ALL_BEGIN',
  CUSTOM_REPORT_INFO_FIND_ALL_SUCCESS: 'CUSTOM_REPORT_INFO_FIND_ALL_SUCCESS',
  CUSTOM_REPORT_INFO_FIND_ALL_ERROR: 'CUSTOM_REPORT_INFO_FIND_ALL_ERROR',

  CUSTOM_REPORT_INFO_FILTER_SUCCESS: 'CUSTOM_REPORT_INFO_FILTER_SUCCESS',
  CUSTOM_REPORT_INFO_FILTER_ERROR: 'CUSTOM_REPORT_INFO_FILTER_ERROR',
  CUSTOM_REPORT_INFO_FILTER_BEGIN: 'CUSTOM_REPORT_INFO_FILTER_BEGIN',

  customReportFindAllBegin: () => {
    return {
      type: actions.CUSTOM_REPORT_FIND_ALL_BEGIN,
    };
  },

  customReportFindAllSuccess: data => {
    return {
      type: actions.CUSTOM_REPORT_FIND_ALL_SUCCESS,
      data,
    };
  },

  customReportFindAllError: error => {
    return {
      type: actions.CUSTOM_REPORT_FIND_ALL_ERROR,
      error,
    };
  },

  customReportFindOneBegin: () => {
    return {
      type: actions.CUSTOM_REPORT_FIND_ONE_BEGIN,
    };
  },

  customReportFindOneSuccess: data => {
    return {
      type: actions.CUSTOM_REPORT_FIND_ONE_SUCCESS,
      data,
    };
  },

  customReportFindOneError: error => {
    return {
      type: actions.CUSTOM_REPORT_FIND_ONE_ERROR,
      error,
    };
  },

  customReportCreateBegin: () => {
    return {
      type: actions.CUSTOM_REPORT_CREATE_BEGIN,
    };
  },

  customReportCreateSuccess: data => {
    return {
      type: actions.CUSTOM_REPORT_CREATE_SUCCESS,
      data,
    };
  },

  customReportCreateError: error => {
    return {
      type: actions.CUSTOM_REPORT_CREATE_ERROR,
      error,
    };
  },

  customReportRemoveBegin: () => {
    return {
      type: actions.CUSTOM_REPORT_REMOVE_BEGIN,
    };
  },

  customReportRemoveSuccess: data => {
    return {
      type: actions.CUSTOM_REPORT_REMOVE_SUCCESS,
      data,
    };
  },

  customReportRemoveError: error => {
    return {
      type: actions.CUSTOM_REPORT_REMOVE_ERROR,
      error,
    };
  },

  customReportUpdateBegin: () => {
    return {
      type: actions.CUSTOM_REPORT_UPDATE_BEGIN,
    };
  },

  customReportUpdateSuccess: data => {
    return {
      type: actions.CUSTOM_REPORT_UPDATE_SUCCESS,
      data,
    };
  },

  customReportUpdateError: error => {
    return {
      type: actions.CUSTOM_REPORT_UPDATE_ERROR,
      error,
    };
  },

  customReportInfoFindAllBegin: () => {
    return {
      type: actions.CUSTOM_REPORT_INFO_FIND_ALL_BEGIN,
    };
  },

  customReportInfoFindAllSuccess: data => {
    return {
      type: actions.CUSTOM_REPORT_INFO_FIND_ALL_SUCCESS,
      data,
    };
  },

  customReportInfoFindAllError: error => {
    return {
      type: actions.CUSTOM_REPORT_INFO_FIND_ALL_ERROR,
      error,
    };
  },

  customReportInfoFilterBegin: () => {
    return {
      type: actions.CUSTOM_REPORT_INFO_FILTER_BEGIN,
    };
  },

  customReportInfoFilterSuccess: data => {
    return {
      type: actions.CUSTOM_REPORT_INFO_FILTER_SUCCESS,
      data,
    };
  },

  customReportInfoFilterError: error => {
    return {
      type: actions.CUSTOM_REPORT_INFO_FILTER_ERROR,
      error,
    };
  },
};

export default actions;
