import moment from 'moment';

import { BotScheduleType } from '../utils/enums/bot-schedule.enum';

export class BotScheduleHelper {
  static formatDate(scheduleType, date) {
    const { incoming, output } = new this().getDateFormatByScheduleType(scheduleType);

    return moment(date, incoming).format(output);
  }

  static formatDateBeforeSubmit(scheduleType, thismoment) {
    const { output } = new this().getDateFormatByScheduleType(scheduleType, true);

    return thismoment.format(output);
  }

  static generaMomentFromDate(scheduleType, dateString) {
    const { output: format } = new this().getDateFormatByScheduleType(scheduleType, true);

    return moment(dateString, format);
  }

  static getInputFormat(type) {
    switch (String(type).toUpperCase()) {
      case BotScheduleType.WEEKLY:
        return {
          picker: 'date',
          format: 'dddd HH:mm',
          placeholder: 'botSchedule.placeholders.weekly',
        };

      case BotScheduleType.MONTHLY:
        return {
          picker: 'date',
          format: 'Do HH:mm',
          placeholder: 'botSchedule.placeholders.monthly',
        };

      case BotScheduleType.DATE:
        return {
          picker: 'date',
          format: 'll HH:mm',
          placeholder: 'botSchedule.placeholders.date',
        };

      default:
        throw new Error('Invalid format, please check!!');
    }
  }

  /**
   * Get the format for the schedule input validation
   *
   * @param {*} type
   * @param {*} forForm boolean that indicates if the formatted value will be returned to use it in the form or for the index
   * @return {string} The format that will be used to create the moment instance
   * @memberof BotScheduleHelper
   */
  // eslint-disable-next-line class-methods-use-this
  getDateFormatByScheduleType(type, forForm = false) {
    switch (String(type).toUpperCase()) {
      case BotScheduleType.WEEKLY:
        return {
          incoming: 'd HH:mm', // 0-6
          output: !forForm ? 'dddd HH:mm' : 'd HH:mm',
        };

      case BotScheduleType.MONTHLY:
        return {
          incoming: 'D HH:mm', // 1 - 31,
          output: !forForm ? 'DD HH:mm' : 'D HH:mm',
        };

      default:
        return {
          incoming: 'YYYY-MM-DD HH:mm',
          output: !forForm ? 'lll' : 'YYYY-MM-DD HH:mm',
        };
    }
  }
}
