const actions = {
  WEB_NOTIFICATION_FIND_ALL_BEGIN: 'WEB_NOTIFICATION_FIND_ALL_BEGIN',
  WEB_NOTIFICATION_FIND_ALL_SUCCESS: 'WEB_NOTIFICATION_FIND_ALL_SUCCESS',
  WEB_NOTIFICATION_FIND_ALL_ERROR: 'WEB_NOTIFICATION_FIND_ALL_ERROR',

  WEB_NOTIFICATION_UPDATE_READED_SUCCESS: 'WEB_NOTIFICATION_UPDATE_READED_SUCCESS',
  WEB_NOTIFICATION_UPDATE_READED_ERROR: 'WEB_NOTIFICATION_UPDATE_READED_ERROR',
  WEB_NOTIFICATION_UPDATE_READED_BEGIN: 'WEB_NOTIFICATION_UPDATE_READED_BEGIN',

  WEB_NOTIFICATION_REMOVE_SUCCESS: 'WEB_NOTIFICATION_REMOVE_SUCCESS',
  WEB_NOTIFICATION_REMOVE_ERROR: 'WEB_NOTIFICATION_REMOVE_ERROR',
  WEB_NOTIFICATION_REMOVE_BEGIN: 'WEB_NOTIFICATION_REMOVE_BEGIN',

  WEB_NOTIFICATION_DELETE_ONE: 'WEB_NOTIFICATION_DELETE_ONE',
  WEB_NOTIFICATION_UPDATE: 'WEB_NOTIFICATION_UPDATE',

  webNotificationDeleteOne:data =>{
    return {
      type:actions.WEB_NOTIFICATION_DELETE_ONE,
      payload: data
    };
  },
  webNotificationUpdate: data => {
    return{
      type:actions.WEB_NOTIFICATION_UPDATE,
      payload: data
    };
  },
  webNotificationFindAllBegin: () => {
    return {
      type: actions.WEB_NOTIFICATION_FIND_ALL_BEGIN,
    };
  },

  webNotificationFindAllSuccess: data => {
    return {
      type: actions.WEB_NOTIFICATION_FIND_ALL_SUCCESS,
      data,
    };
  },

  webNotificationFindAllError: error => {
    return {
      type: actions.WEB_NOTIFICATION_FIND_ALL_ERROR,
      error,
    };
  },

  webNotificationUpdateReadedBegin: () => {
    return {
      type: actions.WEB_NOTIFICATION_UPDATE_READED_BEGIN,
    };
  },

  webNotificationUpdateReadedSuccess: data => {
    return {
      type: actions.WEB_NOTIFICATION_UPDATE_READED_SUCCESS,
      data,
    };
  },

  webNotificationUpdateReadedError: error => {
    return {
      type: actions.WEB_NOTIFICATION_UPDATE_READED_ERROR,
      error,
    };
  },

  webNotificationRemoveBegin: () => {
    return {
      type: actions.WEB_NOTIFICATION_REMOVE_BEGIN,
    };
  },

  webNotificationRemoveSuccess: data => {
    return {
      type: actions.WEB_NOTIFICATION_REMOVE_SUCCESS,
      data,
    };
  },

  webNotificationRemoveError: error => {
    return {
      type: actions.WEB_NOTIFICATION_REMOVE_ERROR,
      error,
    };
  },
};

export default actions;
