import React, { useMemo } from 'react';
import { Col, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ChartjsBarChartTransparent } from '../charts/chartjs';

const OrchestratorDashboardChart = ({ mounted, tableRedraw, recordsByState, orchestratorStates }) => {
  const { t } = useTranslation();

  const labels = useMemo(() => Object.values(orchestratorStates)?.map(state => state.label) || [], [
    orchestratorStates,
  ]);

  if (mounted === false || tableRedraw) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  if (labels.length === 0) {
    return '';
  }

  const data = labels.map(label => recordsByState?.[label] || 0);

  return (
    <Col xs={24} md={12}>
      <ChartjsBarChartTransparent
        labels={labels}
        datasets={[
          {
            data,
            label: t('orchestrator.dashboard.chartName'),
            barThickness: 12,
            barPercentage: 1,
            maxBarThickness: 10,
            backgroundColor: '#4bb3d850',
            hoverBackgroundColor: '#4bb3d8',
          },
        ]}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: true,
            labels: {
              display: true,
            },
          },
        }}
        height={180}
      />
    </Col>
  );
};

OrchestratorDashboardChart.propTypes = {
  mounted: PropTypes.bool.isRequired,
  tableRedraw: PropTypes.bool.isRequired,
  recordsByState: PropTypes.object.isRequired,
  orchestratorStates: PropTypes.array.isRequired,
};

export default OrchestratorDashboardChart;
