import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const OrchestratorFormData = () => {
  const { orchestrator } = useSelector(state => state.orchestrator);

  const [formValues, setFormValues] = useState({
    name: orchestrator?.name || '',
    alias: orchestrator?.alias || '',
    model: orchestrator?.model || '',
    enabled: orchestrator?.enabled || true,
    description: orchestrator?.description || [],
  });

  useEffect(() => {
    if (typeof orchestrator !== 'undefined') {
      setFormValues(orchestrator);
    }
  }, [orchestrator]);

  return [formValues, setFormValues];
};

export default OrchestratorFormData;
