const actions = {
  BOT_LICENSE_GET_ALL_BEGIN: 'BOT_LICENSE_GET_ALL_BEGIN',
  BOT_LICENSE_GET_ALL_SUCCESS: 'BOT_LICENSE_GET_ALL_SUCCESS',
  BOT_LICENSE_GET_ALL_ERROR: 'BOT_LICENSE_GET_ALL_ERROR',

  BOT_LICENSE_ASSIGN_BEGIN: 'BOT_LICENSE_ASSIGN_BEGIN',
  BOT_LICENSE_ASSIGN_SUCCESS: 'BOT_LICENSE_ASSIGN_SUCCESS',
  BOT_LICENSE_ASSIGN_ERROR: 'BOT_LICENSE_ASSIGN_ERROR',

  BOT_LICENSE_DELETE_BEGIN: 'BOT_LICENSE_DELETE_BEGIN',
  BOT_LICENSE_DELETE_SUCCESS: 'BOT_LICENSE_DELETE_SUCCESS',
  BOT_LICENSE_DELETE_ERROR: 'BOT_LICENSE_DELETE_ERROR',

  botLicenseGetAllBegin: () => {
    return {
      type: actions.BOT_LICENSE_GET_ALL_BEGIN,
    };
  },

  botLicenseGetAllSuccess: data => {
    return {
      type: actions.BOT_LICENSE_GET_ALL_SUCCESS,
      data,
    };
  },

  botLicenseGetAllError: error => {
    return {
      type: actions.BOT_LICENSE_GET_ALL_ERROR,
      error,
    };
  },

  botLicenseAssignBegin: () => {
    return {
      type: actions.BOT_LICENSE_ASSIGN_BEGIN,
    };
  },

  botLicenseAssignSuccess: data => {
    return {
      type: actions.BOT_LICENSE_ASSIGN_SUCCESS,
      data,
    };
  },

  botLicenseAssignError: error => {
    return {
      type: actions.BOT_LICENSE_ASSIGN_ERROR,
      error,
    };
  },

  botLicenseDeleteBegin: () => {
    return {
      type: actions.BOT_LICENSE_DELETE_BEGIN,
    };
  },

  botLicenseDeleteSuccess: data => {
    return {
      type: actions.BOT_LICENSE_DELETE_SUCCESS,
      data,
    };
  },

  botLicenseDeleteError: error => {
    return {
      type: actions.BOT_LICENSE_DELETE_ERROR,
      error,
    };
  },
};

export default actions;
