import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { Form, Row, Col, Button, Input, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import customReportActions from '../../../redux/customReport/actions';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { QUERY_CUSTOM_REPORT_CREATE } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const { customReportCreateBegin, customReportCreateSuccess, customReportCreateError } = customReportActions;

const CustomReportCreate = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(state => state.customReport.loading);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [form] = Form.useForm();
  const [customReportCreate] = useMutation(QUERY_CUSTOM_REPORT_CREATE);
  const { botId } = useParams();
  const [state, setState] = useState({
    form: {
      name: '',
      bot: botId,
    },
  });
  const alertSuccess = 'Success';
  const alertError = 'Error';

  const handleSubmit = async () => {
    dispatch(customReportCreateBegin());

    customReportCreate({
      variables: {
        input: {
          displayName: state.form.name,
          bot: state.form.bot,
        },
      },
    })
      .then(({ data }) => {
        if (data.customReportCreate.success) {
          dispatch(customReportCreateSuccess(data.customReportCreate.data));

          showAlertResult(t('transaction.customReport.successCreated'), alertSuccess);

          history.push(`/admin/bot/custom-report/${botId}`);
        } else {
          dispatch(customReportCreateError(data.customReportCreate.code));

          showAlertResult(t(`codeResponse.${data.customReportCreate.code}`), alertError);
        }
      })
      .catch(e => {
        console.log('Custom Report Create Error ', e);
        dispatch(customReportCreateError());

        showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
      });
  };

  const handleChange = e => {
    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  const showAlertResult = (msg, type) => {
    if (type === alertError) notificationError(msg);
    else notificationSuccess(msg);
  };

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1'),
    types: {
      email: t('type.email')
    }
  };
  /* eslint-enable */

  if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.CustomReportCreate)) {
    history.push(`/admin/bot/custom-report/${botId}`);
    notificationError(t(`codeResponse.403`));
    return null;
  }

  return (
    <>
      <PageHeader ghost title={t('transaction.customReport.create')} />
      <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={`/admin/`}>
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/admin/bot`}>
                <span>{t('labels.bussiness.rpa.bots.title')}</span>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to={`/admin/bot/custom-report/${botId}`}>
                <span>{t('transaction.customReport.management')}</span>
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="#">{t('transaction.customReport.create')} </NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbWrapperStyle>
        <Row gutter={15}>
          <Col xs={24}>
            <Cards>
              <Form
                align="center"
                name="customReport"
                form={form}
                validateMessages={validateMessages}
                onFinish={handleSubmit}
                layout="vertical"
                size="middle"
                scrollToFirstError
              >
                <Row align="center" gutter={30}>
                  {/* Name */}
                  <Col md={8} xs={24}>
                    <Form.Item name="name" rules={[{ required: true }]} label={t('properties.name.1')}>
                      <Input
                        name="name"
                        onChange={handleChange}
                        value={state.form.name}
                        placeholder={t('properties.name.1')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button style={{ height: '44px' }} htmlType="submit" type="primary">
                    {loading ? t('loading.1') : t('general.create')}
                  </Button>
                </Form.Item>
              </Form>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default CustomReportCreate;
