import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import NotFound from '../../utils/404';

import BotScheduleManagement from '../../pages/admin/bot-schedule';
import BotScheduleForm from '../../pages/admin/bot-schedule/BotScheduleForm';

const BotScheduleRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={BotScheduleManagement} />
      <Route exact path={`${path}/create`} component={BotScheduleForm} />
      <Route exact path={`${path}/:scheduleId/edit`} component={BotScheduleForm} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default BotScheduleRoute;
