import actions from './actions';

const { PASSWORD_RESET_BEGIN, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_ERR } = actions;

const initState = {
  data: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const passwordResetReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case PASSWORD_RESET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case PASSWORD_RESET_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default passwordResetReducer;
