import actions from './actions';

const {
  BOT_EXECUTION_FIND_ALL_BEGIN,
  BOT_EXECUTION_FIND_ALL_SUCCESS,
  BOT_EXECUTION_FIND_ALL_ERROR,
  BOT_EXECUTION_FILTER_BEGIN,
  BOT_EXECUTION_FILTER_SUCCESS,
  BOT_EXECUTION_FILTER_ERROR,
} = actions;

const initialState = {
  dataFiltered: [],
  data: [],
  loading: false,
  error: false,
};

const botExecutionReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case BOT_EXECUTION_FIND_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BOT_EXECUTION_FIND_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data ?? [],
        dataFiltered: data ?? [],
      };
    case BOT_EXECUTION_FIND_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case BOT_EXECUTION_FILTER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BOT_EXECUTION_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        dataFiltered: state.data.filter(
          x =>
            x.computerInfo.name.toLowerCase().startsWith(data.computerName.toLowerCase()) &&
            x.licenseSerial.toLowerCase().startsWith(data.serial.toLowerCase()),
        ),
      };
    case BOT_EXECUTION_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export default botExecutionReducer;
