import actions from './actions';

const {
  BOT_VAULT_GET_ALL_BEGIN,
  BOT_VAULT_GET_ALL_SUCCESS,
  BOT_VAULT_GET_ALL_ERROR,
  BOT_VAULT_CREATE_BEGIN,
  BOT_VAULT_CREATE_SUCCESS,
  BOT_VAULT_CREATE_ERROR,
  BOT_VAULT_UPDATE_BEGIN,
  BOT_VAULT_UPDATE_SUCCESS,
  BOT_VAULT_UPDATE_ERROR,
  BOT_VAULT_DELETE_BEGIN,
  BOT_VAULT_DELETE_SUCCESS,
  BOT_VAULT_DELETE_ERROR,
} = actions;

const initialState = {
  data: [],
  loading: false,
  error: false,
};

const botLicenseReducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case BOT_VAULT_GET_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BOT_VAULT_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data ?? [],
      };
    case BOT_VAULT_GET_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case BOT_VAULT_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BOT_VAULT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          variables: state.data.variables.filter(x => x.name !== data),
        },
      };
    case BOT_VAULT_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case BOT_VAULT_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BOT_VAULT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BOT_VAULT_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case BOT_VAULT_UPDATE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case BOT_VAULT_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case BOT_VAULT_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
};

export default botLicenseReducer;
