import update from 'immutability-helper';
import actions from './actions';

const {
  WEB_NOTIFICATION_FIND_ALL_BEGIN,
  WEB_NOTIFICATION_FIND_ALL_SUCCESS,
  WEB_NOTIFICATION_FIND_ALL_ERROR,
  WEB_NOTIFICATION_UPDATE_READED_BEGIN,
  WEB_NOTIFICATION_UPDATE_READED_SUCCESS,
  WEB_NOTIFICATION_UPDATE_READED_ERROR,
  WEB_NOTIFICATION_REMOVE_BEGIN,
  WEB_NOTIFICATION_REMOVE_SUCCESS,
  WEB_NOTIFICATION_REMOVE_ERROR,
  WEB_NOTIFICATION_DELETE_ONE,
  WEB_NOTIFICATION_UPDATE,
} = actions;

const initialState = {
  data: [],
  loading: false,
  error: false,
};

const webNotificationReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case WEB_NOTIFICATION_DELETE_ONE: {
      const retu = state?.data?.filter(({ _id }) => _id !== action.payload._id);
      return {
        ...state,
        data: retu
      }
    };

    case WEB_NOTIFICATION_UPDATE: {
      const datas = [...state.data, action.payload]

      return {
        ...state,
        data: datas
      }

    }

    case WEB_NOTIFICATION_FIND_ALL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case WEB_NOTIFICATION_FIND_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data,
      };
    case WEB_NOTIFICATION_FIND_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case WEB_NOTIFICATION_UPDATE_READED_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case WEB_NOTIFICATION_UPDATE_READED_SUCCESS:
      const index = state.data?.findIndex(x => x._id === data._id);
      return update(state, {
        data: {
          [index]: { $set: { ...state.data[index], readed: true } },
        },
        loading: { $set: false },
      });
    case WEB_NOTIFICATION_UPDATE_READED_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    case WEB_NOTIFICATION_REMOVE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case WEB_NOTIFICATION_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data?.filter(x => x._id !== data),
      };
    case WEB_NOTIFICATION_REMOVE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  };
};

export default webNotificationReducer;
