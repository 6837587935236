import { gql } from '@apollo/client';

export const QUERY_BOT_EXECUTION_FIND_ALL = gql`
  query botExecutionFindAll($input: TransactionFindAllInput!) {
    botExecutionFindAll(transactionFindAllInput: $input) {
      success
      code
      data {
        _id
        bot {
          _id
          name
        }
        session
        licenseSerial
        computerInfo {
          name
          domain
          os
          ram
          disk
          arquitecture
        }
        status
        createdLocalAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const QUERY_BOT_GOALS = gql`
  query botGoals($input: TransactionFindAllInput!) {
    botGoals(transactionFindAllInput: $input) {
      success
      code
      data {
        averageExecutions
        totalExecutions {
          total
          labels
          data
        }
        customReportsChart {
          total
          labels
          data
        }
        executionStateChart {
          started {
            labels
            data
            total
          }
          paused {
            labels
            data
            total
          }
          resumed {
            labels
            data
            total
          }
          ended {
            labels
            data
            total
          }
        }
        revenueChart {
          labels
          gained
          lost
        }
      }
    }
  }
`;
