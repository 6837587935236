import React from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CardBarChart } from '../../dashboard/style';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import Heading from '../../../../components/heading/heading';
import { ChartjsBarChartTransparent } from '../../../../components/charts/chartjs';

const ReveneuChart = () => {
  const { t } = useTranslation();
  const { revenueChart, isLoading } = useSelector(state => {
    return {
      revenueChart: state.botGoals.data.revenueChart,
      isLoading: state.botGoals.loading,
    };
  });

  const revenueDatasets = revenueChart !== null && [
    {
      data: revenueChart.gained || [],
      backgroundColor: '#5F63F280',
      hoverBackgroundColor: '#5F63F2',
      label: t('labels.bussiness.rpa.bots.goals.details.revenue.gained'),
      maxBarThickness: 10,
      barThickness: 12,
    },
    {
      data: revenueChart.lost || [],
      backgroundColor: '#FF4D4F80',
      hoverBackgroundColor: '#FF4D4F',
      label: t('labels.bussiness.rpa.bots.goals.details.revenue.lost'),
      maxBarThickness: 10,
      barThickness: 12,
    },
  ];

  return (
    <>
      {revenueChart !== null && (
        <Cards title={t('labels.bussiness.rpa.bots.goals.details.revenue.title')} size="large">
          {isLoading ? (
            <div className="sd-spin">
              <Spin />
            </div>
          ) : (
            <CardBarChart>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div className="card-bar-top">
                  <p>Total</p>
                  <Heading as="h3">
                    {/* {revenueChart.gained.reduce((a, b) => a + b, 0)} */}
                    {/* <sub> */}
                    {/*  <FeatherIcon icon="arrow-up" size={14} /> */}
                    {/*  {youtubeSubscribeState.percent}% */}
                    {/* </sub> */}
                  </Heading>
                </div>
                <ul>
                  {revenueDatasets &&
                    revenueDatasets.map((item, key) => {
                      return (
                        <li key={key + 1} className="custom-label">
                          <span
                            style={{
                              backgroundColor: item.hoverBackgroundColor,
                            }}
                          />
                          {item.label}
                        </li>
                      );
                    })}
                </ul>
              </div>

              <ChartjsBarChartTransparent
                labels={revenueChart.labels}
                datasets={revenueDatasets}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                  layout: {
                    padding: {
                      top: 20,
                    },
                  },
                  legend: {
                    display: false,
                    position: 'top',
                    align: 'end',
                    labels: {
                      boxWidth: 6,
                      display: true,
                      usePointStyle: true,
                    },
                  },

                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: '#e5e9f2',
                          borderDash: [3, 3],
                          zeroLineColor: '#e5e9f2',
                          zeroLineWidth: 1,
                          zeroLineBorderDash: [3, 3],
                        },
                        ticks: {
                          beginAtZero: true,
                          fontSize: 12,
                          fontColor: '#182b49',
                          max: revenueChart.gained ? Math.max(...revenueChart.gained) : 0,
                          stepSize: revenueChart.gained ? Math.max(...revenueChart.gained) / 5 : 0,
                          display: true,
                          min: 0,
                          padding: 10,
                        },
                      },
                    ],
                    xAxes: [
                      {
                        gridLines: {
                          display: true,
                          zeroLineWidth: 2,
                          zeroLineColor: '#fff',
                          color: 'transparent',
                          z: 1,
                        },
                        ticks: {
                          beginAtZero: true,
                          fontSize: 12,
                          fontColor: '#182b49',
                          min: 0,
                        },
                      },
                    ],
                  },
                }}
              />
            </CardBarChart>
          )}
        </Cards>
      )}
    </>
  );
};

export default ReveneuChart;
