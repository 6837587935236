import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useParams ,NavLink} from 'react-router-dom';
import { Row, Col, Spin, Form, Button, Select, Descriptions,Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';

import { BotScheduleInputByType } from './components';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';

import Alert from '../../../components/alerts/alerts';
import botScheduleActions from '../../../redux/botSchedule/actions';
import { BotScheduleHelper } from '../../../helpers/BotSchedule.helper';
import { BotScheduleType } from '../../../utils/enums/bot-schedule.enum';

import { useBotScheduleInput, useBotScheduleForm, useBotScheduleFormData } from '../../../hooks/bot-schedule';
import { QUERY_BOT_SCHEDULE_CREATE, QUERY_BOT_SCHEDULE_UPDATE } from '../../../queries';

import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const {
  botScheduleCreateBegin,
  botScheduleCreateSuccess,
  botScheduleCreateError,
  botScheduleUpdateBegin,
  botScheduleUpdateSuccess,
  botScheduleUpdateError,
  botScheduleResetServerInfo,
} = botScheduleActions;

const BotScheduleForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { botId, scheduleId } = useParams();
  const { loading: currentLoading, currentTenant } = useSelector(state => state.currentUserTenant);
  const currentUser = useSelector(state => state.currentUserTenant.currentUser);

  const [form] = Form.useForm();

  const [formAction, setFormAction] = useState('create');
  const [tenant] = useState(currentTenant?.tenant._id !== null ? currentTenant?.tenant._id : '');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedScheduleTypeIsDate, setSelectedScheduleTypeIsDate] = useState(false);

  const [formValues, setFormValues] = useBotScheduleFormData();

  const { type: scheduleType, action, repeat, licenses } = formValues;

  const redirectToIndex = () => history.push(`/admin/bot/${botId}/schedule`);

  // Graphql actions
  const [botScheduleCreate] = useMutation(QUERY_BOT_SCHEDULE_CREATE);
  const [botScheduleUpdate] = useMutation(QUERY_BOT_SCHEDULE_UPDATE);

  // Setup the schedule input and format the values according the necessities
  const { scheduleList, setScheduleList, handleScheduleButtonAction } = useBotScheduleInput();

  const updateForm = useCallback(taskToUpdate => {
    if (typeof taskToUpdate !== 'undefined') {
      setScheduleList(taskToUpdate.schedule);

      const formData = {
        _id: taskToUpdate._id,
        type: taskToUpdate.type,
        action: taskToUpdate.action,
        repeat: taskToUpdate.repeat,
        enabled: taskToUpdate.enabled,
        licenses: taskToUpdate.licenses,
      };

      setFormValues(formData);
      form.setFieldsValue(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch the server info data and setup the dropdown options according the schedule type
  const {
    mounted,
    serverInfo,
    dropdown: { generateScheduleTypes, generateScheduleActions, generateScheduleLicenses, generateScheduleFrequencies },
  } = useBotScheduleForm(currentTenant, currentLoading, scheduleType, updateForm, formAction === 'create');

  const handleChangeSelect = (e, field) => {
    form.setFieldsValue({
      [field]: e,
    });

    setFormValues({
      ...formValues,
      [field]: e,
    });

    if (field === 'type') {
      setScheduleList([]);

      if (e === BotScheduleType.DATE) {
        setSelectedScheduleTypeIsDate(true);
      } else {
        setSelectedScheduleTypeIsDate(false);
      }
    }
  };

  const alertSuccess = 'Success';
  const alertError = 'Error';

  const showAlertResult = (msg, type) => {
    if (type === alertError) {
      notificationError(msg);

      return;
    }

    notificationSuccess(msg);
  };

  // Set the form action to create or update according the scheduleId value
  useEffect(() => {
    if (typeof scheduleId !== 'undefined') {
      setFormAction('update');
    }
  }, [scheduleId]);

  // Clear the server schedule info from context on page leave
  useEffect(() => {
    return () => {
      dispatch(botScheduleResetServerInfo());
    };
  }, [dispatch]);

  /**
   * AutoSelect the once option on frequency dropdown when the schedule type is date
   * Or clear the selection when is not
   * */
  useEffect(() => {
    const option = selectedScheduleTypeIsDate ? 'once' : '';

    handleChangeSelect(option, 'repeat');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formAction, selectedScheduleTypeIsDate]);

  const scheduleInputIsInvalid = () => {
    const containsInvalidDate = scheduleList.filter(schedule => schedule.moment === null);

    if (!scheduleList.length || containsInvalidDate.length) {
      return true;
    }
  };

  const handleSubmit = async () => {
    if (scheduleInputIsInvalid()) {
      showAlertResult(t('botSchedule.scheduleIsRequired'), alertError);

      return;
    }

    setFormSubmitted(true);

    const payload = {
      type: scheduleType,
      action,
      repeat: repeat === 'indefinitely',
      schedule: scheduleList.map(schedule => BotScheduleHelper.formatDateBeforeSubmit(scheduleType, schedule.moment)),
      licenses,
      localDateTime: moment().format('YYYY-MM-DD HH:mm'),
      serverDateTime: serverInfo.serverDateTime,
      bot: botId,
      tenant,
      user: currentUser?._id,
    };

    if (formAction === 'create') {
      storeBotSchedule(payload);
    } else {
      updateBotSchedule({
        ...payload,
        _id: formValues._id,
      });
    }
  };

  const storeBotSchedule = payload => {
    dispatch(botScheduleCreateBegin());

    botScheduleCreate({
      variables: { botScheduleCreateInput: payload },
    })
      .then(({ data }) => {
        setFormSubmitted(false);

        const { data: botSchedule, success, code: responseCode } = data.botScheduleCreate;

        if (success) {
          dispatch(botScheduleCreateSuccess(botSchedule));
          showAlertResult(t('botSchedule.successCreated'), alertSuccess);

          return redirectToIndex();
        }

        dispatch(botScheduleCreateSuccess(responseCode));
        showAlertResult(t(`codeResponse.${responseCode}`), 'Error');
      })
      .catch(e => {
        console.error('BotSchedule Create Error ', e);

        setFormSubmitted(false);
        dispatch(botScheduleCreateError());
        showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
      });
  };

  const updateBotSchedule = payload => {
    dispatch(botScheduleUpdateBegin());

    botScheduleUpdate({
      variables: { botScheduleUpdateInput: payload },
    })
      .then(({ data }) => {
        setFormSubmitted(false);

        const { data: botSchedule, success, code: responseCode } = data.botScheduleUpdate;

        if (success) {
          dispatch(botScheduleUpdateSuccess(botSchedule));
          showAlertResult(t('botSchedule.successUpdated'), alertSuccess);

          return redirectToIndex();
        }

        dispatch(botScheduleUpdateError(responseCode));
        showAlertResult(t(`codeResponse.${responseCode}`), 'Error');
      })
      .catch(e => {
        console.error('BotSchedule Update Error ', e);

        setFormSubmitted(false);
        dispatch(botScheduleCreateError());
        showAlertResult(t('codeResponse.UNEXPECTED_ERROR'), alertError);
      });
  };

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1'),
    types: {
      email: t('type.email')
    }
  };
  /* eslint-enable */

  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;

  if (mounted) {
    return (
      <>
        <PageHeader ghost title={t(`botSchedule.${formAction}`)} />
        <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
                      <Breadcrumb>
                        <Breadcrumb.Item>
                          <NavLink to={`/admin/`}>
                            <HomeOutlined />
                          </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <NavLink to={`/admin/bot`}>
                            <span  style={{ "font-size": "14px" }}>{t('labels.bussiness.rpa.bots.title')}</span>
                          </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <NavLink to={`/admin/bot/${botId}/schedule`}>
                            <span>{t('botSchedule.management')}</span>
                          </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <NavLink to="#">{t(`botSchedule.${formAction}`)} </NavLink>
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </BreadcrumbWrapperStyle>
          <Row gutter={15}>
            <Col xs={24}>
              <Cards>
                <Row>
                  <Col xs={24} style={{ padding: 0 }}>
                    <Alert
                      type="info"
                      message={t('botSchedule.infoText')}
                      closable
                      showIcon
                      icon={<FeatherIcon icon="info" size={16} />}
                    />
                  </Col>
                </Row>

                <Descriptions bordered layout="vertical" column={{ xs: 24 }} size="small">
                  <Descriptions.Item label={t('botSchedule.fields.localDateTime')}>
                    {moment().format('YYYY-MM-DD HH:mm')}
                  </Descriptions.Item>

                  <Descriptions.Item label={t('botSchedule.fields.serverDateTime')}>
                    {serverInfo?.serverDateTime}
                  </Descriptions.Item>
                </Descriptions>

                <Form
                  name="botScheduleForm"
                  form={form}
                  size="middle"
                  style={{ marginTop: '3rem' }}
                  layout="vertical"
                  onFinish={handleSubmit}
                  autoComplete="off"
                  validateMessages={validateMessages}
                  scrollToFirstError
                >
                  <Row gutter={30}>
                    {/* BotSchedule Type */}
                    <Col md={12} xs={24}>
                      <Form.Item
                        name="type"
                        label={t('botSchedule.fields.type')}
                        rules={[{ required: true }]}
                        initialValue={scheduleType}
                      >
                        <Select
                          name="type"
                          value={scheduleType}
                          onChange={e => handleChangeSelect(e, 'type')}
                          className="sDash_fullwidth-select"
                          placeholder={t('botSchedule.fields.type')}
                        >
                          {generateScheduleTypes()}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* BotSchedule Frequency */}
                    <Col md={12} xs={24}>
                      <Form.Item
                        name="repeat"
                        label={t('botSchedule.fields.repeat')}
                        rules={[{ required: !selectedScheduleTypeIsDate }]}
                        initialValue={!selectedScheduleTypeIsDate ? repeat : 'once'}
                      >
                        <Select
                          name="repeat"
                          value={!selectedScheduleTypeIsDate ? repeat : 'once'}
                          disabled={selectedScheduleTypeIsDate}
                          onChange={e => handleChangeSelect(e, 'repeat')}
                          className="sDash_fullwidth-select"
                          placeholder={t('botSchedule.fields.repeat')}
                        >
                          {generateScheduleFrequencies()}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* BotSchedule Action */}
                    <Col md={12} xs={24}>
                      <Form.Item
                        name="action"
                        label={t('botSchedule.fields.action')}
                        rules={[{ required: true }]}
                        initialValue={action}
                      >
                        <Select
                          name="action"
                          onChange={e => handleChangeSelect(e, 'action')}
                          value={action}
                          placeholder={t('botSchedule.fields.action')}
                          className="sDash_fullwidth-select"
                        >
                          {generateScheduleActions()}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* BotSchedule Licenses */}
                    <Col md={12} xs={24}>
                      <Form.Item
                        name="licenses"
                        label={t('botSchedule.fields.licenses')}
                        rules={[{ required: true }]}
                        initialValue={formValues.licenses}
                      >
                        <Select
                          name="licenses"
                          onChange={e => handleChangeSelect(e, 'licenses')}
                          value={licenses}
                          placeholder={t('botSchedule.fields.licenses')}
                          className="sDash_fullwidth-select"
                          mode="multiple"
                          allowClear
                          autoClearSearchValue
                        >
                          {generateScheduleLicenses()}
                        </Select>
                      </Form.Item>
                    </Col>

                    {scheduleType !== '' && (
                      <BotScheduleInputByType
                        type={scheduleType}
                        scheduleList={scheduleList}
                        handleScheduleButtonAction={handleScheduleButtonAction}
                      />
                    )}
                  </Row>

                  {/* Action Buttons */}
                  <Row gutter={30} style={{ marginTop: '3rem' }}>
                    {/* Cancel Button */}       
                    {/* Submit Button */}
                    <Col md={12} xs={24}>
                      <Form.Item>
                        <Button
                          key="2"
                          type="primary"
                          style={{ height: '44px' }}
                          loading={formSubmitted}
                          htmlType="submit"
                          className="pull-right"
                        >
                          {formSubmitted ? t('loading.1') : t(`general.${formAction}`)}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default BotScheduleForm;
