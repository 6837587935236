import React, { useEffect, useState, useCallback } from 'react';
import { Button, Spin, Table, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useHistory, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Main, TableWrapper } from '../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError } from '../../../components/utilities/notification';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { QUERY_BOT_CV, QUERY_BOT_FIND_ALL } from '../../../queries';
import botActions from '../../../redux/bot/actions';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';

const { botGetAllBegin, botGetAllSuccess, botGetAllError } = botActions;

const BotGoalsReport = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingBots = useSelector(state => state.bot.loading);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const [botDataState, setBotDataState] = useState();
  const [botList, setBotList] = useState({
    list: [],
  });
  const [mounted, setMounted] = useState(false);
  const { data: tenantBots, loading, error } = useQuery(QUERY_BOT_FIND_ALL, {
    variables: { tenantId: currentTenant.tenant._id },
    fetchPolicy: 'network-only',
  });
  const [getBotCv, { data: lazyDataCV }] = useLazyQuery(QUERY_BOT_CV, {
    fetchPolicy: 'network-only',
  });

  const goToBotGoals = useCallback(
    botId => {
      console.log('botId', botId);
      history.push(`/admin/report/bot-goals-report/${botId}`);
    },
    [history],
  );

  const columns = [
    {
      title: t('labels.bussiness.rpa.bots.goals.table.cvName'),
      dataIndex: 'cvName',
      key: 'cvName',
    },
    {
      title: t('labels.bussiness.rpa.bots.goals.table.botName'),
      dataIndex: 'botName',
      key: 'botName',
    },
    {
      title: t('labels.bussiness.rpa.bots.goals.table.actions'),
      key: 'action',
      render: (text, record) => (
        <div className="table-actions">
          <Button className="btn-icon" type="primary" onClick={() => goToBotGoals(record.id)}>
            {t('labels.bussiness.rpa.bots.goals.table.btnView')}
          </Button>
        </div>
      ),
    },
  ];

  const updateTable = useCallback(botsData => {
    if (botsData)
      botsData.forEach(async bot => {
        setBotDataState({
          botName: bot.name,
          botId: bot._id,
        });
        getBotCv({ variables: { id: bot._id } });
        return true;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentLoading && !currentTenant?.userPermissions?.includes(UserPermissions.BotCVFindOne)) {
      history.push('/admin/bot');
      notificationError(t(`codeResponse.403`));
    }

    if (!loading && !mounted && tenantBots) {
      dispatch(botGetAllBegin());
      if (tenantBots.botFindAll.success) {
        dispatch(botGetAllSuccess(tenantBots.botFindAll.data));
        updateTable(tenantBots.botFindAll.data);
      } else {
        dispatch(botGetAllError(tenantBots.botFindAll.code));
      }
      setMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, tenantBots, loading, error, updateTable, currentLoading, t, mounted]);

  useEffect(() => {
    if (lazyDataCV?.botCv.success) {
      const tempCVData = {
        botName: botDataState.botName,
        cvName: lazyDataCV?.botCv.data.alias,
        id: botDataState.botId,
      };
      setBotList(botListState => ({ list: [...botListState.list, tempCVData] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyDataCV]);
  if (!loadingBots && mounted) {
    return (
      <>
        <PageHeader ghost title={t('labels.bussiness.rpa.bots.goals.title')} />
        <Main darkMode={darkMode}>
          <BreadcrumbWrapperStyle darkMode={darkMode}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to={`/admin/`}>
                  <HomeOutlined />
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="#">{`Goals`}</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </BreadcrumbWrapperStyle>
          <Cards>
            <TableWrapper className="table-responsive">
              <Table dataSource={botList.list} columns={columns} pagination />
            </TableWrapper>
          </Cards>
        </Main>
      </>
    );
  }

  return (
    <div className="spin">
      <Spin />
    </div>
  );
};

export default BotGoalsReport;
