import { combineReducers } from 'redux';
import auth from './authentication/reducer';
import botLicense from './botLicense/reducer';
import customReport from './customReport/reducer';
import workFlow from './workFlow/reducer';
import botExecution from './botExecution/reducer';
import user from './user/reducer';
import file from './file/reducer';
import tenant from './tenant/reducer';
import bot from './bot/reducer';
import license from './license/reducer';
import webNotification from './webNotification/reducer';
import mailNotificationReducer from './mailNotification/reducer';
import changeLayoutMode from './themeLayout/reducer';
import headerSearchData from './headerSearch/reducer';
import chartContent from './chartContent/reducer';
import forgotPassword from './forgotPassword/reducer';
import passwordReset from './passwordReset/reducer';
import currentUserTenant from './currentUserTenant/reducer';
import botVault from './botVault/reducer';
import botSchedule from './botSchedule/reducer';
import orchestrator from './orchestrator/reducer';
import orchestratorState from './orchestrator-states/reducer';
import botGoals from './botGoals/reducer';
import apiKey from './apiKey/reducer';

const rootReducers = combineReducers({
  bot,
  auth,
  user,
  file,
  tenant,
  license,
  webNotification,
  mailNotificationReducer,
  botVault,
  botLicense,
  botSchedule,
  orchestrator,
  customReport,
  workFlow,
  chartContent,
  botExecution,
  botGoals,
  passwordReset,
  forgotPassword,
  headerSearchData,
  changeLayoutMode,
  currentUserTenant,
  orchestratorState,
  apiKey,
});

export default rootReducers;
