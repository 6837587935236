import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams ,NavLink} from 'react-router-dom';
import { Form, Row, Col, Input, Spin, Switch, Select,Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import botActions from '../../../redux/bot/actions';
import fileActions from '../../../redux/file/actions';
import { QUERY_BOT_FIND_ONE, QUERY_BOT_UPDATE, QUERY_FILE_FIND_ALL } from '../../../queries';
import { UserPermissions } from '../../../utils/enums/permissions.enum';
import { notificationError, notificationSuccess } from '../../../components/utilities/notification';
import { Button } from '../../../components/buttons/buttons';
import { BreadcrumbWrapperStyle } from '../../../components/ui-elements/ui-elements-styled';
import { HomeOutlined } from '@ant-design/icons';
const {
  botGetOneBegin,
  botGetOneSuccess,
  botGetOneError,
  botUpdateBegin,
  botUpdateSuccess,
  botUpdateError,
} = botActions;
const { fileFindAllBegin, fileFindAllSuccess, fileFindAllError } = fileActions;

const BotEdit = () => {
  const ChangeLayoutMode = useSelector(state => state.changeLayoutMode.data);
  const darkMode = ChangeLayoutMode;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loadingBots = useSelector(state => state.bot.loading);
  const bot = useSelector(state => state.bot.bot);
  const currentLoading = useSelector(state => state.currentUserTenant.loading);
  const currentTenant = useSelector(state => state.currentUserTenant.currentTenant);
  const files = useSelector(state => state.file.files);
  const [form] = Form.useForm();
  const [botUpdate] = useMutation(QUERY_BOT_UPDATE);
  const { TextArea } = Input;
  const [state, setState] = useState({
    form: {
      name: '',
      version: '',
      tenant: currentTenant?.tenant._id !== null ? currentTenant?.tenant._id : '',
      file: '',
      description: '',
      group: '',
      enabled: true,
    },
  });

  const { botId } = useParams();
  const { Option } = Select;
  const optionsFiles = [];

  // Get Bot
  const { data, loading, error } = useQuery(QUERY_BOT_FIND_ONE, {
    variables: { id: botId || '' },
    fetchPolicy: 'network-only',
  });

  // Get Files to select
  const { loading: loadingFiles, data: dataFiles, error: errorFiles } = useQuery(QUERY_FILE_FIND_ALL, {
    variables: {
      fileListInput: { isPlataformaFile: false, tenant: currentTenant?.tenant._id },
    },
    fetchPolicy: 'network-only',
  });

  const botNotFound = useCallback(
    codeError => {
      notificationError(t(`codeResponse.${codeError}`));
      history.push(`/admin/bot/`);
    },
    [history, t],
  );

  const updateForm = useCallback(
    botFound => {
      form.setFieldsValue({
        name: botFound.name,
        version: botFound.version,
        description: botFound.description,
        tenant: botFound.tenant._id,
        file: botFound.file._id,
        enabled: botFound.enabled,
        group: botFound.group,
      });

      setState(x => ({
        form: {
          ...x.form,
          name: botFound.name,
          version: botFound.version,
          description: botFound.description,
          tenant: botFound.tenant._id,
          file: botFound.file._id,
          enabled: botFound.enabled,
          group: botFound.group,
        },
      }));
    },
    [form],
  );

  useEffect(() => {
    if (
      !currentLoading &&
      (!currentTenant?.userPermissions?.includes(UserPermissions.BotFindOne) ||
        !currentTenant?.userPermissions?.includes(UserPermissions.BotUpdate))
    ) {
      history.push('/admin/bot');
      notificationError(t(`codeResponse.403`));
    }

    dispatch(botGetOneBegin());
    dispatch(fileFindAllBegin());

    if (!loadingFiles && dataFiles) {
      if (dataFiles.fileFindAll.success) {
        dispatch(fileFindAllSuccess(dataFiles.fileFindAll.data));
      } else {
        dispatch(fileFindAllError(dataFiles.fileFindAll.code));
        history.push('/admin/bot');
        notificationError(t(`codeResponse.${dataFiles.fileFindAll.code}`));
      }
    }

    if (!loading && !loadingFiles && data) {
      if (data.botFindOne.success) {
        dispatch(botGetOneSuccess(data.botFindOne.data));
        updateForm(data.botFindOne.data);
      } else {
        dispatch(botGetOneError(error));
        botNotFound(data.botFindOne.code);
      }
    }
  }, [
    dispatch,
    data,
    loading,
    error,
    currentTenant,
    currentLoading,
    history,
    t,
    updateForm,
    botNotFound,
    loadingFiles,
    dataFiles,
    errorFiles,
  ]);

  const handleSubmit = async () => {
    dispatch(botUpdateBegin());

    botUpdate({
      variables: {
        botUpdateInput: {
          _id: bot._id,
          name: state.form.name,
          description: state.form.description,
          version: state.form.version,
          tenant: state.form.tenant,
          file: state.form.file,
          enabled: state.form.enabled,
          group: state.form.group,
        },
      },
    })
      .then(({ data: botUpdateData }) => {
        if (botUpdateData.botUpdate.success === true) {
          dispatch(botUpdateSuccess(botUpdateData.botUpdate.data));
          notificationSuccess(t('labels.bussiness.rpa.bots.table.success_edited'));
          history.push(`/admin/bot/`);
        } else {
          dispatch(botUpdateError(botUpdateData.botUpdate.code));
          notificationError(t(`codeResponse.${botUpdateData.botUpdate.code}`));
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('Bot Edit Error ', e);
        dispatch(botUpdateError());
        notificationError(t('codeResponse.UNEXPECTED_ERROR'));
      });
  };

  const handleStatusSwitch = e => {
    setState({
      form: {
        ...state.form,
        enabled: e,
      },
    });
  };

  const handleChangeSelect = (e, field) => {
    form.setFieldsValue({
      [field]: e,
    });

    setState({
      form: {
        ...state.form,
        [field]: e,
      },
    });
  };

  const handleChange = e => {
    // Validation For Textarea
    if (e.target.props !== undefined && e.target.props.name === 'description') {
      e.target.name = 'description';
    }

    setState({
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  /* eslint-disable */
  const validateMessages = {
    required: t('emptyField.1'),
    types: {
      email: t('type.email')
    }
  };
  /* eslint-enable */

  const buildSelectOptions = async () => {
    await Promise.all(
      files.map(file => {
        const { _id, name } = file;
        optionsFiles.push(
          <Option key={_id} value={_id}>
            {name}
          </Option>,
        );
        return true;
      }),
    );
  };

  if (loadingBots) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  if (!loading && files.length !== undefined) {
    buildSelectOptions();

    return (
      <>
        <PageHeader ghost title={t('labels.bussiness.rpa.bots.table.edit')} />
        <Main darkMode={darkMode}>
        <BreadcrumbWrapperStyle darkMode={darkMode}>
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <NavLink to={`/admin/`}>
                          <HomeOutlined />
                        </NavLink>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <NavLink to={`/admin/bot`}>
                          <span>{t('labels.bussiness.rpa.bots.title')}</span>
                        </NavLink>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <NavLink to="#">{t('labels.bussiness.rpa.bots.table.edit')}</NavLink>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </BreadcrumbWrapperStyle>
          <Row gutter={15}>
            <Col xs={24}>
              <Cards>
                <Form
                  name="editBot"
                  form={form}
                  validateMessages={validateMessages}
                  onFinish={handleSubmit}
                  layout="vertical"
                  size="middle"
                  scrollToFirstError
                >
                  <Row gutter={30}>
                    {/* Name */}
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="name"
                        rules={[{ required: true }]}
                        label={t('labels.bussiness.rpa.bots.table.name')}
                      >
                        <Input
                          name="name"
                          onChange={handleChange}
                          value={state.form.name}
                          placeholder={t('labels.bussiness.rpa.bots.table.name')}
                        />
                      </Form.Item>
                    </Col>

                    {/* Version */}
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="version"
                        rules={[{ required: true }]}
                        label={t('labels.bussiness.rpa.bots.table.version')}
                      >
                        <Input
                          name="version"
                          onChange={handleChange}
                          value={state.form.version}
                          placeholder={t('labels.bussiness.rpa.bots.table.version')}
                        />
                      </Form.Item>
                    </Col>

                    {/* File Select */}
                    {state.form.file !== '' ? (
                      <Col md={8} xs={24}>
                        <Form.Item
                          name="file"
                          label={t('labels.bussiness.rpa.bots.table.file')}
                          rules={[
                            () => ({
                              validator() {
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Select
                            showSearch
                            name="file"
                            onChange={e => handleChangeSelect(e, 'file')}
                            placeholder={t('labels.bussiness.rpa.bots.table.select_file')}
                            size="middle"
                            className="sDash_fullwidth-select"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {optionsFiles}
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : null}

                    {/* Description */}
                    <Col xs={18}>
                      <Form.Item
                        name="description"
                        rules={[{}]}
                        label={t('labels.bussiness.rpa.bots.table.description')}
                      >
                        {/* <Input name="description" onChange={handleChange} value={state.form.description} placeholder={t('labels.bussiness.rpa.bots.table.description')} /> */}
                        <TextArea
                          id="description"
                          name="description"
                          onChange={handleChange}
                          value={state.form.description}
                          placeholder={t('labels.bussiness.rpa.bots.table.description')}
                          className="sDash_unresizable"
                        />
                      </Form.Item>
                    </Col>

                    {/* Enabled */}
                    <Col xs={6}>
                      <Form.Item name="enabled" label={t('general.status')}>
                        <Switch
                          checked={!!state.form.enabled}
                          onChange={handleStatusSwitch}
                          checkedChildren={t('general.active')}
                          unCheckedChildren={t('general.inactive')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button style={{ height: '44px' }} htmlType="submit" type="primary">
                      {loadingBots ? t('loading.1') : t('general.update')}
                    </Button>
                  </Form.Item>
                </Form>
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  return null;
};

export default BotEdit;
