import actions from './actions';

const { TENANT_FIND_ONE_BEGIN, TENANT_FIND_ONE_SUCCESS, TENANT_FIND_ONE_ERROR } = actions;

const initialState = {
  data: {},
  loading: false,
  error: false,
};

const tenantReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case TENANT_FIND_ONE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TENANT_FIND_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        data,
      };
    case TENANT_FIND_ONE_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export default tenantReducer;
