import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';
import AuthLayout from '../layout/auth/AuthLayout';
import SignIn from '../pages/auth/SignIn';
import NotFound from '../utils/404';
import ForgotPass from '../pages/auth/ForgotPassword';
import PassReset from '../pages/auth/PasswordReset';

const FrontendRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path="/forgot/password" component={ForgotPass} />
        <Route exact path="/reset/password/:token" component={PassReset} />
        <Route exact path="/" component={SignIn} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default AuthLayout(FrontendRoutes);
