export const BotScheduleType = {
  NONE: 'NONE',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  DATE: 'DATE',
};

export const BotScheduleAction = {
  NONE: 'NONE',
  START: 'START',
  STOP: 'STOP',
  PAUSE: 'PAUSE',
  RESUME: 'RESUME',
  TAKE_SCREENSHOT: 'TAKE_SCREENSHOT',
};
