import update from 'immutability-helper';
import actions from './actions';

const {
  CUSTOM_REPORT_FIND_ALL_BEGIN,
  CUSTOM_REPORT_FIND_ALL_SUCCESS,
  CUSTOM_REPORT_FIND_ALL_ERROR,
  CUSTOM_REPORT_FIND_ONE_BEGIN,
  CUSTOM_REPORT_FIND_ONE_SUCCESS,
  CUSTOM_REPORT_FIND_ONE_ERROR,
  CUSTOM_REPORT_CREATE_BEGIN,
  CUSTOM_REPORT_CREATE_SUCCESS,
  CUSTOM_REPORT_CREATE_ERROR,
  CUSTOM_REPORT_REMOVE_BEGIN,
  CUSTOM_REPORT_REMOVE_SUCCESS,
  CUSTOM_REPORT_REMOVE_ERROR,
  CUSTOM_REPORT_UPDATE_BEGIN,
  CUSTOM_REPORT_UPDATE_SUCCESS,
  CUSTOM_REPORT_UPDATE_ERROR,
  CUSTOM_REPORT_INFO_FIND_ALL_BEGIN,
  CUSTOM_REPORT_INFO_FIND_ALL_SUCCESS,
  CUSTOM_REPORT_INFO_FIND_ALL_ERROR,
  CUSTOM_REPORT_INFO_FILTER_BEGIN,
  CUSTOM_REPORT_INFO_FILTER_SUCCESS,
  CUSTOM_REPORT_INFO_FILTER_ERROR,
} = actions;

const initialState = {
  customReportsInfoFiltered: [],
  customReportsInfo: [],
  customReports: [],
  customReport: {},
  loading: false,
  error: false,
};

const customReportReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case CUSTOM_REPORT_FIND_ALL_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case CUSTOM_REPORT_FIND_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        customReports: data ?? [],
      };
    }
    case CUSTOM_REPORT_FIND_ALL_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case CUSTOM_REPORT_FIND_ONE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case CUSTOM_REPORT_FIND_ONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        customReport: data,
      };
    }
    case CUSTOM_REPORT_FIND_ONE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case CUSTOM_REPORT_CREATE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case CUSTOM_REPORT_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        customReports: [...state.customReports, data],
      };
    }
    case CUSTOM_REPORT_CREATE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case CUSTOM_REPORT_REMOVE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case CUSTOM_REPORT_REMOVE_SUCCESS: {
      return {
        ...state,
        loading: false,
        customReports: state.customReports.filter(x => x._id !== data),
      };
    }
    case CUSTOM_REPORT_REMOVE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case CUSTOM_REPORT_UPDATE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case CUSTOM_REPORT_UPDATE_SUCCESS: {
      const index = state.customReports.findIndex(x => x._id === data._id);
      return update(state, {
        customReports: {
          [index]: { $set: data },
        },
        loading: { $set: false },
      });
    }
    case CUSTOM_REPORT_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case CUSTOM_REPORT_INFO_FIND_ALL_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case CUSTOM_REPORT_INFO_FIND_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        customReportsInfo: data ?? [],
        customReportsInfoFiltered: data ?? [],
      };
    }
    case CUSTOM_REPORT_INFO_FIND_ALL_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case CUSTOM_REPORT_INFO_FILTER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CUSTOM_REPORT_INFO_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        customReportsInfoFiltered: state.customReportsInfo.filter(
          x =>
            x.computerInfo.name.toLowerCase().startsWith(data.computerName.toLowerCase()) &&
            x.licenseSerial.toLowerCase().startsWith(data.serial.toLowerCase()),
        ),
      };
    case CUSTOM_REPORT_INFO_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export default customReportReducer;
