import update from 'immutability-helper';
import actions from './actions';

const {
  BOT_GET_ALL_BEGIN,
  BOT_GET_ALL_SUCCESS,
  BOT_GET_ALL_ERROR,
  BOT_GET_ONE_BEGIN,
  BOT_GET_ONE_SUCCESS,
  BOT_GET_ONE_ERROR,
  BOT_CREATE_BEGIN,
  BOT_CREATE_SUCCESS,
  BOT_CREATE_ERROR,
  BOT_REMOVE_BEGIN,
  BOT_REMOVE_SUCCESS,
  BOT_REMOVE_ERROR,
  BOT_UPDATE_BEGIN,
  BOT_UPDATE_SUCCESS,
  BOT_UPDATE_ERROR,
} = actions;

const initialState = {
  bots: [],
  bot: {},
  loading: false,
  error: false,
};

const botReducer = (state = initialState, action) => {
  const { type, data, error } = action;

  switch (type) {
    case BOT_GET_ALL_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case BOT_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        bots: data ?? [],
      };
    }
    case BOT_GET_ALL_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case BOT_GET_ONE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case BOT_GET_ONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        bot: data,
      };
    }
    case BOT_GET_ONE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case BOT_CREATE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case BOT_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        bots: [...state.bots, data],
      };
    }
    case BOT_CREATE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case BOT_REMOVE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case BOT_REMOVE_SUCCESS: {
      return {
        ...state,
        loading: false,
        bots: state.bots.filter(x => x._id !== data),
      };
    }
    case BOT_REMOVE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case BOT_UPDATE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case BOT_UPDATE_SUCCESS: {
      const index = state.bots.findIndex(x => x._id === data._id);
      return update(state, {
        bots: {
          [index]: { $set: data },
        },
        loading: { $set: false },
      });
    }
    case BOT_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
};

export default botReducer;
