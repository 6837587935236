import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import BotManagement from '../../pages/admin/bot';
import BotCreate from '../../pages/admin/bot/BotCreate';
import BotEdit from '../../pages/admin/bot/BotEdit';
import BotCv from '../../pages/admin/bot/cv';
import NotFound from '../../utils/404';

const BotsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/edit/:botId`} component={BotEdit} />
      <Route path={`${path}/cv/:botId`} component={BotCv} />
      <Route exact path={`${path}/create`} component={BotCreate} />
      <Route exact path={`${path}`} component={BotManagement} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default BotsRoute;
