import React from 'react';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Popover } from '../../popup/popup';
import { DateRangePickerOne } from '../../datePicker/datePicker';
import { Button } from '../buttons';

const CalendarButtonPageHeader = ({ onChange }) => {
  const { t } = useTranslation();

  const content = (
    <>
      <DateRangePickerOne onChange={onChange} />
    </>
  );

  return (
    <Popover placement="bottomRight" content={content} action="hover">
      <Button size="small" type="white">
        <FeatherIcon icon="calendar" size={14} />
        {t('general.calendar')}
      </Button>
    </Popover>
  );
};

CalendarButtonPageHeader.propTypes = {
  onChange: PropTypes.func,
};

export { CalendarButtonPageHeader };
