import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import NotFound from '../../utils/404';

import BotVaultManagement from '../../pages/admin/bot-vault';

const BotVaultRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={BotVaultManagement} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default BotVaultRoute;
